import {runIfHasSelecter, formatFloatNumber, showInfoMessageInput, getKeyByValue} from "../../utils/panel-lib";
import {api} from "../../utils/panel-api";
import _ from "lodash";
// import { forEach } from "lodash";

const initCommon = function (currentData) {
    let availableSizes = currentData.availableSizes;
    let arrPlanSize = currentData.availableSizesForSlider;
    let arrPlanHits = currentData.sizesHitsForSlider;
    let $dropdownMenuPrices = $('.plan-options');
    let $rangePlanSize = $('#range_plan_size');
    let $inputPlanSize = $('[name="plan_size"]');
    let $inputPlanType = $('[name="plan_type"]');
    let toltipPlanSize;

    arrPlanSize = availableSizes;

    if (currentData.vatRateIsNotEmpty) {
        $('#cart_to_pay_without_vat').hide();
        $('#cart_to_pay_with_vat').show();
    } else {
        $('#cart_to_pay_without_vat').show();
        $('#cart_to_pay_with_vat').hide();
    }

    function refreshButtons(discount = 0) {
        let planCode = currentData.planCodeToSwitch;
        let coupon_code = $('#coupon_code').val();

        $('.paycardbutton').hide();
        $('.bluesnap').hide();

        $('.checkbox').hide();
        $('#checkbox-' + planCode).show();

        if (planCode === 'nano' || planCode === 'demo') {
            $('.coupon-block').hide();
            coupon_code = '';
        } else {
            $('.coupon-block').show();
        }

        _.map(currentData.planOptions, function (planOption) {
            if (planCode === planOption.code) {
                let planPrice = planOption.price;
                let planPriceWithDiscount = parseFloat(planPrice - ((discount / 100) * planPrice));

                if (discount === 0) {
                    $('#discount-line').hide();
                } else {
                    $('#discount-line').show();
                }
                $('#cart_discount_value').html(discount === 0 ? '' : ('-' + Math.round(discount) + '%'));

                $("#cart_credit_name").html(planOption.short_name);
                $("#cart_quantity").html(planOption.quantity);
                $("#cart_price").html(formatFloatNumber(planPrice));
                if (currentData.action && parseInt(planOption.promo_quantity) > 0 && planOption.code.indexOf('lifetime') === -1) {
                    $("#cart_price_name").html(planOption.name + '&nbsp;<span class="color-red"><b> +' + (parseInt(planOption.promo_quantity) - parseInt(planOption.quantity)) + ' FREE</b></span>');
                } else {
                    $("#cart_price_name").html(planOption.name);
                }
                $("#cart_price_per_credit").html(formatFloatNumber(planPrice / planOption.quantity));
                $("#cart_vat_value").html(formatFloatNumber(planPrice * currentData.vatRate));
                $("#cart_to_pay_with_vat").html(formatFloatNumber(planPriceWithDiscount * (1 + currentData.vatRate)));
                $("#cart_to_pay_without_vat").html(formatFloatNumber(planPriceWithDiscount));
                $('#proforma-link').attr('href', '/billing/invoice?plan=' + planOption.id + '&currency=' + $('#bank-transfer-currency').find(":selected").val());

                if (planPrice > 2000) {
                    $('#nax-tab-cards').hide();
                    $('#nax-tab-others').hide();

                    if ($("#tab_cards").hasClass("active") || $("#tab_others").hasClass("active")) {
                        $('[href="#tab_crypto"]').tab('show');
                    }
                } else {
                    $('#nax-tab-cards').show();
                    $('#nax-tab-others').show();
                }

                let $couponDescription = $('#coupon_description');
                if (planOption.code.indexOf('lifetime') !== -1) {
                    coupon_code = '';
                    $('#coupon_code').val('').prop('disabled', true);
                    $couponDescription.html('No discount for Lifetime credits').show();
                } else {
                    $('#coupon_code').prop('disabled', false);
                    if ($couponDescription.text() === 'No discount for Lifetime credits')
                    {
                        $couponDescription.html('');
                    }
                }

                if (planOption.paymentwall_enabled === 1) {
                    $('.paycardbutton').show();
                }

                if (planOption.bluesnap_link !== '') {
                    $('.bluesnap').show();
                }

                if (currentData.cashbackBalance >= planPriceWithDiscount) {
                    $('.pay-with-cashback-info').hide();
                    $('.pay-with-cashback-button').show();
                } else {
                    $('.pay-with-cashback-info').show();
                    $('.pay-with-cashback-button').hide();
                }
            }

            $('[data-pay-by]').each(function () {
                let $this = $(this);
                let system = $this.data('payBy');
                let href = '/billing/do-pay?system=' + system + '&size=' + planCode;

                if (coupon_code !== '' && discount > 0) {
                    href += '&coupon_code=' + coupon_code;
                }

                $this.attr('href', href);
            });

            if (coupon_code !== '' && discount > 0) {
                let $proformaLinkEl = $('#proforma-link');
                $proformaLinkEl.attr('href', $proformaLinkEl.attr('href') + '&coupon_code=' + coupon_code);
            }
        });

        // var isMobile = "";
        // if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        //     || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4)))
        //     isMobile = "&mobile=true";
    }

    function updateDropdownPrices(discount = 0) {
        let dropdownHtml = '';
        let iconCheckedCircle = '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="8" fill="#1C7EE0"/><path d="M6.62579 12L4 9.2027L5.19662 7.92793L6.62579 9.45496L10.8034 5L12 6.27477L6.62579 12Z" fill="white"/></svg>';
        let iconNoCheckedCircle = '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" r="7.5" stroke="#8897AE"/></svg>';

        for (var i = 0; i < currentData.planOptions.length; i++) {
            let planOption = currentData.planOptions[i];

            if ((currentData.creditType === 'economy' && planOption.alexa_bar_enabled === 1)
                || (currentData.creditType === 'professional' && planOption.alexa_bar_enabled === 0)) {
                continue;
            }

            dropdownHtml += '<input class="form-check-input' + (planOption.code.indexOf('lifetime') !== -1 ? ' lifetime' : '') + '" type="radio" name="packages_radios" id="packages_radios" style="display:none;" ' +
                'data-price="' + planOption.price + '" ' +
                'data-original-price="' + planOption.price + '" ' +
                'data-product-name="' + planOption.name + '" ' +
                'value="' + planOption.code + '">';

            dropdownHtml += '<button class="plan-options-item' + (planOption.code.indexOf('lifetime') !== -1 ? ' lifetime' : '') + (planOption.code === currentData.planCodeToSwitch ? ' checked' : '') + '" type="button" ' +
                'data-type="' + currentData.creditType + '" ' +
                'data-plan-code="' + planOption.code + '" ' +
                'data-plan-name="' + planOption.name + '" ' +
                'data-plan-quantity="' + planOption.quantity + '" ' +
                'id="' + planOption.code + '">';
            dropdownHtml += '  <div class="row g-0 justify-content-between">';
            dropdownHtml += '    <div class="col-auto">';
            dropdownHtml += '      <div class="row g-0">';
            dropdownHtml += '        <div class="col-auto">';
            dropdownHtml += '          <div class="plan-options-item__icon">';
            if (planOption.code === currentData.planCodeToSwitch) {
                dropdownHtml += iconCheckedCircle;
            } else {
                dropdownHtml += iconNoCheckedCircle;
            }
            dropdownHtml += '          </div>';
            dropdownHtml += '        </div>';
            dropdownHtml += '        <div class="col-auto">';

            if (currentData.action && parseInt(planOption.promo_quantity) > 0 && planOption.code.indexOf('lifetime') === -1) {
                dropdownHtml += '          <div class="plan-options-item__name">' + planOption.name + '&nbsp;<span class="color-red"><b> +' + (parseInt(planOption.promo_quantity) - parseInt(planOption.quantity)) + ' FREE</b></span></div>';
            } else {
                dropdownHtml += '          <div class="plan-options-item__name">' + planOption.name + '</div>';
            }

            if (planOption.code.indexOf('lifetime') !== -1) {
                dropdownHtml += '          <div class="plan-options-item__per-credit">Single payment, No Subscription. Unlimited free manual renewals.</div>';
            }

            if (planOption.quantity !== 1) {
                let priceFor1 = planOption.price / planOption.quantity;
                if (discount > 0) {
                    let priceWithDiscount = parseFloat(priceFor1 - ((discount / 100) * priceFor1));
                    dropdownHtml += '          <div class="plan-options-item__per-credit"><span class="plan-options-item__per-credit-old">$' + formatFloatNumber(planOption.price) + '</span>&nbsp;$' + formatFloatNumber(priceWithDiscount) + ' per credit</div>';
                } else {
                    dropdownHtml += '          <div class="plan-options-item__per-credit">$' + formatFloatNumber(priceFor1) + ' per credit</div>';
                }
            }

            dropdownHtml += '        </div>';

            dropdownHtml += '      </div>';
            dropdownHtml += '    </div>';
            dropdownHtml += '    <div class="col-auto">';
            if (discount > 0) {
                let priceWithDiscount = parseFloat(planOption.price - ((discount / 100) * planOption.price));
                dropdownHtml += '       <div class="plan-options-item__price"><span class="plan-options-item__price-old">$' + formatFloatNumber(planOption.price) + '</span>&nbsp;$' + formatFloatNumber(priceWithDiscount) + (planOption.is_subscription === 1 ? '&nbsp;<span>month</span>' : '') + '</div>';
            } else {
                dropdownHtml += '       <div class="plan-options-item__price">$' + formatFloatNumber(planOption.price) + (planOption.is_subscription === 1 ? '&nbsp;<span>month</span>' : '') + '</div>';
            }

            dropdownHtml += '        <div class="row g-0 justify-content-end">';
            if (planOption.quantity !== 1) {
                dropdownHtml += '            <div class="col-auto">';
                dropdownHtml += '               <div class="plan-options-item__discount">' + planOption.discount + '% off</div>';
                dropdownHtml += '            </div>';
            }
            if (discount > 0) {
                dropdownHtml += '            <div class="col-auto ms-1">';
                dropdownHtml += '                <div class="plan-options-item__discount bg-red color-white">' + discount + '% off</div>';
                dropdownHtml += '            </div>';
            }
            dropdownHtml += '        </div>';
            dropdownHtml += '    </div>';

            dropdownHtml += '  </div>';
            dropdownHtml += '</button>';
        }

        $dropdownMenuPrices.html(dropdownHtml);

        $('.plan-options .plan-options-item').each(function () {
            if ($(this).hasClass('checked')) {
                $(this).prev().prev('.plan-options-item').css({'border-bottom-color': 'transparent'});
            }
        });
    }

    function changePlan(code) {
        $('#packages_radios').val(code);
        $('#packages_radios:checked').val(code);
        $('#payment_options').show();
        refreshButtons();
        updateCoupon();
    }

    function updateCoupon() {
        let couponCode = $('#coupon_code').val();
        if (!couponCode) {
            $('#coupon_description').html('');
            updateDropdownPrices();
            refreshButtons();
            return;
        }

        $('#apply_coupon').prop('disabled', true);

        $.ajax({
            type: "POST",
            url: "/api/apply-coupon",
            data: {
                coupon_code: couponCode
            },
            success: function (result) {
                if (!result.success) {
                    $('#coupon_description').html('<span style="color: #E92215;">ERROR: ' + result.message + '</span>').show();
                    updateDropdownPrices();
                    refreshButtons();
                } else {
                    $('#coupon_description').html('<span style="color: #52A56B;">Coupon successfully applied</span>').show();
                    updateDropdownPrices(result.data.discount);
                    refreshButtons(result.data.discount);
                }
                $('#apply_coupon').prop('disabled', false);
            },
            error: function () {
                updateDropdownPrices();
                refreshButtons();
                $('#apply_coupon').prop('disabled', false);
            }
        });
    }


    function updateData(planCode = '') {
        let planType = $('input[name="plan_type"]:checked').val();
        let planSize = arrPlanSize[$('input[name="plan_size"]').val()];

        currentData.creditType = planType;
        currentData.planCodeToSwitch = planSize;
        if (planType !== 'economy') {
            currentData.planCodeToSwitch = planSize + '_alexa';
        }
        currentData.planOptions = currentData.plansOptions[currentData.planCodeToSwitch];

        let localStorageQuantity;
        if (currentData.planOptionId && currentData.quantity) {
            localStorageQuantity = currentData.quantity;
        } else {
            localStorageQuantity = localStorage.getItem('st-select-quantity');
        }

        if (localStorageQuantity !== null && localStorageQuantity !== 'null' && localStorageQuantity) {
            if (currentData.isLifetime) {
                currentData.planCodeToSwitch = currentData.planCodeToSwitch + '_lifetime';
            } else if (parseInt(localStorageQuantity) !== 1) {
                currentData.planCodeToSwitch = localStorageQuantity + 'x' + currentData.planCodeToSwitch;
            }
        } else {
            localStorage.setItem('st-select-quantity', '1');
        }

        if (!planCode) {
            planCode = currentData.planCodeToSwitch;
        }

        for (let i = 0; i < currentData.planOptions.length; i++) {
            let planOption = currentData.planOptions[i];

            if (planCode === planOption.code) {
                currentData.planCodeToSwitch = planOption.code;
                currentData.creditName = planOption.name;
                currentData.planOptionsId = planOption.id;

                api.log.addUsersCartHistory({plan_options_id: planOption.id});

                break;
            }
        }

        updateDropdownPrices();
        changePlan(currentData.planCodeToSwitch);
    }

    $( "#bank-transfer-currency" ).change(function() {
        refreshButtons();
    });

    $('#apply_coupon').prop('disabled', false).on('click', function () {
        updateCoupon();
    });

    $('.coupon-action .coupon-action__button').on('click', function () {
        let couponCode = $(this).closest('[data-role]').data('coupon-code');

        $('html, body').animate({
            scrollTop: $('.coupon-block').offset().top
        }, {
            duration: 150,
            easing: "linear"
        });

        $('#coupon_code').val(couponCode);
        $('#apply_coupon').trigger('click');

        return false;
    });

    if (currentData.planOptionId) {
        for (let i = 0; i < currentData.planOptions.length; i++) {
            let planOption = currentData.planOptions[i];

            if (currentData.planOptionId === planOption.id) {
                currentData.planCodeToSwitch = planOption.code;
            }
        }
    }

    updateCoupon();
    refreshButtons();
    changePlan(currentData.planCodeToSwitch);

    // range slider
    $('.buy-credits__range-title').on('click', function () {
        $rangePlanSize.slider('value', $(this).data('range-value'));
        $inputPlanSize.val($(this).data('range-value')).trigger('change');
        localStorage.setItem('st-select-size', $rangePlanSize.slider('value'));

        $('.plan-desc').hide();
        $('.plan-desc-' + arrPlanSize[$rangePlanSize.slider('value')] + '-' + currentData.creditType).show();

        $('.tooltip-range-plan-size .tooltip-inner').text(arrPlanHits[$rangePlanSize.slider('value')] + ' hits/month');
        toltipPlanSize.update();
    });

    $rangePlanSize.slider({
        range: "min",
        value: 3,
        min: 0,
        max: 4,
        slide: function (event, ui) {
            $inputPlanSize.val(ui.value).trigger('change');
            localStorage.setItem('st-select-size', ui.value);

            $('.plan-desc').hide();
            $('.plan-desc-' + arrPlanSize[ui.value] + '-' + currentData.creditType).show();

            $('.tooltip-range-plan-size .tooltip-inner').text(arrPlanHits[ui.value] + ' hits/month');
            toltipPlanSize.update();
        }
    });

    $dropdownMenuPrices.on('click', function (e) {
        if ($(e.target).hasClass('plan-options-item') || $(e.target).closest('.plan-options-item').length > 0) {
            let $priceButton = $(e.target);
            if ($(e.target).closest('.plan-options-item').length > 0) {
                $priceButton = $(e.target).closest('.plan-options-item');
            }

            let planCode = $priceButton.data('plan-code');
            // let planNname = $priceButton.data('plan-name');
            let planQuantity = $priceButton.data('plan-quantity');

            $priceButton.addClass('checked');

            localStorage.setItem('st-select-quantity', planQuantity);
            updateData(planCode);
        }
    });

    $inputPlanSize.on('change', function () {
        let selectSize = arrPlanSize[$(this).val()];
        if (selectSize === 'nano') {
            $('.section-no-nano').hide();
            $('.buy-credits__info').hide();
            $('.section-nano').show();
        } else {
            $('.section-no-nano').show();
            $('.buy-credits__info').show();
            $('.section-nano').hide();
            // $('[data-size-currency]').text('$');
            // $('[data-size-price]').text('599.99');

            updateData();
        }
    });

    $inputPlanType.on('change', function () {
        localStorage.setItem('st-select-type', $(this).val());
        updateData();

        $('.plan-desc').hide();
        $('.plan-desc-' + arrPlanSize[$rangePlanSize.slider('value')] + '-' + currentData.creditType).show();
    });

    let localStorageType;
    if (!currentData.planOptionId) {
        localStorageType = localStorage.getItem('st-select-type');
    } else {
        localStorageType = currentData.creditType;
    }

    if (localStorageType !== null && localStorageType !== 'null' && localStorageType) {
        $('[name="plan_type"][value="' + localStorageType + '"]')
            .closest('label').trigger('click');
    }

    let localStorageSize;

    if (!currentData.planOptionId) {
        localStorageSize = localStorage.getItem('st-select-size');
    } else {
        localStorageSize = getKeyByValue(arrPlanSize, currentData.currentPlan.size);
    }
    if (localStorageSize !== null && localStorageSize !== 'null' && localStorageSize) {
        $rangePlanSize.slider('value', localStorageSize);
    } else {
        let arrPlanSizeKey = arrPlanSize.indexOf(currentData.lastPaidPlanSize);
        if (arrPlanSizeKey && arrPlanSizeKey > 0 && arrPlanSizeKey <= 4) {
            $rangePlanSize.slider('value', arrPlanSizeKey);
        }
    }
    $inputPlanSize.val($rangePlanSize.slider('value')).trigger('change');

    $('.plan-desc').hide();
    $('.plan-desc-' + arrPlanSize[$rangePlanSize.slider('value')] + '-' + currentData.creditType).show();

    $('a.vc-button').on('click', function () {
        let $this = $(this);

        $this.addClass('disabled').css('pointer-events', 'none');
        setTimeout(function () {
            $this.removeClass('disabled').css('pointer-events', 'auto');
        }, 5000);
    });


    // Tooltip
    $rangePlanSize.find('.ui-slider-handle').append('<span class="tooltip-plan-size" id="tooltip_plan_size" data-bs-toggle="tooltip" data-html="true"></span>');

    let toltipPlanSizeEl = document.getElementById('tooltip_plan_size');
    toltipPlanSize = new bootstrap.Tooltip(toltipPlanSizeEl, {
        // container: toltipPlanSizeEl,
        container: 'body',
        placement: 'bottom',
        trigger: 'manual',
        // boundary: 'window',
        // offset: [0, 0],
        template: '<div class="tooltip black tooltip-range-plan-size" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
        title: arrPlanHits[$rangePlanSize.slider('value')] + ' hits/month'
    });
    toltipPlanSize.show();
}

const initVatUpdates = function () {
    $("#apply-vat-id").click(function () {
        let $inputVatId = $('#vat_id');

        $.ajax({
            type: "POST",
            url: "/api/apply-vat-id",
            data: {
                vatId: $inputVatId.val()
            },
            success: function (result) {
                if (!result.success) {
                    showInfoMessageInput(
                        $inputVatId,
                        'ERROR: ' + result.message,
                        'red'
                    );
                    $('#cart_to_pay_without_vat').hide();
                    $('#cart_to_pay_with_vat').show();
                } else {
                    showInfoMessageInput(
                        $inputVatId,
                        'VAT ID is correct',
                        'green'
                    );
                    $("#cart_vat_rate").html('0');
                    $("#cart_vat_value").html('0');
                    $('#cart_to_pay_without_vat').show();
                    $('#cart_to_pay_with_vat').hide();
                }
            },
            error: function (xhr) {
                // console.log('xhr', xhr);
            }
        });
    });
}

export const initGuaranteedPayScripts = () => {
    runIfHasSelecter('[data-role="billing-pay"]', [
        initCommon,
        initVatUpdates,
    ]);
}