<template>
  <div class="list-tags" :class="mainClass">
    <ul class="list-tags__ul" ref="wrap">
      <li class="list-tags__li" v-for="item in listTags.slice(0, numberVisibleTags-1)">
        <span class="list-tags__item" @click="$emit('clickTag', item)">
          <span v-if="item.countryCode" class="list-tags__item_flag">
            <v-icon-flag :code="item.countryCode"/>
          </span>
          <span class="list-tags__item_text">
            {{item.name}}
          </span>
        </span>
      </li>
    </ul>
    <div v-if="numberVisibleTags < listTags.length" class="list-tags__show_more" >
      <a href="#" @click.prevent="showMoreCounterClick++">Show more</a>
    </div>
  </div>
</template>

<script setup>
/**
 * Компонента, которая показывает список переданных тегов. Есть следующие возможности:
 * - Показ загрузки ключевых слов c внешним управлением
 * - Показ сообщения, вместо ключевых слов
 * - Использование show more для ограничения показываемых тегов
 * - Использование стран в тегах
 * - При клике на один из тегов срабатывает событие, которое потом можно использовать во внешних компонентах
 */

import VIconFlag from "../Base/VIconFlag.vue";
import {computed, ref} from "vue";

const props = defineProps({
  listTags: {type:Array, default:[]},
  maxTags: {type: Number, default: 20},
  showMoreStep: {type:Number, default: 20},
  disabled: {type:Boolean, default:false,}
});

const emit = defineEmits(['clickTag']);

const showMoreCounterClick = ref(0);

const numberVisibleTags = computed(() => {
  return props.maxTags + showMoreCounterClick.value * props.showMoreStep;
});

const mainClass = computed(() => {
  let classes = [];
  if(props.disabled){
    classes.push('list-tags--disabled');
  }
  return classes;
});

</script>

<style lang="scss" scoped>
.list-tags--disabled{
  opacity:0.5;
  position:relative;
  &:after{
    content:'';
    position:absolute;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    display:block;
    z-index:99;
  }
}
.list-tags__ul{
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  height: auto;
  max-height: none;
  overflow: initial;
  margin-bottom: 8px;
  list-style: none;
}
.list-tags__li{
  margin-right:6px;
  margin-bottom:6px;
}
.list-tags__item{
  background-color:#1C7EE00D;
  font-size:13px;
  color:#0D4E8F;
  border-radius: 4px;
  cursor: pointer;
  padding:7px 10px;
  transition-duration: 0.3s;
  transition-property: background-color;
  display: flex;
  align-items: center;
}
.list-tags__item:before{
  content:'+';
  display: inline-block;
  margin-right: 3px;
  flex-shrink: 0;
}
.list-tags__li.hide-width-animation{
  opacity:0;
}
.list-tags__item:hover{
  background-color: rgba(28, 126, 224, 0.1);
}
.list-tags__title{
  font-weight: 400;
  margin-bottom:14px;
}
.list-tags__message{
  color:#7f7f7f;
}
.list-tags__show_more{
  font-size: 14px;
}
.list-tags__show_more a{
  text-decoration: none;
}
.list-tags__item_flag{
  overflow: hidden;
  border-radius: 1.5px;
  flex-shrink: 0;
  display: flex;
  margin-left:2px;
  margin-right: 5px;
}
.list-tags__item_flag svg{
  width:18px;
}
.list-tags__item_text{
  margin-right:2px;
}
.list-tags__loader{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.list-tags__loader-icon{
  flex-shrink: 0;
  margin-right: 7px;
  width:20px;
  height:20px;
  margin-left:2px;
}
.list-tags__loader-text{
  color: rgba(31, 41, 59, 0.5);
}
</style>