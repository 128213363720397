<template>
  <div class="v-block-empty-list vbel">
    <slot/>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.v-block-empty-list.vbel{
  width:100%;
  height:112px;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color:#AEB7C7;
  @media(max-width: 767px){
    height:initial;
    padding:30px 0px;
  }
}
</style>