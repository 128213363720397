<template>
  <component :is="iconComponent" class="v-icon-svg" :class="mainClass"/>
</template>

<script setup>
import {computed, defineAsyncComponent, defineComponent, h, shallowRef, watch} from 'vue';

const props = defineProps({
  name:    {type: String, default: undefined,},
  asImg:   {default: false, type: Boolean},
  asBlock: {default: false, type: Boolean},
});

const iconComponent = shallowRef();

watch(() => [props.name, props.asImg], async ([name, asImg]) => {
  if(!asImg) {
    iconComponent.value = await defineAsyncComponent(() => import(`../../../../../../public/img/icons/${name}.svg`));
  }else{
    iconComponent.value = defineComponent(() => {
      return () => {
        return h('img', {src: `/img/icons/${props.name}.svg`})
      }
    });
  }
},{immediate: true,});

const mainClass = computed(() => {
  let response = [];
  if(props.asImg) response.push('is-img');
  if(props.asBlock) response.push('is-block');
  return response;
});

</script>

<style lang="scss" scoped>
.v-icon-svg{
  flex-shrink:0;
  &.is-img{
    display:inline-block;
  }
  &.is-block{
    display:block;
  }
}
</style>