<template>
  <div
      v-if="countTags > 0"
      class="bc-choose-package-tags bccpt"
      :class="mainClass"
  >

    <v-tag-user-choice
        v-if="hasBestPrice"
        class="bccpt__tag"
        :icon="(isCustomPackage && isMobile && hasPromo) ? '' : 'percent'"
        color="green"
        slot-text="Best price"
    />

    <v-tag-user-choice
        v-if="hasMostPopular"
        class="bccpt__tag"
        :icon="(isCustomPackage && isMobile && hasPromo) ? '' : 'like'"
        color="blue"
        slot-text="Popular"
    />

    <v-tag-promo
        v-if="hasPromo"
        class="bccpt__tag bccpt__tag--promo"
        :slot-text="'+'+promoAmount"
    />

  </div>
</template>

<script setup>

import VTagUserChoice from "../Base/VTagUserChoice.vue";
import {computed, getCurrentInstance} from "vue";
import VTagPromo from "../Base/VTagPromo.vue";

const props = defineProps({
  hasBestPrice: {default:false},
  hasMostPopular: {default:false},
  promoAmount: {default:0},
  isCustomPackage: {type:Boolean, default:false,},
});

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const hasPromo = computed(() => props.promoAmount > 0);

const countTags = computed(() => {
  return [hasPromo.value, props.hasBestPrice, props.hasMostPopular].filter(item => item==true).length
});

const mainClass = computed(() => {
  let classes = [];
  classes.push('bccpt--count-tags-'+countTags.value);
  return classes;
})

</script>

<style lang="scss" scoped>
.bc-choose-package-tags.bccpt{
  @include flex(column,start,stretch,0px);
  @media(max-width:767px){
    @include flex(row,start,stretch,0px);
  }

  .bccpt__tag{

    border-radius: 0px;

    @media(min-width:768px){
      &:first-child{
        border-top-left-radius:4px;
        border-top-right-radius:4px;
      }
      &:last-child{
        border-bottom-left-radius:4px;
        border-bottom-right-radius:4px;
      }
    }

    @media(max-width:767px){
      &:first-child{
        border-top-left-radius:4px;
        border-bottom-left-radius:4px;
      }
      &:last-child{
        border-top-right-radius:4px;
        border-bottom-right-radius:4px;
      }
    }

  }
  @media(max-width:767px){
    &.bccpt--count-tags-2, &.bccpt--count-tags-3{
      .bccpt__tag.bccpt__tag--promo{
        font-size: 8px;
      }
    }
  }

}
</style>