<template>
  <v-white-container class="lb-projects-chart lbpc">
    <div class="lbpc__head">
      <div class="lbpc__head-left">
        <div class="lbpc__head-title">
          Backlinks Delivered
        </div>
      </div>
      <div class="lbpc__head-right">
        <div class="lbpc__head-period-title">
          Period:
        </div>
        <div class="lbpc__head-period-value">
          <v-period-simple
              :start-date="chartStore.dateFrom"
              :end-date="chartStore.dateTo"
              ref="refInputPeriodSimple"
              @save="savePeriodChart"
          />
        </div>
      </div>
    </div>
    <div class="lbpc__body">
      <chart-backlinks
          :labels="chartStore.labels"
          :in-progress="chartStore.inProgressData"
          :delivered="chartStore.deliveredData"
          :new="chartStore.newData"
          class="lbpc__chart"
          ref="refCanvasChart"
          :graph-attrs="{width:'100%',height:'160px',}"
      />
    </div>
  </v-white-container>
</template>

<script setup>

import VWhiteContainer from "../Base/VWhiteContainer.vue";
import VPeriodSimple from "../Base/VPeriodSimple.vue";
import {ref, watch} from "vue";
import {useLinkBuildChartOrders} from "../../stores/useLinkBuilding";
import ChartBacklinks from "../Chart/ChartBacklinks.vue";

const refInputPeriodSimple = ref();
const refCanvasChart = ref();

const chartStore = useLinkBuildChartOrders();

if(window.preloadData.hasOwnProperty('LbProjectsChartDateTo')){
  chartStore.setDateTo(new Date(window.preloadData.LbProjectsChartDateTo));
  delete window.preloadData.LbProjectsChartDateTo;
}else{
  chartStore.setDateTo(new Date());
}

if(window.preloadData.hasOwnProperty('LbProjectsChartDateFrom')){
  chartStore.setDateFrom(new Date(window.preloadData.LbProjectsChartDateFrom));
  delete window.preloadData.LbProjectsChartDateFrom;
}else{
  chartStore.setDateFrom((() => {
    let startDays = new Date();
    startDays.setDate(startDays.getDate() - 30);
    return startDays;
  })());
}

chartStore.downloadBetween();

const savePeriodChart = async (startDate, endDate) => {
  chartStore.downloadBetween(new Date(startDate), new Date(endDate)).then((response) => {
    let [status,message] = response;
    if(status){
      refInputPeriodSimple.value.saveWithSuccess();
    }else{
      refInputPeriodSimple.value.saveWithError(message);
    }
  });
}

</script>

<style lang="scss" scoped>
.lb-projects-chart.lbpc{
  display:flex;
  flex-direction:column;
  align-items: stretch;
  gap:20px;
  padding-bottom:27px;
  @media(max-width: 767px){
    padding:21px 12px 16px;
  }

  .lbpc__head{
    display:flex;
    justify-content: space-between;
    align-items: center;

    .lbpc__head-left{
      display:flex;
      align-items: center;
      gap:10px;
    }
    .lbpc__head-right{
      display:flex;
      align-items: center;
      gap:10px;
    }
    .lbpc__head-title{
      font-size: 16px;
      font-weight: 600;
      color:#384967;
      @media(max-width: 767px){
        font-size: 14px;
        font-weight: 600;
      }
    }
    .lbpc__head-period-title{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color:#AEB7C7;
      @media (max-width: 767px) {
        display:none;
      }
    }
    .lbpc__head-period-value{
      @media(max-width: 767px){
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .lbpc__body{
    .lbpc__chart{
    }
  }
}
</style>