<template>
  <div class="sc-form-group-keywords scfgk" :class="mainClass" ref="wrap">
    <div ref="formGroupKeywords" class="scfgk__selected-keywords-wrap">
      <v-form-group class="scfgk__selected-keywords-form-group">
        <v-form-tagify
            v-model="useSuggestKeywords().selectedKeywords"
            :suggested-tags="useSuggestKeywords().remainingSuggestedKeywords"
            :current-country-code="currentRegion?.value"
            class="scfgk__selected-keywords"
            @add-tag="useSuggestKeywords().addSelectedKeyword"
            @remove-tag="useSuggestKeywords().removeSelectedKeyword"
            :disabled="disabled"
            :is-error="!!errors"
        />
        <v-form-field-list-errors :errors="errors"/>
      </v-form-group>
    </div>
    <div class="scfgk__suggested-keywords-wrap">

      <v-form-label data-name="suggested-keywords">Suggested Keywords</v-form-label>

      <v-form-select-inline
          placeholder="Country"
          v-model="currentRegion"
          :options="regionsForSelect"
          :style="{marginBottom:(!currentRegionIsNoGeo) ? '' : '10px'}"
          @change="changeCurrentRegion"
          :has-empty-option="false"
          :disabled="disabled"
      />

      <v-form-caption
          :is-under-select="true"
          v-if="!currentRegionIsNoGeo"
          style="margin-bottom: 20px;"
      >
        <div class="d-flex align-items-center">
          <v-icon-flag :code="currentRegion?.value" style="margin-right: 14px;"/>
          (+1 credit per click)
        </div>
      </v-form-caption>

      <list-tags
          v-if="useSuggestKeywords().remainingSuggestedKeywords.length > 0 && !useSuggestKeywords().loading"
          ref="suggestKeywordsList"
          :list-tags="useSuggestKeywords().remainingSuggestedKeywords"
          @click-tag="useSuggestKeywords().addSelectedKeyword"
          :disabled="disabled"
      />

      <div class="scfgk__loading" v-if="useSuggestKeywords().loading">
        <v-loader-ship class="scfgk__loading-icon" color-line-one="#1C7EE080" color-line-two="#1C7EE0"/>
        <span class="scfgk__loading-text">Loading keywords</span>
      </div>

      <div v-if="useSuggestKeywords().keywordsNotFound && !useSuggestKeywords().loading" class="scfgk__message">
        Keywords not found
      </div>

    </div>
  </div>

</template>

<script setup>

import {computed, defineProps, ref, watch} from 'vue';
import VFormCaption from "../VForm/VFormCaption.vue";
import VIconFlag from "../Base/VIconFlag.vue";
import ListTags from "../Blocks/ListTags.vue";
import VFormTagify from "../VForm/VFormTagify.vue";
import {useSuggestKeywords} from "../../stores/useSuggestKeywords.js";
import {useStaticData} from "../../stores/useStaticData.js";
import VFormSelectInline from "../VForm/VFormSelectInline.vue";
import VFormLabel from "../VForm/VFormLabel.vue";
import VLoaderShip from "../Base/VLoaderShip.vue";
import VFormGroup from "../VForm/VFormGroup.vue";
import VFormFieldListErrors from "../VForm/VFormFieldListErrors.vue";
import VForm from "../VForm/VForm.vue";

const props = defineProps({
  website: {default: null},
  errors: {default:''},
  disabled: {type:Boolean,default:false},
});

const mainClass = computed(() => {
  let classes = [];
  if(props.disabled){
    classes.push('scfgk--disabled');
  }
  return classes;
});

const optionNoGeo = {'name': 'No Geo-targeting', value: 'no_geo'}

const regionsForSelect = computed(() => {
  return [optionNoGeo].concat(useStaticData().regionsForSelect);
});

const currentRegion = ref(optionNoGeo);
const currentRegionIsNoGeo = computed(() => {return currentRegion.value?.value === 'no_geo'});

watch(() => useSuggestKeywords().currentLocation, (value) => {
  if(value===null){
    currentRegion.value = optionNoGeo;
  }else{
    if(value?.toLowerCase() === 'gb'){
      value = 'uk';
    }
    currentRegion.value = regionsForSelect.value.find((item) => item.value.toLowerCase() === value.toLowerCase());
  }
});

const changeCurrentRegion = (option) => {
  updateSuggestedKeywords(option);
}

const updateSuggestedKeywords = (option = null) => {
  let geoCode = null;
  if(option !== null){
    geoCode = option.value;
  }
  console.log('website', props.website);
  useSuggestKeywords().updateSuggestedKeywords(props.website, geoCode);
}

defineExpose({updateSuggestedKeywords});

</script>

<style scoped>
.sc-form-group-keywords.scfgk{
  display:flex;
  gap:20px;
  align-items: stretch;
  @media(max-width: 767px){
    flex-direction:column;
  }
  .scfgk__selected-keywords-wrap{
    width:50%;
    min-height:160px;
    display:flex;
    align-items: stretch;
    .scfgk__selected-keywords-form-group{
      width:100%;
      display:flex;
      flex-direction:column;
      align-items:stretch;
      height:100%;
      min-height:160px;
      margin-bottom:0px;
      .scfgk__selected-keywords{
        width:100%;
        height:100%;
        min-height:160px;
      }
    }
    @media(max-width: 767px){
      width:100%;
    }
  }
  .scfgk__suggested-keywords-wrap{
    border-radius:4px;
    width:50%;
    background:#F7FAFF;
    padding:20px;
    @media(max-width: 767px){
      width:100%;
    }
  }

  .scfgk__loading{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .scfgk__loading-icon{
      flex-shrink: 0;
      margin-right: 7px;
      width:20px;
      height:20px;
      margin-left:2px;
    }
    .scfgk__loading-text{
      color: rgba(31, 41, 59, 0.5);
    }
  }

  .scfgk__message{
    color:#7f7f7f;
  }


}
</style>