<template>
  <div class="bc-backlink-quality bcbq">
    <bc-backlinks-quality-pack
        v-for="creditType in useCreditTypes().creditTypesBacklink"
        class="bcbq__item"
        :credit-type-id="creditType.id"
        v-model="qualityTypeConstant"
    />
  </div>
</template>

<script setup>

import BcBacklinksQualityPack from "./BcBacklinksQualityPack.vue";
import {useCreditTypes} from "../../stores/useBilling";

const qualityTypeConstant = defineModel();

</script>

<style lang="scss" scoped>
.bc-backlink-quality.bcbq{
  @include flex(row,between,stretch,10px);

  @media(max-width:767px){
    @include flex(column,start,stretch,10px);
  }
  .bcbq__item{
    width:100%;
  }
}
</style>