export default class Rule{
    /** поступающие данные */
    public data: any;

    /** статус проверки - false, если проверка не пройдена */
    public status: boolean;

    /** сообщение об ошибке */
    public message: string;

    /** объект, в котором хранятся все ошибки пойманные на фронте */
    public frontErrors: any;

    /** ключ к выводу текущей ошибке */
    public frontErrorsKey: string;

    constructor(data: any, frontErrors: any, frontErrorsKey: string) {

        this.data = data;
        this.status = null;
        this.message = '';
        this.frontErrors = frontErrors;
        this.frontErrorsKey = frontErrorsKey;

    }

    setFrontErrorsMessage(message: string|null = null){
        if(message === null){
            message = this.message;
        }
        this.frontErrors[this.frontErrorsKey] = message;
    }

    deleteFrontErrorsMessage(){
        if(this.frontErrors?.[this.frontErrorsKey]){
            delete this.frontErrors?.[this.frontErrorsKey];
        }
    }

    getCurrentFrontErrorsMessage(){
        return this.frontErrors?.[this.frontErrorsKey];
    }

}