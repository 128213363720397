<template>
  <button class="button button-link-dashed" :class="mainClass" @click="toCopy">
    <transition name="fade" mode="out-in">
      <span v-if="!copied">Copy</span>
    </transition>
    <transition name="fade" mode="out-in">
      <span v-if="copied">
        <v-icon-svg name="check-gray"/>
        Link copied
      </span>
    </transition>
  </button>
</template>

<script setup>

import {computed, ref} from "vue";
import VIconSvg from "./VIconSvg.vue";

const copied = ref(false);

const mainClass = computed(() => {
  let classes = [];
  if(copied.value){
    classes.push('button--copied-success');
  }
  return classes;
})

const props = defineProps({
  content: {}
});

const toCopy = async () => {
  if(copied.value){return;}
  await navigator.clipboard.writeText(props.content);
  copied.value = true;
  setTimeout(() => {
    copied.value = false;
  },3000);
}

</script>

<style scoped>
.button{
  display: flex;
  align-items:center;
  gap:2px;
  line-height: 18px;
  transition-duration: 0s;
  &.button--copied-success{
    border-bottom-color:transparent;
    color: #AEB7C7;
    cursor:initial;
  }
}
.fade-enter-from{
  opacity:0;
}
.fade-enter-active{
  transition: opacity 0.5s;
}
.fade-enter-to{
  opacity:1;
}
.fade-leave-from{
  display:none;
}
.fade-leave-active{
  display:none;
}
.fade-leave-to{
  display:none;
}
</style>