<template>
  <div class="lb-orders lbo" :class="linkBuildProjectStore.isDisabled ? 'is-disabled' : ''">

    <v-tabs v-model="tabActive">
      <v-tabs-item hash="backlinks">Backlinks</v-tabs-item>
      <v-tabs-item hash="activity_history">Activity history</v-tabs-item>
    </v-tabs>

    <lb-orders-backlinks v-if="tabActive === 'backlinks'" :project-uniquekey="projectUniquekey"/>

    <lb-orders-activity v-if="tabActive === 'activity_history'" :project-uniquekey="projectUniquekey"/>

  </div>
</template>

<script setup>

import {ref} from "vue";
import {useLinkBuildProject, useLinkBuildProjectOrders} from '../../stores/useLinkBuilding.js';
import VTabs from "../Base/VTabs.vue";
import VTabsItem from "../Base/VTabsItem.vue";
import LbOrdersBacklinks from "./LbOrdersBacklinks.vue";
import LbOrdersActivity from "./LbOrdersActivity.vue";

const tabActive = ref('backlinks');

const props = defineProps({
  projectUniquekey: {}
});

const linkBuildProjectStore = useLinkBuildProject(props.projectUniquekey);

</script>

<style lang="scss" scoped>
.lb-orders.lbo{

  &.is-disabled{
    position:relative;
    opacity:0.5!important;
    &:after{
      content:'';
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
    }
  }

}
</style>