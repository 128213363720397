<template>
  <div class="section-faq sf">
    <div class="sf__title">
      FAQ
    </div>
    <div class="sf__faq">
      <v-faq-list :items="useFaq(type).items"/>
    </div>
  </div>
</template>

<script setup>

import VFaqList from "../Base/VFaqList.vue";
import {useFaq} from "../../stores/useStaticData";

const props = defineProps({
  /**
   * @type {('blogger-outreach'|'clicks')}
   */
  type: {}
});

useFaq(props.type).download();

</script>

<style lang="scss" scoped>
.section-faq.sf{
  .sf__title{
    font-size: 18px;
    font-weight: 600;
    color: #384967;
    margin-bottom:20px;
    @media(max-width:767px){
      margin-bottom:20px;
    }
  }
}
</style>