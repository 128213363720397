<template>
  <div class="bc-cart-item bcci" :class="mainClass">
    <template v-if="!isMobile">

      <div class="bcci__td bcci__td--product">
        <div>

          <v-product :type-constant="cartItem.plan_options.typeConstantBacklink" class="bcci__product"/>
          <div class="bcci__price-per-credit">
            Price per credit ${{priceFormatted(cartItem.pricePerCredit)}}
          </div>

        </div>
      </div>

      <div class="bcci__td bcci__td--counter">
        <div>
          <v-form-input-counter
              :min="cartItem.plan_options.minQuantity"
              v-model="quantityCustom"
              class="bcci__counter"
              has-min-width
              :disabled="useBillingCart().requestExecutionAny"
          />
        </div>
      </div>

      <div class="bcci__td bcci__td--promo" :class="cartItem.promoAmount > 0 ? 'bcci__td--promo-has' : ''">
        <div>
          <v-tag-promo
              v-if="cartItem.promoAmount > 0"
              :slot-text="cartItem.promoAmount"
          />
        </div>
      </div>

      <div class="bcci__td bcci__td--save">
        <div>

          <v-tag-save v-if="cartItem.discountAmount > 0">
            save ${{Math.round(cartItem.discountAmount)}}
          </v-tag-save>

        </div>
      </div>

      <div class="bcci__td bcci__td--amount-and-delete">
        <div>

          <div class="bcci__amount-and-delete">
            <div class="bcci__amount">
              ${{priceFormatted(cartItem.price)}}
            </div>
            <div
                class="bcci__delete"
                @click="deleteFromCart"
                :class="useBillingCart().requestExecutionAny ? 'bcci__delete--disabled' : ''"
            >
              <v-icon-svg name="icon-delete-gray" class="bcci__delete-icon"/>
            </div>
          </div>

        </div>
      </div>


    </template>
    <template v-else>
      <div class="bcci__m-left">

        <v-product :type-constant="cartItem.plan_options.typeConstantBacklink" class="bcci__product"/>

        <div class="bcci__price-per-credit">
          Price per credit ${{priceFormatted(cartItem.pricePerCredit)}}
        </div>

        <div class="bcii__m-counter-and-promo">
          <v-form-input-counter
              :min="cartItem.plan_options.minQuantity"
              v-model="quantityCustom"
              class="bcci__counter"
              has-min-width
              :disabled="useBillingCart().requestExecutionAny"
          />
          <v-tag-promo
              v-if="cartItem.promoAmount > 0"
              :slot-text="cartItem.promoAmount"
          />
        </div>



      </div>
      <div class="bcci__m-right">

        <div class="bcci__amount-and-delete">
          <div class="bcci__amount">
            ${{priceFormatted(cartItem.price)}}
          </div>
          <div
              class="bcci__delete"
              @click="deleteFromCart"
              :class="useBillingCart().requestExecutionAny ? 'bcci__delete--disabled' : ''"
          >
            <v-icon-svg name="icon-delete-gray" class="bcci__delete-icon"/>
          </div>
        </div>

        <v-tag-save v-if="cartItem.discountAmount > 0">
          save ${{Math.round(cartItem.discountAmount)}}
        </v-tag-save>

      </div>
    </template>
  </div>
</template>

<script setup>

import {useBillingCart, useBillingCartItem} from "../../stores/useBilling";
import VProduct from "../Base/VProduct.vue";
import VFormInputCounter from "../VForm/VFormInputCounter.vue";
import VIconSvg from "../Base/VIconSvg.vue";
import VTagSave from "../Base/VTagSave.vue";
import {ref, computed, watch, getCurrentInstance, onMounted} from "vue";
import {priceFormatted} from "../../utils/utils";
import VTagPromo from "../Base/VTagPromo.vue";
const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  id: {}
});

const cartItem = useBillingCartItem(props.id);

const quantityCustom = ref(cartItem.quantity);
watch(() => cartItem.quantity, (quantity) => {
  if(quantityCustom.value!==quantity){
    animateScaleShow();
  }
  quantityCustom.value = quantity;
});

/**
 * отслеживание изменения input counter, которая пользователь меняет значения
 */
watch(() => quantityCustom.value, (newQuantity) => {
  setTimeout(() => {
    if(newQuantity === quantityCustom.value && quantityCustom.value !== cartItem.quantity){

      let quantityValidate = cartItem.plan_options.minQuantity ? quantityCustom.value : cartItem.plan_options.minQuantity;

      cartItem.update(quantityValidate);

    }
  },1000);
})

const mainClass = computed(() => {
  let classes = [];
  if(animateClasses.value.none){
    classes.push('bcci--none');
  }
  if(animateClasses.value.hidden){
    classes.push('bcci--hide');
  }
  return classes;
});

const animateClasses = ref({
  none: true,
  hidden: true,
});
const animateClassesSet = (none, hidden) => {
  animateClasses.value.none = none;
  animateClasses.value.hidden = hidden;
}
const animateScaleShow = () => {

  animateClassesSet(true,true);
  setTimeout(() => {
    animateClassesSet(false,true);
    setTimeout(() => {
      animateClassesSet(false,false);
    },20);
  },20);
}

onMounted(() => {
  animateScaleShow();
})

const deleteFromCart = () => {
  cartItem.delete().then();
}

</script>

<style lang="scss" scoped>
.bc-cart-item.bcci{

  &.bcci--none{
    transition-duration:0s;
    opacity:0;
    transform:scale(0);
  }
  &.bcci--hide{
    transform:scale(0);
  }

  width:100%;
  border-bottom: 1px solid #D6E3EF80;
  position:relative;
  transform:scale(1);
  opacity:1;
  transition-duration:0.4s;
  transition-property:transform;

  @media(min-width: 768px){

    display: table-row;

    .bcci__td{
      display: table-cell;
      height:66px;
      border-bottom: 1px solid #D6E3EF80;
      vertical-align: middle;

      > div{
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        justify-content:center;
        gap:4px;
      }

      &.bcci__td--product{
        width:100%;
      }
      &.bcci__td--counter{

      }
      &.bcci__td--promo.bcci__td--promo-has{
        padding-left:18px;
      }
      &.bcci__td--save{
        padding-left:18px;
        padding-right:18px;
        text-align:right;
        > div{
          align-items:flex-start;
        }
      }
      &.bcci__td--amount-and-delete{
        text-align:right;
        > div{
          align-items:flex-end;
        }
      }
    }

  }

  @media(max-width:767px){
    @include flex(row,between,start,5px);
    padding:15px 0px;
    .bcci__m-left{
      @include flex(column,start,start,5px);
    }
    .bcci__m-right{
      @include flex(column,start,end,5px);
    }
    .bcii__m-counter-and-promo{
      @include flex(row,start,center,5px);
    }
  }

  &.bcci__in-process{
    opacity:0.5;
    &:after{
      content:'';
      position:absolute;
      width:100%;
      height:100%;
      top:0px;
      left:0px;
    }
  }

  .bcci__product{
    font-size: 16px;
  }
  .bcci__price-per-credit{
    font-size: 12px;
    color:#AEB7C7;
  }
  .bcci__counter{
    min-width:114px;
  }
  .bcci__save{

  }
  .bcci__amount-and-delete{
    @include flex(row,start,center,4px);
    @media(max-width:767px){
      @include flex(row-reverse,start,center,6px);
    }
  }
  .bcci__amount{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color:#384967;
  }
  .bcci__delete{
    width:20px;
    height:20px;
    opacity:0.5;
    cursor:pointer;
    transition-duration: 0.2s;
    &:hover{
      opacity:1;
    }
    .bcci__delete-icon{
      display:block;
      width:20px;
      height:20px;
    }
    &.bcci__delete--disabled{
      opacity:0.25;
    }
  }

}
</style>