import * as Sentry from "@sentry/vue";

export function chatSupportShowMessage(message){
    try{
        if(parseInt(userData?.isDirectAuth) === 0 && window.Intercom){
            window.Intercom('showNewMessage', message);
        }else{
            throw new Error('window.Intercom not defined');
        }
    }catch(error){
        Sentry.captureException(error);
    }

}