<template>
  <div class="form-group-wrap" :class="classes">
    <slot/>
  </div>
</template>

<script setup>

import {computed, ref} from "vue";

const props = defineProps({
  isGray: {type:Boolean},
  isSmallBorderRadius: {type:Boolean},
  disabled: {type: Boolean, default:false,}
});

const classes = computed(() => {
  let classes = [];
  if(props.isGray) classes.push('form-group-wrap--is-gray');
  if(props.isSmallBorderRadius) classes.push('form-group-wrap--is-small-border-radius');
  if(props.disabled) classes.push('form-group-wrap--disabled')
  return classes;
});



</script>

<style lang="scss">
.form-group-wrap{
  background: #fff;
  padding:20px 14px;
  border-radius: 8px;
  border:1px solid #DBE7F1;
}
.form-group-wrap .form-group:last-child{
  margin-bottom:0px;
}
.form-group-wrap--is-gray{
  background-color: #F4F7FA;
  border:none;
}
.form-group-wrap--disabled{
    background: #F9FAFA;
    > *{
      opacity:0.5;
    }
    position:relative;
    &:after{
      content:'';
      position:absolute;
      width:100%;
      height:100%;
      top:0px;
      left:0px;
      display:block;
      z-index:99;
    }
}
</style>