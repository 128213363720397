<template>
  <div class="lbv-order-status lbvos" :class="mainClass">
    {{useLinkBuildingStatus().getTitle(statusConstant)}}
  </div>
</template>

<script setup>
import {computed} from "vue";
import {useLinkBuildingStatus} from "../../../stores/useLinkBuilding";

const props = defineProps({
  /**
   * @type {'pending'|'executed'|'in_process'|'cancelled'}
   */
  statusConstant: {},
});

const mainClass = computed(() => {
  if(props.statusConstant === 'delivered'){
    return 'lbvos--delivered';
  }
  if(props.statusConstant === 'in_progress' || props.statusConstant === 'new'){
    return 'lbvos--in-progress';
  }
  if(props.statusConstant === 'canceled'){
    return 'lbvos--canceled';
  }
})

</script>

<style lang="scss" scoped>
.lbv-order-status.lbvos {
  padding: 4px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 600;
  text-transform:uppercase;

  &.lbvos--delivered{
    background-color:#1CC7230D;
    color:#219E26;
  }
  &.lbvos--in-progress{
    background-color:#1C7EE00D;
    color:#1C7EE0;
  }
  &.lbvos--canceled{
    background-color:#C70B0B0D;
    color:#F2001D;
  }
}
</style>