<template>
  <div class="v-tabs-item vti" :class="mainClass" @click="model = hash">
    <div class="vti__inner">
      <span>
        <slot/>
      </span>
    </div>
  </div>
</template>

<script setup>

import {inject,computed} from "vue";

const props = defineProps({
  hash: {}
});

const model = inject('vTabsModel');
const type = inject('vTabsType');

const mainClass = computed(() => {
  let response = [];
  if(props.hash === model.value){ response.push('vti--active'); }
  response.push('vti--type-'+type);
  return response;
});

</script>

<style lang="scss" scoped>
.v-tabs-item.vti{

  &.vti--type-default{
    height: 50px;
    padding: 10px 8px 10px 8px;
    gap: 10px;
    border-bottom: 1px solid transparent;
    margin-bottom:-1px;
    cursor:pointer;
    font-size: 16px;
    font-weight: 600;
    display:flex;
    align-items:center;
    justify-content: center;
    color:#1C7EE0;
    &:hover{
      color:#186CBF;
    }
    &.vti--active{
      border-bottom:1px solid #000;
      color:#0F1F3A;
      cursor:initial;
    }
  }

  &.vti--type-container{
    .vti__inner{
      display: flex;
      align-items:center;
      justify-content: center;
      gap: 10px;
      height: 50px;
      border-radius: 4px 4px 0px 0px;
      font-size: 18px;
      font-weight: 600;
      cursor:pointer;
      padding: 10px 20px;
      transition-duration:0.2s;
      transition-property:background-color,color;
      color:#1C7EE0;
      position:relative;
      z-index:3;
      &:hover{
        color:#186CBF;
      }
    }
    &.vti--active{
      box-shadow:0px 0px 12px 0px #1C7EE02E;
      .vti__inner{
        color:#0F1F3A;
        cursor:initial;
        background-color:#FFFFFF;
      }
    }
  }

}
</style>