import _ from "lodash";
import {api} from "../utils/panel-api";
import {initTooltips, sendGaEvent} from "../utils/panel-lib";

const initAction = () => {

    const $action = $("[data-role=action]");
    if ($action.length === 0) {
        return;
    }

    const timeleft = $action.data('action-timeleft');

    var seconds_passed = 0;
    function periodicall() {
        seconds_passed++;

        var distance = timeleft - Math.round(seconds_passed / 100);
        var days = Math.floor(distance / 86400);
        var hours = Math.floor(distance / 3600) - days * 24;
        var minutes = Math.floor((distance % 3600) / 60);
        var seconds = Math.floor(distance % 60);
        var seconds_parts = Math.floor(60 - 60 * (seconds_passed % 100) / 100);

        if (minutes < 10) minutes = "0" + minutes;
        if (seconds < 10) seconds = "0" + seconds;
        if (seconds_parts < 10) seconds_parts = "0" + seconds_parts;

        let demoEl = document.getElementById("demo");
        if (demoEl !== null) {
            if (days > 0) {
                document.getElementById("demo").innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s " + " :" + seconds_parts;
            } else {
                document.getElementById("demo").innerHTML = hours + "h " + minutes + "m " + seconds + "s " + " :" + seconds_parts;
            }
        }

        let promoBuyEl = document.getElementById("promo_buy");
        if (promoBuyEl !== null) {
            if (days > 0) {
                document.getElementById("promo_buy").innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s " + " :" + seconds_parts;
            } else {
                document.getElementById("promo_buy").innerHTML = hours + "h " + minutes + "m " + seconds + "s " + " :" + seconds_parts;
            }
        }

        setTimeout(periodicall, 10);
    }

    periodicall();
}

const initPersonalDiscount = () => {
    const $action = $('[data-role="personal-discount"]');
    if ($action.length === 0) {
        return;
    }

    const timeleft = $action.data('action-timeleft');

    var seconds_passed = 0;
    function periodicall() {
        seconds_passed++;

        var distance = timeleft - Math.round(seconds_passed / 100);
        var days = Math.floor(distance / 86400);
        var hours = Math.floor(distance / 3600) - days * 24;
        var minutes = Math.floor((distance % 3600) / 60);
        var seconds = Math.floor(distance % 60);
        var seconds_parts = Math.floor(60 - 60 * (seconds_passed % 100) / 100);

        if (minutes < 10) minutes = "0" + minutes;
        if (seconds < 10) seconds = "0" + seconds;
        if (seconds_parts < 10) seconds_parts = "0" + seconds_parts;

        let demoEl = document.getElementById("personal-discount-timer");
        if (demoEl !== null) {

            if (days > 0) {
                demoEl.innerHTML = '<span>' + days + 'd </span>' + '<span>' + hours + 'h </span>' + '<span>' + minutes + 'm </span>' + '<span>' + seconds + 's </span>' + ' :' + '<span>' + seconds_parts + '</span>';
            } else {
                demoEl.innerHTML = '<span>' + hours + 'h </span>' + '<span>' + minutes + 'm </span>' + '<span>' + seconds + 's </span>' + ' :' + '<span>' + seconds_parts + '</span>';
            }
        }

        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
            window.location.reload();
        } else {
            setTimeout(periodicall, 10);
        }
    }

    periodicall();
}

const initPersonalCoupons = () => {
    let $actions = $('[data-role="coupon-action"]');
    let secondsPassed = {};

    $actions.each(function (index, el) {
        let timeleft = $(el).data('action-timeleft');
        let $counterEl = $(el).find('.coupon-action__timeleft');

        secondsPassed[index] = 0;

        periodicall(index, timeleft, $counterEl);
    });

    function periodicall(index, timeleft, $counterEl) {
        secondsPassed[index]++;

        var distance = timeleft - Math.round(secondsPassed[index] / 100);
        var days = Math.floor(distance / 86400);
        var hours = Math.floor(distance / 3600) - days * 24;
        var minutes = Math.floor((distance % 3600) / 60);
        var seconds = Math.floor(distance % 60);
        var secondsParts = Math.floor(60 - 60 * (secondsPassed[index] % 100) / 100);

        if (minutes < 10) minutes = "0" + minutes;
        if (seconds < 10) seconds = "0" + seconds;
        if (secondsParts < 10) secondsParts = "0" + secondsParts;

        if (days > 0) {
            $counterEl.html(days + "d " + hours + "h " + minutes + "m " + seconds + "s " + " :" + secondsParts);
        } else {
            $counterEl.html(hours + "h " + minutes + "m " + seconds + "s " + " :" + secondsParts);
        }

        setTimeout(function () {
            periodicall(index, timeleft, $counterEl);
        }, 10);
    }
}

const initDatapickerRange = () => {
    const $elPickerRange = $('[data-picker-range] input');
    if ($elPickerRange.length === 0) {
        return;
    }

    $elPickerRange.datepicker({
        format: "dd.mm.yyyy",
        keyboardNavigation: false,
        forceParse: false,
        autoclose: true
    });
}

const initMenu = () => {
    const currentPath = document.location.pathname;

    const activateMenuItem = function ($item) {
        $item.addClass('active')
            .closest('ul').show()
            .closest('li').addClass('open');

        $item.parents("li")
            .addClass('active');
    }

    $('[data-paths]').each(function() {
        const $this = $(this);
        const config = $this.data('paths');

        _.map(config.exact || [], path => {
            if (currentPath === path) {
                activateMenuItem($this);
            }
        });
        _.map(config.starts || [], path => {
            if (_.startsWith(currentPath, path)) {
                activateMenuItem($this);
            }
        });
        _.map(config.regexp || [], pattern => {
            if(currentPath.search(new RegExp(pattern)) !== -1){
                activateMenuItem($this);
            }
        })
    })
}

const initGclidHandler = function () {
    const params = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        function(_, key, value) {
            return params[key] = value;
        });

    const gclid = params['gclid'];
    const msclkid = params['msclkid'];
    if (gclid) {
        localStorage.setItem('gclid', gclid);
    }
    if (msclkid) {
        localStorage.setItem('msclkid', msclkid);
    }

    const $gclidInput = $('#gclid_field');
    if ($gclidInput.length > 0) {
        const newValue = localStorage.getItem('gclid') || '';

        $gclidInput.val(newValue);
        $gclidInput.attr("value", newValue);
    }
    const $msclkidInput = $('#msclkid_field');
    if ($msclkidInput.length > 0) {
        const newValue = localStorage.getItem('msclkid') || '';

        $msclkidInput.val(newValue);
        $msclkidInput.attr("value", newValue);
    }
}

const initSendGaEvent = function () {
    let userId = '';
    if (typeof userData !== 'undefined') {
        userId = userData.id ? userData.id : '';
    }

    if (window.__env__ !== 'production' || userId === '') {
        return;
    }

    api.transaction.getForSendToGa({},
        function (response) {
            if (response.success) {
                let data = response.data;

                if (data.transactions) {
                    let transactions = data.transactions;
                    let transactionIds = [];

                    for (let i = 0; i < transactions.length; i++) {
                        let transaction = transactions[i];

                        sendGaEvent('purchase', {
                            // "debug_mode": true,
                            "transaction_id": String(transaction.id),
                            "currency": String((transaction.currency ? transaction.currency : 'USD')),
                            "value": parseFloat(transaction.amount),
                            "items": [{
                                "id": String(transaction.plan_option_id),
                                "name": String(transaction.description),
                                "price": parseFloat(transaction.amount)
                            }]
                        });

                        transactionIds.push(transaction.id);
                    }

                    if (transactionIds) {
                        api.transaction.updateWasSendToGa({transactionIds: transactionIds});
                    }
                }
            }
        }
    );
}

const initBootstrapSelect = function () {
    const $elCustomSelect = $('.custom-select');
    if ($elCustomSelect.length === 0) {
        return;
    }

    $.fn.selectpicker.Constructor.BootstrapVersion = '4';

    $elCustomSelect.each(function (count, el) {
        $(el).selectpicker({
            // liveSearch: true,
            // liveSearchStyle: 'contains',
            container: 'body',
            dropupAuto: false,
            // multipleSeparator: ','
            // virtualScroll: 600,
        });

        let bsContainerId = $(el).closest('.bootstrap-select').find('[aria-owns]').attr('aria-owns');
        let isMultiselect = $(el).hasClass('multiselect');
        if (!isMultiselect) {
            $('#' + bsContainerId).on('click', function () {
                $(el).selectpicker('toggle');
            });
        }
    });
}

const initButtonsDropdown = function () {
    const $dropdownButtons = $('.dropdown-button');

    if ($dropdownButtons.length === 0) {
        return;
    }

    $dropdownButtons.each(function () {
        const $button = $(this);
        const $menu = $button.next();

        if ($menu.length > 0 && $menu.hasClass('dropdown-menu')) {
            const $buttonClose = $menu.find('.button-close');
            const positionDropdown = $button.data('position-dropdown');

            if (positionDropdown && positionDropdown === 'left') {
                $menu.css({
                    'right': 'auto',
                    'left': '0'
                });
            }

            $button.on('click', function (e) {
                e.preventDefault();

                $button.toggleClass('open');
            });

            $buttonClose.on('click', function () {
                $button.removeClass('open');
            });
        }
    });

    $(document).on('click', function (e) {
        const $targetButtonClick = $(e.target).closest('.dropdown-button');
        const $targetClick = $(e.target).closest('.dropdown-menu');

        if ($targetButtonClick.length <= 0 && $targetClick.length <= 0 && !$(e.target).hasClass('dropdown-button')) {
            $dropdownButtons.removeClass('open');
        }
    });
}

const initGoToHashTag = function () {
    $('a[href^="#"]').click(function(){
        const target = $(this).attr('href');

        if (target === '#' || $(this).data('type') === 'accordion' || $(this).data('toggle') === 'tab') {
            return true;
        }

        if ($(target).length > 0) {
            $('html, body').animate({scrollTop: $(target).offset().top}, 300);
            return false;
        }

        return true;
    });
}

const initButtonsPanelCollapse = function () {
    $('.see-recommendations a, .warning-block a').on('click', function () {
        const target = $(this).attr('href');

        $(target).removeClass('panel-collapsed');
    });

    $('[data-button-action="panel-collapse"]').on('click', function () {
        let $panel = $(this).closest('.st-panel');
        let panelId = $panel.data('panel-id');

        $panel.toggleClass('panel-collapsed');

        if (panelId) {
            localStorage.setItem('st-pc-' + panelId, $panel.hasClass('panel-collapsed'));
        }
    });

    $('[data-panel-id]').each(function () {
        let $panel = $(this);
        let panelId = $panel.data('panel-id');
        let localStoragePanel;

        localStoragePanel = JSON.parse(localStorage.getItem('st-pc-' + panelId));

        if (localStoragePanel !== null && localStoragePanel !== 'null' && localStoragePanel) {
            if (localStoragePanel) {
                $panel.addClass('panel-collapsed');
            } else {
                $panel.removeClass('panel-collapsed');
            }
        }
    });
}

const initButtonsDisallow = function () {
    $('[data-disallow]').on('click', function () {
        let $disallow = $(this).data('disallow');
        let $disallowGroup = $(this).data('disallow-group');

        if ($disallow && $disallow === 'double-click') {
            if ($disallowGroup) {
                $('[data-disallow-group="' + $disallowGroup + '"]').each(function (count, el) {
                    if ($(el).is('button')) {
                        $(this).prop('disabled', true);
                    } else {
                        $(this).addClass('disabled');
                    }
                });
            } else {
                if ($(this).is('button')) {
                    $(this).prop('disabled', true);
                } else {
                    $(this).addClass('disabled');
                }
            }

            if ($(this).attr('type') === 'submit') {
                $(this).closest('form').trigger('submit');
            }
        }
    });
}

const initShowMoreButtons = function () {
    $('[data-button="show-more"]').on('click', function () {
        let $showMoreContainer = $(this).next('.show-more-container');
        $(this).hide();
        $showMoreContainer.stop().fadeToggle();
    });
}

export const initCommonScripts = function () {
    $(document).ready(function() {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: 'json'
        });

        initAction();
        initPersonalDiscount();
        initPersonalCoupons();
        initGoToHashTag();
        initDatapickerRange();
        initMenu();
        initGclidHandler();
        initSendGaEvent();
        initBootstrapSelect();
        initTooltips();
        initButtonsDropdown();
        initButtonsPanelCollapse();
        initButtonsDisallow();
        initShowMoreButtons();
    });
}
