<template>
  <div class="v-review-stars vrs"
       :class="mainClass"
       @mouseleave="selectedRating=0"
       :style="styleContainer"
       @click="chooseStar"
  >
    <div class="vrs__item" v-for="number in [1,2,3,4,5]" :style="styleItem">
      <v-icon-svg name="star" class="vrs__item-icon" @mouseenter="selectedRating=number" :style="styleItem"/>
    </div>
  </div>
</template>

<script setup>

import VIconSvg from "../Base/VIconSvg.vue";
import {computed, ref} from "vue";

const props = defineProps({
  /**
   * Выводимый рейтинг
   */
  value: {},
  /**
   * Размер звездочки в px
   */
  size: {default: 16},
  noSelect: {type:Boolean},
  /**
   * Цвет звездочек
   * @type {'default'|'white-on-blue'}
   */
  color: {default: 'default',}
});

const emit = defineEmits(['chooseRating'])

const viewRating = computed(() => {
  if(props.noSelect){
    return props.value;
  }
  let response;
  if(selectedRating.value > 0) {
    response = selectedRating.value;
  }else{
    response = props.value
  }
  if(response >= 1 || response <=5){
    return response;
  }else{
    return 0;
  }
});

const selectedRating = ref(0);

const mainClass = computed(() => {
  let response = [];
  response.push('vrs--select-'+viewRating.value);
  response.push('vrs--color-'+props.color);
  return response;
});

const styleContainer = computed(() => {
  //gap 4px для 16px ширины звездочки пропорционально увеличиваем к указанной ширине props.size
  return {gap: Math.round(props.size / 16 * 4 * 100)/100 + 'px'}
});

const styleItem = computed(() => {
  return {width: props.size + 'px', height: props.size + 'px',}
});

const chooseStar = () => {
  if(!props.noSelect){
    emit('chooseRating', selectedRating.value);
  }
}


</script>

<style lang="scss" scoped>

$start-no-select-color-default: #E5ECF9;
$start-select-color-default: #1C7EE0;

$start-no-select-color-white-on-blue: #E5ECF9;
$start-select-color-white-on-blue: #FFFFFF;

.v-review-stars.vrs{
  display:flex;
  align-items: center;
  cursor: pointer;

  .vrs__item{
    flex-shrink:0;
    .vrs__item-icon{
      display:block;
    }
  }

  &.vrs--color-default{
    .vrs__item-icon{fill:$start-no-select-color-default;}
    &.vrs--select-1 .vrs__item:nth-child(-n+1) .vrs__item-icon{fill: $start-select-color-default}
    &.vrs--select-2 .vrs__item:nth-child(-n+2) .vrs__item-icon{fill: $start-select-color-default}
    &.vrs--select-3 .vrs__item:nth-child(-n+3) .vrs__item-icon{fill: $start-select-color-default}
    &.vrs--select-4 .vrs__item:nth-child(-n+4) .vrs__item-icon{fill: $start-select-color-default}
    &.vrs--select-5 .vrs__item:nth-child(-n+5) .vrs__item-icon{fill: $start-select-color-default}
  }

  &.vrs--color-white-on-blue{
    .vrs__item-icon{fill:$start-no-select-color-white-on-blue;opacity:0.5;}
    &.vrs--select-1 .vrs__item:nth-child(-n+1) .vrs__item-icon{fill: $start-select-color-white-on-blue;opacity:1;}
    &.vrs--select-2 .vrs__item:nth-child(-n+2) .vrs__item-icon{fill: $start-select-color-white-on-blue;opacity:1;}
    &.vrs--select-3 .vrs__item:nth-child(-n+3) .vrs__item-icon{fill: $start-select-color-white-on-blue;opacity:1;}
    &.vrs--select-4 .vrs__item:nth-child(-n+4) .vrs__item-icon{fill: $start-select-color-white-on-blue;opacity:1;}
    &.vrs--select-5 .vrs__item:nth-child(-n+5) .vrs__item-icon{fill: $start-select-color-white-on-blue;opacity:1;}
  }

}
</style>