<template>
  <v-tooltip
      class="v-form-select-dropdown vfsd"
      placement="bottom"
      ref="refTooltip"
      :btn-close-on="false"
      :get-linked-block="getLinkedBlock"
      :hide-click-outside="false"
      :style="{width:'100%',maxWidth:width+'px'}"
      :offset="[0,2]"
      :max-height-visible-area="true"
  >
    <template v-slot:content>
      <div class="vfsd__wrap">
        <template v-if="options.length > 0">
          <div v-for="option in options" class="vfsd__item" @click="setChecked(option)">
          <span>
            {{option.name}}
          </span>
            <span v-if="selectModel?.value === option.value">
            <v-icon-svg name="check-deep-blue"/>
          </span>
          </div>
        </template>
        <template v-else>
          <div class="vfsd__item vfsd__item--not-found">
            <span>Not found</span>
          </div>
        </template>
      </div>
    </template>
  </v-tooltip>
</template>

<script setup lang="ts">

import {isProxy, isReactive, isRef, ref} from "vue";
import VTooltip from "../Base/VTooltip.vue";
import VIconSvg from "../Base/VIconSvg.vue";

const emit = defineEmits(['change']);

const props = defineProps({
  options:{},
  getLinkedBlock: {},
  width:{}
});

const refTooltip = ref();

const selectModel = defineModel();

const show = () => {
  refTooltip.value.show();
}

const hide = () => {
  refTooltip.value.hide();
}

const setChecked = (option) => {
  if(selectModel?.value?.value != option?.value){
    emit('change', option);
  }
  selectModel.value = option;
}

defineExpose({show,hide});

</script>

<style lang="scss">
.v-form-select-dropdown.vfsd{
  border: 1px solid #D6E3EF;
  box-shadow: 0px 0px 14px 0px #1C7EE02E;
  padding:0px;
  border-radius: 4px;
  .vfsd__item{
    padding: 6px 10px;
    font-size:14px;
    line-height:18px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor:pointer;
    min-height:33px;
    &:hover{
      background: #F4F9FD;
    }
    &.vfsd__item--not-found{
      cursor:initial;
      background:#fff;
    }
  }
}
</style>
<style scoped lang="scss">

</style>