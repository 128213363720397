<template>
  <div :data-name="dataName" data-can-be-question="custom-by-name" class="v-form-group-inline vfgi">
    <div class="vfgi__content">
      <div class="vfgi__content-field">
        <slot name="default"></slot>
      </div>
      <div class="vfgi__content-question" v-if="_hasQuestion || keepSpaceForQuestion">
        <v-tooltip-question-icon v-if="_hasQuestion" :tooltip-data="_tooltipData"/>
      </div>
    </div>
    <div class="vfgi__errors">
      <v-form-field-list-errors :errors="errors" class=""/>
    </div>
  </div>
</template>

<script setup>
import VFormFieldListErrors from "./VFormFieldListErrors.vue";
import VTooltipQuestionIcon from "../Base/VTooltipQuestionIcon.vue";
import useTooltipHelpers from "../../utils/useTooltipHelpers";

const props = defineProps({
  hasQuestion: {type:Boolean,default:false},
  questionDescription: {default: ''},
  errors: {default:[]},
  dataName: {type:String,},
  keepSpaceForQuestion: {type: Boolean, default:false,}
});

const {_hasQuestion, _tooltipData} = useTooltipHelpers(
    'custom',
    props.dataName,
    '',
    props.hasQuestion,
    props.questionDescription
);

defineExpose({
  reallyHasQuestion: !!_hasQuestion?.value,
});

</script>

<style lang="scss" scoped>
.v-form-group-inline.vfgi{
  .vfgi__content{
    display:flex;
    .vfgi__content-field{
      width:100%;
    }
    .vfgi__content-question{
      flex-shrink:0;
      width:30px;
      display:flex;
      justify-content:center;
      align-items:center;
    }
  }
  .vfgi__errors{
    margin-top:-4px;
  }
}
</style>