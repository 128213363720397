
import {defineStore} from "pinia";
import {ApiResource} from "../plugins/ApiExtends";
import {useSecretCodes} from "./useStaticData";

export const useSuggestKeywords = defineStore('extractKeywords', {
    state: () => {
        return {
            currentUrl: null,
            currentLocation: null,
            suggestedKeywords: [],
            selectedKeywords: [],
            message: '',
            loading: false,
            hasFirstDownload: false,
        }
    },
    getters: {

        remainingSuggestedKeywords: (state) => {
            return state.suggestedKeywords.filter((item) => {
                return !state.hasSelectedKeyword(item);
            });
        },

        selectedKeywordsForSend: (state) => {
            let response = [];
            state.selectedKeywords.forEach((item) => {
                let responseItem = {};
                if(item.hasOwnProperty('name')){
                    responseItem.keyword = item.name;
                }else{
                    return;
                }
                if(item.hasOwnProperty('countryCode') && item.countryCode !== 'no_geo'){
                    responseItem.geo = item.countryCode?.toLowerCase();
                }
                if(item.hasOwnProperty('is_suggested')){
                    responseItem.is_suggested = item.is_suggested;
                }else{
                    responseItem.is_suggested = false;
                }
                response.push(responseItem);
            });
            return response;
        },

        keywordsNotFound: (state) => {
            return state.hasFirstDownload && state.suggestedKeywords.length === 0;
        }

    },
    actions: {

        async updateSuggestedKeywords(url, geoCode){
            let resource = new ApiResource({
                url:'/api/v2/keywords/get-extract-keywords',
                method:'get',
                params: {
                    url: url,
                    secret: useSecretCodes().apiSecretAuth,
                    location: geoCode,
                }
            });

            this.$patch({loading: true, hasFirstDownload:true});
            await resource.downloadAsync();
            this.$patch({loading: false});

            if(resource.isSuccess()){
                this.$patch((state) => {

                    state.currentUrl = url;
                    if(resource.data.geo?.toLowerCase() === 'gb'){
                        resource.data.geo = 'uk';
                    }
                    state.currentLocation = resource.data.geo;
                    state.message = resource.data.message;
                    state.suggestedKeywords = [];

                    resource.data.keywords.forEach((keyword) => {
                        if(keyword.geo?.toLowerCase() === 'gb'){
                            keyword.geo = 'uk';
                        }
                        state.suggestedKeywords.push({
                            countryCode: keyword.geo,
                            name: keyword.keyword,
                            is_suggested: true,
                        })
                    });

                });
            }else{
                this.reset();
            }

        },

        isSuggestedKeyword(tag){

            return this.suggestedKeywords.find((suggestedTag) => this.compareSelectedKeywords(suggestedTag,tag));

        },

        addSelectedKeyword(tag){
            if(!tag || !tag.hasOwnProperty('name') || !tag.name){return}
            this.$patch((state) => {
                let forInsert = {name: tag.name};
                if(tag.hasOwnProperty('countryCode') && tag.countryCode){
                    forInsert.countryCode = tag.countryCode;
                }

                if(this.isSuggestedKeyword(tag)){
                    forInsert.is_suggested = true;
                }

                if(!this.hasSelectedKeyword(forInsert)){
                    state.selectedKeywords.push(forInsert);
                }

            });
        },

        compareSelectedKeywords(tagA, tagB){
            if(tagA.hasOwnProperty('countryCode') ^ tagB.hasOwnProperty('countryCode')){
                return false;
            }
            if(tagA.hasOwnProperty('countryCode')){
                if(tagA.countryCode?.toLowerCase() !== tagB.countryCode?.toLowerCase()){
                    return false;
                }
            }
            return tagA.name === tagB.name;
        },

        hasSelectedKeyword(tag){

            let find = this.selectedKeywords.find((item) => {
                return this.compareSelectedKeywords(tag, item);
            });
            return find !== undefined;

        },

        removeSelectedKeyword(tag){
            let removeIndexes = [];
            this.selectedKeywords.forEach((value,index) => {
                if(this.compareSelectedKeywords(tag,value)){
                    removeIndexes.push(index);
                }
            });

            removeIndexes.sort((a, b) => b - a);

            this.$patch((state) => {
                removeIndexes.forEach(index => {
                    state.selectedKeywords.splice(index, 1);
                });
            });

        },

        reset(){
            this.$patch({
                currentUrl: null,
                currentLocation: null,
                suggestedKeywords: [],
                message:'',
                loading:false,
                hasFirstDownload:false,
            })
        },

    }
})