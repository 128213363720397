<template>
  <div class="v-period-simple vps">
    <span
        @click.prevent="tooltip.toggle()"
        class="vps__link"
        ref="link"
    >
      {{formattedDate(startDate)}}
      <span class="vps__link-separator"></span>
      {{formattedDate(endDate)}}
      <v-icon-svg name="calendar-dropdown" class="vps__link-svg"/>
    </span>
    <v-tooltip-period
        :start-date="startDate"
        :end-date="endDate"
        ref="tooltip"
        :get-linked-block="() => link"
        @save="save"
    />
  </div>
</template>

<script setup lang="ts">

import VTooltipPeriod from "./VTooltipPeriod.vue";
import {ref, defineProps, watch} from "vue";
import VIconSvg from "./VIconSvg.vue";

const props = defineProps({
  startDate: {},
  endDate: {},
});

const emit = defineEmits(['save']);

const loading = ref(false);

const tooltip = ref();
const link = ref();

const formattedDate = (date) => {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
  const year = date.getFullYear();
  return `${day}.${month}.${year}`
}

const save = (startDate,endDate) => {
  emit('save',startDate,endDate);
}

const saveWithSuccess = () => {
  tooltip.value.saveWithSuccess();
}

const saveWithError = (message) => {
  tooltip.value.saveWithError(message);
}

defineExpose({saveWithSuccess,saveWithError});

</script>

<style scoped>

.vps__link{
  display: flex;
  align-items: center;
  color:#596C8F;
  cursor:pointer;

  .vps__link-separator{
    background-color: #596C8F;
    width:13px;
    height:1px;
    margin:0px 6px;
  }
  .vps__link-svg{
    fill:none;
    margin-left:3px;
    stroke:#596C8F;
  }
}
</style>