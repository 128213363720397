import { nextTick } from 'vue';

let hasScrolled = false;

export default {
    mounted: async (el, binding) => {
        if(hasScrolled){return;}
        const hash = window.location.hash.slice(1);
        if (binding?.value === hash || el.getAttribute('data-scroll-id') === hash) {
            await nextTick();
            setTimeout(() => {
                hasScrolled = true;
                el.scrollIntoView({ behavior: 'smooth' });
            });
        }
    }
};