<template>
  <div class="v-filter-complex vfc" :class="mainClass">
    <div class="vfc__filters">
      <template v-for="filter in filters">
        <v-filter-simple
            :has-selected="false"
            :skin="skin"
            v-model="model[filter.modelName]"
            v-bind="filter.props"
        />
      </template>
    </div>
    <div class="vfc__selected">
      <template v-for="filter in filters">
        <template v-for="(option,key) in model[filter.modelName]">
          <v-filter-simple-selected-item
              v-model="model[filter.modelName][key]"
              :title-head="filter.props.buttonTitle"
              @remove="removeOption(filter.modelName, key)"
              :skin="skin"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script setup>
import VFilterSimple from "./VFilterSimple.vue";
import VFilterSimpleSelectedItem from "./VFilterSimpleSelectedItem.vue";
import {computed} from "vue";

const model = defineModel();

const props = defineProps({
  filters: {},
  skin: {},
  disabled:{type:Boolean,default:false}
});

const removeOption = (modelName, key) => {
  model.value[modelName].splice(key,1);
}

const mainClass = computed(() => {
  if(props.disabled){
    return 'vfc--disabled';
  }else{
    return '';
  }
});

</script>

<style lang="scss" scoped>
.v-filter-complex.vfc{
  display:flex;
  gap:8px;
  align-items:center;
  justify-content:flex-start;
  flex-wrap:wrap;
  max-width:100%;
  position: relative;
  &.vfc--disabled{
    opacity:0.5;
    &:after{
      content:'';
      display:block;
      position:absolute;
      width:100%;
      height:100%;
      z-index:999;
    }
  }
  .vfc__filters{
    display:flex;
    gap:8px;
    align-items:center;
    justify-content:flex-start;
  }
  .vfc__selected{
    display:flex;
    gap:8px;
    align-items:center;
    justify-content:flex-start;
    flex-wrap:wrap;
    max-width:100%;
  }
}
</style>