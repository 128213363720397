<template>
  <div class="skills-graph-circle">
    <p class="skills-graph-circle__count-text">
      <span class="skills-graph-circle__count" data-role="order-action" :data-action-timeleft="timerSeconds">
        <span>{{ textMinutes }}</span>:<span>{{ textSeconds }}</span>
      </span>
    </p>
    <svg class="skills-graph-circle__svg" viewBox="0 0 46 46">
      <circle cx="24" cy="24" r="24" fill="none" stroke="#5b616e33" stroke-width="3"></circle>
    </svg>
    <svg class="skills-graph-circle__svg skills-graph-circle__svg-fill" viewBox="0 0 46 46">
      <circle
          ref="refCircle"
          stroke-dasharray="150.79644737231007"
          stroke-dashoffset="0" r="24" cx="24" cy="24" fill="none" stroke-linecap="round" stroke="#5b616e" stroke-width="3"
      ></circle>
    </svg>
  </div>
</template>

<script setup>

import {defineProps, onMounted, onUnmounted, ref} from 'vue';

const emit = defineEmits(['timerIsOver']);

const props = defineProps({
  startTime: {},
  timerSeconds: {}
})

const textMinutes = ref('00');
const textSeconds = ref('00');

const refCircle = ref();

const dropped = ref(false);

const periodicall = () => {

  let secondsPassed = Math.floor((Date.now() - props.startTime) / 1000);

  let distance = props.timerSeconds - secondsPassed;

  if(distance <= 0){
    emit('timerIsOver');
    textMinutes.value = '00';
    textSeconds.value = '00';
    refCircle.value.setAttribute('stroke-dashoffset', 0);
    return;
  }

  let days = Math.floor(distance / 86400);
  let hours = Math.floor(distance / 3600) - days * 24;
  let minutes = Math.floor((distance % 3600) / 60);
  let seconds = Math.floor(distance % 60);

  if (minutes < 10) minutes = '0' + minutes;
  if (seconds < 10) seconds = '0' + seconds;

  textMinutes.value = minutes;
  textSeconds.value = seconds;

  let stepCircle = 150.79644737231007 / props.timerSeconds;
  let strokeDashoffset = (stepCircle * (props.timerSeconds - distance)) * -1;
  if(refCircle.value){
    refCircle.value.setAttribute('stroke-dashoffset', strokeDashoffset);
  }

  setTimeout(periodicall, 1000);

}

onMounted(() => {
  periodicall();
})

onUnmounted(() => {
  dropped.value = true;
});

</script>

<style scoped lang="scss">
.skills {
  &-graph-circle {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    width: 48px;
    height: 48px;

    &__svg {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transform: rotateY(-180deg) rotateZ(-90deg);
      overflow: visible;
    }

    &__count-text {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      color: $black-1;
      text-align: start;
      transition: color .15s ease-out,opacity .15s ease-out;
    }

    &__count {
      font-variant-numeric: tabular-nums;
    }

    &__bar {
      stroke: tomato;
    }

    &__backg {
      stroke: transparentize(tomato, .7);
    }
  }
}
</style>