import {computed, reactive, ref} from "vue";

export default function (form){

    const frontErrors = reactive({});

    const formRequestExecuting = computed(() => {return form?.value?.formRequestExecuting ?? false});

    const formErrors = computed(() => {
        if (Object.keys(frontErrors).length > 0) {
            return Object.assign(form?.value?.errors?.value ?? {}, frontErrors);
        } else {
            return form?.value?.errors?.value ?? {}
        }
    });

    const formErrorMessage = computed(() => {return form?.value?.errorMessage ?? ''});

    return {formErrors, frontErrors, formErrorMessage, formRequestExecuting}


}