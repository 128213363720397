import {createApp} from "vue";
import store from './src/store';
import { createPinia } from 'pinia'
import RecsPanel from "./src/components/RecsPanel.vue";
import ChecklistsWidget from "./src/components/ChecklistsWidget.vue";
import ScProjectAdd from "./src/components/SeoClicks/ScProjectAdd.vue";
import ScProjects from "./src/components/SeoClicks/ScProjects.vue";
import ScProject from "./src/components/SeoClicks/ScProject.vue";
import * as Sentry from "@sentry/vue";
import BcMain from "./src/components/BuyCredits/BcMain.vue";
import LbAddProject from "./src/components/LinkBuilding/LbAddProject.vue";
import LbProject from "./src/components/LinkBuilding/LbProject.vue";
import LbProjects from "./src/components/LinkBuilding/LbProjects.vue";
import isMobilePlugin from './src/plugins/isMobile';
import currentHashPlugin from './src/plugins/currentHash';
import ScrollAnchor from "./src/directives/scrollAnchor";
import AutoTooltip from "./src/directives/autoTooltip";
import realServerTime from "./src/plugins/realServerTime";
import BsPageList from "./src/components/BillingSubscriptions/BsPageList.vue";
import BsPageSingle from "./src/components/BillingSubscriptions/BsPageSingle.vue";
import DMain from "./src/components/Documentation/DMain.vue";
import ScProjectsPage from "./src/components/SeoClicks/ScProjectsPage.vue";
import LbProjectsPage from "./src/components/LinkBuilding/LbProjectsPage.vue";

function initSentry(apps){
    if(import.meta.env.VITE_SENTRY === 'true' && import.meta.env.VITE_SENTRY_VUE_DSN && apps.length > 0){
        console.log('Init Sentry');
        Sentry.init({
            app: apps,
            dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/app-test\.upseo\.com/, /^https:\/\/app\.upseo\.com/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
}

let Apps = []
const pinia = createPinia();

const compontentsForMount = {
    '#recommendation-panel-vue': RecsPanel,
    '#seo-clicks-project-add-vue': ScProjectAdd,
    '#seo-clicks-projects-vue': ScProjects,
    '#seo-clicks-projects-page-vue': ScProjectsPage,
    '#seo-clicks-project-vue': ScProject,
    '#buy-credits-vue': BcMain,
    '#link-building-add-project': LbAddProject,
    '#link-building-project': LbProject,
    '#link-building-projects-vue': LbProjects,
    '#link-building-projects-page-vue': LbProjectsPage,
    '#checklists-widget-vue': ChecklistsWidget,
    '#billing-subscriptions-page-list': BsPageList,
    '#billing-subscriptions-page-single': BsPageSingle,
    '#upseo-vue-docs': DMain,
}

initSentry(Apps.map(app => app.app));
Object.entries(compontentsForMount).forEach(([rootContainer, component]) => {
    if(document.querySelector(rootContainer)){
        createApp(component)
            .use(store)
            .use(pinia)
            .use(isMobilePlugin)
            .use(currentHashPlugin)
            .use(realServerTime)
            .provide('fishText', 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis')
            .directive('scroll-anchor', ScrollAnchor)
            .directive('auto-tooltip', AutoTooltip)
            .mount(rootContainer);
    }
});