<template>
  <span class="v-underlined-text" :class="mainClass"><slot/></span>
</template>

<script setup>

import {computed} from "vue";

const props = defineProps({
  cursorClickable: {type:Boolean, default:false},
  cursorDefault: {type:Boolean, default:false},
});

const mainClass = computed(() => {
  let response = [];
  if(props.cursorClickable){
    response.push('is-cursor-clickable');
  }
  if(props.cursorDefault){
    response.push('is-cursor-default');
  }
  return response;
})

</script>

<style lang="scss" scoped>
.v-underlined-text{
  border-bottom: 1px dashed;
  &.is-cursor-clickable{
    cursor:pointer;
  }
  &.is-cursor-default{
    cursor:default;
  }
}
</style>