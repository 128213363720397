<template>
  <div class="lbv-recommended-backlinks-bagel">
    <canvas ref="refCanvas"/>
    <div class="bagel-center-text">
      <div class="bagel-center-text__count">
        {{amountLinks}}
      </div>
      <div class="bagel-center-text__period">
        /month
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, ref, watchEffect} from "vue";
import { Chart as ChartJS } from 'chart.js'
import {useLinkBuildingType} from "../../../stores/useLinkBuilding";

const props = defineProps({
  /**
   * {
   *   const_da10: counts,
   *   const_da20: counts,
   *   ...
   * }
   */
  ratioNumberLinks: {type: Object,default: {}},
  amountLinks: {},
});

const dataArr = computed(() => Object.values(props.ratioNumberLinks).reverse());
const backgroundArr = computed(() =>
    Object.keys(props.ratioNumberLinks).reverse().map((key) =>
        useLinkBuildingType().getColor(key)
    )
);

const refCanvas = ref();

const getData = () => {
  return {
    labels: [],
    datasets: [
      {
        data: dataArr.value,
        backgroundColor: backgroundArr.value,
        borderWidth:0,
      }
    ]
  }
}

const getConfig = () => {
  return {
    type: 'doughnut',
        data: getData(),
        options: {
          animation: {
            duration: 0 // Устанавливаем продолжительность анимации в 0, чтобы её отключить
          },
      responsive: true,
          hover: {mode: null},
      layout:{
        padding: 0
      },
      plugins: {
        tooltip:{
          enabled:false,
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        labels: {
          render: 'label',
              position: 'outside',
              fontSize: 9,
              fontColor: '#384967',
              fontStyle: 600,
              fontFamily: 'Inter',
              textMargin: 3,
        }
      },
      cutout: '76%',
    },
  }
}

onMounted(() => {

  let chartObj = new ChartJS(refCanvas.value,getConfig());

  watchEffect(() => {
    chartObj.data = getData();
    chartObj.update();
  });
})

</script>

<style lang="scss" scoped>
.lbv-recommended-backlinks-bagel{
  position:relative;
  width:133px;
  canvas{
    width:133px;
  }
  .bagel-center-text{
    @include flex(column,center,center,5px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top:2px;
    color:#384967;

    .bagel-center-text__count{
      font-size: 34px;
      font-weight: 700;
      text-align: center;
      line-height:25px;
    }
    .bagel-center-text__period{
      text-align:center;
      opacity:0.6;
      font-size: 11px;
      font-weight: 400;
      line-height: 13px;
    }
  }
}
</style>