export function normalizeSegmentAndPoints(start, end, points = []){

    if (start > end) {
        points = points.map((point) => {
            return end + (start - point);
        });
        [start, end] = [end, start];
    }

    return [start, end, points];

}

/**
 * Расчитывает процент нахождения point между start и end.
 *
 * @param {number} point - Точка, для которой рассчитывается процент.
 * @param {number} start - Начальная точка диапазона.
 * @param {number} end - Конечная точка диапазона.
 * @returns {number} Процент нахождения point между start и end.
 */
export function calculatePercentage(point, start, end) {

    // Нормализуем порядок start, end и point
    [start, end, [point]] = normalizeSegmentAndPoints(start, end, [point]);

    // Вычисляем диапазон
    const range = end - start;

    // Если диапазон равен 0, возвращаем 0, чтобы избежать деления на 0
    if (range === 0) {
        return 0;
    }

    // Если point находится вне диапазона, возвращаем 0 или 100
    if (point <= start) {
        return 0;
    } else if (point >= end) {
        return 100;
    }

    // Вычисляем и возвращаем процентное соотношение
    return Math.round((point - start) / range * 100 ) * 100 / 100;

}

/**
 * Рассчитывает процент длины отрезка между pointFirst и pointEnd относительно диапазона между start и end.
 *
 * @param {number} pointFirst - Начальная точка отрезка.
 * @param {number} pointEnd - Конечная точка отрезка.
 * @param {number} start - Начальная точка диапазона.
 * @param {number} end - Конечная точка диапазона.
 * @returns {number} Процент длины отрезка относительно диапазона.
 */
export function calculateSegmentPercentage(pointFirst, pointEnd, start, end) {
    // Нормализуем порядок start и end
    [start, end, [pointFirst, pointEnd]] = normalizeSegmentAndPoints(start, end, [pointFirst, pointEnd]);

    // Нормализуем порядок pointFirst и pointEnd
    if (pointFirst > pointEnd) {
        [pointFirst, pointEnd] = [pointEnd, pointFirst];
    }

    // Вычисляем длину диапазона и отрезка
    const rangeLength = end - start;
    const segmentLength = Math.min(Math.max(pointEnd, start), end) - Math.max(Math.min(pointFirst, end), start);

    // Если диапазон равен 0, возвращаем 0, чтобы избежать деления на 0
    if (rangeLength === 0) {
        return 0;
    }

    // Вычисляем процент длины отрезка относительно диапазона
    const percentage = (segmentLength / rangeLength) * 100;

    // Ограничиваем процент до диапазона от 0 до 100
    return Math.min(Math.max(percentage, 0), 100);
}

/**
 * Преобразует шестнадцатеричный цвет в объект RGB.
 * @param {string} hex - Шестнадцатеричный цвет.
 * @returns {Array} Массив с числами [r,g,b]
 */
function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    return [
        (bigint >> 16) & 255,
        (bigint >> 8) & 255,
        bigint & 255,
    ];
}

/**
 * Преобразует объект RGB в шестнадцатеричный цвет.
 * @param {Number} r - red
 * @param {Number} g - green
 * @param {Number} b - blue
 * @returns {string} Шестнадцатеричный цвет.
 */
function rgbToHex(r, g, b) {
    return (
        '#' +
        ((1 << 24) + (r << 16) + (g << 8) + b)
            .toString(16)
            .slice(1)
            .toUpperCase()
    );
}

/**
 * Вычисляет промежуточный цвет градиента
 * @param {string} startColor - Начальный цвет в шестнадцатеричном формате.
 * @param {string} endColor - Конечный цвет в шестнадцатеричном формате.
 * @param {number} percent - Процент (0-100).
 * @returns {string} Промежуточный цвет в шестнадцатеричном формате.
 */
export function getIntermediateGradientColor(startColor, endColor, percent) {
    return getGradientColor([[startColor,0],[endColor,100]], percent);
}
export function getGradientColor(gradient, value) {

    if(value < 0){
        value = 0;
    }else if(value > 100){
        value = 100;
    }else if(!(value >= 0 && value <= 100)){
        return '#000000';
    }

    // Находим два цвета, между которыми находится значение
    let startColor, endColor, startValue, endValue;

    for (let i = 0; i < gradient.length - 1; i++) {
        startColor = gradient[i][0];
        startValue = gradient[i][1];
        endColor = gradient[i + 1][0];
        endValue = gradient[i + 1][1];

        if (value >= startValue && value <= endValue) {
            break;
        }
    }

    // Преобразуем цвета из шестнадцатеричной системы в RGB
    const startRgb = hexToRgb(startColor);
    const endRgb = hexToRgb(endColor);

    const ratio = (value - startValue) / (endValue - startValue);
    const interpolatedRgb = [
        Math.round(startRgb[0] + ratio * (endRgb[0] - startRgb[0])),
        Math.round(startRgb[1] + ratio * (endRgb[1] - startRgb[1])),
        Math.round(startRgb[2] + ratio * (endRgb[2] - startRgb[2])),
    ];

    return rgbToHex(...interpolatedRgb);

}

export function calculateScaleYMaxForBar(maxHits){
    let maxHitsD = parseInt(maxHits + maxHits / 10);
    let maxY = 10;

    if (maxHitsD <= 10) maxY = 10;
    else if (maxHitsD > 10 && maxHitsD <= 50) maxY = 50;
    else if (maxHitsD > 50 && maxHitsD <= 100) maxY = 100;
    else if (maxHitsD > 100 && maxHitsD <= 150) maxY = 150;
    else if (maxHitsD > 150 && maxHitsD <= 200) maxY = 200
    else if (maxHitsD > 200 && maxHitsD <= 250) maxY = 250
    else if (maxHitsD > 250 && maxHitsD <= 300) maxY = 300
    else if (maxHitsD > 300 && maxHitsD <= 350) maxY = 350
    else if (maxHitsD > 350 && maxHitsD <= 400) maxY = 400
    else if (maxHitsD > 400 && maxHitsD <= 450) maxY = 450
    else if (maxHitsD > 450 && maxHitsD <= 500) maxY = 500
    else if (maxHitsD > 500 && maxHitsD <= 550) maxY = 550
    else if (maxHitsD > 550 && maxHitsD <= 600) maxY = 600
    else if (maxHitsD > 600 && maxHitsD <= 650) maxY = 650
    else if (maxHitsD > 650 && maxHitsD <= 700) maxY = 700
    else if (maxHitsD > 700 && maxHitsD <= 750) maxY = 750
    else if (maxHitsD > 750 && maxHitsD <= 800) maxY = 800
    else if (maxHitsD > 800 && maxHitsD <= 850) maxY = 850
    else if (maxHitsD > 850 && maxHitsD <= 900) maxY = 900
    else if (maxHitsD > 900 && maxHitsD <= 950) maxY = 950
    else if (maxHitsD > 950 && maxHitsD <= 1000) maxY = 1000
    else if (maxHitsD > 1000 && maxHitsD <= 1500) maxY = 1500
    else if (maxHitsD > 1500 && maxHitsD <= 2000) maxY = 2000
    else if (maxHitsD > 2000 && maxHitsD <= 2500) maxY = 2500
    else if (maxHitsD > 2500 && maxHitsD <= 3000) maxY = 3000
    else if (maxHitsD > 3000 && maxHitsD <= 3500) maxY = 3500
    else if (maxHitsD > 3500 && maxHitsD <= 4000) maxY = 4000
    else if (maxHitsD > 4000 && maxHitsD <= 4500) maxY = 4500
    else if (maxHitsD > 4500 && maxHitsD <= 5000) maxY = 5000
    else if (maxHitsD > 5000 && maxHitsD <= 5500) maxY = 5500
    else if (maxHitsD > 5500 && maxHitsD <= 6000) maxY = 6000
    else if (maxHitsD > 6000 && maxHitsD <= 6500) maxY = 6500
    else if (maxHitsD > 6500 && maxHitsD <= 7000) maxY = 7000
    else if (maxHitsD > 7000 && maxHitsD <= 7500) maxY = 7500
    else if (maxHitsD > 7500 && maxHitsD <= 8000) maxY = 8000
    else if (maxHitsD > 8000 && maxHitsD <= 8500) maxY = 8500
    else if (maxHitsD > 8500 && maxHitsD <= 9000) maxY = 9000
    else if (maxHitsD > 9000 && maxHitsD <= 9500) maxY = 9500
    else if (maxHitsD > 9500 && maxHitsD <= 10000) maxY = 10000
    else if (maxHitsD > 10000) {
        let maxHits1 = parseInt(maxHitsD / 1000);
        maxHitsD = parseInt(String(maxHits1) + '000');
        maxY = maxHitsD + 2000
    }
    return maxY;
}

export function calculateScaleYMaxForLine(maxHits){
    let maxHitsD = parseInt(maxHits + ((maxHits / 3) * 2));

    let maxY = 10;

    if (maxHitsD <= 10) {
        maxY = 10
    } else if (maxHitsD > 10 && maxHitsD <= 30) {
        maxY = 30
    } else if (maxHitsD > 30 && maxHitsD <= 50) {
        maxY = 50
    } else if (maxHitsD > 50 && maxHitsD <= 100) {
        maxY = 100
    } else if (maxHitsD > 100 && maxHitsD <= 300) {
        maxY = 300
    } else if (maxHitsD > 300 && maxHitsD <= 500) {
        maxY = 500
    } else if (maxHitsD > 500 && maxHitsD <= 1000) {
        maxY = 1000
    } else if (maxHitsD > 1000 && maxHitsD <= 3000) {
        maxY = 3000
    } else if (maxHitsD > 3000 && maxHitsD <= 5000) {
        maxY = 5000
    } else if (maxHitsD > 5000 && maxHitsD <= 10000) {
        maxY = 10000
    } else if (maxHitsD > 10000) {
        let maxHits1 = parseInt(maxHitsD / 1000);
        // let maxHits2 = parseInt(maxHitsD % 1000);

        maxHitsD = parseInt(String(maxHits1) + '000');
        maxY = maxHitsD + 3000;
    }
    return maxY
}