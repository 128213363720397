<template>
  <div class="v-bs-bonus-chart">
    <div class="columns">
      <div
          v-for="(col,key) in columns"
          class="column"
          :class="[
            (key === active) ? 'is-active' : '',
            (key <= fillTo && fillTo!==null) ? 'is-filling' : '',
          ]"
          :style="{height: col.height + '%'}"
          @click="emit('columnClick', key)"
      ></div>
    </div>
    <div class="months">
      <span v-for="month in months" v-text="month"/>
    </div>
  </div>
</template>

<script setup>

import {computed, ref} from "vue";

const props = defineProps({
  /**
   * @type {Number}
   */
  active: {},
  /**
   * @type {Number}
   */
  fillTo: {default:null,},
  /**
   * @type {Number}
   */
  countColumns: {default: 12,},
  /**
   * @type {Number}
   */
  columnStartHeight: {default: 15.38,},
  /**
   * @type {Date}
   */
  dateStart: {type:Date,}
});

const emit = defineEmits(['columnClick']);

const columnHeightStep = computed(() => {
  return (100 - props.columnStartHeight) / (props.countColumns - 1);
});

const columns = computed(() => {
  let response = [];
  for(let i = 0; i < props.countColumns; i++){
    response.push({
      height: props.columnStartHeight + columnHeightStep.value * i ,
    });
  }
  response[response.length - 1].height = 100;
  return response;
});

const months = computed(() => {
  const countDates = 5;
  const options = { month: 'short', year: '2-digit' };
  let dates = [];

  const start = new Date(props.dateStart);
  start.setMonth(start.getMonth() + 1);
  const end = new Date(props.dateStart);
  end.setFullYear(end.getFullYear() + 1);

  const timeInterval = (end - start) / (countDates-1); // интервал между датами

  for (let i = 0; i < countDates+1; i++) {
    const date = new Date(start.getTime() + (i * timeInterval));
    const formattedDate = date.toLocaleDateString('en-US', options);
    dates.push(formattedDate);
  }

  return dates.slice(0,countDates);
});

</script>

<style lang="scss" scoped>
.v-bs-bonus-chart{
  @include flex(column,start,stretch,8px);
  width:304px;

  .columns{
    @include flex(row, between, end);
    width:100%;
    height:91px;
    position:relative;

    &:after{
      content:'';
      position:absolute;
      width:100%;
      height:1px;
      background:#A1CEA54F;
      bottom:0px;
    }

    .column{
      width:17.85px;
      background:#E6F3E6;
      border:2px solid #E6F3E6;
      cursor:pointer;
      transition-duration:0.2s;
      &.is-filling{
        border-color:#C6E1C6;
        background-color:#C6E1C6
      }
      &.is-active{
        border-color:#219E26;
      }
    }
  }

  .months{
    @include flex(row,between,center,0px);
    width:100%;
    font-size: 10px;
    color:#79A67D;
  }
}
</style>