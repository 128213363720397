<template>
  <div class="v-product vp" :class="mainClass">
    {{displayedText}}
  </div>
</template>

<script setup>

import {computed} from "vue";
import {useLinkBuildingType} from "../../stores/useLinkBuilding";

const props = defineProps({
  /**
   * @type {'da10'|'da20'|'da30'|'da40'|'da50'|'seo_credit'}
   */
  typeConstant: {},
  isShort: {default: false,type:Boolean,},
  isGray: {default:false, type:Boolean,},
  withoutPlus: {default:false, type:Boolean,}
});

const displayedText = computed(() => {
  let response;
  if(props.isShort){
    response = useLinkBuildingType().getTitleShort(props.typeConstant);
  }else{
    response = useLinkBuildingType().getTitleUsual(props.typeConstant);
  }
  if(props.withoutPlus){
    response = response.replace(/\+$/, '');
  }
  return response;
});

const mainClass = computed(() => {
  let response = [];
  response.push('vp--' + props.typeConstant);
  if(props.isGray){
    response.push('vp--is-gray');
  }
  return response;
});

</script>

<style lang="scss" scoped>
.v-product.vp{
  font-size: 14px;
  font-weight: 600;
  &.vp--da10{color:#CC99EB;}
  &.vp--da20{color:#B26EDC;}
  &.vp--da30{color:#9A59C2;}
  &.vp--da40{color:#783B9E;}
  &.vp--da50{color:#612B83;}
  &.vp--seo_credit{color:#224CB9;}
  &.vp--is-gray{color:#AEB7C7;}

}
</style>