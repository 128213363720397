<template>
  <div class="lb-project-analytics lbpa">

    <template v-if="recsStore.statusShow === 'recoms'">
      <div class="lbpa__body">
        <div class="lbpa__head">

          <div class="lbpa__title" data-name="recommendations-analytic" v-auto-tooltip>
            Analytics
          </div>
          <div class="lbpa__tabs">
            <v-link
                v-for="tab in tabs"
                :href="tab.link"
                class="lbpa__tabs-item"
                :class="(activeTab === tab.link) ? 'lbpa__tabs-item--active' : ''"
                v-text="tab.title"
                @click.prevent="activeTab = tab.link"
            />
          </div>

        </div>
        <div class="lbpa__content">

          <div class="lbpa__recommendations">
            <div class="lbpa__recommendations-list">
              <template
                  v-for="(recommendation) in recsStore.recommendations"
                  :key="recommendation.metric"
              >
                <reco-block
                    v-show="objRecoDisplay[recommendation.metric]"
                    :reco-metric="recommendation.metric"
                    :project-uniquekey="projectUniquekey"
                    class="lbpa__recommendations-list-item"
                />
              </template>
            </div>
            <div class="lbpa__recommendations-show-more" v-if="showMore">
              <v-link v-if="showMoreOpen===false" href="#" @click.prevent="showMoreOpen = true">Show {{countCurrentRecommendations - previewCount}} more</v-link>
              <v-link v-if="showMoreOpen===true" href="#" @click.prevent="showMoreOpen = false;">Hide {{countCurrentRecommendations - previewCount}}</v-link>
            </div>
          </div>
          <div class="lbpa__loader" v-if="recomsLoading">
            <div class="lbpa__loader-icon-wrap">
              <v-loader-ship color-line-one="#1C7EE080" color-line-two="#1C7EE0" class="lbpa__loader-icon"/>
            </div>
            <div class="lbpa__loader-text">Analysing your website ...</div>
          </div>

        </div>
      </div>
      <div class="lbpa__sidebar" v-if="true">

        <v-circle-state
            v-if="recsStore.optimizationRatingPercent!==null"
            class="lbpa__rating-circle" :color="recsStore.optimizationRatingType"
            :value="recsStore.optimizationRatingPercent"
            :size="isMobile ? 'middle' : 'big'"
        />

      </div>
    </template>

    <template v-else-if="recsStore.statusShow === 'norecoms'">
      <div class="lbpa__norecoms">
        No recommendations for this project
      </div>
    </template>

    <template v-else-if="recsStore.statusShow === 'error'">
      <div class="lbpa__error">
        An error occurred on the server when uploading recommendations
      </div>
    </template>
  </div>
</template>

<script setup>
import VLink from '../Base/VLink.vue';
import {computed, getCurrentInstance, inject, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import VCircleState from "../Base/VCircleState.vue";
import RecoBlock from "../Reco/RecoBlock.vue";
import {useRecsStore} from "../../stores/useRecsStore";
import VLoaderShip from "../Base/VLoaderShip.vue";
import VTooltipQuestionIcon from "../Base/VTooltipQuestionIcon.vue";

const props = defineProps({
  projectUniquekey: {},
});

const recsStore = useRecsStore(props.projectUniquekey);

const activeTab = ref('#all');
const tabs = reactive([
  {
    'title' : 'All',
    'link' : '#all',
  },
  {
    'title' : 'External',
    'link' : '#external',
  },
  {
    'title' : 'Internal',
    'link' : '#internal',
  },
]);

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const previewCount = computed(() => {
  return isMobile.value ? 2 : 4;
});

const countCurrentRecommendations = computed(() => {
  let count = 0;
  Object.keys(recsStore.recommendations).forEach((recommendationKey) => {
    let recommendation = recsStore.recommendations[recommendationKey];
    if(activeTab.value === '#all'
        || (activeTab.value === '#external' && recommendation.group_name === 'Backlinks')
        || (activeTab.value === '#internal' && recommendation.group_name === 'Content')
    ){
      count++;
    }
  })
  return count;
});

const showMoreOpen = ref(false);
const showMore = computed(() => {
  if(countCurrentRecommendations.value > previewCount.value){
    return true;
  }else{
    return false;
  }
});

const objRecoDisplay = computed(() => {
  let show = {};
  let countShowed = 0;
  Object.keys(recsStore.recommendations).forEach((recommendationKey) => {
    let recommendation = recsStore.recommendations[recommendationKey];
    if(activeTab.value === '#all'
        || (activeTab.value === '#external' && recommendation.group_name === 'Backlinks')
        || (activeTab.value === '#internal' && recommendation.group_name === 'Content')
    ){
      if(!showMoreOpen.value && countShowed >= previewCount.value){
        show[recommendationKey] = false;
      }else{
        countShowed++;
        show[recommendationKey] = true;
      }
    }else{
      show[recommendationKey] = false;
    }
  });
  return show;
});

watch(() => activeTab.value, () => showMoreOpen.value = false);

const recomsLoading = computed(() => recsStore.loading);

</script>

<style lang="scss" scoped>
.lb-project-analytics.lbpa{
  width:100%;
  padding: 20px;
  border-radius: 4px;
  background-color:#F7FAFF;
  display:flex;
  align-items: flex-start;
  justify-content: space-between;
  gap:20px;
  position: relative;

  @media(max-width: 767px){
    gap:0px;
    display:block;
  }

  .lbpa__body{
    display:flex;
    flex-direction:column;
    gap:20px;
    align-items:stretch;
    width:100%;
    .lbpa__head{
      display:flex;
      gap:12px;
      align-items:center;
      @media(max-width:767px){
        flex-direction:column;
        align-items:flex-start;
      }
    }
    .lbpa__content{
      width:100%;
      display:flex;
      flex-direction:column;
      align-items:stretch;
      gap:20px;
      @media(max-width:767px){
        gap:16px;
      }
    }
  }

  .lbpa__sidebar{
    flex-shrink:0;
    width:86px;
    min-height:80px;
    @media(max-width: 767px){
      width:0px;
      min-height:initial;
    }
  }

  .lbpa__title{
    font-size: 14px;
    font-weight: 600;
    color: #384967;
    display:flex;
    gap:6px;
    align-items:center;
  }

  .lbpa__tabs{
    display:flex;
    align-items:center;
    gap:2px;
    .lbpa__tabs-item{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      padding: 5px 10px 5px 10px;
      border-radius: 50px;
      transition-duration:0.2s;

      &.lbpa__tabs-item--active{
        background-color:#384967;
        color:#fff;
        cursor:pointer;
      }
    }
  }

  .lbpa__recommendations{
    display:flex;
    flex-direction: column;
    align-items: stretch;
    gap:20px;
    @media(max-width:767px){
      gap:16px;
    }
    .lbpa__recommendations-list{
      display:flex;
      gap:20px 24px;
      flex-wrap:wrap;
      @media(max-width:767px){
        gap:18px;
        flex-direction:column;
        align-items:stretch;
      }
      .lbpa__recommendations-list-item{
        width:50%;
        width:calc(50% - 12px);
        @media(max-width:767px){
          width:100%;
        }
      }
    }
    .lbpa__recommendations-show-more{
      font-size:13px;
      line-height:18px;
    }
  }

  .lbpa__loader{
    display:flex;
    gap:16px;
    align-items:center;
    justify-content:flex-start;
    color: #596C8F;
    .lbpa__loader-icon{
      width:20px;
      height:20px;
    }
    .lbpa__loader-text{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #798CAE;
    }
  }

  .lbpa__norecoms,.lbpa__error {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #00000080;
    padding: 20px;
  }

  .lbpa__rating-circle{
    position:absolute;
    top:10px;
    right:16px;
  }

}
</style>