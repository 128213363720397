import {runIfHasSelecter} from "../../utils/panel-lib";
import {api} from "../../utils/panel-api";
import _ from "lodash";

const initCommon = () => {
    console.log('gu');
    let $multiplier = $('[data-role="multiplier"]');
    let $sizeInput = $('[data-role="size-input"]');
    let $titleInput = $('[data-role="title-input"]');
    let $urlInput = $('[data-role="url-input"]');
    let $addButton = $('[data-ajax-action="add-form"]');
    let $errorBox = $('[data-role="error"]');
    let $youtubeErrorBox = $('#youtube-error');
    let $wrongFormErrorBox = $('#msgfail');

    let credits = $multiplier.data('credits');
    let sizes = _.reduce($multiplier.data('sizes'), function (acc, size) {
        acc[size.code] = size;

        return acc;
    }, {});

    let $multiplierRange = $('#multiplier_range_slider');
    $multiplierRange.slider({
        range: "min",
        value: 1,
        min: 1,
        max: 12,
        step: 1,
        slide: function (event, ui) {
            $('[data-role="multiplier--input"]').val(ui.value).trigger('change');
            $('[data-role="multiplier--value"]').text(ui.value + 'x');
        },
        change: function (event, ui) {
            $('[data-role="multiplier--input"]').val(ui.value).trigger('change');
            $('[data-role="multiplier--value"]').text(ui.value + 'x');
        }
    });
    $multiplierRange.slider('value', $('[data-role="multiplier--input"]').val());

    const handleSizeChanging = function () {
        let size = $sizeInput.val();
        let creditCode = 'credits_' + size;
        let sizeConfig = sizes[size];
        let creditMax = credits[creditCode];
        let multiplierMax = (creditMax < 0 ? 0 : creditMax);

        $multiplierRange.slider('option', 'max', (creditMax > 12 ? creditMax : 12));
        $multiplierRange.slider('option', 'value', (multiplierMax > 0 ? multiplierMax : 1));

        $('[data-role="multiplier--max"]').text(multiplierMax);
        $('[data-role="multiplier--value"]').text((multiplierMax > 0 ? multiplierMax + 'x' : 1 + 'x'));
        if (sizeConfig) {
            $('[data-role="multiplier--size"]').text(sizeConfig.captionShort);
        }

        if (size !== 'nano' && size !== 'demo') {
            $multiplier.show();
        } else {
            $multiplier.hide();
        }
    }

    $sizeInput.on('change', handleSizeChanging);
    handleSizeChanging();

    $addButton.on('click', function(e) {
        e.preventDefault();

        let data = {
            size: $sizeInput.val(),
            multiplier: $('[data-role="multiplier--input"]').val(),
            title: $titleInput.val(),
            url: $urlInput.val(),
        }

        let $button = $(this);
        let $form = $button.closest('form');
        let formId = $form.data('form-id');

        let formData = $form.serializeArray();
        formData.push({name: 'formId', value: formId});

        $youtubeErrorBox.hide();
        $wrongFormErrorBox.hide();
        $errorBox.html('').hide();

        $button.prop('disabled', true);

        api.guaranteedProject.add(data,
            function (response) {
                let data = response.data;

                if (data.success) {
                    if (data.projectId) {
                        window.location.replace('/project?id=' + data.projectId);
                    } else {
                        window.location.replace("/projects");
                    }
                } else {
                    $button.prop('disabled', false);

                    if (data.message === 'YOUTUBEERROR') {
                        $youtubeErrorBox.show();
                    } else if (data.message === 'WRONGFORM') {
                        $wrongFormErrorBox.show();
                    } else {
                        $errorBox.html(data.message).show();
                    }
                }
            },
            function (xhr) {
                let errors = xhr.responseJSON.errors;

                // console.log(xhr);

                // formValidationErrorHandling($form, errors);

                $button.prop('disabled', false);
            });
    });
}

export const initGuaranteedProjectAddScripts = () => {
    runIfHasSelecter('[data-role="guaranteed-project-add"]', [
        initCommon,
    ]);
}
