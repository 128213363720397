import {runIfHasSelecter} from "../../utils/panel-lib";

const initAction = function () {
    // Show/Hide password
    $('.icon-showhide-password').on('click', function () {
        let $inputPassword = $(this).prev('input[data-showhide]');

        if ($inputPassword.attr('type') === 'password') {
            $inputPassword.attr('type', 'text');
        } else {
            $inputPassword.attr('type', 'password');
        }
    });
}

export const initLoginScripts = function () {
    runIfHasSelecter('[data-role="login"]', [
        initAction
    ]);
}