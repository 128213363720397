<template>
  <div class="vlb-difficulty-chart">
    <div class="chart-wrap">
      <canvas ref="refCanvas"/>
    </div>
    <div
        class="text"
        :style="{color:useKeywordDifficultySettings().getColorForPercent(value)}"
        v-text="value"
    />
  </div>
</template>

<script setup>

import {onMounted, ref, watchEffect} from "vue";
import {Chart as ChartJS} from "chart.js";
import {calculatePercentage, getIntermediateGradientColor} from "../../../utils/chartUtils";
import {useKeywordDifficultySettings} from "../../../stores/useLinkBuilding";

const props = defineProps({
  value: {},
});

const refCanvas = ref();

function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

function getGradient(ctx, chartArea) {

  function getPos(num){
    return num / 150;
  }

  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;

  const gray = '#DFE7F5';

  const gradient = ctx.createConicGradient(degreesToRadians(-90), chartWidth/2, chartHeight/2);

  for(let i = 0; i < useKeywordDifficultySettings().data.length; i++){

    let data = useKeywordDifficultySettings().data[i];
    let dataPrev = i > 0 ? useKeywordDifficultySettings().data[i-1] : null;

    if(props.value > data.percent){
      gradient.addColorStop(getPos(data.percent), data.color);
    }else if(dataPrev){
      gradient.addColorStop(
          getPos(props.value),
          getIntermediateGradientColor(
              dataPrev.color,
              data.color,
              calculatePercentage(
                  props.value,
                  dataPrev.percent,
                  data.percent
              )
          )
      );
    }

  }

  gradient.addColorStop(getPos(props.value), gray);
  gradient.addColorStop(getPos(100), gray);
  gradient.addColorStop(getPos(100), "transparent");
  gradient.addColorStop(getPos(150), "transparent");

  return [gradient];

}

function getBackgroundColorForChart(context){
  const chart = context.chart;
  const {ctx, chartArea} = chart;
  if (!chartArea) {
    return;
  }
  return getGradient(ctx, chartArea);
}

const getData = () => {
  return {
    labels: [],
    datasets: [
      {
        data: useKeywordDifficultySettings().lengths.concat([50]), // 50 - для серого цвета
        backgroundColor: getBackgroundColorForChart,
        spacing:2,
        borderWidth:0,
      }
    ]
  }
}

const getConfig = () => {
  return {
    type: 'doughnut',
    data: getData(),
    options: {
      responsive: true,
      hover: {mode: null},
      animation: {
        duration: 0 // Устанавливаем продолжительность анимации в 0, чтобы её отключить
      },
      layout:{
        padding: 0
      },
      plugins: {
        tooltip:{
          enabled:false,
        },
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        labels: {
          render: 'label',
          position: 'outside',
          fontSize: 9,
          fontColor: '#384967',
          fontStyle: 600,
          fontFamily: 'Inter',
          textMargin: 3,
        }
      },
      cutout: '75%',
    },
  }
}

onMounted(() => {

  let chartObj = new ChartJS(refCanvas.value,getConfig());

  watchEffect(() => {
    chartObj.data = getData();
    chartObj.update();
  });
})

</script>

<style lang="scss" scoped>
.vlb-difficulty-chart{
  position:relative;
  height:55px;
  overflow:hidden;
  .text{
    font-size: 26px;
    font-weight: 700;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    line-height: 18px;
    color: #219E26;
    text-align: center;
    bottom:10px;
  }
  .chart-wrap{
    width:80px;
    height:80px;
    transform:rotate(-120deg);
  }

}
</style>