<template>
  <div class="v-tabs vt" :class="mainClass">
    <slot/>
  </div>
</template>

<script setup>

import {provide,computed} from 'vue'

const model = defineModel();

const props = defineProps({
  /**
   * @type {'default'|'container'}
   */
  type: {default:'default',},
});

const mainClass = computed(() => {
  return 'vt--type-'+props.type;
});

provide('vTabsModel', model);
provide('vTabsType', props.type);

</script>

<style lang="scss" scoped>
.v-tabs.vt{

  &.vt--type-default{
    display:flex;
    border-bottom: 1px solid #D6E3EF80;
  }

  &.vt--type-container{
    display:flex;
  }

}
</style>