<template>
  <div class="lb-orders-backlinks lbob">

    <v-table-info class="lbob__table" v-if="!isMobile" is-sticky-head>
      <thead>
      <tr>
        <th class="lbob__table-th-status">Status</th>
        <th class="lbob__table-th-date-id">Date/ID</th>
        <th class="lbob__table-th-type">Backlink</th>
        <th class="lbob__table-th-keyword-domain">Keyword</th>
        <th class="lbob__table-th-article-review">Blog Post</th>
      </tr>
      </thead>
      <tbody>
        <tr
            v-for="item in linkBuildOrdersStore.ordersManager().ordersSortedByCreatedAt"
            class="lbob__table-tr"
            :class="'lbob__table-tr--'+item.statusConstant"
        >

          <td class="lbob__table-td-status">
            <div>
              <v-lb-order-status :status-constant="item.statusConstant" class="lbob__order-status"/>
            </div>
          </td>
          <td class="lbob__table-td-date-id">
            <div>
              <div class="lbob__order-date">
                {{item.createdAtFormatted}}
              </div>
              <div class="lbob__order-id">
                {{item.id}}
              </div>
            </div>
          </td>
          <td class="lbob__table-td-type">
            <div>
              <v-product :type-constant="item.typeConstant" is-short :is-gray="item.statusConstant==='canceled'"/>
            </div>
          </td>
          <td class="lbob__table-td-keyword-domain">
            <div>
              <div class="lbob__order-keyword">
                {{item.keyword}}
              </div>
              <v-link class="lbob__order-link" :href="item.correctUrl" target="_blank">
                {{item.urlShort}}
              </v-link>
            </div>
          </td>
          <td class="lbob__table-td-article-review">
            <div>
              <v-link
                  v-if="item.result_url && item.statusConstant==='delivered'"
                  :href="item.result_url"
                  target="_blank"
                  class="lbob__order-result-url"
              >
                {{item.resultUrlShort}}
              </v-link>
              <lb-order-review
                  class="lbob__order-review"
                  v-if="item.statusConstant==='delivered'"
                  :project-uniquekey="projectUniquekey"
                  :order-id="item.id"
              />
            </div>
          </td>

        </tr>
      </tbody>
    </v-table-info>

    <div class="lbob__list" v-if="isMobile">
      <div
          v-for="item in linkBuildOrdersStore.ordersManager().ordersSortedByCreatedAt"
          class="lbob__list-item"
          :class="'lbob__list-item--'+item.statusConstant"
      >

        <div class="lbob__list-item-head">
          <div class="lbob__list-item-head-left">

            <div class="lbob__list-item-date-id">

              <div class="lbob__order-date">
                {{item.createdAtFormatted}}
              </div>
              <div class="lbob__order-id">
                {{item.id}}
              </div>

            </div>

            <div class="lbob__list-item-type">
              <v-product :type-constant="item.typeConstant" is-short :is-gray="item.statusConstant==='canceled'"/>
            </div>

          </div>
          <div class="lbob__list-item-head-right">
            <div class="lbob__list-item-status">
              <v-lb-order-status :status-constant="item.statusConstant" class="lbob__order-status"/>
            </div>
          </div>
        </div>

        <div class="lbob__list-item-body">
          <div class="lbob__list-item-body-row">
            <div class="lbob__list-item-body-table-row-left">Website:</div>
            <div class="lbob__list-item-body-table-row-right">
              <div class="lbob__list-item-domain">
                <v-link class="lbob__order-link" :href="item.correctUrl" target="_blank">
                  {{item.urlShort}}
                </v-link>
              </div>
            </div>
          </div>
          <div class="lbob__list-item-body-row">
            <div class="lbob__list-item-body-table-row-left">Keyword:</div>
            <div class="lbob__list-item-body-table-row-right">
              <div class="lbob__list-item-keyword">
                <div class="lbob__order-keyword">
                  {{item.keyword}}
                </div>
              </div>
            </div>
          </div>
          <div class="lbob__list-item-body-row" v-if="item.result_url && item.statusConstant==='delivered'">
            <div class="lbob__list-item-body-table-row-left">Blog Post:</div>
            <div class="lbob__list-item-body-table-row-right">
              <div class="lbob__list-item-article">
                <v-link
                    :href="item.result_url"
                    target="_blank"
                    class="lbob__order-result-url"
                >
                  {{item.resultUrlShort}}
                </v-link>
              </div>
            </div>
          </div>
        </div>

        <div class="lbob__list-item-footer" v-if="item.statusConstant==='delivered'">
          <div class="lbob__list-item-review">
            <lb-order-review
                class="lbob__order-review"
                :project-uniquekey="projectUniquekey"
                :order-id="item.id"
            />
          </div>
        </div>

      </div>
    </div>

    <v-block-empty-list class="lbob__empty" v-if="linkBuildOrdersStore.ordersManager().ordersSortedByCreatedAt.length === 0">
      <span class="lbob__not-found-text" data-name="you-have-no-backlinks" v-auto-tooltip>
        You have no Backlinks yet
      </span>
    </v-block-empty-list>

  </div>

</template>

<script setup>

import LbOrderReview from "./LbOrderReview.vue";
import VTableInfo from "../Base/VTableInfo.vue";
import VProduct from "../Base/VProduct.vue";
import VLink from "../Base/VLink.vue";
import {useLinkBuildProjectOrders} from "../../stores/useLinkBuilding";
import {computed, getCurrentInstance, inject} from "vue";
import VBlockEmptyList from "../Base/VBlockEmptyList.vue";
import VLbOrderStatus from "../Base/LinkBuilding/VLbOrderStatus.vue";

const props = defineProps({
  projectUniquekey: {},
});

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const linkBuildOrdersStore = useLinkBuildProjectOrders(props.projectUniquekey);

</script>

<style lang="scss" scoped>
.lb-orders-backlinks.lbob{

  .lbob__table{

    th{
      background:#fff;
    }

    .lbob__table-th-date-id{width: 90px;}
    .lbob__table-th-type{width: 80px;}
    .lbob__table-th-keyword-domain{width: 100%;}
    .lbob__table-th-article-review{width: 100%;}
    .lbob__table-th-status{width: 100px;}

    td{
      height: 70px;
      border-bottom: 1px solid #E8F2FB;

      > div{
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        gap:3px;
      }

      &.lbob__table-td-status{
        align-items:flex-start;
        > div{
          align-items:flex-start;
        }
      }

    }

    .lbob__table-tr{
      &.lbob__table-tr--canceled{
        .lbob__table-td-keyword-domain,
        .lbob__table-td-type{
          opacity:0.5;
        }
      }
    }

  }

  .lbob__list{
    display:flex;
    flex-direction: column;
    align-items: stretch;

    .lbob__list-item{
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap:10px;
      padding-top:20px;
      padding-bottom:20px;
      border-bottom: 1px solid #D6E3EF80;

      .lbob__list-item-head{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .lbob__list-item-head-left{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap:2px;
        }
        .lbob__list-item-head-right{

        }
      }
      .lbob__list-item-body{
        display:flex;
        flex-direction: column;
        align-items: stretch;
        gap:4px;

        .lbob__list-item-body-row{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap:4px;

          .lbob__list-item-body-table-row-left{
            font-size: 14px;
            font-weight: 400;
            color:#798CAE;
          }
          .lbob__list-item-body-table-row-right{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          }
        }
      }
      .lbob__list-item-footer{

      }

      .lbob__list-item-date-id{
        display:flex;
        align-items:center;
        gap:3px;
      }
      .lbob__list-item-type{}
      .lbob__list-item-domain{
        display:flex;
      }
      .lbob__list-item-keyword{
        display:flex;
      }
      .lbob__list-item-article{
        display:flex;
        max-width:100%;
      }
      .lbob__list-item-review{
        display:flex;
        align-items:center;
        height:30px;
      }

      &.lbob__list-item--canceled{
        .lbob__list-item-type,
        .lbob__list-item-body{
          opacity:0.5;
        }
      }

    }

  }

  .lbob__order-date{
    font-size: 12px;
    font-weight: 400;
    color:#AEB7C7;
  }
  .lbob__order-id{
    color:#798CAE;
    font-weight: 400;
    font-size:12px;
  }
  .lbob__order-keyword{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color:#384967;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:100%;
  }
  .lbob__order-link{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:100%;
  }
  .lbob__order-result-url{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:100%;
  }
  .lbob__order-review{

  }
  .lbob__order-status{

  }

  .lbob__not-found-text{
    display:flex;
    align-items:center;
    gap:10px;
  }

}
</style>