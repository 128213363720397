<template>
  <span class="v-number vn">
    <span v-for="digit in digits">{{digit}}</span>
  </span>
</template>

<script setup>

import {computed} from "vue";

const props = defineProps({
  value: {default:''},
})

const digits = computed(() => {
  const numStr = Math.abs(props.value).toString();
  const digits = [];

  for(let i = numStr.length; i >= 0; i -= 3){
    const start = Math.max(0, i - 3);
    digits.unshift(numStr.substring(start, i));
  }

  // Если исходное число было отрицательным, изменяем знак у первого элемента массива
  if (props.value < 0) {
    digits[0] = '-'+digits[0];
  }

  return digits.filter((digit) => digit.length > 0);
});

</script>

<style lang="scss" scoped>
.v-number.vn{
  display:inline-flex;
  gap:2px;
  align-items:center;
  justify-content: flex-start;
}
</style>