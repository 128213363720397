<template>
  <div
      class="v-lb-recommendations-like-icon"
      :class="mainClass"
      @mouseenter="isMouse = true"
      @mouseleave="isMouse = false"
      ref="refContainer"
  >
    <div class="icon-container">
      <v-icon-svg name="like" class="icon"/>
    </div>
    <div class="text-container">
      <div class="text" ref="refText">
        We recommended to buy
        <v-link href="#" class="text__link" @click.prevent="emit('clickNumberLinks', backlinksRecommendation.recommendationsCount[typeConstant]);hide();">
          {{backlinksRecommendation.recommendationsCount[typeConstant]}} Backlinks per month
        </v-link>
      </div>
    </div>
    <v-tooltip
        placement="bottom-start"
        ref="refTooltip"
        :style="{width:'100%', maxWidth:'340px', borderRadius:'4px', padding:'0px',overflow:'hidden',}"
        :btn-close-on="false"
        class="v-tooltip-period vtp"
        :get-linked-block="() => refContainer"
        @eventShow="() => {}"
        :has-arrow="false"
        background="#F7FAFF"
        :hide-click-outside="false"
    >
      <template v-slot:content>
        <v-lb-recommendations-tooltip :project-uniquekey="projectUniquekey" @close="refTooltip.hide()"/>
      </template>
    </v-tooltip>
  </div>
</template>

<script setup>

import VIconSvg from "../VIconSvg.vue";
import VLink from "../VLink.vue";
import {computed, onMounted, ref, watch} from "vue";
import VTooltip from "../VTooltip.vue";
import VLbRecommendationsTooltip from "./VLbRecommendationsTooltip.vue";
import {useBacklinksRecommendations} from "../../../stores/useLinkBuilding";

const refContainer = ref();
const refText = ref();
const refTooltip = ref();

const emit = defineEmits(['clickNumberLinks'])

const props = defineProps({
  projectUniquekey: {},
  typeConstant: {},
  forcedOpened: {type:Boolean,default:false,}
});

let backlinksRecommendation = useBacklinksRecommendations(props.projectUniquekey);

const isMouse = ref(false);
const containerFullWidth = ref(null);
const containerShortWidth = ref(38);

function updateFullWidth(){
  containerFullWidth.value = refText.value.offsetWidth;
}

const isActive = computed(() => {
  return isMouse.value || props.forcedOpened;
});

const mainClass = computed(() => {
  return isActive.value ? 'is-active' : '';
});

watch(() => isMouse.value, (isMouseValue) => {
  if(isMouseValue){
    refTooltip.value.show();
  }else{
    refTooltip.value.hide();
  }
});

const containerWidth = computed(() => {
  if(isActive.value){
    return containerFullWidth.value;
  }else{
    return containerShortWidth.value;
  }
});

watch(() => containerWidth.value, (value) => {
  refContainer.value.style.width = value + 'px';
})

onMounted(() => {
  updateFullWidth();
});

function hide(){
  isMouse.value = false;
}

</script>

<style lang="scss" scoped>
.v-lb-recommendations-like-icon{
  cursor:default;
  width:38px;
  height:38px;
  background-color: #F7FAFF;
  border-radius: 4px;
  overflow:hidden;
  position:relative;
  transition-duration: 0.2s;
  .icon-container{
    width:38px;
    height:38px;
    position:relative;
  }
  .icon{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    transition-duration:0.2s;
    opacity:1;
  }
  .text{
    @include flex(row,start,center,9px);
    position:absolute;
    top:0px;
    left:0px;
    height:100%;
    text-wrap:nowrap;
    padding:10px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color:#AEB7C7;
    opacity:0;
    transition-duration:0.2s;
    .text__link{
      border-bottom:1px solid;
    }
  }

  &.is-active {
    .text{
      opacity:1;
    }
    .icon{
      opacity:0;
    }
  }
}
</style>