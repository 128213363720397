import {defineStore} from "pinia";
import {ApiResource} from "../plugins/ApiExtends";

export const useBillingSubscriptions = defineStore('billing-subscriptions', {
    state: () => {
        return {
            list:[],
        }
    },
    getters:{
        listActive: (state) => {
            return state.list.filter((item) => item.isActive);
        }
    },
    actions: {
        async download(){
            let resource = new ApiResource({url: '/api/v1/billing/subscriptions'});
            let [status,response] = await resource.downloadAsync();
            if(status) {
                response.forEach(subscription => this.add(subscription));
            }
            return [status,response];
        },
        add(subscription){
            useBillingSubscription(subscription.id).set(subscription);
            if(!this.has(subscription.id)){
                this.$patch((state) => {
                    state.list.push(useBillingSubscription(subscription.id).set(subscription));
                });
            }
            return useBillingSubscription(subscription.id);
        },
        get(id){
            return this.list.find(subscription => subscription.id === id) ?? null;
        },
        has(id){
            return !!this.get(id);
        },
    },
});

export const useBillingSubscription = (subscriptionId) => defineStore(`billing-subscription-${subscriptionId}`,{
    state: () => {
        return {
            id: subscriptionId,
            source: null,
            createdAt: null,
            amount: null,
            cancelAt: null,
            items: [],

            planOptionId: null,

            current_transaction: null,
            bonuses: [],
        }
    },
    getters: {
        createdDate: (state) => {
            return new Date(state.createdAt);
        },
        isActive: (state) => {
            return state.cancelAt === null;
            return true;
        },
        products(state){
            return state.groupAndSumByName(
                state.items.map((item) => {
                    return {
                        quantity: item.quantity,
                        name: item.name,
                    }
                })
            );
        },
        nextBonuses(state){
            return state.groupAndSumByName(
                state.items.map((item) => {
                    return item.bonuses.map((bonus) => {
                        return {
                            quantity: bonus.quantity,
                            name:     bonus.name,
                        }
                    });
                }).flat()
            );
        },
        maxCountBonuses(state){
            return Object.values(this.allBonuses).reduce((res, item) => item.length > res ? item.length : res, 0);
        },
        countCollectedBonuses: (state) => {
            /**
             * Пример для 5 колонок
             * current transaction | fill | countCollected
             * 0  -----  0
             * 1  +----  1
             * 2  ++---  2
             * 3  +++--  3
             * 4  ++++-  4
             *
             * 5  -----  0
             * 6  +----  1
             * 7  ++---  2
             * 8  +++--  3
             * 9  ++++-  4
             *
             * 10 -----  0
             * ...
             */
            return state.current_transaction % 12

        },
        nextBonusNumber: (state) => {
            return state.current_transaction % 12 + 1;
        },
        allBonuses(state){
            let bonuses = {};
            let lastBonusIndex = -1;
            for(let i = 1; i <= 12; i++){
                if(state.bonuses.hasOwnProperty(i)){
                    bonuses[i] = this.groupAndSumByName(state.bonuses[i]);
                    lastBonusIndex = i;
                }else{
                    if(lastBonusIndex===-1){
                        bonuses[i] = [];
                    }else{
                        bonuses[i] = this.groupAndSumByName(state.bonuses[lastBonusIndex]);
                    }
                }
            }
            return bonuses;
        },
        hasBonuses(){
            return Object.values(this.allBonuses).reduce((res,item) => res+item.length, 0) > 0;
        },
        nextWithPlusOneMonth(){
            let response = {};
            let nextBonus = this.allBonuses[this.nextBonusNumber];
            let nextBonusOneMonth = this.allBonuses[this.nextBonusNumber + 1 > 12 ? 1 : this.nextBonusNumber+1];

            nextBonus.forEach(bonusA => {
                response[bonusA.name] = {
                    name: bonusA.name,
                    nextQuantity: bonusA.quantity,
                }
            });

            nextBonusOneMonth.forEach(bonusB => {
                if(!response.hasOwnProperty(bonusB.name)){
                    response[bonusB.name] = {
                        name: bonusB.name,
                        nextQuantityOneMonth: bonusB.quantity,
                    }
                }else{
                    response[bonusB.name].nextQuantityOneMonth = bonusB.quantity;
                }
            });

            return Object.values(response);
        }
    },
    actions: {
        set(data){
            this.$patch((state) => {
                for(let [key,value] of Object.entries(data)){
                    if(state.hasOwnProperty(key)){
                        state[key] = value;
                    }
                }
            });
            return this;
        },
        groupAndSumByName(items){
            return items.reduce((res, item) => {
                let findItem = res.find(i => i.name === item.name);
                if(findItem){
                    findItem.quantity += item.quantity;
                }else{
                    res.push(Object.assign({}, item));
                }
                return res;
            },[]);
        },

        async download(){
            let resource = new ApiResource({url: `/api/v1/billing/subscriptions/${this.id}`});
            let [status,response] = await resource.downloadAsync();
            if(status) {
                this.set(response.subscription);
                this.$patch({
                    bonuses: response.bonuses,
                    current_transaction: response.current_transaction,
                });
            }
            return [status,response];
        },

        async cancel(){
            let resource = new ApiResource({
                url: `/api/v1/billing/subscriptions/${this.id}/cancel`,
                params: {
                    'id': this.id,
                    'source': this.source,
                },
            });
            let [status,response] = await resource.downloadAsync();
            if(status){
                return [status];
            }else{
                return [status, resource.errorMessage];
            }
        }
    }
})();