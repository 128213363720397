<template>
  <div class="v-tag-save vts">
    <slot/>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.v-tag-save.vts{
  padding: 2px 6px 2px 6px;
  border-radius: 4px;
  background: #E6F6E7B2;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  flex-shrink:0;
  color:#54AC58;
  white-space: nowrap;
  text-align:center;
}
</style>