import {runIfHasSelecter} from "../../utils/panel-lib";

const initScripts = function (currentData, translations) {
    const $historyTable = $('#cashback-history-table');
    const $searchInput = $('.search-box input[type="search"]');
    const $loadingBlock = $('.loading-block');

    $historyTable.dataTable({
        responsive: true,
        processing: true,
        dom: "<'row'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 d-none'i><'col-12'p>>",
        order: [[0, "desc"]],
        language: {
            emptyTable: translations.dataTable.emptyTable,
            lengthMenu: translations.dataTable.lengthMenu,
            zeroRecords: translations.dataTable.zeroRecords,
            info: translations.dataTable.info,
            infoEmpty: translations.dataTable.infoEmpty,
            infoFiltered: translations.dataTable.infoFiltered,
            loadingRecords: translations.dataTable.loadingRecords,
            processing: translations.dataTable.processing,
            searchPlaceholder: translations.dataTable.searchPlaceholder,
            aria: {
                sortAscending: ": " + translations.sortAscending,
                sortDescending: ": " + translations.sortAscending,
            },
            paginate: {
                first: '<i class="fas fa-arrow-to-left"></i>',
                last: '<i class="fas fa-arrow-to-right"></i>',
                next: '<i class="fas fa-chevron-right"></i>',
                previous: '<i class="fas fa-chevron-left"></i>'
            },
        },
        buttons: [],
        columns: [
            {"width": "10%"},
            {"width": "20%"},
            {"width": "20%"},
            {"width": "20%"},
            {"width": "30%"},
        ],
        createdRow: function (row, data) {
        }
    });

    // Search
    let localStorageSearch;
    localStorageSearch = localStorage.getItem('st-cashback-history-table-search');

    if (localStorageSearch !== null && localStorageSearch !== 'null' && localStorageSearch) {
        $searchInput.val(localStorageSearch);
    }

    $searchInput.on('keyup search', function () {
        $historyTable.DataTable().search($(this).val()).draw();
        localStorage.setItem('st-cashback-history-table-search', $(this).val());
    });

    updateHistory();

    function updateHistory() {
        const url = "/api/cashback/get-history";

        $historyTable.DataTable().search($searchInput.val()).ajax.url(url);

        /** DataTables before receiving the data */
        $historyTable.on('preXhr.dt', function () {
            $loadingBlock.addClass('processing');
        });

        /** DataTables after receiving the data */
        $historyTable.on('xhr.dt', function (e, settings, json) {
            $loadingBlock.removeClass('processing');
            $('body').removeClass('loading');
        }).DataTable().ajax.reload();
    }
}

export const initCashbackHistoryScripts = () => {
    runIfHasSelecter('[data-role="cashback-history"]', [
        initScripts,
    ]);
}
