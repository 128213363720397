import {defineStore} from "pinia";
import {UrlManipulator} from "../utils/UrlManipulator";

export const useRoutes = defineStore('routes', {
    state: () => {
        return {
            routes: {
                'seo-clicks.list' : '/seo-traffic',
                'seo-clicks.add' : '/seo-traffic/create-new',
                'seo-clicks.get' : '/seo-traffic/{id}',
                'purchase-clicks.get' : '/buy-credits#tab-seo-traffic',
                'billing-balance.get' : '/billing-balance',
                'buy-credits' : '/buy-credits#tab-seo-traffic',
                'backlinks-project.list' : '/backlinks-projects',
                'backlinks-pay.get' : '/buy-credits#tab-link-building',
                'backlinks-project.add' : '/backlinks-project/add',
                'backlinks-project.get' : '/backlinks-project/{uniquekey}',
                'subscriptions' : '/subscriptions',
                'subscription' : '/subscriptions/{id}',
            }
        };
    },
    actions: {
        url(name, params = {}, getParams = {}) {
            if(this.routes.hasOwnProperty(name)){
                let url = this.routes[name];
                Object.keys(params).forEach((key) => {
                    let value = params[key];
                    url = url.replace('{'+key+'}',value);
                });
                url = (new UrlManipulator(window.location.origin + url)).addGetParams(getParams).getUrl();
                return url;
            }else{
                return null;
            }
        },
        getProjectKey(){
            const url = window.location.pathname; // Получаем текущий URL из адресной строки
            const segments = url.split('/'); // Разделяем URL на сегменты
            return segments.pop();
        },
        getUrlSections(){
            const url = window.location.pathname; // Получаем текущий URL из адресной строки
            return url.split('/');
        },
        getUrlLastSection(){
            return this.getUrlSections().at(-1);
        }
    }
});