<template>
  <div class="v-chart-sectional-gradient vcsg">
    <template  v-for="section in sections">
      <div
          class="vcsg__part"
          :style="{width:section.size+'%', background:section.color}"
      >
        <div class="vcsg__part-gray" :style="{width:section.sizeGray+'%'}"/>
      </div>
    </template>
  </div>
</template>

<script setup>

import {computed} from "vue";
import {
  calculatePercentage,
  calculateSegmentPercentage,
  getIntermediateGradientColor,
  normalizeSegmentAndPoints
} from "../../utils/chartUtils";

const props = defineProps({
  percents: {},
  colors: {},
  value: {},
});

const sections = computed(() => {

  let response = [];

  for(let i = 1; i < props.percents.length; i++){
    let itemPrev = {
      percent: props.percents[i-1],
      color: props.colors[i-1],
    }
    let itemNext = {
      percent: props.percents[i],
      color: props.colors[i],
    }

    response.push({
      size: itemNext.percent - itemPrev.percent,
      color: `linear-gradient(90deg, ${itemPrev.color}, ${itemNext.color})`,
      sizeGray: 100 - calculatePercentage(props.value,itemPrev.percent,itemNext.percent)
    });
  }

  return response;

});


// ++++++++++ ++++++++++ ++++++++-- ----------

</script>

<style lang="scss" scoped>
.v-chart-sectional-gradient.vcsg{
  @include flex(row,between,center,3px);
  height:6px;
  width:100%;
  overflow: hidden;
  border-radius:30px;

  .vcsg__part{
    @include flex(row,between,center,0px);
    height:100%;
    position: relative;

    .vcsg__part-gray{
      height:100%;
      background: #DFE7F5;
      position:absolute;
      right:0px;
    }
  }

}
</style>