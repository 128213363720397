<template>
  <div class="v-chart-backlinks vcb">

    <div class="vcb__graph" v-bind="graphAttrs">
      <div class="vcb__wrap">
        <canvas ref="refCanvas" class="vcb__canvas"/>
      </div>
    </div>

    <div class="vcb__legend">
      <div class="vcb__legend-item vcb__legend-item--delivered">
        Delivered
      </div>
      <div class="vcb__legend-item vcb__legend-item--in-progress">
        In Progress
      </div>
    </div>

  </div>

</template>

<script setup>

import {onMounted, ref, watch, watchEffect} from "vue";
import {Chart as ChartJS} from "chart.js";

const props = defineProps({
  labels: {},
  new: {},
  inProgress: {},
  delivered: {},
  graphAttrs: {type: Object, default:{}},
});

const refCanvas = ref();

const getData = () => {
  return {
    labels: props.labels,
    datasets: [
      {
        label: 'New',
        data: props.new,
        backgroundColor: '#AEB7C7',
        maxBarThickness: 16,
      },
      {
        label: 'In progress',
        data: props.inProgress,
        backgroundColor: '#5988DB',
        maxBarThickness: 16,
      },
      {
        label: 'Delivered',
        data: props.delivered,
        backgroundColor: '#219E26',
        maxBarThickness: 16,
      },
    ],
  };
}

onMounted(() => {
  const config = {
    type: 'bar',
    data: getData(),
    options: {
      maintainAspectRatio:false,
      responsive: true,
      hover: {mode: null},
      scales: {
        x: {
          stacked: true,
          ticks: {
            //callback: function(value, index, ticks) {
            //  return labels[index];
            //},
            maxRotation:0,
            minRotation:0,
            maxTicksLimit: 8,
            autoSkip: true,
            color: "rgba(31,41,59, 0.5)",
            crossAlign: "near",
            display: true,
            padding: 0,
            font: {
              family: 'Inter',
              size: 10,
              weight: '400'
            }
          },
          grid: {
            color: '#D6E3EF80',
            drawBorder: true,    // Рисует границу оси X
            drawOnChartArea: false // Убирает сеточные линии внутри графика
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: "rgba(31,41,59, 0.5)",
            maxTicksLimit: 6,
            padding: 0,
            stepSize: 1,
            font: {
              family: 'Inter',
              size: 10,
              weight: '400'
            }
          },
          grid: {
            color: '#D6E3EF80',
            drawTicks:true,
            drawBorder: true,    // Рисует границу оси X
            drawOnChartArea: false, // Убирает сеточные линии внутри графика
            tickWidth:0,
            tickLength:6,
          },
        }
      },
      plugins: {
        title: {
          display: false,
        },
        tooltip:{
          borderWidth: 0,
          boxPadding: 0,
          enabled: true,
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
              let value = context.raw;
              return `${label} - ${value}`;
            },
          }
        },
        legend: {
          display: false,
        },
      },
    }
  };

  let chartObj = new ChartJS(refCanvas.value,config);

  watchEffect(() => {
    chartObj.data = getData();
    chartObj.update();
  });

});

</script>

<style scoped lang="scss">
.v-chart-backlinks.vcb{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap:23px;

  .vcb__graph{
    display:flex;
    height:160px;
    .vcb__wrap{
      position:relative;
      width:100%;
      height:100%;

      .vcb__canvas{
        transition-duration: 0.3ms;
      }
    }
  }
  .vcb__legend{
    display:flex;
    gap:18px;
    align-items:center;
    margin-left:16px;

    .vcb__legend-item{
      display:flex;
      align-items:center;
      gap:6px;
      font-size: 12px;
      color:#596C8F;

      &:before{
        flex-shrink:0;
        content:'';
        width:8px;
        height: 8px;
        display:block;
        border-radius:50%;
      }
      &.vcb__legend-item--new{
        &:before{
          background-color:#AEB7C7
        }
      }
      &.vcb__legend-item--in-progress{
        &:before{
          background-color:#5988DB;
        }
      }
      &.vcb__legend-item--delivered{
        &:before{
          background-color: #219E26;
        }
      }
    }
  }
}
</style>