import {defineStore} from "pinia";
import {ApiResource} from "../plugins/ApiExtends";
import _, {isDate} from "lodash";
import {delay} from "../utils/utils";

export const useServerTime = defineStore('server-time',{
    state: () => {
        return {
            /**
             * @type {string}
             */
            _timeServer: null,
            /**
             * @type {Date}
             */
            _timeUser: null,
            /**
             * @type {Date}
             */
            _realTime: null,
        }
    },
    getters: {
        realTimeServer(state){
            if(state._realTime===null && state._timeUser!==null && state._timeServer!==null){
                return this.getServerTime();
            }else{
                return state._realTime;
            }
        }
    },
    actions: {
        async updateFromServer(){

            if(window.serverTimePatchInProcess){
                await delay(1);
                return await this.updateFromServer();
            }

            if(window.serverTimePatch){
                this.$patch(window.serverTimePatch);
                return [true, window.serverTimePatch._timeServer];
            }

            let resource = new ApiResource({
                url: '/api/v1/server-time',
                method: 'get',
            });
            window.serverTimePatchInProcess = true;
            let [status, response] = await resource.downloadAsync();
            window.serverTimePatchInProcess = false;
            if(status){
                let patch = {
                    _timeServer: response.time,
                    _timeUser: new Date(),
                }
                this.$patch(patch);
                window.serverTimePatch = patch;
                return [true,response._timeServer];
            }else{
                return [false, resource.errorMessage];
            }

        },
        getDiffInSeconds(timeA, timeB){
            if(!isDate(timeA)){
                timeA = new Date(timeA);
            }
            if(!isDate(timeB)){
                timeB = new Date(timeB);
            }
            return (timeA - timeB)/1000;
        },
        getServerTime(){
            const diff = this.getDiffInSeconds(new Date, this._timeUser);
            let response = new Date(this._timeServer);
            response.setSeconds(response.getSeconds() + diff);
            return response;
        },
        updateRealTimeServer(){
            this.$patch({
                _realTime: this.getServerTime(),
            })
        },
        hasPreloadData: () => {
            return ApiResource.hasPreloadData('/api/v1/server-time');
        },
    }
});