<template>
  <div class="v-bs-slider-bonus-chart" ref="refMainWrap">
    <v-bs-bonus-chart
        class="chart"
        :active="activeColumn"
        :fill-to="countFill-1"
        :count-columns="12"
        :date-start="dateStart"
        @column-click="changeActiveColumn"
    />
    <div class="points">
      <div
          v-for="key in range(12)"
          class="point"
          :class="[
              key === activeColumn ? 'is-active' : '',
              countFill!==null && key <= countFill-1 ? 'is-filling' : ''
          ]"
          @click="changeActiveColumn(key)"
      />
    </div>
  </div>
</template>

<script setup>
import VBsBonusChart from "./VBsBonusChart.vue";
import {ref, watch} from "vue";
import {range} from "lodash";
import { useSwipe } from '@vueuse/core'

const props = defineProps({
  countFill: {type:Number,default:0,},
  dateStart: {type:Date,}
});

const refMainWrap = ref();
const { isSwiping, direction } = useSwipe(refMainWrap);

watch(() => isSwiping.value, (isSwiping) => {
  if(isSwiping === false){
    if(direction.value === 'left'){
      activeColumnToNext();
    }else{
      activeColumnToLast();
    }
  }
});

const activeColumn = defineModel({default: 0,});
function activeColumnToNext(){
  if(activeColumn.value + 1 >= 12){
    activeColumn.value = 0;
  }else{
    activeColumn.value++;
  }
}
function activeColumnToLast() {
  if(activeColumn.value - 1 < 0){
    activeColumn.value = 11;
  }else{
    activeColumn.value--;
  }
}

function changeActiveColumn(columnNumber){
  activeColumn.value = columnNumber;
}

</script>

<style>@import url('/node_modules/@egjs/vue3-flicking/dist/flicking-inline.css');</style>
<style lang="scss" scoped>
.v-bs-slider-bonus-chart{
  @include flex(column,start,stretch,16px);
  .slider{
    width: 100%;
    .chart{
      width:100%;
    }
  }
  .points{
    @include flex(row,center,center,6px);
    .point{
      flex-shrink:0;
      width:8px;
      height:8px;
      border-radius:50%;
      border:1px solid #E6F3E6;
      background-color:#E6F3E6;
      cursor:pointer;
      transition-duration: 0.2s;
      &.is-filling{
        border-color:#C6E1C6;
        background-color:#C6E1C6
      }
      &.is-active{
        border-color:#219E26;
      }
    }
  }
}

</style>