import {runIfHasSelecter} from "../../utils/panel-lib";
import {api} from "../../utils/panel-api";

const initCommon = function (currentData) {
    const plans = currentData.plans;

    const $sizeRangeSlider = $('#size_range_slider');
    const $sizeInput = $('#size_range');
    const $name = $('#name');
    const $price = $('#price');
    const $clicks = $('#clicks');
    const $discount = $('#discount');

    const updateInfo = ({name, price, price_per_credit, discount}) => {
        $name.text(name);
        $price.text(`$${price}`);
        $clicks.text(`$${price_per_credit}`);
        $discount.text(discount > 0 ? `-${discount}% Discount` : '');
    }

    updateInfo(plans[0]);

    $sizeRangeSlider.slider({
        range: "min",
        value: 1,
        min: 1,
        max: 7,
        step: 1,
        slide: function (event, ui) {
            $sizeInput.val(ui.value).trigger('change');

            const planIndex = parseInt($sizeInput.val()) - 1;
            updateInfo(plans[planIndex]);
        },
        change: function (event, ui) {
            $sizeInput.val(ui.value).trigger('change');

            const planIndex = parseInt($sizeInput.val()) - 1;
            updateInfo(plans[planIndex]);
        }
    });
}

const initButtonStartFreeTrial = () => {
    const buttonStartFreeTrial = $('[data-ajax-action="start-free-trial"]');

    buttonStartFreeTrial.on('click', function(e) {
        e.preventDefault();

        let $button = $(this);

        $button.prop('disabled', true);

        api.searchTrafficProject.startFreeTrial({},
            function (response) {
                if (response.success) {
                    window.location.replace('/search-traffic-add');
                } else {
                    window.location.replace('/search-traffic-pay');
                }
            },
            function () {
                $button.prop('disabled', false);
            });
    });
}

const initCalculator = () => {
    const $calculatorInput = $('[data-id="search-calculator-input"]');
    const $monthlySearchVolumeRangeSlider = $('#monthly_search_volume_slider');
    const $currentPositionRangeSlider = $('#current_position_slider');
    const $targetPositionRangeSlider = $('#target_position_slider');
    const $currentPositionInput = $('#current_position');
    const $targetPositionInput = $('#target_position');

    if ($calculatorInput.length === 0) {
        return;
    }

    $monthlySearchVolumeRangeSlider.slider({
        range: "min",
        value: 0,
        min: 0,
        max: 100,
        step: 1,
        slide: function (event, ui) {
            $calculatorInput.val(ui.value).trigger('change');
        },
        change: function (event, ui) {
            $calculatorInput.val(ui.value).trigger('change');
        }
    });

    $currentPositionRangeSlider.slider({
        range: "min",
        value: 1,
        min: 1,
        max: 100,
        step: 1,
        slide: function (event, ui) {
            $currentPositionInput.val(ui.value).trigger('change');
        },
        change: function (event, ui) {
            $currentPositionInput.val(ui.value).trigger('change');
        }
    });

    $targetPositionRangeSlider.slider({
        range: "min",
        value: 1,
        min: 1,
        max: 10,
        step: 1,
        slide: function (event, ui) {
            $targetPositionInput.val(ui.value).trigger('change');
        },
        change: function (event, ui) {
            $targetPositionInput.val(ui.value).trigger('change');
        }
    });

    $calculatorInput.on('change', recalcuateEstimate);
    recalcuateEstimate();

    function recalcuateEstimate() {
        var monthly_search_volume = $('#monthly_search_volume').val();
        var current_position = $currentPositionInput.val();
        var target_position = $targetPositionInput.val();

        monthly_search_volume = Number(monthly_search_volume) || 1;
        current_position = Number(current_position) || 1;
        target_position = Number(target_position) || 1;

        monthly_search_volume = Math.round(Math.pow(monthly_search_volume / 4, 3.57607) + 200)
        const m = { 1: 32, 2: 14, 3: 10, 4: 7, 5: 6, 6: 4, 7: 4, 8: 4, 9: 4, 10: 4 };
        let f = ((m[current_position] || 0) * monthly_search_volume) / 100,
          g = ((m[target_position] || 1) * monthly_search_volume) / 100,
          h = Math.max(Math.round(1.25 * g - f), 1),
          b = Math.round( (0.19 * h * 100) / 100 );

        $('#monthly_search_volume_info').html(monthly_search_volume.toLocaleString("en-US", { minimumFractionDigits: 0 }));
        $('#current_position_info').html(current_position.toLocaleString("en-US", { minimumFractionDigits: 0 }));
        $('#target_position_info').html(target_position.toLocaleString("en-US", { minimumFractionDigits: 0 }));
        $('#clicks_estimated').html( h.toLocaleString("en-US", { minimumFractionDigits: 0 }) + " Clicks" );
        $('#budget_estimated').html( "from $"+b.toLocaleString("en-US", { minimumFractionDigits: 0 }) + "/month" );
    }
}

export const initSeoClicksStartFreeTrial = () => {
    runIfHasSelecter('[data-role="search-traffic-start-free-trial"]', [
        initCommon,
        initCalculator,
        initButtonStartFreeTrial,
    ]);
}
