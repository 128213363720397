<template>
  <div class="bc-backlink-quality-pack bcbqp" @click="setChecked" :class="mainClass">

    <template v-if="!isMobile">

      <div class="bcbqp__head">

        <v-form-input-radio class="bcbqp__radio" v-model="qualityTypeConstant" :value="creditType.firstPlanOptions.typeConstantBacklink"/>

        <v-product class="bcbqp__product-type" :type-constant="creditType.firstPlanOptions.typeConstantBacklink" is-short/>

        <div class="bcbqp__title" v-html="creditType.title"/>

        <div class="bcbqp__content" v-html="creditType.description"/>

      </div>

      <div class="bcbqp__footer">

        <div class="bcbqp__price">
          <div class="bcbqp__price-title">Price per credit from:</div>
          <div class="bcbqp__price-value">${{priceFormatted(creditType.firstPlanOptions?.price)}}</div>
        </div>

      </div>

    </template>

    <template v-else>

      <div class="bcbqp__m-head">
        <div class="bcbqp__m-head-left">
          <div class="bcbqp__m-head-left-top">

            <v-form-input-radio class="bcbqp__radio" v-model="qualityTypeConstant" :value="creditType.firstPlanOptions.typeConstantBacklink"/>
            <v-product class="bcbqp__product-type" :type-constant="creditType.firstPlanOptions.typeConstantBacklink" is-short/>

          </div>
          <div class="bcbqp__m-head-left-bottom">

            <div class="bcbqp__title" v-html="creditType.title"/>

          </div>
        </div>
        <div class="bcbqp__m-head-right">

          <div class="bcbqp__price">
            <div class="bcbqp__price-title">Price per credit from:</div>
            <div class="bcbqp__price-value">${{priceFormatted(creditType.firstPlanOptions?.price)}}</div>
          </div>

        </div>
      </div>

      <div class="bcbqp__m-footer">

        <div class="bcbqp__content" v-html="creditType.description"/>

      </div>

    </template>

  </div>
</template>

<script setup>

import VFormInputRadio from "../VForm/VFormInputRadio.vue";
import VProduct from "../Base/VProduct.vue";
import {computed, getCurrentInstance} from "vue";
import {useCreditType} from "../../stores/useBilling";
import {priceFormatted} from "../../utils/utils";
import {useLinkBuildingType} from "../../stores/useLinkBuilding";

const props = defineProps({
  creditTypeId: {},
});

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const qualityTypeConstant = defineModel();

const creditType = useCreditType(props.creditTypeId);

const setChecked = () => {
  qualityTypeConstant.value = creditType.firstPlanOptions.typeConstantBacklink;
}

const mainClass = computed(() => {
  return (qualityTypeConstant.value === creditType.firstPlanOptions.typeConstantBacklink) ? 'bcbqp--checked' : '';
});

</script>

<style lang="scss" scoped>
.bc-backlink-quality-pack.bcbqp{
  @include flex(column,between,stretch,16px);

  padding: 12px;
  border-radius: 4px;
  border: 1px solid #D6E3EF80;
  cursor:pointer;

  &.bcbqp--checked{
    border: 1px solid #1C7EE0;
  }

  .bcbqp__head{
    @include flex(column,start,start);
    gap:16px;
  }

  .bcbqp__footer{
    @include flex(column,start,start,16px);
  }

  @media(max-width:767px){
    @include flex(column,start,stretch,12px);
    padding:16px 12px;

    .bcbqp__m-head{
      @include flex(row, between, start, 5px);

      .bcbqp__m-head-left{
        @include flex(column, start, start, 6px);

        .bcbqp__m-head-left-top{
          @include flex(row, start, center, 8px);

        }
        .bcbqp__m-head-left-bottom{}
      }
      .bcbqp__m-head-right{
      }
    }

    .bcbqp__footer{

    }
  }


  .bcbqp__radio{}

  .bcbqp__product-type{
    font-size:16px;
  }

  .bcbqp__title{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color:#596C8F;
  }

  .bcbqp__content{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color:#798CAE;
  }

  .bcbqp__price{
    @include flex(column,start,start,4px);
    @media(max-width: 767px){
      @include flex(column,start,end,0px);
    }

    .bcbqp__price-title{
      font-size: 11px;
      font-weight: 400;
      line-height: 16px;
      color:#798CAE;
    }
    .bcbqp__price-value{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #384967;
    }
  }

}
</style>