const saveInterfaceSettings = function (settings, callback, callbackError) {
    $.ajax({
        url: "/api/save-interface-settings",
        method: 'POST',
        data: settings,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const sendInviteFriend = function (data, callback, callbackError) {
    $.ajax({
        url: "api/user/send-invite-friend",
        method: 'POST',
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const addUsersCartHistory = function (data, callback, callbackError) {
    $.ajax({
        type: "POST",
        url: "/api/log/users-cart-history",
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const autoRenewStatusSubscription = function (data, callback, callbackError) {
    $.ajax({
        type: "POST",
        url: "/api/billing/subscription/auto-renew-status",
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const getCashbackHistory = function (data, callback, callbackError) {
    $.ajax({
        type: "POST",
        url: "/api/cashback/get-history",
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const guaranteedProjectResume = function (id, callback, callbackError) {
    $.ajax({
        url: "/api/guaranteed/project/resume",
        method: 'POST',
        data: {
            id: id
        },
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}
const guaranteedProjectPause = function (id, callback, callbackError) {
    $.ajax({
        url: "/api/guaranteed/project/pause",
        method: 'POST',
        data: {
            id: id
        },
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}
const guaranteedProjectCheck = function (id, callback, callbackError) {
    $.ajax({
        url: "/api/guaranteed/project/check",
        method: 'GET',
        data: {
            id: id
        },
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const guaranteedProjectAdd = function (data, callback, callbackError) {
    $.ajax({
        type: "POST",
        url: "/api/guaranteed/project/add",
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const guaranteedProjectUpdate = function (data, callback, callbackError) {
    $.ajax({
        type: "POST",
        url: "/api/guaranteed/project/update",
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const guaranteedProjectClone = function (data, callback, callbackError) {
    $.ajax({
        type: "POST",
        url: "/api/guaranteed/project/clone",
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}


const guaranteedProjectStartCountersUpdating = function (projectId) {
    $.post("/api/guaranteed/project/start-counters-updating?id=" + projectId);
}

const guaranteedProjectCheckCountersUpdating = function (projectId, callback) {
    $.get("/api/guaranteed/project/check-counters-updating?id=" + projectId, callback);
}

const guaranteedProjectStartReciprocalCheck = function (projectId) {
    $.post("/api/guaranteed/project/start-reciprocal-check?id=" + projectId);
}

const guaranteedProjectCheckReciprocalCheck = function (projectId, callback) {
    $.get("/api/guaranteed/project/check-reciprocal-check?id=" + projectId, callback);
}

const guaranteedProjectStartRecommendationsUpdating = function (projectId) {
    $.post("/api/guaranteed/project/start-recommendations-updating?id=" + projectId);
}

const guaranteedProjectCheckRecommendationsUpdating = function (projectId, callback) {
    $.get("/api/guaranteed/project/check-recommendations-updating?id=" + projectId, callback);
}

const guaranteedProjectCheckStartingIndicators = function (projectId, callback) {
    $.get('/api/guaranteed/project/check-starting-indicators?id=' + projectId, callback);
}

const searchTrafficProjectResume = function (id, callback, callbackError) {
    $.ajax({
        url: "/api/seo-clicks/project/resume",
        method: 'POST',
        data: {
            id: id
        },
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}
const searchTrafficProjectPause = function (id, callback, callbackError) {
    $.ajax({
        url: "/api/seo-clicks/project/pause",
        method: 'POST',
        data: {
            id: id
        },
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}
const searchTrafficProjectCheck = function (id, callback, callbackError) {
    $.ajax({
        url: "/api/seo-clicks/project/check",
        method: 'GET',
        data: {
            id: id
        },
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const searchTrafficProjectUpdate = function (data, callback, callbackError) {
    $.ajax({
        type: "POST",
        url: "/api/seo-clicks/project/update",
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const searchTrafficProjectStartPositionUpdating = function (projectId) {
    $.post("/api/seo-clicks/project/start-position-updating?id=" + projectId);
}

const searchTrafficProjectCheckPositionUpdating = function (projectId, callback) {
    $.get("/api/seo-clicks/project/check-position-updating?id=" + projectId, callback);
}

const seoClicksStartFreeTrial = function (data, callback, callbackError) {
    $.ajax({
        type: "POST",
        url: "/api/seo-clicks/start-free-trial",
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const seoClicksCheckKeywords = function (data, callback, callbackError) {
    $.ajax({
        type: "POST",
        url: "/api/seo-clicks/check-keywords",
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const backlinksProjectResume = function (id, callback, callbackError) {
    $.ajax({
        url: "/api/backlinks/project/resume",
        method: 'POST',
        data: {
            id: id
        },
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const backlinksProjectPause = function (id, callback, callbackError) {
    $.ajax({
        url: "/api/backlinks/project/pause",
        method: 'POST',
        data: {
            id: id
        },
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const backlinksProjectActive = function (data, callback, callbackError) {
    $.ajax({
        url: "/api/backlinks-project/active",
        method: 'POST',
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const backlinksProjectCheck = function (id, callback, callbackError) {
    $.ajax({
        url: "/api/backlinks/project/check",
        method: 'GET',
        data: {
            id: id
        },
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const backlinksProjectUpdate = function (data, callback, callbackError) {
    $.ajax({
        type: "POST",
        url: "/api/backlinks/project/update",
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const backlinksProjectAdd = function (data, callback, callbackError) {
    $.ajax({
        type: "POST",
        url: "/api/backlinks/project/add",
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const backlinksStartFreeTrial = function (data, callback, callbackError) {
    $.ajax({
        type: "POST",
        url: "/api/backlinks/start-free-trial",
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const getTransactionsForSendToGa = function (data, callback, callbackError) {
    $.ajax({
        type: "GET",
        url: '/api/transactions/get-for-send-to-ga',
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

const updateTransactionsWasSendToGa = function (data, callback, callbackError) {
    $.ajax({
        type: "POST",
        url: '/api/transactions/update-was-sent-to-ga',
        data: data,
        dataType: 'json',
        success: callback,
        error: callbackError
    });
}

export const api = {
    user: {
        saveInterfaceSettings: saveInterfaceSettings,
        sendInviteFriend: sendInviteFriend,
    },
    log: {
        addUsersCartHistory: addUsersCartHistory,
    },
    billing: {
        autoRenewStatusSubscription: autoRenewStatusSubscription,
    },
    cashback: {
        getHistory: getCashbackHistory,
    },
    guaranteedProject: {
        resume: guaranteedProjectResume,
        pause: guaranteedProjectPause,
        check: guaranteedProjectCheck,
        add: guaranteedProjectAdd,
        update: guaranteedProjectUpdate,
        clone: guaranteedProjectClone,
        startCountersUpdating: guaranteedProjectStartCountersUpdating,
        checkCountersUpdating: guaranteedProjectCheckCountersUpdating,
        startReciprocalCheck: guaranteedProjectStartReciprocalCheck,
        checkReciprocalCheck: guaranteedProjectCheckReciprocalCheck,
        startRecommendationsUpdating: guaranteedProjectStartRecommendationsUpdating,
        checkRecommendationsUpdating: guaranteedProjectCheckRecommendationsUpdating,
        checkStartingIndicators: guaranteedProjectCheckStartingIndicators,
    },
    searchTrafficProject: {
        resume: searchTrafficProjectResume,
        pause: searchTrafficProjectPause,
        check: searchTrafficProjectCheck,
        update: searchTrafficProjectUpdate,
        startPositionUpdating: searchTrafficProjectStartPositionUpdating,
        checkPositionUpdating: searchTrafficProjectCheckPositionUpdating,
        startRecommendationsUpdating: guaranteedProjectStartRecommendationsUpdating,
        checkRecommendationsUpdating: guaranteedProjectCheckRecommendationsUpdating,
        startFreeTrial: seoClicksStartFreeTrial,
        checkKeywords: seoClicksCheckKeywords,
    },
    backlinksProject: {
        resume: backlinksProjectResume,
        pause: backlinksProjectPause,
        active: backlinksProjectActive,
        check: backlinksProjectCheck,
        add: backlinksProjectAdd,
        update: backlinksProjectUpdate,
        startFreeTrial: backlinksStartFreeTrial,
    },
    transaction: {
        getForSendToGa: getTransactionsForSendToGa,
        updateWasSendToGa: updateTransactionsWasSendToGa,
    }
}
