import {runIfHasSelecter} from "../../utils/panel-lib";
import {api} from "../../utils/panel-api";

const initButtonStartFreeTrial = () => {
    const buttonStartFreeTrial = $('[data-ajax-action="start-free-trial"]');
    const $errorBox = $('[data-role="error"]');
    const $wrongFormErrorBox = $('#msgfail');

    buttonStartFreeTrial.on('click', function(e) {
        e.preventDefault();

        let $button = $(this);
        let url = $('[name="url"]').val();
        let keyword = $('[name="keyword"]').val();

        let dataAdd = {
            url: url,
            keyword: keyword
        }

        $button.prop('disabled', true);

        api.backlinksProject.add(dataAdd,
            function (response) {
                const data = response.data;

                if (data.success) {
                    api.backlinksProject.startFreeTrial({},
                        function (response) {
                            if (response.success) {
                                window.location.replace("/backlinks-projects");
                            } else {
                                // window.location.replace('/backlinks-pay');
                                $button.prop('disabled', false);
                            }
                        },
                        function () {
                            $button.prop('disabled', false);
                        });
                } else {
                    $button.prop('disabled', false);

                    if (data.message === 'WRONGFORM') {
                        $wrongFormErrorBox.show();
                    } else {
                        $errorBox.html(data.message).show();
                    }
                }
            },
            function () {
                $button.prop('disabled', false);
            });
    });
}

export const initBacklinksStartFreeTrial = () => {
    runIfHasSelecter('[data-role="backlinks-start-free-trial"]', [
        initButtonStartFreeTrial,
    ]);
}
