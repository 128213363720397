<template>
  <div class="sc-projects-list scpl">
    <template v-if="useProjects._projects.length > 0" v-for="project in useProjects._projects">
      <sc-projects-preview :project-id="project.id"/>
    </template>
    <v-not-found-projects v-if="useProjects._projects.length === 0" :link="useRoutes().url('seo-clicks.add')"/>
  </div>
</template>

<script setup>
import ScProjectsPreview from "./ScProjectsPreview.vue";
import {useSeoClicksProjects} from "../../stores/useSeoClicks.js"
import {useRoutes} from "../../stores/useRoutes";
import VNotFoundProjects from "../Base/VNotFoundProjects.vue";

const useProjects = useSeoClicksProjects();

</script>

<style scoped>

</style>