<template>
  <div class="v-form-radio vfr" :class="checked ? 'checked' : ''">
    <v-icon-svg name="icon-radio" class="vfr__icon-check"/>
    <input type="radio" ref="checkbox" v-model="checkboxModel" :name="name" :value="value">
  </div>
</template>

<script setup lang="ts">
import VIconSvg from "../Base/VIconSvg.vue";
import {computed, ref, watch} from "vue";

import {defineProps} from 'vue';

const props = defineProps({
  name: {},
  value:{},
})

const checkboxModel = defineModel();

const checked = computed(() => {
  return props.value === checkboxModel.value;
});

</script>

<style scoped lang="scss">
.v-form-radio.vfr{
  background-color: #fff;
  width:18px;
  height:18px;
  border-radius: 50%;
  border:1px solid #AEB7C7;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .vfr__icon-check{
    display: block;
    opacity:0;
  }
  .vfr__icon-minus{
    .vfr__icon-minus-icon{

    }
  }
  input{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity:0;
    cursor: pointer;
  }

  &.checked{
    background: #1C7EE0;
    border-color:#1C7EE0;
    .vfr__icon-check{
      opacity:1
    }
  }

}
</style>