<template>
  <div
      v-if="messages && messages.constructor === Array && messages.length > 0"
      class="v-form-message-error vfme invalid invalid-feedback"
      :class="mainClass"
  >
    <span v-for="message in messages" v-html="message"/>
  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  messageError: {},
  noMargin: {type:Boolean,default:false,},
})

const messages = computed(() => {
  if(props.messageError){
    return [props.messageError];
  }else{
    return false;
  }
})

const mainClass = computed(() => {
  return props.noMargin ? 'vfme--no-margin' : '';
});

</script>

<style lang="scss" scoped>
.v-form-message-error.vfme{
  &.invalid.invalid-feedback{
    background: #F2001D0D;
    color:#F2001D;
    padding: 12px 10px 12px 10px;
    border-radius: 4px;
    margin-bottom:20px;
    word-wrap: break-word;
    &.vfme--no-margin{
      margin-bottom:0px;
      margin-top:0px;
    }
  }
  :deep(a){
    color: #F2001D;
    text-decoration:underline!important;
  }
}

</style>