<template>
  <div class="v-project-deleted-bar vpdb">
    <div class="vpdb__title">DELETED</div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.v-project-deleted-bar.vpdb{
  width:100%;
  background:#C70B0B;
  padding:10px 30px;

  .vpdb__title{
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color:#FFFFFF;
  }
}
</style>