<template>
  <div class="bc-payer bcp">
    <bc-payer-tabs class="bcp__tabs" v-model="currentTab"/>
    <div class="bcp__body">

      <transition name="fade" mode="out-in">
        <div class="bcp__item" v-show="currentTab === '#tab_cards'">
          <bc-payer-base :items="cardsItems" type="cards"/>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="bcp__item" v-show="currentTab === '#tab_paypal'">
          <bc-payer-base :items="paypalItems" type="paypal"/>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="bcp__item" v-show="currentTab === '#tab_crypto'">
          <bc-payer-crypto/>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="bcp__item" v-show="currentTab === '#tab_bank_transfer'">
          <bc-payer-bank-transfer/>
        </div>
      </transition>

    </div>
  </div>
</template>

<script setup lang="ts">
import BcPayerTabs from "./BcPayerTabs.vue";
import {computed, onMounted, reactive, ref} from "vue";
import BcPayerBase from "./BcPayerBase.vue";
import BcPayerCrypto from "./BcPayerCrypto.vue";
import BcPayerBankTransfer from "./BcPayerBankTransfer.vue";

const currentTab = ref('#tab_cards');

const cardsItems = reactive([
  {
    icons: [
      {src: '/img/icons/payments/payment-visa.svg',},
      {src:'/img/icons/payments/payment-mastercard.svg',},
      {src:'/img/icons/payments/payment-american-express.svg',},
      {src:'/img/icons/payments/payment-jcb.svg',},
      {src:'/img/icons/payments/payment-union-pay.svg',},
      {src:'/img/icons/payments/payment-diners.svg',},
      {src:'/img/icons/payments/payment-discover.svg',},

    ],
    buttonTitle: 'Pay with Card',
    payType: 'stripe',
  }
]);

const paypalItems = reactive([
  {
    icons: [
      {
        src: '/img/icons/payments/paypal.svg',
        style: {
          width:'105px',
          height: 'auto'
        }
      },
    ],
    buttonTitle: 'Pay with PayPal',
    payType: 'paypal',
  }
]);

defineExpose({currentTab});

</script>

<style scoped lang="scss">

.bc-payer.bcp{
  .bcp__item{
    padding-top:20px;
  }
}

.fade-enter-from{
  opacity:0;
}
.fade-enter-active{
  transition: opacity 0.5s;
}
.fade-enter-to{
  opacity:1;
}
.fade-leave-from{
  display:none;
}
.fade-leave-active{
  display:none;
}
.fade-leave-to{
  display:none;
}

</style>