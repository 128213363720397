import {runIfHasSelecter} from "../../utils/panel-lib";
import { api } from "../../utils/panel-api";

const initScripts = function (currentData, translations) {
    const $historyTable = $('#billing-history-table');
    const $showOnlyPayments = $('#show-only-payments');
    const $searchInput = $('.search-box input[type="search"]');
    const $loadingBlock = $('.loading-block');

    $historyTable.dataTable({
        responsive: true,
        processing: true,
        dom: "<'row'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 d-none'i><'col-12'p>>",
        order: [[0, "desc"]],
        language: {
            emptyTable: translations.dataTable.emptyTable,
            lengthMenu: translations.dataTable.lengthMenu,
            zeroRecords: translations.dataTable.zeroRecords,
            info: translations.dataTable.info,
            infoEmpty: translations.dataTable.infoEmpty,
            infoFiltered: translations.dataTable.infoFiltered,
            loadingRecords: translations.dataTable.loadingRecords,
            processing: translations.dataTable.processing,
            searchPlaceholder: translations.dataTable.searchPlaceholder,
            aria: {
                sortAscending: ": " + translations.sortAscending,
                sortDescending: ": " + translations.sortAscending,
            },
            paginate: {
                first: '<i class="fas fa-arrow-to-left"></i>',
                last: '<i class="fas fa-arrow-to-right"></i>',
                next: '<i class="fas fa-chevron-right"></i>',
                previous: '<i class="fas fa-chevron-left"></i>'
            },
        },
        buttons: [],
        columns: [
            {"width": "10%"},
            {"width": "10%"},
            {"width": "10%"},
            {"width": "25%"},
            {"width": "10%"},
            {
                "width": "25%",
                orderable: false,
                render: (data, type, row) => {
                    return wrapHashtagWithLinkSeoTrafficProject(data)
                }
            },
            {"width": "10%", orderable: false},
        ],
        createdRow: function (row, data) {
            if (parseInt(data.is_decline) === 1) {
                $(row).addClass('bg-red');
            }
        }
    });

    $("#show-only-payments").prop('checked', currentData.billingShowOnlyPayments);

    function getShowOnlyParameter() {
        return $showOnlyPayments.is(":checked") ? 1 : 0;
    }

    $showOnlyPayments.change(function () {
        updateBillingHistory();

        api.user.saveInterfaceSettings({
            billingShowOnlyPayments: getShowOnlyParameter(),
        });
    });

    // Search
    let localStorageSearch;
    localStorageSearch = localStorage.getItem('st-billing-history-table-search');

    if (localStorageSearch !== null && localStorageSearch !== 'null' && localStorageSearch) {
        $searchInput.val(localStorageSearch);
    }

    $searchInput.on('keyup search', function () {
        $historyTable.DataTable().search($(this).val()).draw();
        localStorage.setItem('st-billing-history-table-search', $(this).val());
    });

    updateBillingHistory();

    function updateBillingHistory() {
        const url = "/api/billing-history?showOnlyPayments=" + getShowOnlyParameter();

        $historyTable.DataTable().search($searchInput.val()).ajax.url(url);

        /** DataTables before receiving the data */
        $historyTable.on('preXhr.dt', function () {
            $loadingBlock.addClass('processing');
        });

        /** DataTables after receiving the data */
        $historyTable.on('xhr.dt', function (e, settings, json) {
            $loadingBlock.removeClass('processing');
            $('body').removeClass('loading');
        }).DataTable().ajax.reload();
    }

    function wrapHashtagWithLinkSeoTrafficProject(inputString) {

        if (inputString.toLowerCase().includes("SEO Traffic Project".toLowerCase())) {
            const regex = /#(\S{12})/g;
            return inputString.replace(regex, '<a href="/seo-traffic/$1" target="_blank">#$1</a>');
        }
        return inputString;

    }
}

export const initBillingHistoryScripts = () => {
    runIfHasSelecter('[data-role=billing-history]', [
        initScripts,
    ]);
}
