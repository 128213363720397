<template>

  <v-container class="bs-page-list">

    <v-head-title class="lbap__title">
      Subscriptions & Bonuses
    </v-head-title>

    <div class="list">
      <template v-for="subscription in useBillingSubscriptions().listActive" :key="subscription.id">
        <bs-preview :id="subscription.id"/>
      </template>

    </div>

  </v-container>

</template>

<script setup>
import VHeadTitle from "../Base/VHeadTitle.vue";
import VContainer from "../Base/VContainer.vue";
import BsPreview from "./BsPreview.vue";
import {useBillingSubscriptions} from "../../stores/useBillingSubscriptions";

useBillingSubscriptions().download();

</script>

<style lang="scss" scoped>
.bs-page-list{
  @include flex(column,start,stretch,32px);
  padding-bottom:100px;

  @media(max-width:767px){
    @include flex(column,start,stretch,20px);
    padding-top:16px!important;
    padding-bottom:100px!important;
  }

  .list{
    @include flex(column,start,stretch,32px);
    @media (max-width:767px) {
      @include flex(column,start,stretch,20px);
    }
  }
}
</style>