import {initCommonScripts} from "./common";
import {initCommonAuthScripts} from "./auth/common-auth";
import {initRegisterScripts} from "./auth/register";
import {initLoginScripts} from "./auth/login";
import {initResetPasswordScripts} from "./auth/reset-password";
import {initProfileScripts} from "./profile";
import {initInviteFriendScripts} from "./cashback/invite-friend";
import {initCashbackHistoryScripts} from "./cashback/cashback-history";

import {initBillingHistoryScripts} from "./billing/billing-history";
import {initBillingSubscriptionScripts} from "./billing/billing-subscriptions";

import {initGuaranteedProjectsScripts} from "./guaranteed/guaranteed-projects";
import {initGuaranteedProjectAddScripts} from "./guaranteed/guaranteed-project-add";
import {initGuaranteedProjectEditScripts} from "./guaranteed/guaranteed-project-edit";
import {initGuaranteedProjectRenewScripts} from "./guaranteed/guaranteed-project-renew";
import {initGuaranteedPayScripts} from "./guaranteed/guaranteed-pay";

import {initSeoClicksProjectsScripts} from "./search/seo-clicks-projects";
import {initSeoClicksProjectAddScripts} from "./search/seo-clicks-project-add";
import {initSeoClicksProjectEditScripts} from "./search/seo-clicks-project-edit";
import {initSeoClicksPayScripts} from "./search/seo-clicks-pay";
import {initSeoClicksStartFreeTrial} from "./search/seo-clicks-start-free-trial";

import {initBacklinksProjectsScripts} from "./backlinks/backlinks-projects";
import {initBacklinksProjectAddScripts} from "./backlinks/backlinks-project-add";
import {initBacklinksProjectEditScripts} from "./backlinks/backlinks-project-edit";
import {initBacklinksPayScripts} from "./backlinks/backlinks-pay";

import {initCalendlyWidget} from "./items/calendly-widget";
import {initBacklinksStartFreeTrial} from "./backlinks/backlinks-start-free-trial";

initCommonScripts();
initCommonAuthScripts();
initRegisterScripts();
initLoginScripts();
initResetPasswordScripts();
initProfileScripts();
initInviteFriendScripts();
initCashbackHistoryScripts();

initBillingHistoryScripts();
initBillingSubscriptionScripts();

initGuaranteedProjectsScripts();
initGuaranteedProjectAddScripts();
initGuaranteedProjectEditScripts();
initGuaranteedProjectRenewScripts();
initGuaranteedPayScripts();

initSeoClicksProjectsScripts();
initSeoClicksProjectAddScripts();
initSeoClicksProjectEditScripts();
initSeoClicksPayScripts();
initSeoClicksStartFreeTrial();

initBacklinksProjectsScripts();
initBacklinksProjectAddScripts();
initBacklinksProjectEditScripts();
initBacklinksPayScripts();
initBacklinksStartFreeTrial();

initCalendlyWidget();