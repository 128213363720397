<template>
  <div class="sc-projects scps">
    <v-container class="scps__projects-main">
      <v-breadcrumbs :items="breadcrumbs" class="scps__projects-breadcrumbs"/>
      <bc-balance class="scps__projects-balance" :credit-type="CREDIT_TYPE_SEO"/>
      <v-head-title class="scps__title">
        My Projects
      </v-head-title>
      <div class="scps__controls" v-if="useProjects._projects.length > 0">
        <v-button tag="a" :href="useRoutes().url('seo-clicks.add')" class="scps__controls-button" color="blue2" :is-bold="true">Add project</v-button>
      </div>

      <div class="scps__charts" v-if="useProjects._projects.length > 0">

        <div class="scps__chart-column">

          <div class="scps__column-title">
            <div class="scps__column-title-left">
              <span class="scps__column-title--bold">Clicks Delivered</span>
              <div class="scps__column-title-period-wrap">
                <div class="scps__column-title-period-label">Period:</div>
                <v-period-simple
                    :start-date="periodSelectedStart"
                    :end-date="periodSelectedEnd"
                    ref="inputPeriodSimple"
                    @save="savePeriodChart"
                />
              </div>
            </div>
            <div class="scps__column-title-right">
              <span class="scps__column-title--bold">{{useProjectsDailyChart?.chartData?.totalHits}} clicks</span>
            </div>
          </div>

          <div class="scps__chart">
            <chart-seo-clicks :chart="useProjectsDailyChart.chartData" chart-size="normal" class="scps__chart-inner" ref="canvasChart"/>
          </div>

        </div>

        <div class="scps__realtime-column">

          <div class="scps__column-title">
            <div class="scps__column-title-left scps__column-title-left--short-gap">
          <span class="scps__column-title--bold scps__column-title-realtime">
            <v-icon-realtime-pulse class="scps__column-title-realtime-icon"/>
            Realtime
          </span>
              <div class="scps__column-title--muted">
                Last 30 min
              </div>
            </div>
          </div>

          <div class="scps__realtime">
            <chart-realtime :chart-data="useRealtime.chartData" ref="canvasRealtimeChart"/>
          </div>

        </div>
      </div>

      <sc-projects-list class="scps__projects-list"/>
    </v-container>
  </div>
</template>

<script setup>
import VBreadcrumbs from "../Base/VBreadcrumbs.vue";
import {onMounted, ref, watch} from "vue";
import VButton from "../Base/VButton.vue";
import VContainer from "../Base/VContainer.vue";
import ScProjectsList from "./ScProjectsList.vue";
import VHeadTitle from "../Base/VHeadTitle.vue";
import VPeriodSimple from "../Base/VPeriodSimple.vue";
import ChartSeoClicks from "../Chart/ChartSeoClicks.vue";
import ChartRealtime from "../Chart/ChartRealtime.vue";
import VIconRealtimePulse from "../Base/VIconRealtimePulse.vue";
import {
  useRealtimeSeoClicksProjects, useSeoClicksProjects,
  useSeoClicksProjectsDailyChart
} from "../../stores/useSeoClicks.js";
import {useRoutes} from '../../stores/useRoutes.js';
import BcBalance from "../BuyCredits/BcBalance.vue";
import {CREDIT_TYPE_SEO} from "../../stores/useBilling";

const useProjectsDailyChart = useSeoClicksProjectsDailyChart();
useProjectsDailyChart.update();
const useProjects = useSeoClicksProjects();
useProjects.download();

const breadcrumbs = ref([
  {url: useRoutes().url('seo-clicks.list'), title: 'SEO Traffic',},
  {title: 'My Projects',}
]);

const canvasChart = ref();
const inputPeriodSimple = ref();
watch(() => useProjectsDailyChart.chartData, () => {
  canvasChart?.value?.update(useProjectsDailyChart.chartData);
}, {deep:true});

const periodSelectedStart = ref((() => {
  let startDays = new Date();
  startDays.setDate(startDays.getDate() - 30);
  return startDays.getTime();
})());
const periodSelectedEnd = ref((new Date().getTime()));
const savePeriodChart = async (startDate, endDate) => {

  let [status,message] = await useProjectsDailyChart.updateFromTo(new Date(startDate), new Date(endDate));

  if(status){
    periodSelectedStart.value = startDate;
    periodSelectedEnd.value = endDate;
    inputPeriodSimple.value.saveWithSuccess();
  }else{
    inputPeriodSimple.value.saveWithError(message);
  }

}

const useRealtime = useRealtimeSeoClicksProjects();
useRealtime.update().then(() => {//первая загрузка и отрисовка
  canvasRealtimeChart?.value?.updateChart(useRealtime.chartData);
});
const canvasRealtimeChart = ref();

onMounted(() => {
  canvasRealtimeChart?.value?.updateChart(useRealtime.chartData);//еще отрисовка, если загрузилось раньше , чем построился дом
  setInterval(() => {//и таймер каждую минуту
    useRealtime.update().then(() => {
      canvasRealtimeChart?.value?.updateChart(useRealtime.chartData);
    });
  },1000*10);
});

</script>

<style lang="scss" scoped>
.sc-projects.scps{
  .scps__projects-breadcrumbs{}
  .scps__projects-balance{
    margin-bottom:20px;
  }
  .scps__projects-main{
    max-width:1060px;
  }
  .scps__title{
    margin-bottom:20px;
  }
  .scps__controls{
    margin-bottom:20px;
  }
  .scps__projects-list{}
  .scps__charts{
    display: flex;
    box-shadow: 0px 0px 14px 0px #1C7EE02E;
    padding: 30px ;
    gap: 30px;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom:20px;
    max-width: 1000px;


    @media(max-width: $mobile-width){
      flex-direction: column;
      gap:0px;
    }

    .scps__chart-column{
      width:100%;
    }

    .scps__column-title{
      font-size: 14px;
      line-height:18px;
      margin-bottom:24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media(max-width:$mobile-width){
        font-size:16px;
        line-height: 19px;
        margin-bottom:20px;
      }

      .scps__column-title-realtime{
        display: flex;
        align-items: center;
        gap:12px;
        .scps__column-title-realtime-icon{
        }
        .scps__realtime-icon{
          flex-shrink: 0;
          width:14px;
          height:14px;
          background-color: #1A73E81A;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
        .scps__realtime-icon:before{
          content:'';
          display: block;
          width:6px;
          height:6px;
          background-color: #1A73E8;
          border-radius: 50%;
        }
      }

      .scps__column-title--bold{
        font-weight: 600;
      }
      .scps__column-title--muted{
        font-size:10px;
        line-height:12px;
        color:#00000080;
        text-transform: uppercase;
      }

      .scps__column-title-left{
        display: flex;
        align-items: center;
        gap:10px;
        &.scps__column-title-left--short-gap{
          gap:5px;
        }
        .scps__column-title-period-label{
          display: none;
        }
        @media(max-width: $mobile-width){
          .scps__column-title-period-wrap{
            display: flex;
            justify-content: space-between;
            font-size:14px;
            width:100%;
          }
          .scps__column-title-period-label{
            display:block;
          }
        }

        @media(max-width: $mobile-width){
          flex-direction: column;
          width:100%;
          align-items: flex-start;
        }

      }
      .scps__column-title-right{
        @media(max-width:$mobile-width){
          display: none;
        }
      }

    }

    .scps__realtime-column{
      flex-shrink: 0;
      width:300px;
      @media(min-width:$mobile-width-for-min-width) and (max-width: 1050px){
        width:160px;
      }
      @media(min-width:1050px) and (max-width: 1100px){
        width:200px;
      }
      @media(min-width:1100px) and (max-width: 1150px){
        width:250px;
      }
      @media(min-width:1150px){
        width:300px;
      }

      @media(max-width:$mobile-width){
        width:100%;
        max-width:100%;
        .scps__column-title-left{
          display: flex;
          flex-direction: row;
          align-items: center;
          gap:5px;
        }
      }
    }

    .scps__chart{
      .scps__chart-inner{
        width:100%;
        height:166px;
      }
    }

    @media(max-width: $mobile-width){
      > div{
        margin-bottom:20px;
        padding-bottom:20px;
        border-bottom:1px solid #D6E3EF80;
        &:last-child{
          padding-bottom:0px;
          border-bottom:0px;
          margin-bottom:0px;
        }
      }
    }

  }
}
</style>