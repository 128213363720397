export function generateUUID() {
    return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function priceFormatted(price){
    return parseFloat(price).toFixed(2);
}

export function pluralize(word, count, ending = 's'){
    return count === 1 ? word : word + ending;
}

export function delay(ms){
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function convertSecondsToTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return {hours, minutes, seconds };
}

/**
 * @param {Date} date
 * @return {string}
 */
export function formatDate(date){
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}

/**
 * @param {Number} number
 * @return {string}
 */
export function shortNumber(number){
    let isNegative = number < 0;
    let response;
    number = Math.abs(number);
    if (number >= 1_000_000) {
        response = (number / 1_000_000).toFixed(0) + 'M'; // 1,000,000 -> 1.0M
    } else if (number >= 1_000) {
        response = (number / 1_000).toFixed(0) + 'K'; // 1,000 -> 1.0K
    } else {
        if(typeof number === 'number' && !isNaN(number)){
            response = number.toString();
        }else{
            response = number;
        }
    }
    return isNegative ? '-' + response : response;
}

export function getScssVar(name){
    return getComputedStyle(document.documentElement).getPropertyValue(`--${name}`).trim();
}

export function isObject(variable){
    return variable && typeof variable === 'object' && variable.constructor === Object;
}

/**
 *
 * @param {Function} func
 * @param {Array} params
 * @return {Promise<*>}
 */
export async function processFunction(func, params) {
    let result = func(...params);
    return result instanceof Promise ? await result : result;
}

export function isValidDate(date){
    return date instanceof Date && !isNaN(date);
}

export function parseDateString(dateString) {
    const dateParts = dateString.split(/[-/.]/); // Разделители могут быть "-", ".", "/"

    let day, month, year;

    if (dateParts.length === 2) {
        // Формат дд-мм или дд/мм
        [day, month] = dateParts;
        year = new Date().getFullYear(); // Используем текущий год, если он не указан
    } else if (dateParts.length === 3) {
        if (dateParts[0].length === 4) {
            // Формат ГГГГ-мм-дд
            [year, month, day] = dateParts;
        } else {
            // Формат дд-мм-ГГГГ
            [day, month, year] = dateParts;
        }
    } else {
        return new Date("Invalid Date"); // Возвращаем невалидную дату, если формат неизвестен
    }

    // Преобразуем день и месяц к двухзначному формату (например, "1" в "01")
    day = day.padStart(2, '0');
    month = month.padStart(2, '0');

    return new Date(`${year}-${month}-${day}`);
}