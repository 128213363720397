<template>
  <vue-multiselect
      :options="options"
      v-model="modelValue"
      class="v-form-select-custom"
      :showLabels="false"
      track-by="value"
      label="name"
      :allowEmpty="false"
      @update:model-value="(option) => {updateModelValue(option)}"
  >
    <template #singleLabel="singleLabel">
      {{ getCurrentLabel(singleLabel.option) }}
    </template>
    <template #option="option">
      <span class="multiselect__tag">
        <span>{{option.option.name}}</span>
      </span>
    </template>
    <template #caret="caret">
      <div @mousedown.prevent.stop="caret.toggle()" class="multiselect__select">
        <v-icon-svg name="select-dropdown-arrow"/>
      </div>
    </template>
  </vue-multiselect>
</template>

<script setup>
import VueMultiselect from 'vue-multiselect/src/Multiselect.vue'
import {computed, ref, watch, watchEffect} from "vue";
import VIconSvg from "../Base/VIconSvg.vue";

const props = defineProps({
  options : {
    default : []
  },
  strictValue: {
    default:true,
  }
});

const compareValues = (val1,val2) => {
  if(props.strictValue){
    return val1 === val2
  }{
    if(val1 && val2){
      return (
          (val1.hasOwnProperty('toLowerCase') ? val1.toLowerCase() : val1)
          ===
          (val2.hasOwnProperty('toLowerCase') ? val1.toLowerCase() : val2)
      );
    }else{
      return false;
    }
  }
}

const model = defineModel();
const modelValue = ref();

modelValue.value = props.options.find((opt) => compareValues(opt.value,model.value));

const emit = defineEmits(['change']);

watch([() => model.value, () => props.options], () => {
  if(modelValue.value?.value !== model.value){
    modelValue.value = props.options.find((opt) => compareValues(opt.value,model.value));
  }
  if(typeof modelValue.value === 'undefined' && props.options.length > 0){
    modelValue.value = props.options[0];
  }
});

watch(() => modelValue.value, () => {
  if(modelValue.value && !compareValues(modelValue.value.value,model.value)){
    model.value = modelValue.value.value;
  }
});

const updateModelValue = (option) => {
  emit('change',{value: option.value})
}

const getCurrentLabel = (currentOption) => {
  if(typeof currentOption === 'object' && currentOption.hasOwnProperty('name')){
    return currentOption.name;
  }else{
    let foundOption = props.options.find((option) => {
      return option.value === currentOption;
    });
    if(foundOption){
      return foundOption.name;
    }else{
      return '-';
    }
  }
}

</script>

<style lang="scss" scoped>

:deep(.multiselect__spinner ){
  position: absolute;
  right: 1px;
  top: 1px;
  width: 40px;
  height: 38px;
  background: #fff;
  display: block;
}

:deep(.multiselect__spinner::before),
:deep(.multiselect__spinner::after ){
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #41b883 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}

:deep(.multiselect__spinner::before ){
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}

:deep(.multiselect__spinner::after ){
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}

:deep(.multiselect__loading-enter-active),
:deep(.multiselect__loading-leave-active ){
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

:deep(.multiselect__loading-enter),
:deep(.multiselect__loading-leave-active ){
  opacity: 0;
}

.v-form-select-custom,
:deep(.multiselect__input),
:deep(.multiselect__single ){
  font-family: inherit;
  font-size: 14px;
  touch-action: manipulation;
}

.v-form-select-custom{
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}

.v-form-select-custom :deep( * ){
  box-sizing: border-box;
}

.v-form-select-custom:focus{
  outline: none;
}

.v-form-select-custom.multiselect--disabled{
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}

.v-form-select-custom.multiselect--active{
  z-index: 50;
}

.v-form-select-custom.multiselect--active:not(.multiselect--above) :deep(.multiselect__current),
.v-form-select-custom.multiselect--active:not(.multiselect--above) :deep(.multiselect__input),
.v-form-select-custom.multiselect--active:not(.multiselect--above) :deep(.multiselect__tags ){
  border-radius: 5px;
}

.v-form-select-custom.multiselect--active :deep(.multiselect__select svg ){
  transform: rotateZ(180deg);
}

.v-form-select-custom.multiselect--above.multiselect--active :deep(.multiselect__current),
.v-form-select-custom.multiselect--above.multiselect--active :deep(.multiselect__input),
.v-form-select-custom.multiselect--above.multiselect--active :deep(.multiselect__tags ){
  border-radius: 5px;
}

:deep(.multiselect__input),
:deep(.multiselect__single ){
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: calc(100%);
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}

:deep(.multiselect__input::placeholder ){
  color: #8d8d8d;
}

:deep(.multiselect__tag ~ .multiselect__input),
:deep(.multiselect__tag ~ .multiselect__single ){
  width: auto;
}

:deep(.multiselect__input:hover),
:deep(.multiselect__single:hover ){
  border-color: #cfcfcf;
}

:deep(.multiselect__input:focus),
:deep(.multiselect__single:focus ){
  border-color: #a8a8a8;
  outline: none;
}

:deep(.multiselect__single ){
  padding-left: 5px;
  margin-bottom: 8px;
}

:deep(.multiselect__tags-wrap ){
  display: inline;
}

:deep(.multiselect__tags ){
  min-height: 38px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #D6E3EF;
  background: #fff;
  font-size: 14px;
  color:#1F293B;
}

:deep(.multiselect__tag ){
  position: relative;
  display: block;
  border-radius: 0px;
  margin-right: 0px;
  color: #1F293B;
  line-height: 1;
  background: none;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  padding:7px 16px;
  width:100%;
}
:deep(.multiselect__tag:hover ){
  background-color: #f8f9fa;
}
:deep(.multiselect__option--selected .multiselect__tag){
  background-color: #fff!important;
  span{
    display:flex;
    justify-content: space-between;
    align-items:center;
    &:after{
      content:'';
      width:14px;
      height:14px;
      display:block;
      flex-shrink:0;
      background-image:url(/img/icons/check-deep-blue.svg);
      background-size:18px 18px;
      background-position:50%
    }
  }
}
:deep(.multiselect__tag-icon ){
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}

:deep(.multiselect__tag-icon::after ){
  content: "×";
  color: #266d4d;
  font-size: 14px;
}

/* // Remove these lines to avoid green closing button
//.multiselect__tag-icon:focus,
//.multiselect__tag-icon:hover {
//  background: #369a6e;
//} */

:deep(.multiselect__tag-icon:focus::after),
:deep(.multiselect__tag-icon:hover::after ){
  color: white;
}

:deep(.multiselect__current ){
  line-height: 16px;
  min-height: 40px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

:deep(.multiselect__select ){
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  width: 32px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

:deep(.multiselect__select::before ){
  display: none;
}

:deep(.multiselect__placeholder ){
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}

.v-form-select-custom.multiselect--active :deep(.multiselect__placeholder ){
  display: none;
}

:deep(.multiselect__content-wrapper ){
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #D6E3EF;
  transform: translateY(2px);
}

:deep(.multiselect__content ){
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}

.v-form-select-custom.multiselect--above :deep(.multiselect__content-wrapper ){
  bottom: 100%;
  border-radius: 5px;
  border: 1px solid #D6E3EF;
  transform: translateY(-2px);
}

:deep(.multiselect__content::-webkit-scrollbar ){
  display: none;
}

:deep(.multiselect__element ){
  display: block;
}

:deep(.multiselect__option ){
  display: block;
  min-height: initial;
  padding:0px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}

:deep(.multiselect__option::after ){
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}

:deep(.multiselect__option--highlight ){
  background: none;
  outline: none;
}

:deep(.multiselect__option--highlight::after ){
  content: attr(data-select);
  background: #F4F9FD;;
  color: #384967;
}

:deep(.multiselect__option--selected ){
  background-color: #8897ae!important;
  color: inherit!important;
  font-weight: 400!important;
}

:deep(.multiselect__option--selected::after ){
  content: attr(data-selected);
  color: silver;
  background: inherit;
}

:deep(.multiselect__option--selected.multiselect__option--highlight ){
  background: #ff6a6a;
  color: #fff;
}

:deep(.multiselect__option--selected.multiselect__option--highlight::after ){
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.v-form-select-custom.multiselect--disabled :deep(.multiselect__current),
.v-form-select-custom.multiselect--disabled :deep(.multiselect__select ){
  background: #ededed;
  color: #a6a6a6;
}

:deep(.multiselect__option--disabled ){
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}

:deep(.multiselect__option--group ){
  background: #ededed;
  color: #35495e;
}

:deep(.multiselect__option--group.multiselect__option--highlight ){
  background: #35495e;
  color: #fff;
}

:deep(.multiselect__option--group.multiselect__option--highlight::after ){
  background: #35495e;
}

:deep(.multiselect__option--disabled.multiselect__option--highlight ){
  background: #dedede;
}

:deep(.multiselect__option--group-selected.multiselect__option--highlight ){
  background: #ff6a6a;
  color: #fff;
}

:deep(.multiselect__option--group-selected.multiselect__option--highlight::after ){
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.v-form-select-custom.multiselect-enter-active,
.v-form-select-custom.multiselect-leave-active,
:deep(.multiselect-enter-active),
:deep(.multiselect-leave-active){
  transition: all .15s ease;
}

.v-form-select-custom.multiselect-enter-from,
.v-form-select-custom.multiselect-leave-to,
:deep(.multiselect-enter-from),
:deep(.multiselect-leave-to){
  opacity: 0;
}

:deep(.multiselect__strong){
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}


@keyframes spinning {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(2turn);
  }
}

</style>