<template>
  <v-container class="sc-projects-page">

    <v-breadcrumbs :items="breadcrumbs" no-padding/>

    <bc-balance :credit-type="CREDIT_TYPE_BACKLINK"/>

    <v-head-title>
      My Projects
    </v-head-title>

    <v-search-bar skin="gray-on-gray" placeholder="Search by ID, date or description" v-model="searchProjectStr"/>

    <div class="controls">
      <v-button color="green">+ Add project</v-button>
      <div class="filters">

        <v-sort-by-simple
            :options="typesSortBy"
            v-model="sortBySimpleSelected"
            v-model:sorting-direct="sortBySimpleDirect"
            class="filter-sort"
            arrow-sorting-direct
        />

      </div>
    </div>

    <div class="list">

    </div>

    <div class="footer">
      18 Link-Building projects
    </div>

  </v-container>
</template>

<script setup>
import VContainer from "../Base/VContainer.vue";
import VBreadcrumbs from "../Base/VBreadcrumbs.vue";
import {CREDIT_TYPE_BACKLINK} from "../../stores/useBilling";
import BcBalance from "../BuyCredits/BcBalance.vue";
import VHeadTitle from "../Base/VHeadTitle.vue";
import VButton from "../Base/VButton.vue";
import {reactive, ref} from "vue";
import {useRoutes} from "../../stores/useRoutes";
import VSearchBar from "../Base/VSearchBar.vue";
import VSortBySimple from "../Base/VSortBySimple.vue";
import {useLinkBuildProjects} from "../../stores/useLinkBuilding";

const breadcrumbs = ref([
  {'url': useRoutes().url('backlinks-project.list'), 'title': 'Link-building Projects'},
  { 'title': 'My Projects'}
]);

useLinkBuildProjects().download();

const searchProjectStr = ref();

const typesSortBy = reactive([
  {
    value: 'date_created',
    name: 'Date created',
  },
  {
    value: 'name',
    name: 'Name',
  },
]);
const sortBySimpleSelected = ref(typesSortBy[0]);
const sortBySimpleDirect = ref('asc');

</script>

<style lang="scss" scoped>
.sc-projects-page{
  @include flex(column,start,stretch,24px);
  padding-bottom:100px;
  .list{
    @include flex(column,start,stretch,20px);
  }
  .footer{
    color:$dark-30;
    text-align:center;
  }
  .controls{
    @include flex(row,between,center);
  }
  .filter-sort{
    color:$dark-50;
  }
}
</style>