import {
    formatNumber,
    formatTimeleft,
    formValidationErrorClear,
    formValidationErrorHandling,
    prepareChartData,
    runIfHasSelecter
} from "../../utils/panel-lib";
import {api} from "../../utils/panel-api";
import Chart from 'chart.js/auto';
import _ from "lodash";

const initGaErrorMessages = function (currentData) {
    if (currentData.isDeleted) {
        return;
    }

    let nowTime = new Date().getTime();
    let createdProject = new Date(new Date(currentData.created_human).getTime() - new Date(currentData.created_human).getTimezoneOffset() * 60 * 1000).getTime();

    if (createdProject) {
        let expireTime = new Date(createdProject + 10 * 60000).getTime();

        if (nowTime >= expireTime) {
            $('.ga-error-edit').show();
        }
    }
}

const initModals = function (currentData) {
    $('#confirm-delete')
        .on('show.bs.modal', function (e) {
            $(this).find('.btn-ok').attr('data-href', $(e.relatedTarget).data('href'));
        })
        .find('.btn-ok')
        .on('click', function (e) {
            e.preventDefault();

            $(this).prop('disabled', true);

            $.ajax({
                url: $(this).data('href'),
                method: 'post',
                success: function () {
                    // $('#confirm-delete').modal('toggle');
                    window.location.reload();
                },
                error: function () {
                    $(this).prop('disabled', false);
                }
            });
        });

    $('#confirm-undelete')
        .on('show.bs.modal', function (e) {
            $(this).find('.btn-ok').attr('data-href', $(e.relatedTarget).data('href'));
        })
        .find('.btn-ok')
        .on('click', function (e) {
            e.preventDefault();

            $(this).prop('disabled', true);

            $.ajax({
                url: $(this).data('href'),
                method: 'post',
                success: function () {
                    // $('#confirm-undelete').modal('toggle');
                    window.location.reload();
                },
                error: function () {
                    $(this).prop('disabled', true);
                }
            })
        });

    $('#confirm-clone .btn-ok').on('click', function (e) {
        e.preventDefault();

        let $button = $(this);

        let data = {
            originalId: currentData.projectId
        }

        $button.prop('disabled', true);

        api.guaranteedProject.clone(data,
            function (response) {
                let data = response.data;

                if (data.success) {
                    if (data.projectId) {
                        window.location.replace('/project?id=' + data.projectId);
                    }
                } else {
                    // addNotification(response.message);
                    $button.closest('.modal')
                        .find('.info-message-form')
                        .show()
                        .find('.info-message-form__desc')
                        .html(data.message);
                    $button.prop('disabled', false);
                }
            },
            function () {
                $button.prop('disabled', false);
            });
    });

    $('#confirm-upgrade').on('show.bs.modal', function (e) {
        $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));
    });
}

const initExpiration = function (currentData, translations) {
    if (currentData.isDeleted) {
        return;
    }

    let $timer = $('[data-timeleft]');
    if ($timer.length === 0) {
        return;
    }

    let distance = parseInt($timer.data('timeleft'));
    let interval;

    const refreshTime = function () {
        $timer.text(formatTimeleft(distance, translations));

        if (distance < 0) {
            clearInterval(interval);
            $timer.html(translations.expired);
        }

        distance--;
    }
    refreshTime();

    interval = setInterval(refreshTime, 1000);
}

const initProjectStarting = function (currentData) {
    if (currentData.hasHits) {
        $('[data-panel-id="project-chart-realtime"]').show();

        return;
    }

    lottie.loadAnimation({
        container: document.querySelector('.project-starting-panel__indicator0 .lottie-blinking-circle'),
        renderer: 'svg',  // svg, canvas, html
        loop: true,
        autoplay: true,
        path: '/img/lottie/pulsing-circle.json'
    });

    lottie.loadAnimation({
        container: document.querySelector('.project-starting-panel__indicator1 .lottie-blinking-circle'),
        renderer: 'svg',  // svg, canvas, html
        loop: true,
        autoplay: true,
        path: '/img/lottie/pulsing-circle.json'
    });

    lottie.loadAnimation({
        container: document.querySelector('.project-starting-panel__indicator2 .lottie-blinking-circle'),
        renderer: 'svg',  // svg, canvas, html
        loop: true,
        autoplay: true,
        path: '/img/lottie/pulsing-circle.json'
    });

    lottie.loadAnimation({
        container: document.querySelector('.project-starting-panel__indicator3 .lottie-blinking-circle'),
        renderer: 'svg',  // svg, canvas, html
        loop: true,
        autoplay: true,
        path: '/img/lottie/pulsing-circle.json'
    });

    let startingInterval;

    const startWaiting = function () {
        startingInterval = setInterval(function () {
            api.guaranteedProject.checkStartingIndicators(currentData.projectId, function (response) {
                let data = response.data;

                if ((data.indicator1 === 1 && data.indicator2 === 1 && data.indicator3 === 1) || data.indicator3 === 1) {
                    $('[data-panel-id="project-chart-realtime"]').show();
                    $('[data-panel-id="project-starting-panel"]').hide();

                    clearInterval(startingInterval);
                    startingInterval = null;

                    return;
                }

                if (data.isNotEnoughCredits) {
                    $('.project-starting-panel__indicator0').show();
                    $('.project-starting-panel__indicator0 .lottie-blinking-circle').show();
                    $('.project-starting-panel__indicator0 .icon-indicator-off').hide();
                    $('.project-starting-panel__indicator0 .icon-indicator-on').hide();

                    $('.project-starting-panel__indicator1 .lottie-blinking-circle').hide();
                    $('.project-starting-panel__indicator1 .icon-indicator-off').show();
                    $('.project-starting-panel__indicator1 .icon-indicator-on').hide();

                    $('.project-starting-panel__indicator2 .lottie-blinking-circle').hide();
                    $('.project-starting-panel__indicator2 .icon-indicator-off').show();
                    $('.project-starting-panel__indicator2 .icon-indicator-on').hide();

                    $('.project-starting-panel__indicator3 .lottie-blinking-circle').hide();
                    $('.project-starting-panel__indicator3 .icon-indicator-off').show();
                    $('.project-starting-panel__indicator3 .icon-indicator-on').hide();
                } else {
                    $('.project-starting-panel__indicator0 .lottie-blinking-circle').hide();
                    $('.project-starting-panel__indicator0 .icon-indicator-off').hide();
                    $('.project-starting-panel__indicator0 .icon-indicator-on').show();

                    if (data.indicator1 === 0) {
                        $('.project-starting-panel__indicator1 .lottie-blinking-circle').hide();
                        $('.project-starting-panel__indicator1 .icon-indicator-off').show();
                        $('.project-starting-panel__indicator1 .icon-indicator-on').hide();
                    } else if (data.indicator1 === 1) {
                        $('.project-starting-panel__indicator1 .lottie-blinking-circle').hide();
                        $('.project-starting-panel__indicator1 .icon-indicator-off').hide();
                        $('.project-starting-panel__indicator1 .icon-indicator-on').show();
                    } else {
                        $('.project-starting-panel__indicator1 .lottie-blinking-circle').show();
                        $('.project-starting-panel__indicator1 .icon-indicator-off').hide();
                        $('.project-starting-panel__indicator1 .icon-indicator-on').hide();
                    }

                    if (data.indicator2 === 0) {
                        $('.project-starting-panel__indicator2 .lottie-blinking-circle').hide();
                        $('.project-starting-panel__indicator2 .icon-indicator-off').show();
                        $('.project-starting-panel__indicator2 .icon-indicator-on').hide();
                    } else if (data.indicator2 === 1) {
                        $('.project-starting-panel__indicator2 .lottie-blinking-circle').hide();
                        $('.project-starting-panel__indicator2 .icon-indicator-off').hide();
                        $('.project-starting-panel__indicator2 .icon-indicator-on').show();
                    } else {
                        $('.project-starting-panel__indicator2 .lottie-blinking-circle').show();
                        $('.project-starting-panel__indicator2 .icon-indicator-off').hide();
                        $('.project-starting-panel__indicator2 .icon-indicator-on').hide();
                    }

                    if (data.indicator3 === 0) {
                        $('.project-starting-panel__indicator3 .lottie-blinking-circle').hide();
                        $('.project-starting-panel__indicator3 .icon-indicator-off').show();
                        $('.project-starting-panel__indicator3 .icon-indicator-on').hide();
                    } else if (data.indicator3 === 1) {
                        $('.project-starting-panel__indicator3 .lottie-blinking-circle').hide();
                        $('.project-starting-panel__indicator3 .icon-indicator-off').hide();
                        $('.project-starting-panel__indicator3 .icon-indicator-on').show();
                    } else {
                        $('.project-starting-panel__indicator3 .lottie-blinking-circle').show();
                        $('.project-starting-panel__indicator3 .icon-indicator-off').hide();
                        $('.project-starting-panel__indicator3 .icon-indicator-on').hide();
                    }
                }
            });
        }, 5000);
    }

    $('[data-panel-id="project-starting-panel"]').show();
    startWaiting();
}

const initChart = function (currentData) {
    if (currentData.isDeleted) {
        return;
    }

    let projectChart = null;
    let projectChartRealtime = null;
    let $dataPickerRange = $('[data-picker-range]');
    let fromDate = $dataPickerRange.find('[name="from"]').val();
    let toDate = $dataPickerRange.find('[name="to"]').val();
    let $typeTrafficSelect = $('[name="type_traffic"]');
    let $typeTrafficRealtimeSelect = $('[name="type_traffic_realtime"]');
    let typeTraffic = $typeTrafficSelect.val();
    let typeTrafficRealtime = $typeTrafficRealtimeSelect.val();
    // let deviceType = $('[data-device-type]').data('device-type');
    // let isMobile = (deviceType === 'phone' || deviceType === 'tablet');
    let timerChartRealtimeUpdate;

    lottie.loadAnimation({
        container: document.querySelector('.lottie-pulsing-circle'),
        renderer: 'svg',  // svg, canvas, html
        loop: true,
        autoplay: true,
        path: '/img/lottie/pulsing-circle.json'
    });

    $dataPickerRange.find('input').on('show', function () {
        $(this).closest('label').find('.icon-arrow-down').addClass('open');
    });

    $dataPickerRange.find('input').on('hide', function () {
        $(this).closest('label').find('.icon-arrow-down').removeClass('open');
    });

    $dataPickerRange.find('input').on('changeDate', function (e) {
        let selectDate = new Date(e.date);
        let dateString = ('0' + selectDate.getDate()).slice(-2) + '.' + ('0' + (selectDate.getMonth() + 1)).slice(-2) + '.' + selectDate.getFullYear();

        $(this).val(dateString);
        fromDate = $dataPickerRange.find('[name="from"]').val();
        toDate = $dataPickerRange.find('[name="to"]').val();
        typeTraffic = $typeTrafficSelect.val();

        getChartData(currentData.projectId, fromDate, toDate, typeTraffic);
    });

    $typeTrafficSelect.on('change', function () {
        fromDate = $dataPickerRange.find('[name="from"]').val();
        toDate = $dataPickerRange.find('[name="to"]').val();
        typeTraffic = $(this).val();

        getChartData(currentData.projectId, fromDate, toDate, typeTraffic, 'line');
    });

    $typeTrafficRealtimeSelect.on('change', function () {
        typeTrafficRealtime = $(this).val();

        clearTimeout(timerChartRealtimeUpdate);
        getChartRealtimeData(currentData.projectId, null, null, typeTrafficRealtime, 'bar');
    });

    getChartData(currentData.projectId, fromDate, toDate, typeTraffic, 'line');
    getChartRealtimeData(currentData.projectId, null, null, typeTrafficRealtime, 'bar');

    function getChartData(projectId, fromDate = null, toDate = null, typeTraffic = 'clicks', typeChart = 'line') {
        if (!projectId) {
            return {};
        }

        let url = '/api/guaranteed/project/chart-data';

        if (!fromDate) {
            fromDate = new Date();
        }

        if (!toDate) {
            toDate = new Date();
            toDate.setDate(fromDate.getDate() - 1);
        }

        if (typeChart === 'bar') {
            fromDate = '';
            toDate = '';
        }

        let params = {
            projectId: projectId,
            from: fromDate,
            to: toDate,
            type_traffic: typeTraffic,
            type_chart: typeChart
        }

        $.ajax({
            url: url,
            data: params,
            dataType: 'json',
            success: function (response) {
                let $totalCount = $('[data-id="totalHitsOrVisitsCount"]');

                if (response.data) {
                    response.data.typeTraffic = typeTraffic;
                    response.data.typeChart = typeChart;

                    let titleVisits = (typeChart === 'bar' ? _.upperFirst(response.data.translations.visits.toLowerCase()) : response.data.translations.visits);
                    let titlePageViews = (typeChart === 'bar' ? _.upperFirst(response.data.translations.pageViews.toLowerCase()) : response.data.translations.pageViews);

                    if (typeTraffic === 'visits') {
                        $('.type-traffic-title').text(titleVisits);
                    } else if (typeTraffic === 'hits') {
                        $('.type-traffic-title').text(titlePageViews);
                    }

                    $totalCount.text(formatNumber(response.data.totalHits));

                    getProjectChart(response.data);
                }
            },
            error: function (err) {
                // console.log(err);
            }
        });
    }

    function getProjectChart(chartData) {
        if (!chartData) {
            return;
        }

        let ctx = document.getElementById('project-chart').getContext('2d');
        let chartConfig = prepareChartData(ctx, chartData, 'normal');

        if (chartConfig) {
            if (projectChart) {
                projectChart.data = chartConfig.data;
                projectChart.options = chartConfig.options;
                projectChart.update();
            } else {
                projectChart = new Chart(ctx, chartConfig);
            }
        }
    }

    function getChartRealtimeData(projectId, fromDate = null, toDate = null, typeTraffic = 'clicks', typeChart = 'line') {
        if (!projectId) {
            return {};
        }

        let url = '/api/guaranteed/project/chart-data';

        if (!fromDate) {
            fromDate = new Date();
        }

        if (!toDate) {
            toDate = new Date();
            toDate.setDate(fromDate.getDate() - 1);
        }

        if (typeChart === 'bar') {
            fromDate = '';
            toDate = '';
        }

        let params = {
            projectId: projectId,
            from: fromDate,
            to: toDate,
            type_traffic: typeTraffic,
            type_chart: typeChart
        }

        $.ajax({
            url: url,
            data: params,
            dataType: 'json',
            success: function (response) {
                let $totalCount = $('[data-id="totalHitsOrVisitsRealtimeCount"]');

                if (response.data) {
                    response.data.typeTraffic = typeTraffic;
                    response.data.typeChart = typeChart;

                    let titleVisits = (typeChart === 'bar' ? _.upperFirst(response.data.translations.visits.toLowerCase()) : response.data.translations.visits);
                    let titlePageViews = (typeChart === 'bar' ? _.upperFirst(response.data.translations.pageViews.toLowerCase()) : response.data.translations.pageViews);

                    if (typeTraffic === 'visits') {
                        $('.type-traffic-realtime-title').text(titleVisits);
                    } else if (typeTraffic === 'hits') {
                        $('.type-traffic-realtime-title').text(titlePageViews);
                    }

                    $totalCount.text(formatNumber(response.data.totalHits));

                    getProjectChartRealtime(response.data);
                }
            },
            error: function (err) {
                // console.log(err);
            },
            complete: function () {
                if (typeChart === 'bar') {
                    timerChartRealtimeUpdate = setTimeout(function () {
                        getChartRealtimeData(projectId, null, null, typeTraffic, typeChart);
                    }, 10000);
                }
            }
        });
    }

    function getProjectChartRealtime(chartData) {
        if (!chartData) {
            return;
        }

        let ctx = document.getElementById('project-chart-realtime').getContext('2d');
        let chartConfig = prepareChartData(ctx, chartData, 'normal');

        if (chartConfig) {
            if (projectChartRealtime) {
                projectChartRealtime.data = chartConfig.data;
                projectChartRealtime.options = chartConfig.options;
                projectChartRealtime.update();
            } else {
                projectChartRealtime = new Chart(ctx, chartConfig);
            }
        }
    }
}

const initTrafficTypeChangeListener = function () {
    let $referrersGroup = $('#referrers-group');
    let $socialGroup = $('#social-group');
    let $keywordsGroup = $('#keywords-group');
    let $trafficType = $("#traffic_type");

    $trafficType.on('change', updateTrafficType);
    updateTrafficType();

    function updateTrafficType() {
        let type = $trafficType.val();

        $referrersGroup.hide();
        $socialGroup.hide();
        $keywordsGroup.hide();

        if (type === 'referral') $referrersGroup.show();
        if (type === 'social') $socialGroup.show();
        if (type === 'organic') $keywordsGroup.show();
    }
}

const initFormDailyHitsEstimates = function (currentData, translations) {
    if (currentData.isDeleted) {
        return;
    }

    calculateDailyHitsEstimate();

    let $trafficspeedRange = $('#trafficspeed_range_slider');
    $trafficspeedRange.slider({
        range: "min",
        value: 0,
        min: 0,
        max: 100,
        step: 1,
        slide: function (event, ui) {
            $('#trafficspeed_range').val(ui.value).trigger('change');
            $('#trafficspeed').text(ui.value);
            calculateDailyHitsEstimate();
        },
        change: function (event, ui) {
            $('#trafficspeed_range').val(ui.value).trigger('change');
            $('#trafficspeed').text(ui.value);
            calculateDailyHitsEstimate();
        }
    });
    $trafficspeedRange.slider('value', $('#trafficspeed_range').val());

    let $bouncerateRange = $('#bouncerate_range_slider');
    $bouncerateRange.slider({
        range: "min",
        value: 0,
        min: 0,
        max: 100,
        step: 1,
        slide: function (event, ui) {
            $('#bouncerate_range').val(ui.value).trigger('change');
            $('#bouncerate').html(ui.value);
            calculateDailyHitsEstimate();
        }
    });
    $bouncerateRange.slider('value', $('#bouncerate_range').val());

    let $returnRateRange = $('#return_rate_slider');
    $returnRateRange.slider({
        range: "min",
        value: 0,
        min: 0,
        max: 100,
        step: 1,
        slide: function (event, ui) {
            $('#return_rate').val(ui.value).trigger('change');
            $('#returnrate').html(ui.value);
            calculateDailyHitsEstimate();
        }
    });
    $returnRateRange.slider('value', $('#return_rate').val());


    function calculateDailyHitsEstimate() {
        let bouncerate = parseInt($("#bouncerate_range").val());
        let returnrate = parseInt($("#return_rate").val());
        let speed = parseInt($("#trafficspeed_range").val());
        // let estimate = Math.round((currentData.dailyHits / 3 + (1 - bouncerate / 100) * (2 * currentData.dailyHits / 3)) * speed / 100);
        let dailyHits = Math.round(currentData.multiplier * currentData.dailyHits * speed / 100);
        let dailyUsers = Math.round(dailyHits / currentData.dailyUsersKoef);
        let bounce_rate_deduction = Math.round(currentData.multiplier * (((bouncerate / 100) * (2 * currentData.dailyHits / 3)) * speed / 100));
        // let show_total;
        let shortener_deduction = 0;
        $("#dailyhits").html(dailyHits);

        // if (bounce_rate_deduction > 0) {
        $("#bounce_rate_deduction")
            .show()
            .html('<br><small class="help-block" style="margin-left: 24px;">-' + bounce_rate_deduction + translations.dayHitsFor + ' ' + bouncerate + '% ' + translations.bounceRate + '</small>');

        // show_total = true;
        // } else {
        // $("#bounce_rate_deduction").hide();
        // }

        $("#daily_users").html(Math.round(dailyUsers * speed / 100));

        if (currentData.shortener !== '') {
            let shortenerConsumption = (dailyHits / currentData.shortenerConsumptionKoef);
            let shortenerDeduction = Math.round(shortenerConsumption * speed / 100);
            $("#shortener_deduction").html(shortenerDeduction);
            // show_total = true;
        }

        let rerurn_rate_deduction = Math.round(dailyUsers * (speed / 100) * (returnrate / 100));
        // if (rerurn_rate_deduction > 0) {
        $("#return_rate_deduction")
            .show()
            .html('<br><small class="help-block" style="margin-left: 24px;">-' + rerurn_rate_deduction + translations.dayUsersFor + ' ' + returnrate + '% ' + translations.returnRate + '</small>');

        // show_total = true;
        // } else {
        // $("#return_rate_deduction").hide();
        // }

        // if (show_total) {
        let $totalMaxHits = $("#total_max_hits");
        let totalHits = Math.round(dailyHits - bounce_rate_deduction - shortener_deduction);
        let totalUsers = Math.round(dailyUsers * speed / 100 - rerurn_rate_deduction);

        $totalMaxHits
            .show()
            .html('<br><small class="help-block" style="margin-left: 0;">' + translations.total + ': ' + totalHits + translations.dayHits + ', ' + totalUsers + translations.dayUsers + '</small>');
        // } else {
        $totalMaxHits.hide();
        // }
    }

    // let $btnactivate = $('#btnactivate');
    // let $btnpause = $('#btnpause');

    function checkStatus() {
        $.get("/api/guaranteed/project/check?id=" + currentData.projectId, function (response) {
            // $btnactivate.hide();
            // $btnpause.hide();

            // let status = response.data.status;
            let speed = response.data.speed;

            $('#trafficspeed').text(speed);
            $('#trafficspeed_range').val(speed);

            calculateDailyHitsEstimate();

            // if (status === 'running') {
            //     $btnactivate.addClass('noclick');
            //     $btnactivate.removeClass('btn-success');
            //     $btnactivate.addClass('btn-default');
            //
            //     $btnpause.addClass('btn-danger');
            //     $btnpause.removeClass('noclick');
            //     $btnpause.removeClass('btn-default');
            //     $btnpause.show();
            // } else if (status === 'paused') {
            //     $btnpause.addClass('noclick');
            //     $btnpause.removeClass('btn-danger');
            //     $btnpause.addClass('btn-default');
            //
            //     $btnactivate.addClass('btn-success');
            //     $btnactivate.removeClass('noclick');
            //     $btnactivate.removeClass('btn-default');
            //     $btnactivate.show();
            // } else {
            //     $btnpause.addClass('noclick');
            //     $btnactivate.addClass('noclick');
            //
            //     $btnpause.removeClass('btn-danger');
            //     $btnpause.addClass('btn-default');
            //
            //     $btnactivate.removeClass('btn-success');
            //     $btnactivate.addClass('btn-default');
            // }
        });
    }

    checkStatus();
}

const initGeoTypeChange = function (currentData) {
    if (currentData.isDeleted) {
        return;
    }

    let $citiesGeoCountry = $('#cities_geo_country');
    let $geoType = $("#geo_type");

    onGeoTypeChange();

    $citiesGeoCountry.on('change', function () {
        fill_in_cities();
    });

    $geoType.on('change', function () {
        onGeoTypeChange();
    });

    function onGeoTypeChange() {
        let $countries = $('#countries');
        let $cities = $('#cities');

        if ($geoType.val() === 'cities') {
            if ($cities.find('option:selected').length <= 0) {
                let countrySelect = $.trim($countries.find('option:selected').first().text());

                if (countrySelect) {
                    $citiesGeoCountry.find('option').each(function (index, el) {
                        if ($(el).text() === countrySelect) {
                            // $(el).attr('selected', 'selected');
                            $(el).prop('selected', true);
                            $citiesGeoCountry.selectpicker('destroy').selectpicker();
                        }
                    });
                }
            }

            $(".geo_type_countries").hide();
            $(".geo_type_states").hide();
            $(".geo_type_cities").show();

            fill_in_cities();
        } else if ($geoType.val() === 'countries') {
            $(".geo_type_countries").show();
            $(".geo_type_states").hide();
            $(".geo_type_cities").hide();
        } else {
            $(".geo_type_countries").show();
            $(".geo_type_states").hide();
            $(".geo_type_cities").hide();
        }
    }

    function fill_in_cities(remove_all) {
        let url = '/api/geolocations/cities-by-country?id=' + $citiesGeoCountry.val() + '&cities_provider=' + currentData.citiesProvider;
        let $cities = $('#cities');

        if (remove_all) {
            $cities.find('option').remove();
        } else {
            $cities.find('option:not(:selected)').remove();
        }

        let selectedCitiesIds = [];
        $cities.find('option:selected').each(function (index, el) {
            selectedCitiesIds.push(parseInt($(el).val()));
        });

        $.getJSON(url, function (response) {
            let data = response.data;

            for (let i = 0, len = data.length; i < len; i++) {
                // let $id = (parseInt(data[i]["id_ga"]) !== 0 ? data[i]["id_ga"] : data[i]["id"]);
                let $id = data[i]["id"];

                if (selectedCitiesIds.includes($id)) {
                    continue;
                }

                $cities.append($("<option></option>")
                    .attr("value", $id)
                    .text(data[i]["name"]));
            }

            $cities.selectpicker('destroy').selectpicker();
        })
    }

}

const initCheckLinkStatus = function (currentData, translations) {
    if (currentData.projectSize !== 'nano' || currentData.isDeleted) {
        return;
    }

    let $btnreciprocalcheck = $("#button_check_link_status");
    let $elLinkFoundStatus = $('#link_found_status');
    let buttonInterval;
    let secondsLeft = 0;

    const updateButton = function (countPoints = 0) {
        let points = _.reduce(_.range(0, countPoints), function (acc) {
            return acc + '.'
        }, '');

        $btnreciprocalcheck.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;' + translations.waiting + points);
    }

    const startWaiting = function () {
        secondsLeft = 0;

        updateButton();
        $btnreciprocalcheck.prop("disabled", true);

        buttonInterval = setInterval(function () {
            updateButton(secondsLeft++ % 4);
            api.guaranteedProject.checkReciprocalCheck(currentData.projectId, function (response) {
                if (!response.data.isDone) {
                    return;
                }

                if (response.data.isFound) {
                    checkReciprocalStatus(true);
                } else {
                    checkReciprocalStatus(false);
                }

                $btnreciprocalcheck.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;' + translations.check);
                $btnreciprocalcheck.prop("disabled", false);
                clearInterval(buttonInterval);
                buttonInterval = null;
            });
        }, 2000);
    }

    const checkReciprocalStatus = function (isFound = false) {
        if (isFound) {
            $elLinkFoundStatus.html('<span style="color: green; ">' + translations.trafficIsDoubled + '</span>');
        } else {
            $elLinkFoundStatus.html('<span style="color: red; ">' + translations.notFound + '</span>');
        }
    }

    $btnreciprocalcheck.click(function (e) {
        e.preventDefault();
        api.guaranteedProject.startReciprocalCheck(currentData.projectId);

        startWaiting();
    });

    if (currentData.force_reciprocal_check === 1) {
        startWaiting();
    }

    // $.get("/check-reciprocal.php?campaignid=" + currentData.projectId, function (data) {
    //     if (data === 'FOUND') {
    //         $elLinkFoundStatus.html('<font color="green">' + translations.trafficIsDoubled + '</font>');
    //         $("#guaranteed_monthly_hits").html(formatNumber(currentData.monthlyHits * 2));
    //     } else {
    //         $elLinkFoundStatus.html('<font color="red">' + translations.notFound + '</font>');
    //         $("#guaranteed_monthly_hits").html(formatNumber(currentData.monthlyHits));
    //     }
    // })
}

const initCheckUpdateCountersStatus = function (currentData, translations) {
    let hostName = window.location.hostname;
    if (hostName.indexOf('localhost') !== -1 || hostName.indexOf('127.0.0.1') !== -1) {
        return;
    }

    let $btnupdatecounters = $("#btnupdatecounters");
    let buttonInterval;
    let secondsLeft = 0;

    const updateButton = function (countPoints = 0) {
        const points = _.reduce(_.range(0, countPoints), function (acc) {
            return acc + '.'
        }, '');

        $btnupdatecounters.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;' + translations.updatingCounters + points);
    }

    const startWaiting = function () {
        secondsLeft = 0;
        updateButton();
        $btnupdatecounters.prop('disabled', true);

        buttonInterval = setInterval(function () {
            updateButton(secondsLeft++ % 4);

            api.guaranteedProject.checkCountersUpdating(currentData.projectId, function (response) {
                if (!response.data.isDone) {
                    return;
                }

                $btnupdatecounters.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;' + translations.updateCounters);
                $btnupdatecounters.prop("disabled", false);
                clearInterval(buttonInterval);
                buttonInterval = null;
            });
        }, 2000);
    }

    $btnupdatecounters.on('click', function (e) {
        e.preventDefault();
        api.guaranteedProject.startCountersUpdating(currentData.projectId);

        startWaiting();
    });

    if (currentData.force_update_counters === 1) {
        startWaiting();
    }
}

const initCheckUpdateBtnRecommendationsStatus = function (currentData, translations) {
    let hostName = window.location.hostname;
    if (hostName.indexOf('localhost') !== -1 || hostName.indexOf('127.0.0.1') !== -1) {
        return;
    }

    let $buttonUpdateRecommendations = $(".button-update-recommendations");
    let buttonInterval;
    let secondsLeft = 0;

    const updateButton = function (countPoints = 0) {
        const points = _.reduce(_.range(0, countPoints), function (acc) {
            return acc + '.'
        }, '');

        $buttonUpdateRecommendations.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;' + translations.updatingRecommendations + points);
    }

    const startWaiting = function () {
        secondsLeft = 0;
        updateButton();
        $buttonUpdateRecommendations.prop('disabled', true);

        buttonInterval = setInterval(function () {
            updateButton(secondsLeft++ % 4);

            api.guaranteedProject.checkRecommendationsUpdating(currentData.projectId, function (response) {
                if (!response.data.isDone) {
                    return;
                }

                $buttonUpdateRecommendations.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;' + translations.updateRecommendations);
                $buttonUpdateRecommendations.prop("disabled", false);
                clearInterval(buttonInterval);
                buttonInterval = null;
            });
        }, 2000);
    }

    $buttonUpdateRecommendations.click(function (e) {
        e.preventDefault();
        api.guaranteedProject.startRecommendationsUpdating(currentData.projectId);

        startWaiting();
    });

    if (currentData.force_check_recommendations === 1) {
        startWaiting();
    }
}

/** Project Pause */
const initPause = function (currentData, translation) {
    $('[data-ajax-action="pause"]').on('click', function () {
        let $button = $(this);

        $button.prop('disabled', true);

        $.ajax({
            type: "POST",
            url: "/api/guaranteed/project/pause",
            data: {
                id: currentData.projectId
            },
            success: function (response) {
                if (response.success) {
                    $('.action-panel .action-panel__state').addClass('no-active');
                    $('.action-panel .action-panel__status').html(translation.paused).addClass('no-active');
                    $('.action-panel .button-pause').hide();
                    $('.action-panel .button-resume').show();
                } else {
                    // addNotification(response.message);
                }
            },
            complete: function () {
                $button.prop('disabled', false);
            }
        });
    });
}

/** Project Resume */
const initResume = function (currentData, translation) {
    $('[data-ajax-action="resume"]').on('click', function () {
        let $button = $(this);

        $button.prop('disabled', true);

        $.ajax({
            type: "POST",
            url: "/api/guaranteed/project/resume",
            data: {
                id: currentData.projectId
            },
            success: function (response) {
                if (response.success) {
                    $('.action-panel .action-panel__state').removeClass('no-active');
                    $('.action-panel .action-panel__status').html(translation.active).removeClass('no-active');
                    $('.action-panel .button-pause').show();
                    $('.action-panel .button-resume').hide();
                } else {
                    // addNotification(response.message);
                }
            },
            complete: function () {
                $button.prop('disabled', false);
            }
        });
    });
}

/** Project Renew */
const initRenew = function (currentData) {
    if (currentData.projectSize === 'demo' || currentData.projectSize === 'nano') {
        let buttonDemoRenew = $('[data-ajax-action="renew"]');

        buttonDemoRenew.on('click', function (e) {
            e.preventDefault();

            buttonDemoRenew.prop('disabled', true);

            $.ajax({
                type: "POST",
                url: "/api/guaranteed/project/renew",
                data: {
                    id: currentData.projectId,
                    size: currentData.projectSize
                },
                success: function (response) {
                    let data = response.data;

                    if (data.success) {
                        window.location.reload();
                        // $('.action-panel .action-panel__state').removeClass('no-active');
                        // $('.action-panel .action-panel__status').html(translation.active).removeClass('no-active');
                        // $('.action-panel .button-pause').show().prop('disabled', false);
                        // $('.action-panel .button-resume').hide();
                    } else {
                        // addNotification(response.message);
                        //alert(data.message);
                        buttonDemoRenew.prop('disabled', false);
                    }
                },
                error: function () {
                    buttonDemoRenew.prop('disabled', false);
                }
            });
        });
    }

    // Animation
    let animSwipeRight = document.querySelector('.lottie-swipe-right');
    let animSwipeLeft = document.querySelector('.lottie-swipe-left');

    let lottieSwipeRight = lottie.loadAnimation({
        container: animSwipeRight,
        renderer: 'svg',  // svg, canvas, html
        loop: true,
        autoplay: false,
        path: '/img/lottie/swipe-right.json'
    });

    let lottieSwipeLeft = lottie.loadAnimation({
        container: animSwipeLeft,
        renderer: 'svg',  // svg, canvas, html
        loop: true,
        autoplay: false,
        path: '/img/lottie/swipe-left.json'
    });

    lottieSwipeLeft.addEventListener('DOMLoaded', function() {
        lottieSwipeRight.play();
        lottieSwipeLeft.play();
    });
}

/**  Auto-renew Activation */
const initAutoRenew = function (currentData) {
    $('[data-ajax-action="auto-renew"]').on('change', function () {
        let isAutoRenew = ($(this).prop('checked') ? 'yes' : 'no');
        let $button = $(this);

        $button.prop('disabled', true);

        $.ajax({
            type: "POST",
            url: "/api/guaranteed/project/auto-renew-activate",
            data: {
                projectId: currentData.projectId,
                isAutoRenew: isAutoRenew,
            },
            success: function (response) {
                // nothing
            },
            error: function () {
                if (isAutoRenew === 'yes') {
                    $button.prop('checked', false);
                } else {
                    $button.prop('checked', true);
                }
            },
            complete: function () {
                $button.prop('disabled', false);
            }
        });
    });
}

const initForms = function (currentData) {
    const updateFormFields = function ($form) {
        $form.find('input, select, textarea').each(function (index, el) {
            if ($(el).attr('type') === 'checkbox') {
                $(el).data('val', $(el).prop('checked'));
            } else {
                $(el).data('val', $(el).val());
            }
        });
    }

    const getFormFields = function ($form) {
        let data = {};

        $form.find('input, select, textarea').each(function (index, el) {
            let fieldName = $(el).attr('name');
            if (fieldName) {
                fieldName = fieldName.replace('[]','');

                if ($(el).attr('type') === 'checkbox') {
                    data[fieldName] = ($(el).prop('checked') ? 1 : 0);
                } else {
                    data[fieldName] = $(el).val();
                }
            }
        });

        return data;
    }

    let $forms = $('[data-role="guaranteed-project-edit"] form[data-form-id]');

    if ($forms.length === 0) {
        return;
    }

    $forms.each(function () {
        let $form = $(this);
        let $saveButton = $form.find('[data-ajax-action="save-form"]');
        let isChangesInputs = [];

        updateFormFields($form);

        $form.find('input, select, textarea').on('input change', function() {
            let isChanges = false;

            formValidationErrorClear($form);

            if ($(this).attr('type') === 'checkbox') {
                isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).prop('checked');
            } else {
                isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).val();
            }

            for (var key in isChangesInputs) {
                if (isChangesInputs.hasOwnProperty(key) && isChangesInputs[key]) {
                    isChanges = true;

                    break;
                }
            }

            if (isChanges) {
                $saveButton.find('.button-text').html('Save Changes');
                $saveButton.find('.icon-from-button').hide();
                $saveButton.prop('disabled', false);
            } else {
                $saveButton.find('.button-text').html('Saved');
                $saveButton.find('.icon-from-button').show();
                $saveButton.prop('disabled', true);
            }
        });

        $('[name="respect_time_day"]').on('change', function () {
            $('[name="timezone_from_gmt"]').val($(this).val());
        });

        $saveButton.on('click', function (e) {
            e.preventDefault();

            let $button = $(this);
            let $form = $button.closest('form');

            let data = {
                projectId: currentData.projectId,
                title: $('[name="title"]').data('val'),
                bots: $('[name="bots"]').data('val'),
                bouncerate: $('[name="bouncerate"]').data('val'),
                return_rate: $('[name="return_rate"]').data('val'),
                timeonpage: $('[name="timeonpage"]').data('val'),
                randomize_time: $('[name="randomize_time"]').data('val') ? 1 : 0,
                rss_feed: $('[name="rss_feed"]').data('val'),
                url: $('[name="url"]').data('val'),
                urlsecond: $('[name="urlsecond"]').data('val'),
                urlarray: $('[name="urlarray"]').data('val'),
                traffic_type: $('[name="traffic_type"]').data('val'),
                keywords: $('[name="keywords"]').data('val'),
                behaviour: $('[name="behaviour"]').data('val'),
                geo_type: $('[name="geo_type"]').data('val'),
                countries: $('[name="countries[]"]').data('val'),
                cities: $('[name="cities[]"]').data('val'),
                social_links: $('[name="social_links"]').data('val'),
                referrer: $('[name="referrer"]').data('val'),
                respect_time_day: $('[name="respect_time_day"]').data('val'),
                timezone_from_gmt: $('[name="timezone_from_gmt"]').data('val'),
                shortener: $('[name="shortener"]').data('val'),
                utm_source: $('[name="utm_source"]').data('val'),
                utm_medium: $('[name="utm_medium"]').data('val'),
                utm_campaign: $('[name="utm_campaign"]').data('val'),
                language: $('[name="language"]').data('val'),
                ga_id: $('[name="ga_id"]').data('val'),
            }

            if (currentData.hasAdditionalUrl) {
                data = _.assignIn(data, {
                    urlarray4: $('#urlarray4').data('val'),
                    urlarray5: $('#urlarray5').data('val'),
                    urlarray6: $('#urlarray6').data('val'),
                    urlarray7: $('#urlarray7').data('val'),
                    urlarray8: $('#urlarray8').data('val'),
                    urlarray9: $('#urlarray9').data('val'),
                    urlarray10: $('#urlarray10').data('val'),
                    urlarray11: $('#urlarray11').data('val'),
                });
            }

            let dataSave = getFormFields($form);
            data = {...data, ...dataSave};

            $button.find('.button-text').html('Saving...');
            $button.find('.icon-from-button').hide();
            $button.prop('disabled', true);

            api.guaranteedProject.update(data,
                function (response) {
                    let data = (response.data ? response.data : []);

                    if (response.success) {
                        $button.find('.button-text').html('Saved');
                        $button.find('.icon-from-button').show();

                        isChangesInputs = [];
                        updateFormFields($form);
                    } else {
                        $button.find('.button-text').html('Save Changes');
                        $button.find('.icon-from-button').hide();
                        $button.prop('disabled', false);

                        if (response.message === 'CREDITS') {
                            window.location.replace('/buy');
                        }

                        if (data.errorsForm) {
                            formValidationErrorHandling($form, data.errorsForm);
                        }
                    }
                },
                function () {
                    // const errors = xhr.responseJSON.errors;

                    $button.find('.button-text').html('Save Changes');
                    $button.find('.icon-from-button').hide();
                    $button.prop('disabled', false);
                });
        });
    });
}

export const initGuaranteedProjectEditScripts = () => {
    console.log('initGuaranteedProjectEditScripts');
    runIfHasSelecter('[data-role="guaranteed-project-edit"]', [
        initGaErrorMessages,
        initModals,
        initExpiration,
        initProjectStarting,
        initChart,
        initTrafficTypeChangeListener,
        initFormDailyHitsEstimates,
        initGeoTypeChange,
        initCheckLinkStatus,
        initCheckUpdateCountersStatus,
        initCheckUpdateBtnRecommendationsStatus,
        initPause,
        initResume,
        initAutoRenew,
        initRenew,
        initForms
    ]);
}