<template>
  <div class="v-tag-user-choice vtuc" :class="mainClass">
    <div class="vtuc__icon-wrap" v-if="iconName!==null">
      <v-icon-svg :name="iconName" class="vtuc__icon" :as-img="true"/>
    </div>
    <div class="vtuc__text">
      <slot>{{slotText}}</slot>
    </div>
  </div>
</template>

<script setup>

import VIconSvg from "./VIconSvg.vue";
import {computed} from "vue";

const props = defineProps({
  /**
   * @type {"percent"|"like"|null}
   */
  icon: {default:null},
  /**
   * @type {"green"|"blue"|"red"}
   */
  color: {default:'green'},
  slotText: {default:'User choice',}
});

const mainClass = computed(() => {
  let classes = [];
  if(props.color === 'green'){
    classes.push('vtuc--green');
  }else if(props.color === 'blue'){
    classes.push('vtuc--blue');
  }else if(props.color === 'red'){
    classes.push('vtuc--red');
  }
  return classes;
});

const iconName = computed(() => {
  if(props.icon === 'percent'){
    return 'percent-white'
  }else if(props.icon === 'like'){
    return 'like-white'
  }else{
    return null;
  }
});

</script>

<style scoped lang="scss">
.v-tag-user-choice.vtuc{
  @include flex(row,center,center,4px);
  white-space:nowrap;
  border-radius: 4px;
  padding: 4px 7px;
  color:#fff;

  &.vtuc--green{
    background-color: #219E26;
  }
  &.vtuc--blue{
    background-color: #5991FF;
  }

  .vtuc__icon-wrap{
    height:10px;
    flex-shrink: 0;
    .vtuc__icon{
      height:10px;
      display: block;
    }
  }

  .vtuc__text{
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    text-transform: uppercase;
    @media(max-width:767px){
      font-size: 8px;
      line-height:10px;
    }
  }
}
</style>