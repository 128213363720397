import { runIfHasSelecter, formatFloatNumber, showInfoMessageInput } from "../../utils/panel-lib";
import {api} from "../../utils/panel-api";
import _ from "lodash";

const initCommon = function (currentData) {
    if (currentData.vatRateIsNotEmpty) {
        $('#cart_to_pay_without_vat').hide();
        $('#cart_to_pay_with_vat').show();
    } else {
        $('#cart_to_pay_without_vat').show();
        $('#cart_to_pay_with_vat').hide();
    }

    function refreshButtons(discount = 0) {
        let coupon_code = $('#coupon_code').val();

        $('.paycardbutton').hide();
        $('.bluesnap').hide();

        _.map(currentData.planOptions, function (planOption) {
            let planSize = planOption.code;

            if ($('.vc-search-plans__input:checked').val().toString() === planOption.id.toString()) {
                let planPrice = planOption.price;
                let planPriceWithDiscount = parseFloat(planPrice - ((discount / 100) * planPrice));
                let $proformaLinkEl = $('#proforma-link');

                if (discount === 0) {
                    $('#discount-line').hide();
                } else {
                    $('#discount-line').show();
                }
                $('#cart_discount_value').html(discount === 0 ? '' : ('-' + Math.round(discount) + '%'));

                $("#cart_credit_name").html(planOption.short_name);
                $("#cart_quantity").html(planOption.quantity);
                $("#cart_price").html(formatFloatNumber(planPrice));
                if (currentData.action && parseInt(planOption.promo_quantity) > 0) {
                    $("#cart_price_name").html(planOption.name + '&nbsp;<span class="color-red"><b> +' + (parseInt(planOption.promo_quantity) - parseInt(planOption.quantity)) + ' FREE</b></span>');
                } else {
                    $("#cart_price_name").html(planOption.name);
                }
                $("#cart_price_per_credit").html(formatFloatNumber(planPrice));
                $("#cart_vat_value").html(formatFloatNumber(planPrice * currentData.vatRate));
                $("#cart_to_pay_with_vat").html(formatFloatNumber(planPriceWithDiscount * (1 + currentData.vatRate)));
                $("#cart_to_pay_without_vat").html(formatFloatNumber(planPriceWithDiscount));
                $proformaLinkEl.attr('href', '/billing/invoice?plan=' + planOption.id + '&currency='+$('#bank-transfer-currency').find(":selected").val());

                $('.method-payment-link').each(function () {
                    let currentHref = $(this).attr('href').split("?")[0];

                    currentHref += '?id='+planOption.id+'&quantity='+ 1 +'&href=purchase-clicks';

                    $(this).attr('href', currentHref);
                });

                $('[data-pay-by]').each(function () {
                    let $this = $(this);
                    let system = $this.data('payBy');
                    let href = '/billing/do-pay?system=' + system + '&size=' + planSize;

                    if (coupon_code !== '' && discount > 0) {
                        href += '&coupon_code=' + coupon_code;
                    }

                    $this.attr('href', href);
                });

                if (coupon_code !== '' && discount > 0) {
                    $proformaLinkEl.attr('href', $proformaLinkEl.attr('href') + '&coupon_code=' + coupon_code);
                }

                if (planOption.paymentwall_enabled === 1) {
                    $('.paycardbutton').show();
                }

                if (planOption.bluesnap_link !== '') {
                    $('.bluesnap').show();
                }

                if (currentData.cashbackBalance >= planPriceWithDiscount) {
                    $('.pay-with-cashback-info').hide();
                    $('.pay-with-cashback-button').show();
                } else {
                    $('.pay-with-cashback-info').show();
                    $('.pay-with-cashback-button').hide();
                }

                //api.log.addUsersCartHistory({plan_options_id: planOption.id});
            }
        });
    }

    function updatePrices(discount = 0) {
        let $plans = $('.vc-search-plans .search_packet_label');

        $plans.each(function (index, el) {
            let planId = $(el).data('plan-id');

            _.map(currentData.planOptions, function (planOption) {
                if (planId.toString() === planOption.id.toString()) {
                    let planPrice = planOption.price;

                    if (discount > 0) {
                        let planPriceWithDiscount = parseFloat(planPrice - ((discount / 100) * planPrice));

                        $(el).find('.plan-price').addClass('line-through--red');
                        $(el).find('.plan-price-with-discount').html( '$' + formatFloatNumber(planPriceWithDiscount));
                    } else {
                        $(el).find('.plan-price').removeClass('line-through--red');
                        $(el).find('.plan-price-with-discount').html('');
                    }
                }
            });
        });
    }

    function updateCoupon() {
        let couponCode = $('#coupon_code').val();
        if (!couponCode) {
            $('#coupon_description').html('');
            updatePrices();
            refreshButtons();
            return;
        }

        $('#apply_coupon').prop('disabled', true);

        $.ajax({
            type: "POST",
            url: "/api/apply-coupon",
            data: {
                coupon_code: couponCode
            },
            success: function (result) {
                if (!result.success) {
                    $('#coupon_description').html('<span style="color: red;">ERROR: ' + result.message + '</span>').show();
                    updatePrices();
                    refreshButtons();
                } else {
                    $('#coupon_description').html('<span style="color: green;">Coupon successfully applied</span>').show();
                    updatePrices(result.data.discount);
                    refreshButtons(result.data.discount);

                    $('.method-payment-link').each(function(){
                        let oldUrl = $(this).attr("href");
                        let newUrl = oldUrl + '&coupon=' +couponCode;
                        $(this).attr("href", newUrl);
                    });
                }
                $('#apply_coupon').prop('disabled', false);
            },
            error: function () {
                updatePrices();
                refreshButtons();
                $('#apply_coupon').prop('disabled', false);
            }
        });
    }

    if (currentData.vatRateIsNotEmpty) {
        $('#cart_to_pay_without_vat').hide();
        $('#cart_to_pay_with_vat').show();
    } else {
        $('#cart_to_pay_without_vat').show();
        $('#cart_to_pay_with_vat').hide();
    }

    $('.vc-search-plans__input').on('change', function () {
        refreshButtons();
        updateCoupon();
    });

    $( "#bank-transfer-currency" ).on('change', function () {
        refreshButtons();
    });

    $('#apply_coupon').prop('disabled', false).on('click', function () {
        updateCoupon();
    });

    $('.coupon-action .coupon-action__button').on('click', function () {
        let couponCode = $(this).closest('[data-role]').data('coupon-code');

        $('html, body').animate({
            scrollTop: $('.coupon-block').offset().top
        }, {
            duration: 150,
            easing: "linear"
        });

        $('#coupon_code').val(couponCode);
        $('#apply_coupon').trigger('click');

        return false;
    });

    updateCoupon();
    refreshButtons();

    $('#payment_options').show();

    $('a.vc-button').on('click', function () {
        let $this = $(this);

        $this.addClass('disabled').css('pointer-events', 'none');
        setTimeout(function () {
            $this.removeClass('disabled').css('pointer-events', 'auto');
        }, 5000);
    });
}

const initVatUpdates = function () {
    $("#apply-vat-id").click(function () {
        let $inputVatId = $('#vat_id');

        $.ajax({
            type: "POST",
            url: "/api/apply-vat-id",
            data: {
                vatId: $inputVatId.val()
            },
            success: function (result) {
                if (!result.success) {
                    showInfoMessageInput(
                        $inputVatId,
                        'ERROR: ' + result.message,
                        'red'
                    );
                    $('#cart_to_pay_without_vat').hide();
                    $('#cart_to_pay_with_vat').show();
                } else {
                    showInfoMessageInput(
                        $inputVatId,
                        'VAT ID is correct',
                        'green'
                    );
                    $("#cart_vat_rate").html('0');
                    $("#cart_vat_value").html('0');
                    $('#cart_to_pay_without_vat').show();
                    $('#cart_to_pay_with_vat').hide();
                }
            }
        });
    });
}

export const initSeoClicksPayScripts = () => {
    runIfHasSelecter('[data-role="search-traffic-pay"]', [
        initCommon,
        initVatUpdates,
    ]);
}
