import {
    formatNumber, formValidationErrorClear, formValidationErrorHandling,
    prepareChartData,
    runIfHasSelecter
} from "../../utils/panel-lib";
import {api} from "../../utils/panel-api";
import Chart from 'chart.js/auto';
import _ from "lodash";

const initCommon = function () {
    const $geo_targeting_enabled = $('#geo_targeting_enabled');
    const $isActive = $('#is-active');
    const $maxClicksPerDay = $('#max_clicks_per_day');
    const $countries = $('#countries').closest('.form-group');
    const $languages = $('#languages').closest('.form-group');

    $geo_targeting_enabled.on('change', function () {
        if ($(this).is(':checked')) {
            $(this).next('label').html('<b>GEO Targeting is On</b> 1 click = 2 credits');
            $countries.show();
            $languages.show();
        } else {
            $(this).next('label').html('GEO Targeting is Off');
            $countries.hide();
            $languages.hide();
        }
    });

    if ($geo_targeting_enabled.is(':checked')) {
        $(this).next('label').html('<b>GEO Targeting is On</b> 1 click = 2 credits');
        $countries.show();
        $languages.show();
    } else {
        $(this).next('label').html('GEO Targeting is Off');
        $countries.hide();
        $languages.hide();
    }

    $isActive.on('change', function () {
        if ($(this).is(':checked')) {
            $maxClicksPerDay.show();
        } else {
            $maxClicksPerDay.hide();
        }
    });
    if ($isActive.is(':checked')) {
        $maxClicksPerDay.show();
    } else {
        $maxClicksPerDay.hide();
    }
}

const initModals = function () {
    $('#confirm-delete')
        .on('show.bs.modal', function (e) {
            $(this).find('.btn-ok').attr('data-href', $(e.relatedTarget).data('href'));
        })
        .find('.btn-ok')
        .on('click', function (e) {
            e.preventDefault();

            $(this).prop('disabled', true);

            $.ajax({
                url: $(this).data('href'),
                method: 'post',
                success: function () {
                    // $('#confirm-delete').modal('toggle');
                    window.location.reload();
                },
                error: function () {
                    $(this).prop('disabled', false);
                }
            });
        });

    $('#confirm-undelete')
        .on('show.bs.modal', function (e) {
            $(this).find('.btn-ok').attr('data-href', $(e.relatedTarget).data('href'));
        })
        .find('.btn-ok')
        .on('click', function (e) {
            e.preventDefault();

            $(this).prop('disabled', true);

            $.ajax({
                url: $(this).data('href'),
                method: 'post',
                success: function () {
                    // $('#confirm-undelete').modal('toggle');
                    window.location.reload();
                },
                error: function () {
                    $(this).prop('disabled', true);
                }
            })
        });

    $('#confirm-clone').on('show.bs.modal', function (e) {
        $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));
    });
    $('#confirm-upgrade').on('show.bs.modal', function (e) {
        $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));
    });
}

const initChart = function (currentData) {
    if (currentData.isDeleted) {
        return;
    }
    console.log('fromDate');
    let projectChart = null;
    const $dataPickerRange = $('[data-picker-range]');
    let test = $dataPickerRange.find('[name="from"]').val();
    let fromDate = currentData.clicksStartDate;
    let toDate = currentData.clicksEndDate;

    $dataPickerRange.find('input').on('show', function () {
        $(this).closest('label').find('.icon-arrow-down').addClass('open');
    });

    $dataPickerRange.find('input').on('hide', function () {
        $(this).closest('label').find('.icon-arrow-down').removeClass('open');
    });

    $dataPickerRange.find('input').on('changeDate', function (e) {
        let selectDate = new Date(e.date);
        let dateString = ('0' + selectDate.getDate()).slice(-2) + '.' + ('0' + (selectDate.getMonth() + 1)).slice(-2) + '.' + selectDate.getFullYear();

        $(this).val(dateString);
        fromDate = $dataPickerRange.find('[name="from"]').val();
        toDate = $dataPickerRange.find('[name="to"]').val();

        getChartData(currentData.projectId, fromDate, toDate);
    });

    getChartData(currentData.projectId, fromDate, toDate);

    function getChartData(projectId, fromDate = null, toDate = null) {
        console.log(projectId);
        if (!projectId) {
            return {};
        }

        if (!fromDate) {
            fromDate = new Date();
        }

        if (!toDate) {
            toDate = new Date();
            toDate.setDate(fromDate.getDate() - 1);
        }

        let params = {
            projectId: projectId,
            from: fromDate,
            to: toDate,
        }

        $.ajax({
            url: '/api/project/chart-data',
            data: params,
            dataType: 'json',
            success: function (response) {
                const $totalHits = $('[data-id=totalHitsCount]');

                if (response.data) {
                    $totalHits.text(formatNumber(response.data.totalHits));
                    getProjectChart(response.data);
                }
            },
            error: function (err) {
                // console.log(err);
            }
        });
    }

    function getProjectChart(chartData) {
        if (chartData) {
            let projectChartEl = document.getElementById('project-chart');
            if(!projectChartEl){return;}
            let ctx = projectChartEl.getContext('2d');
            let chartConfig = prepareChartData(ctx, chartData, 'normal');

            if (chartConfig) {
                if (!projectChart) {
                    projectChart = new Chart(ctx, chartConfig);
                } else {
                    projectChart.data = chartConfig.data;
                    projectChart.options = chartConfig.options;
                    projectChart.update();
                }
            }
        }
    }
}

const initCheckUpdateBtnRecommendationsStatus = function (currentData, translations) {
    let hostName = window.location.hostname;
    if (hostName.indexOf('localhost') !== -1 || hostName.indexOf('127.0.0.1') !== -1) {
        return;
    }

    const $buttonUpdateRecommendations = $(".button-update-recommendations");
    let buttonInterval;
    let secondsLeft = 0;

    const updateButton = function (countPoints = 0) {
        const points = _.reduce(_.range(0, countPoints), function (acc) {
            return acc + '.'
        }, '');

        $buttonUpdateRecommendations.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;' + translations.updatingRecommendations + points);
    }

    const startWaiting = function () {
        secondsLeft = 0;
        updateButton();
        $buttonUpdateRecommendations.prop('disabled', true);

        buttonInterval = setInterval(function () {
            updateButton(secondsLeft++ % 4);

            api.guaranteedProject.checkRecommendationsUpdating(currentData.projectId, function (response) {
                if (!response.data.isDone) {
                    return;
                }

                $buttonUpdateRecommendations.html('<i class="fal fa-redo"></i>&nbsp;&nbsp;' + translations.updateRecommendations);
                $buttonUpdateRecommendations.prop("disabled", false);
                clearInterval(buttonInterval);
                buttonInterval = null;
            });
        }, 2000);
    }

    $buttonUpdateRecommendations.click(function (e) {
        e.preventDefault();
        api.guaranteedProject.startRecommendationsUpdating(currentData.projectId);

        startWaiting();
    });

    if (currentData.force_check_recommendations === 1) {
        startWaiting();
    }
}

/** Project Pause */
const initPause = function (currentData, translation) {
    $('[data-ajax-action="pause"]').on('click', function () {
        const $button = $(this);

        $button.prop('disabled', true);

        api.searchTrafficProject.pause(currentData.projectId,
            function (response) {
                // console.log('response', response.success);
                if (response.success) {
                    $('.action-panel .action-panel__state').addClass('no-active');
                    $('.action-panel .action-panel__status').html('paused').addClass('no-active');
                    $('.action-panel .button-pause').hide();
                    $('.action-panel .button-resume').show();

                    $('.action-panel__not-top100').hide();
                    $('.button-check-position').hide();

                    $button.prop('disabled', false);
                } else {
                    // addNotification(response.message);
                }

                $button.prop('disabled', false);
                // addNotification(response.message);
            },
            function () {
                $button.prop('disabled', false);
            });
    });
}

const initResume = function (currentData, translation) {
    $('[data-ajax-action="resume"]').on('click', function () {
        const $button = $(this);

        $button.prop('disabled', true);

        api.searchTrafficProject.resume(currentData.projectId,
            function (response) {
                // console.log('response', response.success);
                if (response.success) {
                    $('.action-panel .action-panel__state').removeClass('no-active');
                    $('.action-panel .action-panel__status').html('active').removeClass('no-active');
                    $('.action-panel .button-pause').show();
                    $('.action-panel .button-resume').hide();

                    if (!currentData.isInTop100 && !currentData.isDeleted && currentData.credits != null && currentData.credits["total"] > 0) {
                        $('.action-panel__not-top100').show();
                        $('.button-check-position').show();
                    }
                } else {
                    // addNotification(response.message);
                }

                $button.prop('disabled', false);
                // addNotification(response.message);
            },
            function () {
                $button.prop('disabled', false);
            });
    });
}

const initCheckUpdatePosition = function (currentData) {
    let projectId = currentData.projectId;
    let buttonInterval;
    let secondsLeft;

    const updateButton = function (countPoints = 0) {
        const points = _.reduce(_.range(0, countPoints), function (acc) {
            return acc + '.'
        }, '');


        $('.button-check-position .text-from-button').html('Checking' + points);
    }

    const startWaiting = function () {
        secondsLeft = 0;
        updateButton();

        let button = $('.button-check-position');
        let top100Icon = $('.action-panel__top100');
        let errorNotInTop = $('.action-panel__not-top100');

        button.prop('disabled', true);

        buttonInterval = setInterval(function () {
            updateButton(secondsLeft++ % 4);

            api.searchTrafficProject.checkPositionUpdating(projectId, function (response) {
                if (!response.data.isDone) {
                    return;
                }

                button.find('.text-from-button').html('Check position');
                button.prop("disabled", false);
                if (response.data.isTop100) {
                    currentData.isInTop100 = true;

                    button.hide();
                    top100Icon.show();
                    errorNotInTop.hide();
                }

                clearInterval(buttonInterval);
                buttonInterval = null;
            });
        }, 5000);
    }

    $('.button-check-position').on('click', function () {
        api.searchTrafficProject.startPositionUpdating(projectId);
        startWaiting(projectId);
    });
}

const initForms = function (currentData) {
    const updateFormFields = function ($form) {
        $form.find('input, select, textarea').each(function (index, el) {
            if ($(el).attr('type') === 'checkbox') {
                $(el).data('val', $(el).prop('checked'));
            } else {
                $(el).data('val', $(el).val());
            }
        });
    }

    const getFormFields = function ($form) {
        let data = {};

        $form.find('input, select, textarea').each(function (index, el) {
            let fieldName = $(el).attr('name');
            if (fieldName) {
                fieldName = fieldName.replace('[]','');

                if ($(el).attr('type') === 'checkbox') {
                    data[fieldName] = ($(el).prop('checked') ? 1 : 0);
                } else {
                    data[fieldName] = $(el).val();
                }
            }
        });

        return data;
    }

    let $forms = $('[data-role="seo-clicks-project-edit"] form[data-form-id]');

    if ($forms.length === 0) {
        return;
    }

    $forms.each(function () {
        let $form = $(this);
        let $saveButton = $form.find('[data-ajax-action="save-form"]');
        let isChangesInputs = [];

        updateFormFields($form);

        $form.find('input, select, textarea').on('input change', function() {
            let isChanges = false;

            formValidationErrorClear($form);

            if ($(this).attr('type') === 'checkbox') {
                isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).prop('checked');
            } else {
                isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).val();
            }

            for (var key in isChangesInputs) {
                if (isChangesInputs.hasOwnProperty(key) && isChangesInputs[key]) {
                    isChanges = true;

                    break;
                }
            }

            if (isChanges) {
                $saveButton.find('.button-text').html('Save Changes');
                $saveButton.find('.icon-from-button').hide();
                $saveButton.prop('disabled', false);
            } else {
                $saveButton.find('.button-text').html('Saved');
                $saveButton.find('.icon-from-button').show();
                $saveButton.prop('disabled', true);
            }
        });
    });
}

export const initSeoClicksProjectEditScripts = () => {
    runIfHasSelecter('[data-role="seo-clicks-project-edit"]', [
        initCommon,
        initModals,
        initChart,
        initCheckUpdateBtnRecommendationsStatus,
        initPause,
        initResume,
        initCheckUpdatePosition,
        initForms
    ]);
}
