<template>
  <v-container class="lb-project lbp">

    <v-breadcrumbs :items="breadcrumbs" no-padding/>

    <bc-balance :credit-type="CREDIT_TYPE_BACKLINK"/>

    <lb-project-head :project-uniquekey="projectUniquekey"  v-if="!linkBuildProjectStore.is_demo"/>

    <lb-project-head-demo :project-uniquekey="projectUniquekey"  v-if="linkBuildProjectStore.is_demo"/>

    <v-white-container class="lbp__content" v-if="!linkBuildProjectStore.is_demo">

      <lb-project-settings :project-uniquekey="projectUniquekey" ref="refSettings" v-scroll-anchor data-scroll-id="create-new-backlinks"/>

      <lb-orders :project-uniquekey="projectUniquekey" data-scroll-id="backlinks" v-scroll-anchor/>

    </v-white-container>

  </v-container>
</template>

<script setup>

import VBreadcrumbs from "../Base/VBreadcrumbs.vue";
import VContainer from "../Base/VContainer.vue";
import {useRoutes} from "../../stores/useRoutes";
import LbProjectHead from "./LbProjectHead.vue";
import VWhiteContainer from "../Base/VWhiteContainer.vue";
import LbProjectSettings from "./LbProjectSettings.vue";
import {useRecsStore} from "../../stores/useRecsStore";
import {
  useAutoRenewals,
  useLinkBuildProjectOrders,
  useLinkBuildProject,
  useBacklinksRecommendations
} from "../../stores/useLinkBuilding";
import LbOrders from "././LbOrders.vue";
import {computed, onMounted, ref, watch} from "vue";
import {useUserBalance} from "../../stores/useStaticData";
import {CREDIT_TYPE_BACKLINK} from "../../stores/useBilling";
import BcBalance from "../BuyCredits/BcBalance.vue";
import LbProjectHeadDemo from "./LbProjectHeadDemo.vue";

const projectUniquekey = useRoutes().getProjectKey();
const linkBuildOrdersStore = useLinkBuildProjectOrders(projectUniquekey);

const linkBuildProjectStore = useLinkBuildProject(projectUniquekey);

const downloadProjectAndOrders = async () => {

  let [[projectStatus,projectResponse],[ordersStatus,ordersResponse]] = await Promise.all([
    linkBuildProjectStore.downloadProject(),
    linkBuildOrdersStore.download(),
  ]);
  if(
      (
          projectResponse.is_demo
          && projectResponse.demo_status!== 'expired'
          && projectResponse.demo_status!== 'delivered'
      )
      ||
      (
          projectResponse.is_demo
          && (projectResponse.demo_status=== 'expired' || projectResponse.demo_status=== 'delivered')
          && !linkBuildProjectStore.demoOrderHasLink
      )
  ){
    setTimeout(() => {
      downloadProjectAndOrders();
    },2000);
  }

}
downloadProjectAndOrders();

linkBuildProjectStore.updateTestAnalytics();
useAutoRenewals(projectUniquekey).download();
const backlinksRecommendations = useBacklinksRecommendations(projectUniquekey)

useRecsStore(projectUniquekey).fetchUntilNotLoaded().then((iterationNumber) => {
  if(iterationNumber > 1){
    backlinksRecommendations.download({forcedDownloadFromServer:true});
  }else{
    backlinksRecommendations.download();
  }
});
useUserBalance().download();

const breadcrumbs = computed(() => {
  let list = [];
  list.push({url: useRoutes().url('backlinks-project.list'), title: 'Link-building Projects'});
  if(linkBuildProjectStore.name){
    list.push({title: linkBuildProjectStore.name});
  }else{
    list.push({title: 'Project'});
  }
  return list;
})

const refSettings = ref()
const refDeleteModalConfirm = ref()

const deleteProject = async () => {
  let [status,message] = await linkBuildProjectStore.saveIsDeleted(true);
  if(status){
    window.location.href=useRoutes().url('backlinks-project.list');
    refDeleteModalConfirm.value.closeWithSuccess();
  }else{
    refDeleteModalConfirm.value.closeWithError(message)
  }
}

</script>

<style lang="scss" scoped>
.lb-project.lbp{
  display: flex;
  flex-direction:column;
  gap:30px;
  align-items: stretch;
  @media(max-width:767px){
    gap:20px;
  }
  .lbp__content{
    display:flex;
    flex-direction:column;
    align-items:stretch;
    gap:20px;
  }
  margin-bottom:20px;
  @media(max-width: 767px){
    margin-bottom:100px;
  }
  .lbp__button-delete{
    align-self:flex-start;
  }
}
</style>