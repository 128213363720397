<template>
  <v-container class="bc-main bcm">

    <v-head-title class="bcm__title">
      Buy Сredits
    </v-head-title>

    <bc-balance :credit-type="balanceCreditType"/>

    <div class="bcm__wrap-content">
      <v-tabs v-model="currentPage" type="container" class="bcm__tabs">
        <v-tabs-item hash="tab-seo-traffic">SEO Traffic</v-tabs-item>
        <v-tabs-item hash="tab-link-building">Link-Building</v-tabs-item>
      </v-tabs>
      <v-white-container with-tabs class="bcm__content">

        <bc-promo-timer v-if="useCreditTypes().is_show_promo_banner"/>

        <transition name="fade" mode="out-in">
          <bc-seo-packages
              class="bcm__seo"
              v-show="currentPage === 'tab-seo-traffic'"
              @add-to-cart-click="scrollToCart"
          />
        </transition>

        <transition name="fade" mode="out-in">
          <div
              class="bcm__backlinks"
              v-show="currentPage === 'tab-link-building'"
          >
            <div class="bcm__backlinks-quality">
              <v-step-cart value="1">Choose Quality</v-step-cart>
              <bc-backlink-quality v-model="qualityTypeConstant"/>
            </div>

            <div class="bcm__backlinks-quantity">
              <v-step-cart value="2">Choose quantity</v-step-cart>
              <bc-backlink-quantity :type-constant="qualityTypeConstant" @add-to-cart-click="scrollToCart"/>
            </div>
          </div>
        </transition>

        <div class="bcm__cart" ref="refCartWrap">
          <v-step-cart value="cart">Cart</v-step-cart>
          <bc-cart/>
        </div>

        <bc-payer ref="refBcPayer"/>

      </v-white-container>
    </div>

  </v-container>
</template>

<script setup>

import VContainer from "../Base/VContainer.vue";
import VHeadTitle from "../Base/VHeadTitle.vue";
import VTabs from "../Base/VTabs.vue";
import VTabsItem from "../Base/VTabsItem.vue";
import {computed, getCurrentInstance, onMounted, onUnmounted, ref, watch} from "vue";
import VWhiteContainer from "../Base/VWhiteContainer.vue";
import {CREDIT_TYPE_BACKLINK, CREDIT_TYPE_SEO, useCreditTypes} from "../../stores/useBilling";
import BcPayer from "./BcPayer/BcPayer.vue";
import BcCart from "./BcCart.vue";
import VStepCart from "../Base/VStepCart.vue";
import BcSeoPackages from "./BcSeoPackages.vue";
import BcBacklinkQuantity from "./BcBacklinkQuantity.vue";
import BcBacklinkQuality from "./BcBacklinkQuality.vue";
import {usePayByCrypto} from "../../stores/usePayByCrypto";
import BcPromoTimer from "./BcPromoTimer.vue";
import BcBalance from "./BcBalance.vue";
import {useLinkBuildingType} from "../../stores/useLinkBuilding";

const { proxy } = getCurrentInstance();
const currentPage = ref('tab-seo-traffic');

const balanceCreditType = computed(() => {
  if(currentPage.value === 'tab-seo-traffic'){
    return CREDIT_TYPE_SEO;
  }else{
    return CREDIT_TYPE_BACKLINK;
  }
});

watch(() => proxy.$locationHash.value, (locationHash) => {
  if(locationHash === 'tab-seo-traffic' || locationHash === 'tab-link-building'){
    currentPage.value = locationHash;
  }
},{immediate:true});

watch(() => currentPage.value, (currentPage) => {
  window.location.hash = currentPage;
});

const qualityTypeConstant = ref((() => {
  let da_type = (new URLSearchParams((new URL(window.location.href)).search)).get('da_type');
  if(useLinkBuildingType().isBacklinkTypeConstant(da_type)){
    return da_type;
  }else{
    return 'da10';
  }
})());

useCreditTypes().download();

const refCartWrap = ref();
const refBcPayer = ref();

const scrollToCart = () => {
  refCartWrap.value.scrollIntoView({ behavior: 'smooth' });
}

onMounted(() => {
  usePayByCrypto().check().then(() => {
    if(usePayByCrypto().hasOrder && usePayByCrypto().orderIsPending){
      usePayByCrypto().openWindow();
      refBcPayer.value.currentTab = '#tab_crypto';
    }else if(usePayByCrypto().hasOrder && !usePayByCrypto().orderIsPending){
      usePayByCrypto().$reset();
    }
  });
})

</script>

<style lang="scss" scoped>
.bc-main.bcm{
  @include flex(column,start,stretch,30px);
  margin-bottom:30px;
  @media(max-width:767px){
    padding-top:16px;
    @include flex(column,start,stretch,20px);
  }

  .bcm__content{
    @include flex(column,start,stretch,24px);
  }

  .bcm__cart{
    @include flex(column,start,stretch,24px);
    padding-top:24px;
    border-top: 2px solid #D6E3EF80;
    @media(max-width:767px){
      @include flex(column,start,stretch,8px);
    }
  }

  .bcm__backlinks{
    @include flex(column,start,stretch,30px);
    @media(max-width:767px){
      @include flex(column,start,stretch,24px);
    }

    .bcm__backlinks-quality{
      @include flex(column,start,stretch,20px);
      @media(max-width:767px){
        @include flex(column,start,stretch,8px);
      }
    }

    .bcm__backlinks-quantity{
      @include flex(column,start,stretch,0px);
    }

  }

}
.fade-enter-from{
  opacity:0;
}
.fade-enter-active{
  transition: opacity 0.5s;
}
.fade-enter-to{
  opacity:1;
}
.fade-leave-from{
  display:none;
}
.fade-leave-active{
  display:none;
}
.fade-leave-to{
  display:none;
}
</style>