<template>
  <div class="recommendations-groups rg">
    <div
        v-for="groupRecommendations in maxRecommendationGroups"
        class="rg__group"
        :class="'rg__group--'+groupRecommendations.type"
    >
      <div class="rg__group-icon">
        <v-icon-svg name="exclamation-mark-circle" class="rg__group-icon-svg"/>
      </div>
      <ul class="rg__group-list">
        <li v-for="recommendation in groupRecommendations.items">
          <v-link :href="link" class="rg__group-list-link" @click.prevent="clickLink">{{recommendation.title}}</v-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">

import {computed, defineProps, PropType} from 'vue';
import {Recommendation} from "../../interfaces/SeoClicksInterfaces";
import VIconSvg from "../Base/VIconSvg.vue";
import VLink from "../Base/VLink.vue";

const emit = defineEmits(['recommendation-group-click']);

const props = defineProps({
  recommendations: {type: Array as PropType<Recommendation[]>},
  link: {default:''}
});

const clickLink = () => {
  if(typeof props.link === 'string' && props.link.length > 0){
    window.location.href=props.link;
  }else{
    emit('recommendation-group-click')
  }
}

const groupsRecommendations = computed(() => {
  const grouped = props.recommendations.reduce((acc: Record<string, Recommendation[]>, recommendation: Recommendation) => {
    const key = recommendation.type;
    if (!acc[key]) {acc[key] = [];}
    acc[key].push(recommendation);
    return acc;
  }, {});
  return Object.entries(grouped).map(([type, recommendations]) => ({type, items: recommendations,}));
});

const maxRecommendationGroups = computed(() => {
  let groups = groupsRecommendations;
  let maxLevel = null;
  groups.value.forEach((group) => {
    if(group.type === 'danger'){
      maxLevel = 'danger';
    }else if(group.type === 'warning'){
      if(maxLevel !== 'danger'){
        maxLevel = 'warning';
      }
    }
  });
  return groups.value.filter((group) => {
    return group.type === maxLevel;
  })
});

</script>

<style scoped lang="scss">
.recommendations-groups.rg{
  .rg__group{

    display: flex;
    align-items: center;
    margin-bottom:5px;

    &:last-child{
      margin-bottom:0px;
    }

    .rg__group-icon{
      width:20px;
      height:20px;
      margin-right: 6px;
      flex-shrink: 0;
    }

    .rg__group-list{
      list-style:none;
      padding:0px;
      margin:0px;
      display: flex;
      flex-wrap:wrap;

      li {
        .rg__group-list-link{
          font-weight: 600;
          font-size:16px;
          line-height:20px;
          &:after{
            content:',';
            margin-right:4px;
          }
          @media(max-width: $mobile-width){
            font-size:14px;
          }
        }

        &:last-child{
          .rg__group-list-link:after{
            display: none;
          }
        }

      }

    }

    &.rg__group--danger{
      .rg__group-icon-svg{
        fill:#F2001D;
      }
    }

    &.rg__group--warning{
      .rg__group-icon-svg{
        fill:#EF8E34;
      }
    }

  }
}
</style>