<template>
  <div
      class="v-form-checkbox-slider"
      :class="mainClass"
  >
    <input type="checkbox" v-model="checkboxModel" :id="id ?? formGroupUid" @click.prevent="save">
  </div>
</template>

<script setup>

import {computed, inject, ref, watch, watchEffect} from "vue";

const props = defineProps({
  id: {},
  type: {default: 'model'}, // model or events
  value: {} // for type=events
});

const loading = ref(false);

const checkboxModel = defineModel();
const checkboxModelAfterSave = ref(!checkboxModel.value);

if(props.type === 'events'){
  watch(() => props.value, () => {
    checkboxModel.value = props.value;
  },{immediate:true});
}

const formGroupUid = inject('formGroupUid', '');

const mainClass = computed(() => {
  if(loading.value){
    if(checkboxModelAfterSave.value){
      return 'v-form-checkbox-slider--loading v-form-checkbox-slider--checked';
    }else{
      return 'v-form-checkbox-slider--loading';
    }
  }else if(checkboxModel.value){
    return 'v-form-checkbox-slider--checked';
  }else{
    return '';
  }
});

const startLoading = () => {
  loading.value = true;
}
const endLoading = () => {
  loading.value = false;
}

const emit = defineEmits(['save']);

const save = () => {
  if(loading.value){return;}
  if(props.type === 'events'){
    loading.value = true;
    checkboxModelAfterSave.value = !checkboxModel.value;
    emit('save', !checkboxModel.value);
  }else if(props.type === 'model'){
    checkboxModel.value = !checkboxModel.value;
  }
}

const saveWithSuccess = () => {
  endLoading();
}

const saveWithError = () => {
  checkboxModel.value = props.value;
  endLoading();
}

defineExpose({startLoading,endLoading,saveWithSuccess,saveWithError});

</script>

<style lang="scss" scoped>
.v-form-checkbox-slider{
  width:32px;
  height: 16px;
  position: relative;
  cursor:pointer;
}
.v-form-checkbox-slider:before{
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 32px;
  height: 16px;
  background: #8897AE;
  border: none;
  border-radius: 21px;
  pointer-events: all;
  box-shadow: none;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  z-index:1;
}
.v-form-checkbox-slider:after{
  content: "";
  position: absolute;
  display: block;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  background: #FFFFFF;
  border-radius: 50%;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  z-index:2;
}
.v-form-checkbox-slider--checked:before{
  background: #52A56B;
  border: none;
}
.v-form-checkbox-slider--checked:after{
  background: #FFFFFF;
  transform: translateX(16px);
}
.v-form-checkbox-slider input{
  position: absolute;
  opacity:0;
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index:3;
  cursor:pointer;
}
</style>