<template>
  <div class="v-tag-active">
    <slot>Active</slot>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.v-tag-active{
  border: 1px solid $primary-green-100;
  background: $primary-green-5;
  color: $primary-green-100;
  text-transform:uppercase;
  font:$font-mini-9-semi;
  border-radius:4px;
  padding: 1px 4px;
}
</style>