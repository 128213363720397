<template>
  <div class="v-white-container vwc" :class="mainClass">

    <slot v-if="!levelOut"/>

    <template v-if="levelOut">
      <slot name="level-out-outer-before"/>
      <div class="vwc__level-out-inner">
        <slot name="level-out-inner"/>
      </div>
      <slot name="level-out-outer-after"/>
    </template>

  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  hasWrap: {type:Boolean, default:false,},
  wrapHasTopBar: {type:Boolean, default:false,},
  withTabs: {type:Boolean, default:false,},
  levelOut: {type:Boolean, default:false,},
  withoutPadding: {type:Boolean, default:false,},
  onlyPadding: {type:Boolean, default:false,},
});

const mainClass = computed(() => {
  let response = [];
  if(props.hasWrap){response.push('vwc--has-wrap');}
  if(props.wrapHasTopBar){response.push('vwc--wrap-has-top-bar');}
  if(props.withTabs){response.push('vwc--with-tabs');}
  if(props.levelOut){response.push('vwc--level-out');}
  if(props.withoutPadding){response.push('vwc--without-padding');}
  if(props.onlyPadding){response.push('vwc--only-padding');}
  return response;
});

</script>

<style lang="scss" scoped>
.v-white-container.vwc{

  width:100%;
  max-width:920px;
  padding: 30px;
  border-radius: 8px;
  background:#fff;
  box-shadow: 0px 0px 12px 0px #1C7EE02E;

  &.vwc--has-wrap{
    background:none;
    box-shadow: none;
    border-radius:0px;
  }

  &.vwc--wrap-has-top-bar{
    padding-top:20px;
  }

  &.vwc--with-tabs{
    border-top-left-radius:0px;
    border-top-right-radius:0px;
    position:relative;
    z-index:2;
  }

  @media(max-width:767px){
    padding: 16px;
  }

  &.vwc--level-out{
    box-shadow:none;
    border-radius:0px;
    padding:0px;
    max-width:initial;
    background:initial;

    width:calc(100% + 60px);
    margin-left:-30px;
    margin-right:-30px;
    .vwc__level-out-inner{
      padding-left:30px;
      padding-right:30px;
    }

    @media(max-width:767px){
      width:calc(100% + 32px);
      margin-left:-16px;
      margin-right:-16px;
      .vwc__level-out-inner{
        padding-left:16px;
        padding-right:16px;
      }
    }

  }

  &.vwc--level-out-full{
    margin-top:-30px;
    margin-bottom:-30px;
    .vwc__level-out-inner{
      padding-top:30px;
      padding-bottom:30px;
    }
    @media(max-width:767px){
      margin-top:-16px;
      margin-bottom:-16px;
      .vwc__level-out-inner{
        padding-top:16px;
        padding-bottom:16px;
      }
    }
  }

  &.vwc--without-padding{
    padding:0px;
  }

  &.vwc--only-padding{
    background:initial;
    box-shadow:none;
    padding:30px;
    @media(max-width:767px){
      padding:16px;
    }
  }

}
</style>