<template>
  <div class="recommendations-keyword rk" :class="mainClasses">
    <div class="rk__icon">
      <v-icon-svg name="exclamation-mark-circle" class="rk__icon-svg"/>
    </div>
    <div class="rk__info">
      <span
          class="rk__info-text"
          :class="hasTooltip ? 'rk__info-text--tooltip' : ''"
          ref="infoText"
          @click="openTooltip"
      >
        <slot name="text">
          {{text}}
        </slot>
      </span>
    </div>
    <v-tooltip
        v-if="hasTooltip"
        ref="tooltip"
        :get-linked-block="() => infoText"
        placement="bottom-start"
        :style="{maxWidth:'292px',padding:'20px',borderRadius:'6px'}"
    >
      <template v-slot:content>
        <slot name="tooltip"></slot>
      </template>
    </v-tooltip>
  </div>
</template>

<script setup lang="ts">

import VIconSvg from "../Base/VIconSvg.vue";
import {computed, ref} from "vue";
import VTooltip from "../Base/VTooltip.vue";

const loading = ref(false);

const props = defineProps({
  text:{},
  hasTooltip:{type:Boolean,default:false},
  degree: {default:'warning'},
});

const mainClasses = computed(() => {
  let classes = [];

  if(loading){
    classes.push('rk--loading');
  }

  if(props.degree === 'warning'){
    classes.push('rk--warning');
  }else if(props.degree === 'danger'){
    classes.push('rk--danger');
  }

  return classes;

})

const update = () => {
  loading.value = true;
  setTimeout(() => {loading.value = false;},1000);
}

const infoText = ref();
const tooltip = ref();

const openTooltip = () => {
  if(props.hasTooltip){
    tooltip.value.toggle();
  }
}


</script>

<style scoped lang="scss">

.recommendations-keyword.rk{
  display: flex;
  align-items:center;
  gap:8px;

  .rk__icon{
    flex-shrink: 0;
    .rk__icon-svg{
      width: 18px;
      height: 18px;
      fill:#EF8E34;
    }
  }

  &.rk--warning{
    .rk__icon{
      .rk__icon-svg{
        fill:#EF8E34;
      }
    }
  }

  &.rk--danger{
    .rk__icon{
      .rk__icon-svg{
        fill:#F2001D;
      }
    }
  }

  .rk__info{
    .rk__info-text{
      font-size: 14px;
      line-height: 17px;
      &.rk__info-text--tooltip{
        border-bottom:1px dashed #0000004D;
        cursor:pointer;
      }
    }
  }
  .rk__update{
    .rk__update-svg{
      flex-shrink: 0;
      width:18px;
      height:16px;
      cursor: pointer;
    }
  }

  &.rk--loading{
    .rk__update-svg{
      animation: recommendation-keyword_rk_spin 1s infinite linear;
    }
  }
}

@keyframes recommendation-keyword_rk_spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>