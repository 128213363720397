<template>
  <div class="recommendation-daily-limit rdl">
    <div class="rdl__head">
      RECOMMENDED DAILY LIMIT
    </div>
    <div class="rdl__cols">
      <div class="rdl__col">
        <div class="rdl__col-title">
          top 1
        </div>
        <div class="rdl__col-value">
          <v-link href="#" v-if="top1 !== null">
            <v-number :value="top1" @click.prevent="chooseDaily(top1)"/>
          </v-link>
          <div class="rdl__col-na" v-else>
            N/A
          </div>
        </div>
      </div>
      <div class="rdl__col">
        <div class="rdl__col-title">
          top 3
        </div>
        <div class="rdl__col-value">
          <v-link href="#" v-if="top3 !== null">
            <v-number :value="top3" @click.prevent="chooseDaily(top3)"/>
          </v-link>
          <div class="rdl__col-na" v-else>
            N/A
          </div>
        </div>
      </div>
      <div class="rdl__col">
        <div class="rdl__col-title">
          top 10
        </div>
        <div class="rdl__col-value">
          <v-link href="#" v-if="top10 !== null">
            <v-number :value="top10" @click.prevent="chooseDaily(top10)"/>
          </v-link>
          <div class="rdl__col-na" v-else>
            N/A
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import VLink from "../Base/VLink.vue";
import VNumber from "../Base/VNumber.vue";

const props = defineProps({
  top1: {required:true,},
  top3: {required:true,},
  top10: {required:true,}
});

const emit = defineEmits(['choose-daily']);

const chooseDaily = (number) => {
  emit("choose-daily",number);
}

</script>

<style lang="scss" scoped>
.recommendation-daily-limit.rdl{
  display: flex;
  flex-direction: column;
  gap:2px;
  @media (max-width:767px) {
    width:100%;
  }

  .rdl__head{
    font-size: 9px;
    font-weight: 500;
    line-height: 12px;
    color: #AEB7C7;
  }
  .rdl__cols{
    display:flex;
    gap:10px;
    align-items:flex-start;
    justify-content: flex-start;

    @media(max-width:767px){
      justify-content:space-between;
    }

    .rdl__col{
      width:60px;
      .rdl__col-title{
        font-size: 9px;
        font-weight: 500;
        line-height: 12px;
        color:#AEB7C7;
        text-transform:uppercase;

      }
      .rdl__col-value{
        font-size: 11px;
        font-weight: 400;
        line-height: 18px;
        cursor:pointer;

      }
      .rdl__col-na{
        color:#858585;
        font-size: 11px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
}
</style>