import {formValidationErrorClear, formValidationErrorHandling, makeQueryString, runIfHasSelecter} from "../../utils/panel-lib";
import { api } from "../../utils/panel-api";

const initBacklinksProjectEditCommonScripts = function (currentData) {
}

const initModals = function () {
    $('#confirm-delete')
        .on('show.bs.modal', function (e) {
            $(this).find('.btn-ok').attr('data-href', $(e.relatedTarget).data('href'));
        })
        .find('.btn-ok')
        .on('click', function (e) {
            e.preventDefault();

            $(this).prop('disabled', true);

            $.ajax({
                url: $(this).data('href'),
                method: 'post',
                dataType: 'json',
                success: function () {
                    // $('#confirm-delete').modal('toggle');
                    window.location.reload();
                },
                error: function () {
                    $(this).prop('disabled', false);
                }
            });
        });

    $('#confirm-undelete')
        .on('show.bs.modal', function (e) {
            $(this).find('.btn-ok').attr('data-href', $(e.relatedTarget).data('href'));
        })
        .find('.btn-ok')
        .on('click', function (e) {
            e.preventDefault();

            $(this).prop('disabled', true);

            $.ajax({
                url: $(this).data('href'),
                method: 'post',
                dataType: 'json',
                success: function () {
                    // $('#confirm-undelete').modal('toggle');
                    window.location.reload();
                },
                error: function () {
                    $(this).prop('disabled', true);
                }
            })
        });

    $('#confirm-clone').on('show.bs.modal', function (e) {
        $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));
    });
    $('#confirm-upgrade').on('show.bs.modal', function (e) {
        $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));
    });
}

const initBacklinksProjectHistoryTable = function (currentData) {
    if (parseInt(currentData.projectOrdersCount) <= 0 || currentData.isDeleted) {
        return;
    }

    let $projectHistoryTable = $('#project-history-table');
    let translations = $projectHistoryTable.data('translations');

    $projectHistoryTable.dataTable( {
        responsive: true,
        dom: "<'row'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5 d-none'i><'col-12'p>>",
        order: [[ 0, "desc" ]],
        language: {
            emptyTable: translations.emptyTable,
            lengthMenu: translations.lengthMenu,
            zeroRecords: translations.zeroRecords,
            info: translations.info,
            infoEmpty: translations.infoEmpty,
            infoFiltered: translations.infoFiltered,
            loadingRecords: translations.loadingRecords,
            processing: translations.processing,
            searchPlaceholder: translations.searchPlaceholder,
            aria: {
                sortAscending: ": " + translations.sortAscending,
                sortDescending: ": " + translations.sortAscending,
            },
            paginate: {
                first: '<i class="fas fa-arrow-to-left"></i>',
                last: '<i class="fas fa-arrow-to-right"></i>',
                next: '<i class="fas fa-chevron-right"></i>',
                previous: '<i class="fas fa-chevron-left"></i>'
            },
        },
        buttons: [],
        columns: [
            {"width": "20%"},
            {"width": "50%"},
            {"width": "15%"},
            {"width": "15%"},
            // {"width": "40%", orderable: false},
        ],
        createdRow: function (row, data, dataIndex) {
        },
        columnDefs: [
            {
                targets: -1,
                render: function(data, type, row, meta) {

                    if (row[9].length <= 0) {
                        return '<span></span>';
                    }

                    if (row[3] === 'Finished') {
                        return '<a href="' + row[9] + '" target="_blank">link</a>';
                    }

                    return '<span>Waiting</span>';
                },
            },
            {
                targets: -2,
                render: function(data, type, row, meta) {
                    return '<span>' + row[3] + '</span>';
                },
            },
            {
                targets: -3,
                render: function(data, type, row, meta) {
                    return '<span>' + row[10] + '</span>';
                },
            },
            {
                targets: -4,
                render: function(data, type, row, meta) {
                    let date = new Date(row[1]);
                    return '<span>' + date.toISOString().slice(0, 10) + '</span>';
                },
            },
        ]
    });

    getProjectHistory();

    function getProjectHistory() {
        let data = {
            projectId: currentData.projectId
        };

        let url = '/api/backlinks/project/orders?' + makeQueryString(data);
        $projectHistoryTable.DataTable().ajax.url(url);

        /** DataTables after receiving the data */
        // $projectsTable.on('preXhr.dt', function () {
        // $loadingBlock.addClass('processing');
        // });

        /** DataTables after receiving the data */
        $projectHistoryTable.on('xhr.dt', function (e, settings, json) {

            console.log('ok');

            if (!json) {
                return;
            }

            let projectHistoryCount = (json.data ? json.data.length : 0);
            if (projectHistoryCount > 0) {
                $('#project-history-table_wrapper').show();
            } else {
                $('#project-history-table_wrapper').hide();
            }

            // $loadingBlock.removeClass('processing');
        }).DataTable().ajax.reload();

        /** DataTables after draw */
        $projectHistoryTable.DataTable().draw();
    }
}

const initPause = function (currentData, translation) {
    $('[data-ajax-action="pause"]').on('click', function () {
        let $button = $(this);

        $button.prop('disabled', true);

        api.backlinksProject.pause(currentData.projectId,
            function (response) {
                // console.log('response', response.success);
                if (response.success) {
                    $('.action-panel .action-panel__state').addClass('no-active');
                    $('.action-panel .action-panel__status').html(translation.paused).addClass('no-active');
                    $('.action-panel .button-pause').hide();
                    $('.action-panel .button-resume').show();
                } else {
                    // addNotification(response.message);
                }

                $button.prop('disabled', false);
                // addNotification(response.message);
            },
            function () {
                $button.prop('disabled', false);
            });
    });
}

const initResume = function (currentData, translation) {
    $('[data-ajax-action="resume"]').on('click', function () {
        let $button = $(this);

        $button.prop('disabled', true);

        api.backlinksProject.resume(currentData.projectId,
            function (response) {
                // console.log('response', response.success);
                if (response.success) {
                    $('.action-panel .action-panel__state').removeClass('no-active');
                    $('.action-panel .action-panel__status').html(translation.active).removeClass('no-active');
                    $('.action-panel .button-pause').show();
                    $('.action-panel .button-resume').hide();
                } else {
                    // addNotification(response.message);
                }

                $button.prop('disabled', false);
                // addNotification(response.message);
            },
            function () {
                $button.prop('disabled', false);
            });
    });
}

/**  Enable / Disable */
const initIsActive = function (currentData, translation) {
    $('[name="is_active"]').off('change').on('change', function () {
        let $this = $(this);
        let projectId = $this.data('project-id');
        let isActive = ($this.prop('checked') ? 1 : 0);
        let data = {
            id: projectId,
            is_active: isActive,
        }

        $this.prop('disabled', true);

        api.backlinksProject.active(data,
            function (response) {
                let data = response.data;

                if (response.success) {
                    if (parseInt(data.isActive) === 1) {
                        $('.action-panel__state').removeClass('no-active');
                        $('.action-panel__status')
                            .text('Active')
                            .removeClass('no-active');
                    } else {
                        $('.action-panel__state').addClass('no-active');
                        $('.action-panel__status')
                            .text('Disabled')
                            .addClass('no-active');
                    }
                } else {
                    if (isActive === 1) {
                        $this.prop('checked', false);
                    } else {
                        $this.prop('checked', true);
                    }
                }

                $this.prop('disabled', false);
            },
            function () {
                if (isActive === 1) {
                    $this.prop('checked', false);
                } else {
                    $this.prop('checked', true);
                }

                $this.prop('disabled', false);
            });
    });
}

const initForms = function (currentData) {
    const updateFormFields = function ($form) {
        $form.find('input, select, textarea').each(function (index, el) {
            if ($(el).attr('type') === 'checkbox') {
                $(el).data('val', $(el).prop('checked'));
            } else {
                $(el).data('val', $(el).val());
            }
        });
    }

    const getFormFields = function ($form) {
        let data = {};

        $form.find('input, select, textarea').each(function (index, el) {
            let fieldName = $(el).attr('name');
            if (fieldName) {
                fieldName = fieldName.replace('[]','');

                if ($(el).attr('type') === 'checkbox') {
                    data[fieldName] = ($(el).prop('checked') ? 1 : 0);
                } else {
                    data[fieldName] = $(el).val();
                }
            }
        });

        return data;
    }

    let $forms = $('[data-role="backlinks-project-edit"] form[data-form-id]');

    if ($forms.length === 0) {
        return;
    }

    $forms.each(function () {
        let $form = $(this);
        let $saveButton = $form.find('[data-ajax-action="save-form"]');
        let isChangesInputs = [];

        updateFormFields($form);

        $form.find('input, select, textarea').on('input change', function() {
            let isChanges = false;

            formValidationErrorClear($form);

            if ($(this).attr('type') === 'checkbox') {
                isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).prop('checked');
            } else {
                isChangesInputs[$(this).attr('name')] = $(this).data('val') !== $(this).val();
            }

            for (var key in isChangesInputs) {
                if (isChangesInputs.hasOwnProperty(key) && isChangesInputs[key]) {
                    isChanges = true;

                    break;
                }
            }

            if (isChanges) {
                $saveButton.find('.button-text').html('Save Changes');
                $saveButton.find('.icon-from-button').hide();
                $saveButton.prop('disabled', false);
            } else {
                $saveButton.find('.button-text').html('Saved');
                $saveButton.find('.icon-from-button').show();
                $saveButton.prop('disabled', true);
            }
        });
    });


    $('[name="is_da10_monthly_limit"], [name="is_da20_monthly_limit"], [name="is_da30_monthly_limit"], [name="is_da40_monthly_limit"], [name="is_da50_monthly_limit"]').on('change', function () {
        let $inputNumber = $(this).closest('.form-group').find('input[type="number"]');

        if ($(this).is(':checked')) {
            $inputNumber.data('val', $inputNumber.val()).val('').prop('disabled', true);
        } else {
            $inputNumber.val(($inputNumber.data('val') ? $inputNumber.data('val') : '0' )).prop('disabled', false);
        }
    });
}

export const initBacklinksProjectEditScripts = () => {
    runIfHasSelecter('[data-role="backlinks-project-edit"]', [
        initBacklinksProjectEditCommonScripts,
        initBacklinksProjectHistoryTable,
        initModals,
        // initPause,
        // initResume,
        initIsActive,
        initForms
    ]);
}
