<template>
  <div class="v-timer">
    <slot :hours="hours" :minutes="minutes" :seconds="seconds" :milliseconds="milliseconds">
      <span>{{hours}}h</span>
      <span>{{minutes}}m</span>
      <span>{{seconds}}s</span>
      <span>:{{milliseconds}}</span>
    </slot>
  </div>
</template>

<script setup>
import {computed, onBeforeUnmount, onMounted, ref} from "vue";

const currentTime = ref(new Date());

const emit = defineEmits(['endTime']);

const props = defineProps({
  targetTime: {
    default: 0
  }
});

const timeDiff = computed(() => {
  return formatTimeUnit(Math.max(props.targetTime - currentTime.value, 0));
})

const hours = computed(() => {
  return formatTimeUnit(Math.floor(timeDiff.value / (1000 * 60 * 60)));
});

const minutes = computed(() => {
  return formatTimeUnit(Math.floor((timeDiff.value % (1000 * 60 * 60)) / (1000 * 60)));
});

const seconds = computed(() => {
  return formatTimeUnit(Math.floor((timeDiff.value % (1000 * 60)) / 1000));
});

const milliseconds = computed(() => {
  return formatTimeUnit(Math.floor((timeDiff.value % 1000) / 10));
});

const formatTimeUnit = unit => {
  return unit < 10 ? `0${unit}` : unit; // Добавляем ведущий ноль, если единица времени меньше 10
};

const updateCurrentTime = () => {
  currentTime.value = new Date();
  if(timeDiff.value <= 0){
    emit('endTime');
  }
};

let timer = null

onMounted(() => {
  timer = setInterval(updateCurrentTime, 10);
});

onBeforeUnmount(() => {
  clearInterval(timer);
});
</script>

<style scoped lang="scss">
.v-timer{
  display:flex;
  ju
  span{

  }
}
</style>