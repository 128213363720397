import {getGaClientId, runIfHasSelecter} from "../../utils/panel-lib";

const initAction = () => {
    let $form = $('[data-form="register"]');

    $form.find('[data-button="register"]').on('click', function (e) {
        e.preventDefault();

        $(this).prop('disabled', true);
        $form.trigger('submit');
    });

    let gaClientId = getGaClientId();
    $form.find('[name="cid_field"]').val(gaClientId);
}

export const initRegisterScripts = function () {
    runIfHasSelecter('[data-role="register"]', [
        initAction
    ]);
}
