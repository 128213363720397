import {defineStore} from "pinia";
import axios from "axios";
import {useRecoStore} from "./useRecoStore";
import {ApiResource} from "../plugins/ApiExtends";
import {useLinkBuildingType} from "./useLinkBuilding";
import {CREDIT_TYPE_BACKLINK, CREDIT_TYPE_SEO} from "./useBilling";

export const useStaticData = defineStore('staticData', {
    state: () => {
        return {
            _regions: null,
            _languages: null,
            _searchEngines: null,
            _backlinksCategories: null,
        }
    },
    getters: {

        regions: function(state){
            if(state._regions === null){
                new ApiResource({url: '/api/v1/regions'}).downloadAsync().then((response) => {
                    let [status, data] = response;
                    if(status){
                        state._regions = data.regions;
                    }else{
                        console.log('error', data);
                    }
                });
                return [];
            }else{
                return state._regions;
            }
        },

        regionsForSelect: function(state){
            let response = [];
            state.regions.forEach((region) => {
                response.push({
                    value: region.code,
                    name: region.name,
                });
            });
            return response.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                // Сравнение строк
                if (nameA < nameB) {
                    return -1;  // a идёт первым
                }
                if (nameA > nameB) {
                    return 1;   // b идёт первым
                }
                return 0;       // порядок не изменяется
            });
        },

        languages: function(state){
            if(state._languages === null){
                new ApiResource({url: '/api/v1/languages'}).downloadAsync().then((response) => {
                    let [status, data] = response;
                    if(status){
                        state._languages = data.languages;
                    }else{
                        console.log('error', data);
                    }
                });
                return [];
            }else{
                return state._languages;
            }
        },

        languagesForSelect: function(state){
            let response = [];
            state.languages.forEach((language) => {
                response.push({
                    value: language.code,
                    name: language.name,
                });
            });
            return response;
        },

        searchEngines: function(state){
            if(state._searchEngines === null){
                new ApiResource({url: '/api/v1/search-engines'}).downloadAsync().then((response) => {
                    let [status, data] = response;
                    if(status){
                        state._searchEngines = data.searchEngines;
                    }else{
                        console.log('error', data);
                    }
                });
                return [];
            }else{
                return state._searchEngines;
            }
        },

        searchEnginesForSelect: function(state){
            let response = [];
            state.searchEngines.forEach((searchEngine) => {
                response.push({
                    value: searchEngine.id,
                    name: searchEngine.name,
                });
            });
            return response;
        },

        backlinksCategories(state){
            if(state._backlinksCategories === null){
                new ApiResource({url: '/api/v1/backlinks-category'}).downloadAsync().then((response) => {
                    let [status, data] = response;
                    if(status){
                        state._backlinksCategories = data;
                    }else{
                        console.log('error', data);
                    }
                });
                return [];
            }else{
                return state._backlinksCategories;
            }
        },

        backlinksCategoriesForSelect: function(state){
            let response = [];
            state.backlinksCategories.forEach((backlinksCategory) => {
                response.push({
                    value: backlinksCategory.id,
                    name: backlinksCategory.name,
                });
            });
            return response;
        },

    },
    actions: {

    }
})

export const useBacklinkOptions = defineStore('useBacklinkOptions', {
    state: () => {
        return {
            downloaded: false,
            options: [],
        }
    },
    actions: {
        async download(){
            let resource = new ApiResource({
                url: '/api/v1/backlinks-project-options',
                method: 'get'
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch((state) => {
                    state.downloaded = true;
                    state.options = [];
                    resource.data.options.forEach((option) => {
                        state.options.push({...option, typeConstant: option.value.replace(/_/g, '')});
                    });
                });
                return [true,resource.data];
            }else{
                console.log('error', resource.errorMessage,resource.errorErrors);
                return [false];
            }

        }
    }
});

export const useUserBalance = defineStore('user-balance',{
    state: () => {
        return {
            items: [],
        }
    },
    getters: {
        seoCreditBalances: (state) => {
            return state.items.filter((item) => item.creditType === CREDIT_TYPE_SEO);
        },
        backlinkBalances: (state) => {
            return state.items.filter((item) => item.creditType === CREDIT_TYPE_BACKLINK);
        },
    },
    actions: {
        async download(){
            let resource = new ApiResource({
                'url' : '/api/v1/user/balance',
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){

                Object.entries(resource.data).forEach(([type,balanceData]) => {

                    let typeConstant = useLinkBuildingType().getConstant(type);
                    if(!typeConstant){return}

                    useUserBalanceItem(typeConstant).init(balanceData);

                    if(!this.getByTypeConstant(typeConstant)){
                        this.$patch((state) => {
                            state.items.push(useUserBalanceItem(typeConstant));
                        })
                    }

                });

                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        },
        getByTypeConstant(typeConstant){
            return this.items.find((item) => item.typeConstant === typeConstant);
        }
    }

});

export const useUserBalanceItem = (typeConstant) => defineStore('user-balance-'+typeConstant,{
    state: () => {
        return {
            typeConstant: typeConstant,
            /**
             * @type {'red'|'green'|'yellow'}
             */
            status: null,
            balance: null,
        }
    },
    getters: {
        creditType: (state) => {
            return useLinkBuildingType().getTypeCredit(state.typeConstant);
        },
    },
    actions: {
        init(data){
            this.$patch({
                status: data.status,
                balance: data.balance,
            });
        }
    }
})();

export const useUserInfo = defineStore('/api/userinfo',{
    state: () => {
        return {
            _downloaded: false,
            id:null,
            login: null,
        }
    },
    actions: {
        async download(){
            let resource = new ApiResource({
                'url' : '/api/userinfo'
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({
                    id: resource.data.id,
                    login: resource.data.login
                });
                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        }
    }

});

export const useFaq = (type) => defineStore('/api/v1/faq/'+type,{
    state: () => {
        return {
            /**
             * @type {{type: string,question: string, answer: string, sort_order:numeric}[]}
             */
            type: type,
            items: [],
        }
    },
    actions: {
        async download(){
            let resource = new ApiResource({
                'url' : '/api/v1/faq/',
                'method' : 'get',
                'params' : {
                    type: this.type
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                this.$patch({items: resource.data});
                return [true];
            }else{
                return [false,resource.errorMessage];
            }
        }
    }
})()

export const useCheckWebsite = defineStore('api/v1/check-website', {
    state: () => {
        return {
            loading: false,
            errorMessage: null
        }
    },
    actions: {
        async check(url, isDemo){
            let resource = new ApiResource({
                'url' : '/api/v1/check-website',
                'method' : 'get',
                'params' : {
                    url: url,
                    is_demo: isDemo,
                    secret: useSecretCodes().apiSecretAuth,
                }
            });

            this.$patch({loading: true});
            await resource.downloadAsync();
            this.$patch({loading: false});

            if(resource.isSuccess()){
                this.$patch({errorMessage: null});
                return [true];
            }else{
                this.$patch({errorMessage: resource.errorMessage});
                return [false,resource.errorMessage];
            }
        }
    }
});

export const useSecretCodes = defineStore('secretCodes', {
    state: () => {
        return {
            apiSecretAuth: 'sj1ms71nal2maj2odytypmwn23',
        }
    }
});