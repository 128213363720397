<template>
  <div class="bc-choose-package bccp" :class="mainClass">

    <div class="bccp__left">
      <div class="bccp__left-btn">

        <v-button
            color="blue2"
            is-bold
            class="bccp__btn-add"
            @click="addToCart()"
            :loading="loadingBtn"
            :disabled="useBillingCart().requestExecutionAny"
            text="+Add"
        />

      </div>
      <div class="bccp__left-title">
        <div class="bccp__left-title-info">

          <div class="bccp__credits" v-if="isCustom">
            <div class="bccp__credits-value">
              <v-form-input-counter
                  :min="planOptions.minQuantity"
                  v-model="quantityCustom"
                  :disabled="useBillingCart().requestExecutionAny"
              />
            </div>
          </div>

          <div class="bccp__seocredits-title" v-if="pack.type===CREDIT_TYPE_SEO && !isCustom">
            {{quantityValidate}} Credits
          </div>

          <div class="bccp__backlink-title" v-if="pack.type === CREDIT_TYPE_BACKLINK">
            <span>
              {{!isCustom ? quantityValidate : ''}}
              {{quantityValidate > 1 ? 'Backlinks' : 'Backlink'}}
            </span>
            <v-product class="bccp__backlink-title-type" :type-constant="pack.typeConstantBacklink" is-short without-plus/>
          </div>

        </div>

        <bc-choose-package-tags
            v-if="pack.is_best_price || pack.is_most_popular || promo_amount > 0"
            class="bccp__user-choices"
            :promo-amount="promo_amount"
            :has-best-price="pack.is_best_price"
            :has-most-popular="pack.is_most_popular"
            :is-custom-package="isCustom"
        />

      </div>
    </div>

    <div class="bccp__right">

      <v-tag-save v-if="discount_amount > 0">
        save ${{Math.round(discount_amount)}}
      </v-tag-save>
      <div class="bccp__price">
        ${{priceFormatted(planOptions.priceForQuantity(quantityValidate))}}
      </div>

    </div>
  </div>
</template>

<script setup>

import {computed, ref, watch} from "vue";
import VFormInputCounter from "../VForm/VFormInputCounter.vue";
import VButton from "../Base/VButton.vue";
import BcChoosePackageTags from "./BcChoosePackageTags.vue";
import VProduct from "../Base/VProduct.vue";
import {
  CREDIT_TYPE_BACKLINK,
  CREDIT_TYPE_SEO,
  useBillingCart, useCreditTypes, usePlanOptions,
  usePlanOptionsPackage
} from "../../stores/useBilling";
import {priceFormatted} from "../../utils/utils";
import VTagSave from "../Base/VTagSave.vue";

const props = defineProps({
  planOptionsId: {},
  planOptionsPackageId: {},
  isCustom: {type:Boolean, default:false},
});

const pack = usePlanOptionsPackage(props.planOptionsPackageId);
const planOptions = usePlanOptions(props.planOptionsId);

const emit = defineEmits(['add-to-cart-click']);

const quantityCustom = ref();
const quantityValidate = computed(() => {
  return quantityCustom.value >= planOptions.minQuantity ? quantityCustom.value : planOptions.minQuantity;
});

const resetCustomPack = () => {
  quantityCustom.value = pack.quantity;
}
resetCustomPack();

const discount_amount = computed(() => {
  return planOptions.discountAmountForQuantity(quantityValidate.value);
});

const promo_amount = computed(() => {
  return planOptions.promoAmountForQuantity(quantityValidate.value);
});

const mainClass = computed(() => {
  let classes = [];
  if(props.isCustom){
    classes.push('bccp__is-custom');
  }
  return classes;
});

const loadingBtn = ref(false);
const addToCart = () => {
  emit('add-to-cart-click');
  useBillingCart().add(pack.plan_id, quantityValidate.value);
}

</script>

<style lang="scss" scoped>
.bc-choose-package.bccp{
  @include flex(row,between,center,0);
  padding:0px 0px;
  height:74px;
  border-bottom: 1px solid #D6E3EF80;

  .bccp__left{
    @include flex(row,start,center,16px);

    .bccp__left-title{
      @include flex(row,start,center,16px);

      .bccp__left-title-info{
        @include flex(row,start,center,16px);

      }
    }
    @media(max-width:767px){
      .bccp__left-title{
        @include flex(column,center,start,4px);

        .bccp__left-title-info{
          @include flex(row,center,start,5px);

        }
      }
    }
  }

  .bccp__right{
    @include flex(row,end,center,14px);
    @media(max-width:767px){
      @include flex(column-reverse,center,end,2px);
    }
  }

  .bccp__price{

    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: right;
    min-width:69px;

  }

  .bccp__user-choices{

  }

  .bccp__btn-add{
    height:32px;
    padding:0px 12px;
  }

  .bccp__seocredits-title{
    font-size: 16px;
    font-weight: 600;
    color:#224CB9;
    @media(max-width:767px){
      font-size: 14px;
    }
  }

  .bccp__backlink-title{
    @include flex(row,start,center,4px);
    font-size: 16px;
    font-weight: 600;
    color:#384967;
    @media(max-width:767px){
      font-size: 14px;
    }

    .bccp__backlink-title-type{
      font-size: 16px;
      font-weight: 600;
      @media(max-width:767px){
        font-size: 14px;
      }
    }
  }

  &.bccp__is-custom{
    .bccp__backlink-title{
      @media(max-width:767px){
        @include flex(column,center,start,0px);
      }
    }
  }

}
</style>