<template>
  <div class="d-buttons">
    <v-button v-for="color in colors" :color="color">{{color}}</v-button>
  </div>
</template>

<script setup>

import VButton from "../Base/VButton.vue";

const colors = [
  'blue2-stroke',
  'blue',
  'green',
  'link',
  'blue2',
  'red',
];

</script>

<style lang="scss" scoped>
.d-buttons{
  @include flex(column,start,start,20px);
}
</style>