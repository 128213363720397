import {runIfHasSelecter, getUserLanguage} from "../../utils/panel-lib";
import {api} from "../../utils/panel-api";

const initCommon = function () {
    let xhrKeywords, xhrAddProject;
    let $loadingBlock = $('.loading-block');
    let $geo_targeting_enabled = $('#geo_targeting_enabled');
    let $countries = $('#countries').closest('.form-group');
    let $languages = $('#languages').closest('.form-group');
    let $addButton = $('[data-ajax-action="add-form"]');
    let $errorBox = $('[data-role="error"]');
    let $wrongFormErrorBox = $('#msgfail');
    let $websiteInput = $('[name="website"]');
    let $keywordInput = $('[name="keyword"]');
    let $geoTargetingEnabledInput = $('[name="geo_targeting_enabled"]');
    let $countryInput = $('[name="region"]');
    let $languageInput = $('[name="language"]');
    let $dailyLimit = $('[name="daily_limit"]');
    // let $monthlyLimit = $('[name="monthly_limit"]');

    $geo_targeting_enabled.on('change', function () {
        if ($(this).is(':checked')) {
            $countries.show();
            $languages.show();
        } else {
            setUserLanguage();
            $countries.hide();
            $languages.hide();
        }

        $websiteInput.data('val', '');
        $websiteInput.trigger('change');
    });

    if ($geo_targeting_enabled.is(':checked')) {
        $countries.show();
        $languages.show();
    } else {
        setUserLanguage();
        $countries.hide();
        $languages.hide();
    }

    $countryInput.on('change', function () {
        $websiteInput.data('val', '');
        $websiteInput.trigger('change');
    });

    $addButton.on('click', function(e) {
        e.preventDefault();

        if (xhrAddProject) {
            xhrAddProject.abort();
        }

        let keywords = parseKeywords($.trim($keywordInput.val()));
        let isMultiKeywords = (keywords.length > 1);

        let data = {
            website: $websiteInput.val(),
            keywords: keywords,
            geo_targeting_enabled: ($geoTargetingEnabledInput.is(':checked') ? 1 : 0),
            country: ($geoTargetingEnabledInput.is(':checked') ? $countryInput.val() : 'US'),
            language: ($geoTargetingEnabledInput.is(':checked') ? $languageInput.val() : 'en'),
            daily_limit: $dailyLimit.val(),
            is_multi_keywords: isMultiKeywords ? 1 : 0,
        }

        let $button = $(this);
        let $form = $button.closest('form');
        let formId = $form.data('form-id');

        let formData = $form.serializeArray();
        formData.push({
            name: 'formId',
            value: formId
        });

        $button.prop('disabled', true);

        xhrAddProject = $.ajax({
            type: 'POST',
            url: '/api/seo-clicks/project/add',
            data: data,
            dataType: 'json',
            success: function (response) {
                let data = response.data;

                if (data.success) {
                    if (isMultiKeywords) {
                        window.location.replace("/search-traffic-projects");
                    } else if (data.projectId) {
                        window.location.replace('/search-traffic-project?id=' + data.projectId);
                    } else {
                        window.location.replace("/search-traffic-projects");
                    }
                } else {
                    if (data.message === 'WRONGFORM') {
                        $wrongFormErrorBox.show();
                    } else {
                        $errorBox.html(data.message).show();
                    }

                    $button.prop('disabled', false);
                }
            },
            error: function () {
                $button.prop('disabled', false);
            }
        });
    });

    // $websiteInput.on('blur paste change', function () {
    $websiteInput.on('keyup paste change', function () {
        return;
        let $this = $(this);
        let website = $this.val();
        let region = 'us';
        if ($geo_targeting_enabled.is(':checked')) {
            region = $countryInput.val();
        }

        if (website && $this.val() !== $this.data('val')) {
            showKeywords([]);

            if (xhrKeywords) {
                xhrKeywords.abort();
            }

            let data = {
                location: region.toLowerCase(),
                url: website,
                secret: 'sj1ms71nal2maj2odytypmwn23',
                where: ''
            };

            setTimeout((website) => {
                if (website === $websiteInput.val()) {
                    $loadingBlock.addClass('processing');

                    xhrKeywords = $.ajax({
                        type: "GET",
                        url: '/api/v1/keywords/get-extract-keywords',
                        data: data,
                        dataType: 'json',
                        success: function (response) {
                            // console.log(response);

                            if (response.success) {
                                let keywords = response.data.keywords;
                                if (keywords) {
                                    // console.log(keywords);
                                    api.searchTrafficProject.checkKeywords({
                                            "website": website,
                                            "keywords": keywords
                                        },
                                        function (response) {
                                            if (response.success) {
                                                if (response.data.keywords) {
                                                    showKeywords(response.data.keywords);
                                                } else {
                                                    showKeywords([]);
                                                }
                                            } else {
                                                showKeywords([]);
                                            }

                                            $loadingBlock.removeClass('processing');
                                        },
                                        function (xhr) {
                                            let error = xhr.responseText;
                                            console.log(error);

                                            showKeywords([]);
                                            $loadingBlock.removeClass('processing');
                                        });
                                } else {
                                    showKeywords([]);
                                    $loadingBlock.removeClass('processing');
                                }
                            } else {
                                showKeywords([]);
                                $loadingBlock.removeClass('processing');
                            }
                        },
                        error: function () {
                            showKeywords([]);
                            $loadingBlock.removeClass('processing');
                        }
                    });
                }
            }, '1000', website);
        }

        $(this).data('val', $(this).val());
    });

    $keywordInput.on('blur paste change', function () {
        let keywords = parseKeywords($.trim($keywordInput.val()));

        $('.suggested-keywords [data-insert-to="keyword"]').each(function () {
            let keyword = $(this).find('.suggested-keywords__keyword').text();

            if ($.inArray(keyword, keywords) !== -1) {
                $(this).prop('disabled', true);
                $(this).closest('.suggested-keywords__item').hide();
            } else {
                $(this).prop('disabled', false);
                $(this).closest('.suggested-keywords__item').show();
            }
        });
    });

    function showKeywords(keywords) {
        let html = '';

        for (let i = 0, len = keywords.length; i < len; i++) {
            let item = keywords[i];

            html += '<li class="suggested-keywords__item">\n' +
                '    <button class="button button-link-dashed" type="button" data-insert-to="keyword">\n' +
                '        <span class="suggested-keywords__keyword">' + item.keyword + '</span>\n' +
                '    </button>\n' +
                '</li>\n';
        }

        $('.suggested-keywords ul').html(html);

        if (html) {
            $('.suggested-keywords [data-insert-to="keyword"]').off('click').on('click', function () {
                let keywordsValue = $keywordInput.val();
                let keywords = parseKeywords($.trim($keywordInput.val()));
                let selectKeyword = $(this).find('.suggested-keywords__keyword').text();

                if ($.inArray(selectKeyword, keywords) === -1) {
                    if (keywordsValue) {
                        $keywordInput.val(keywordsValue + '\n' + selectKeyword).focus();
                    } else {
                        $keywordInput.val(selectKeyword).focus();
                    }
                }

                $(this).prop('disabled', true);
                $(this).closest('.suggested-keywords__item').hide();
            });
        }
    }

    function setUserLanguage() {
        if (!$geo_targeting_enabled.is(':checked') && $languages.val() === 'af') {
            $('#languages option[value="' + getUserLanguage() + '"]').attr('selected', 'selected');
        }
    }

    function parseKeywords(keywords) {
        return keywords.replace(/\;|\r\n|\r|\n/g, ',').split(',').filter(n => n);
    }
}

export const initSeoClicksProjectAddScripts = () => {
    runIfHasSelecter('[data-role="search-project-add"]', [
        initCommon,
    ]);
}
