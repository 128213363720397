<template>
  <v-project-preview class="sc-project-preview"
                     :is-demo="useProject.is_demo"
                     :modal-blackout="modalBlackout">

    <template #default>
      <div class="content">

        <div class="info content__info">

          <div class="info__top">

            <div class="info__id" :class="modalBlackout ? 'info__id--hide' : ''">
              {{useProject.id}}
              <div class="info__tag-demo" v-if="useProject.is_demo" v-text="'DEMO'"/>
              <div class="info__tag-deleted" v-if="useProject.is_deleted" v-text="'DELETED'"/>
            </div>

            <div class="info__title"
                 :class="useProject.is_deleted ? 'opacity-50' : ''"
                 v-text="useProject.name"/>

            <v-link class="info__domain"
                    :href="useProject.correctUrl"
                    :class="useProject.is_deleted ? 'opacity-50' : ''"
                    v-text="useProject.shortUrl"/>

          </div>

          <v-button class="info__button"
                    tag="a"
                    :href="useProject.linkProject"
                    color="blue2-stroke"
                    :class="modalBlackout ? 'info__button--hide' : ''"
                    text="Settings"/>

        </div>

        <div class="charts content__charts"
             v-if="!useProject.is_deleted"
             :class="useProject.is_demo ? 'charts--demo' : ''">

          <div class="realtime charts__realtime">
            <div class="realtime__title">
              <v-tag-active/>
              Realtime
            </div>
            <div class="realtime__chart-wrap">
              <div class="realtime__chart-title">
                {{useProjectRealtime.values.slice(-16).reduce((s,i) => s+i)}}
                <v-icon-realtime-pulse/>
              </div>
              <chart-realtime-v2 :values="useProjectRealtime.values.slice(-16)" class="realtime__chart"/>
            </div>
          </div>

          <div v-if="!useProject.is_demo" class="clicks charts__clicks">
            <div class="clicks__title">
              Clicks
            </div>
            <div class="clicks__chart-wrap">
              <div class="clicks__chart-title">
                <span class="clicks__chart-title-current">
                  <v-number-short :value="useProject.chartTotalValues"/>
                </span>
                <v-number-diff class="clicks__chart-title-diff" :value="useProject.diff_clicks"/>
              </div>
              <div class="clicks__chart">
                <chart-seo-clicks-v2 :labels="useProject.chartLabels"
                                     :values="useProject.chartValues"
                                     :type="useProject.chartValueName"
                                     class="clicks__chart_component"
                                     :count-horizontal-lines="3"
                                     show-labels="at-the-ends-absolute"/>
              </div>
            </div>
          </div>

          <div v-if="!useProject.is_demo" class="countries">
            <div class="countries__title">
              PV TOP 5 Countries
            </div>
            <div class="countries__timer" v-if="Object.keys(useProject.clicks_by_country).length === 0">
              <v-icon-svg name="timer" class="countries__timer-icon"/>
            </div>
            <table class="countries-table" v-else>
              <tr v-for="(countryData, countryCode) in useProject.clicks_by_country">
                <td class="countries-table__flag-group">
                  <span>
                    <v-icon-flag :code="countryCode"/> {{countryCode}}
                  </span>
                </td>
                <td class="countries-table__number">
                  <v-number-short :value="countryData.count"/>
                </td>
                <td class="countries-table__diff">
                  <v-number-diff :value="countryData.diff"/>
                </td>
              </tr>
            </table>
          </div>

          <div v-if="useProject.is_demo" class="charts__separator"/>

          <sc-project-demo-bar v-if="useProject.is_demo"
                               class="charts__demo-info"
                               :project-uniquekey="useProject.id"
                               is-compact/>

        </div>

      </div>
    </template>

    <template #blackout>
      <div class="content content--modal">
        <div class="info">
          <div class="info__id info__id--white" v-text="useProject.id"/>
          <v-button class="info__button"
                    color="white-stroke"
                    :href="useProject.linkProject"
                    tag="a"
                    text="Settings"/>
        </div>
      </div>
    </template>

    <template #content-after v-if="!useProject.is_deleted">

      <div class="modal-info" v-if="!useProject.is_active">
        <div class="modal-info__title">Paused</div>
        <v-button class="modal-info__button" color="green"
                  :click-action="[useProject.saveIsActive, true]"
                  icon="icon-play-white"
                  text="Resume"/>
      </div>

      <div class="modal-info" v-else-if="useProject.demoCompleted">
        <div class="modal-info__title">Demo Completed</div>
        <div class="modal-info__sub_title" v-if="useProject.insufficientBalance">
          <v-icon-svg name="icon_notification-warning" as-img width="20"/>
          Buy credits to start Project
        </div>
        <v-button v-if="useProject.insufficientBalance"
                  text="Buy credits"
                  icon="shopping-cart-white"
                  class="modal-info__button"
                  color="green"
                  tag="a"
                  :href="useRoutes().url('buy-credits')"/>
        <v-button v-else
                  text="Start Project"
                  icon="icon-play-white"
                  class="modal-info__button"
                  color="green"
                  :click-action="[useProject.convertDemoToReal]"/>
      </div>

      <div class="modal-info" v-else-if="useProject.insufficientBalance && !useProject.is_demo">
        <div class="modal-info__title">Not enough credits</div>
        <v-button class="modal-info__button"
                  color="green"
                  text="Buy credits"
                  icon="shopping-cart-white"
                  tag="a"
                  :href="useRoutes().url('buy-credits')"/>
      </div>

    </template>

  </v-project-preview>
</template>

<script setup>

import VLink from "../Base/VLink.vue";
import VButton from "../Base/VButton.vue";
import VTagActive from "../Base/VTagActive.vue";
import VIconRealtimePulse from "../Base/VIconRealtimePulse.vue";
import ChartRealtimeV2 from "../Chart/ChartRealtimeV2.vue";
import {computed, onMounted, ref} from "vue";
import ChartSeoClicksV2 from "../Chart/ChartSeoClicksV2.vue";
import VIconSvg from "../Base/VIconSvg.vue";
import VIconFlag from "../Base/VIconFlag.vue";
import ScProjectDemoBar from "./ScProjectDemoBar.vue";
import {useRealtimeSeoClicksProject, useSeoClicksProject, useSeoClicksProjects} from "../../stores/useSeoClicks";
import VProjectPreview from "../Base/VProjectPreview.vue";
import VNumberDiff from "../Base/VNumberDiff.vue";
import VNumberShort from "../Base/VNumberShort.vue";
import {useRoutes} from "../../stores/useRoutes";

const props = defineProps({
  projectId: {},
});

const useProject = useSeoClicksProject(props.projectId);
const useProjectRealtime = useRealtimeSeoClicksProject(props.projectId);

function updateInfoProject(){
  if(useProject.is_demo && !useProject.demoCompleted){
    setTimeout(() => {
      useProject.download().then(() => updateInfoProject());
    },2000);
  }
}

onMounted(() => updateInfoProject());

const modalBlackout = computed(() => {
  if(useProject.is_deleted) return null;
  if(useProject.demoCompleted) return 'black';
  if(!useProject.is_active) return 'black';
  if(useProject.insufficientBalance && !useProject.is_demo) return 'red';
  return null;
});

</script>

<style lang="scss" scoped>
.sc-project-preview{

  .content{
    @include flex(row,between,stretch,24px);
    width:100%;
    padding:24px 24px 24px 20px;
    &.content--modal{
      height:100%;
    }
    .content__info{
      width:calc(100% - 600px - 24px);
    }
    .content__charts{
      flex-shrink:0;
      width:600px;
    }
  }

  .info{
    @include flex(column,between,start, 19px);

    .info__top{
      @include flex(column,start,start,6px);
      max-width:100%;
    }

    .info__id{
      @include flex(row,start,center, 6px);
      font:$font-mini-12-regu;
      color:$dark-40;
      &.info__id--white{
        color:#fff;
      }
      &.info__id--hide{
        opacity:0;
      }
    }

    .info__title{
      @include ellipsis();
      font:$font-16-semi;
      color:$dark-80;
    }

    .info__domain{
      @include ellipsis();
    }

    .info__tag-demo{
      font:$font-mini-12-semi;
      color:$dark-40;
    }

    .info__tag-deleted{
      font:$font-mini-12-semi;
      color:$optional-red_120;
    }

    .info__button{
      padding:0px 14px;
      height:32px;
      &.info__button--hide{
        opacity:0;
      }
    }

  }

  .charts{
    @include flex(row,between,stretch,36px);

    &.charts--demo{gap:24px;}

    .charts__realtime{
      width: 170px;
      flex-shrink: 0;
    }
    .charts__clicks{
      flex-grow:2;
    }
    .charts__separator{
      width:1px;
      background:$dark-10;
      flex-shrink:0;
    }
    .charts__demo-info{
      flex-grow:2;
      align-self:flex-start;
    }
  }

  .realtime {
    @include flex(column, start, stretch, 4px);

    .realtime__title {
      @include flex(row, end, center, 8px);
      height:18px;
      color: $dark-40;
      font: $font-mini-12-regu;
    }

    .realtime__chart-wrap {
      @include flex(column,start,stretch,8px);
    }

    .realtime__chart-title {
      @include flex(row,end,center,7px);
      font: $font-16-semi;
      color: $dark-80;
    }

    .realtime__chart {
      width:100%;
      height:90px;
    }
  }

  .clicks{
    @include flex(column, start, stretch, 4px);

    .clicks__title{
      color: $dark-40;
      font: $font-mini-12-regu;
      line-height:18px;
      text-align:right;
    }
    .clicks__chart-wrap{
      @include flex(column,start,stretch,8px);
    }
    .clicks__chart-title{
      @include flex(row,end,center,4px);
      .clicks__chart-title-current{
        font:$font-16-semi;
        color:$dark-80;
      }
      .clicks__chart-title-diff{
        padding:5px 0px 2px;
        font: $font-mini-12-semi;
        &.is-zero{
          display:none;
        }
      }
    }
    .clicks__chart{
      width:100%;
      height:90px;
      position:relative;
      .clicks__chart_component{
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
      }
    }

  }

  .countries{
    @include flex(column,start,stretch,10px);
    width:128px;
    flex-shrink: 0;

    .countries__title{
      color: $dark-40;
      font: $font-mini-12-regu;
      line-height:18px;
      text-align:left;
      width:100%;
    }
    .countries__timer{
      @include flex(row,center,center);
      width:100%;
      flex-grow:2;
    }
  }

  .countries-table{
    table-layout: fixed;

    tr{
      td{padding-bottom:9px;}
      &:last-child{ td{padding-bottom:0px;} }
    }

    .countries-table__flag-group{
      text-transform:uppercase;
      font:$font-mini-12-regu;
      color:$dark-50;
      span{
        @include flex(row,start,center,4px);
      }
      text-align:left;
    }

    .countries-table__number{
      width:40px;
      font:$font-mini-12-regu;
      color:$dark-50;
      text-align:right;
    }

    .countries-table__diff{
      width:32px;
      text-transform:uppercase;
      font:$font-mini-12-regu;
      text-align:right;
    }

  }

  .modal-info{
    @include flex(column,center,center, 12px);
    position:absolute;
    z-index:10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.modal-info--has-subtitle{
      gap:8px;
    }

    .modal-info__title{
      font:$font-h2-20-semi;
      color:$white-100;
    }
    .modal-info__sub_title{
      @include flex(row,center,center,8px);
      color: $white-100;
    }
    .modal-info__button{
      @include flex(row,center,center,8px);
    }
  }

}
</style>