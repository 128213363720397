import {formValidationErrorClear, formValidationErrorHandling, runIfHasSelecter} from "../../utils/panel-lib";
import {api} from "../../utils/panel-api";

const initBacklinksProjectsAddCommonScripts = function () {
}

const initForms = function () {
    let $forms = $('[data-role="backlinks-project-add"] form[data-form-id]');

    if ($forms.length === 0) {
        return;
    }

    $forms.each(function () {
        let $form = $(this);
        let $addButton = $form.find('[data-ajax-action="add-form"]');

        $form.find('input, select, textarea').on('input change', function() {
            formValidationErrorClear($form);
        });

        $addButton.on('click', function (e) {
            e.preventDefault();

            let $button = $(this);
            let $form = $button.closest('form');

            let data = {
                url: $('[name="url"]').val(),
                keyword: $('[name="keyword"]').val(),
                category_id: $('[name="category_id"]').val(),
                owner_comments: $('[name="owner_comments"]').val(),
                da10_monthly_limit: $('[name="da10_monthly_limit"]').val(),
                da20_monthly_limit: $('[name="da20_monthly_limit"]').val(),
                da30_monthly_limit: $('[name="da30_monthly_limit"]').val(),
                da40_monthly_limit: $('[name="da40_monthly_limit"]').val(),
                da50_monthly_limit: $('[name="da50_monthly_limit"]').val(),
            }

            $button.prop('disabled', true);

            api.backlinksProject.add(data,
                function (response) {
                    let data = (response.data ? response.data : []);

                    if (response.success) {
                        $button.find('.button-text').html('Saved');
                        $button.find('.icon-from-button').show();
                        formValidationErrorClear($form);

                        // if (data.id) {
                        //     window.location.replace('/backlinks-project?id=' + data.id);
                        // } else {
                        //     window.location.replace('/backlinks-projects');
                        // }

                        window.location.replace('/backlinks-projects');
                    } else {
                        $button.prop('disabled', false);

                        if (response.message) {
                            $form.find('.error-box').html(response.message).show();
                        }

                        if (data.errorsForm) {
                            formValidationErrorHandling($form, data.errorsForm);
                        }
                    }
                },
                function () {
                    $form.find('.error-box').html('An error occurred while saving').show();

                    $button.prop('disabled', false);
                });
        });
    });

    $('[name="is_da10_monthly_limit"], [name="is_da20_monthly_limit"], [name="is_da30_monthly_limit"], [name="is_da40_monthly_limit"], [name="is_da50_monthly_limit"]').on('change', function () {
        let $inputNumber = $(this).closest('.form-group').find('input[type="number"]');

        if ($(this).is(':checked')) {
            $inputNumber.data('val', $inputNumber.val()).val('').prop('disabled', true);
        } else {
            $inputNumber.val(($inputNumber.data('val') ? $inputNumber.data('val') : '0' )).prop('disabled', false);
        }
    });


    let $geo_targeting_enabled = $('#geo_targeting_enabled');
    let $countries = $('#countries').closest('.form-group');
    let $languages = $('#languages').closest('.form-group');

    $geo_targeting_enabled.on('change', function () {
        if ($(this).is(':checked')) {
            $countries.show();
            $languages.show();
        } else {
            $countries.hide();
            $languages.hide();
        }

    });

    if ($geo_targeting_enabled.is(':checked')) {
        $countries.show();
        $languages.show();
    } else {
        $countries.hide();
        $languages.hide();
    }
}



export const initBacklinksProjectAddScripts = () => {
    runIfHasSelecter('[data-role="backlinks-project-add"]', [
        initBacklinksProjectsAddCommonScripts,
        initForms
    ]);
}
