<template>
  <div class="sc-project-add scpa">
    <v-container>

      <v-breadcrumbs :items="breadcrumbs" class="scpa__projects-breadcrumbs"/>

      <v-head-title class="scpa__title">
        Create New SEO Traffic Project
      </v-head-title>

      <div class="scpa__wrap">
        <sc-project-add-form/>
      </div>

      <div class="scpa__faq">
        <section-faq type="clicks"/>
      </div>

    </v-container>
  </div>
</template>

<script setup lang="ts">
import VBreadcrumbs from "../Base/VBreadcrumbs.vue";
import VContainer from "../Base/VContainer.vue";
import VHeadTitle from "../Base/VHeadTitle.vue";
import {ref} from "vue";
import {useRoutes} from '../../stores/useRoutes.js'
import ScProjectAddForm from "./ScProjectAddForm.vue";
import SectionFaq from "../Blocks/SectionFaq.vue";

const breadcrumbs = ref([
  {url: useRoutes().url('seo-clicks.list'), title: 'SEO Traffic',},
  {url: useRoutes().url('seo-clicks.list'), title: 'My Projects',},
  {title: 'Create New',}
]);

</script>

<style lang="scss" scoped>
.sc-project-add.scpa{
  .scpa__projects-breadcrumbs{
    margin-bottom:10px;
    @media(max-width: 767px){
      margin-bottom:0px;
    }
  }
  .scpa__title{
    margin-bottom:30px;
    @media(max-width:767px){
      margin-bottom:20px;
    }
  }
  .scpa__wrap{
    width: 100%;
    max-width:920px;
    padding: 30px;
    border-radius: 8px;
    margin-bottom:30px;
    background:#fff;
    @media(max-width:767px){
      padding:16px;
      margin-bottom:20px;
    }
  }
  .scpa__faq{}
}
</style>