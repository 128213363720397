<template>
  <div class="v-form-loading-lock vfll"></div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.v-form-loading-lock.vfll{
  position:absolute;
  width:100%;
  height:100%;
  z-index:30;
  top:0px;
  left:0px;
  background:#fff;
  opacity:0.5;
}
</style>