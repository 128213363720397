import {runIfHasSelecter, showInfoMessageForm} from "../../utils/panel-lib";
import {api} from "../../utils/panel-api";

const initAction = () => {
    $('[data-ajax-action="invite-friend"]').on('click', function (e) {
        e.preventDefault();

        let button = $(this);
        let $form = $(this).closest('form');
        let $infoBlock = $form.find('.info-message-form');

        let data = {
            username: $form.find('[name="email"]').val(),
            note: $form.find('[name="note"]').val()
        }

        button.prop('disabled', true);

        api.user.sendInviteFriend(data,
            function (response) {
                if (response.success) {
                    showInfoMessageForm(
                        $infoBlock,
                        'Email has been sent. Do you have more friends? Invite another one',
                        'green'
                    );
                } else {
                    showInfoMessageForm(
                        $infoBlock,
                        response.message,
                        'red'
                    );
                }

                button.prop('disabled', false);
            },
            function (xhr) {
                showInfoMessageForm(
                    $infoBlock,
                    'Something went wrong. Please check the email.',
                    'red'
                );
                button.prop('disabled', false);
            });
    });
}

export const initInviteFriendScripts = function () {
    runIfHasSelecter('[data-role="invite-friend"]', [
        initAction
    ]);
}
