<template>
  <div class="bc-promo-timer bcpt">
    <div class="bcpt__head">
      <div class="bcpt__time-limited">Time Limited Offer Get FREE Credits</div>
      <div class="bcpt__promo-experies">Promo Expires in: </div>
    </div>

    <div class="bcpt__body">
      <div class="bcpt__timer">
        <v-timer :target-time="useCreditTypes().promo_target_time" @end-time="endTime()">
          <template v-slot="{hours, minutes, seconds, milliseconds}">
            <div class="bcpt__timer-inner">
              <span class="bcpt__timer-inner-hour">{{hours}}h</span>
              <span class="bcpt__timer-inner-minutes">{{minutes}}m</span>
              <span class="bcpt__timer-inner-seconds">{{seconds}}s</span>
              <span class="bcpt__timer-inner-milliseconds">:{{milliseconds}}</span>
            </div>
          </template>
        </v-timer>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed, getCurrentInstance, onMounted, onUnmounted, ref} from "vue";
import VTimer from '../Base/VTimer.vue';
import {useCreditTypes} from "../../stores/useBilling";
const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const endTime = () => {
  useCreditTypes().clearPromo();
}

</script>

<style lang="scss" scoped>
.bc-promo-timer.bcpt{

  width: 100%;
  padding: 16px;
  @include flex(column,center,center,2px);
  background:#C70B0B;
  border-radius:4px;
  color:#fff;

  .bcpt__head{
    @include flex(row,center,center,10px);
    @media(max-width:767px){
      @include flex(column,center,center,2px);
    }
  }

  .bcpt__body{

  }

  .bcpt__time-limited,
  .bcpt__promo-experies{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .bcpt__timer{
    font-size: 18px;
    font-weight: 700;
    line-height: initial;
    .bcpt__timer-inner{
      display:flex;
      gap:0px;
      @media(max-width:767px){
        justify-content:center;
        width:100%;
      }
      span{
        display:flex;
        justify-content:flex-start;
        padding-right:2px;
      }
      .bcpt__timer-inner-hour{
        min-width:36.64px;
      }
      .bcpt__timer-inner-minutes{
        min-width:42.82px;
      }
      .bcpt__timer-inner-seconds{
        min-width:36.52px;
      }
      .bcpt__timer-inner-milliseconds{
        min-width:32px;
      }
    }
  }

}
</style>