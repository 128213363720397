<template>
  <div class="lb-add-project-form lbapf">

    <v-form-loading-lock v-if="loading"/>

    <v-form-group-inline
        data-name="is-demo"
        :errors="errs.errors?.is_demo"
        :keep-space-for-question="someHasQuestion"
        ref="refGroupInlineIsDemo"
    >
      <template v-slot:default>
        <v-form-select-inline
            placeholder="Type"
            v-model="fields.is_demo"
            :options="[{value:true,name:'Demo',},{value:false,name:'Normal',},]"
            :is-error="!!errs.errors?.is_demo"
            :has-empty-option="false"
            :enable-search="false"
        />
      </template>
    </v-form-group-inline>

    <v-form-group-inline
        :errors="errs.errors?.url"
        data-name="url"
        :keep-space-for-question="someHasQuestion"
        ref="refGroupInlineUrl"
    >
      <template v-slot:default>
        <v-form-input-inline placeholder="URL" :required="true" v-model="fields.url" :is-error="!!errs.errors?.url"/>
      </template>
    </v-form-group-inline>

    <v-form-group-inline
        :errors="errs.errors?.keyword"
        data-name="keyword"
        :keep-space-for-question="someHasQuestion"
        ref="refGroupInlineKeyword"
    >
      <template v-slot:default>
        <v-form-input-inline placeholder="Keyword" :required="true" v-model="fields.keyword" :is-error="!!errs.errors?.keyword"/>
      </template>
    </v-form-group-inline>

    <v-form-group-inline
        :errors="errs.errors?.category"
        data-name="category_id"
        :keep-space-for-question="someHasQuestion"
        ref="refGroupInlineCategory"
    >
      <template v-slot:default>
        <v-form-select-inline
            placeholder="Choose a category"
            v-model="fields.category"
            :options="useStaticData().backlinksCategoriesForSelect"
            :is-error="!!errs.errors?.category"
        />
      </template>
    </v-form-group-inline>

    <v-form-group-inline
        :errors="errs.errors?.comment"
        data-name="owner_comments"
        :keep-space-for-question="someHasQuestion"
        ref="refGroupInlineComment"
    >
      <template v-slot:default>
        <v-form-textarea-inline placeholder="Comments" :required="false" v-model="fields.comment" :is-error="!!errs.errors?.comment"/>
      </template>
    </v-form-group-inline>

    <v-form-group-inline v-if="errs.message" :keep-space-for-question="someHasQuestion">
      <template v-slot:default>
        <v-form-message-error :message-error="errs.message" class="lbapf__error-message"/>
      </template>
    </v-form-group-inline>

    <v-button class="lbapf__button-submit" color="blue2" @click.prevent="create" :loading="loading" :is-bold="true">
      Create project
    </v-button>

  </div>
</template>

<script setup>

import {computed, getCurrentInstance, onMounted, onUnmounted, reactive, ref} from "vue";
import VFormInputInline from "../VForm/VFormInputInline.vue";
import VFormSelectInline from "../VForm/VFormSelectInline.vue";
import VFormGroupInline from "../VForm/VFormGroupInline.vue";
import VFormTextareaInline from "../VForm/VFormTextareaInline.vue";
import VButton from "../Base/VButton.vue";
import {useLinkBuildProject} from "../../stores/useLinkBuilding";
import VFormMessageError from "../VForm/VFormMessageError.vue";
import {useStaticData} from "../../stores/useStaticData";
import VFormLoadingLock from "../VForm/VFormLoadingLock.vue";

const linkBuildProject = useLinkBuildProject(null);
const errs = linkBuildProject.errors.create;

const fields = reactive({
  url: '',
  keyword: '',
  category: null,
  comment: '',
  is_demo: {value: true, name: 'Demo',},
});

const loading = ref(false);

onMounted(() => {
  if(window.preloadData.hasOwnProperty('url') && window.preloadData.url!==null && window.preloadData.url.length > 0){
    fields.url = window.preloadData.url;
  }
  if(window.preloadData.hasOwnProperty('keyword') && window.preloadData.keyword!==null && window.preloadData.keyword.length > 0){
    fields.keyword = window.preloadData.keyword;
  }
  if(window.preloadData.hasOwnProperty('is_demo')){
    if(window.preloadData.is_demo==='true'){
      fields.is_demo = {value: true, name: 'Demo',}
    }else if(window.preloadData.is_demo==='false'){
      fields.is_demo = {value: false, name: 'Normal',}
    }
  }
  if(window.preloadData.hasOwnProperty('owner_comments') && window.preloadData.owner_comments!==null && window.preloadData.owner_comments.length > 0){
    fields.comment = window.preloadData.owner_comments;
  }
  if(window.preloadData.hasOwnProperty('category_id') && window.preloadData.category_id!==null){
    fields.category = {value: parseInt(window.preloadData.category_id)}
  }
});

const create = () => {
  if(loading.value){return;}
  loading.value = true;
  linkBuildProject.create(fields.url, fields.keyword, fields.category?.value, fields.comment, fields.is_demo.value).then((response) => {
    let [status,data] = response;
    if(status){
      window.location.href='/backlinks-project/'+data.uniquekey;
    }else{
      loading.value = false;
    }
  });
}

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const refGroupInlineIsDemo = ref();
const refGroupInlineUrl = ref();
const refGroupInlineKeyword = ref();
const refGroupInlineCategory = ref();
const refGroupInlineComment = ref();

const someHasQuestion = computed(() => {
  return [
    refGroupInlineIsDemo,
    refGroupInlineUrl,
    refGroupInlineKeyword,
    refGroupInlineCategory,
    refGroupInlineComment
  ].some(i => {
    return i.value?.reallyHasQuestion === true;
  });
});

</script>

<style scoped>
.lb-add-project-form.lbapf{
  display:flex;
  flex-direction:column;
  gap:20px;
  position:relative;

  .lbapf__button-submit{
    align-self:flex-start;
    position:relative;
    z-index:3;
    @media (max-width:767px) {
      width:100%;
    }
  }
  .lbapf__error-message{
    margin:0px;
  }
  .lbapf__loading-block{
    position:absolute;
    width:100%;
    height:100%;
    z-index:2;
    top:0px;
    left:0px;
    background:#fff;
    opacity:0.5;
  }
}
</style>