<template>
  <label
      :data-name="dataName"
      v-auto-tooltip
      class="v-form-label form-label"
      :class="formGroupIsOneLine ? 'v-form-label__is-one-line' : ''"
      :for="props.for ?? formGroupId"
  >
    <slot/>
  </label>
</template>

<script setup>
import VTooltipSmall from "../Base/VTooltipSmall.vue";
import {computed, inject} from "vue";

const props = defineProps({
  for: {},
  tooltipQuestion: {type: String, default:''},
  dataName: {}
});

const formGroupId = inject('formGroupUid');
const formGroupIsOneLine = inject('formGroupIsOneLine');

</script>

<style lang="scss" scoped>
.v-form-label{
  line-height:18px;
  display:flex;
  gap:2px;
  align-items: center;
}
.v-form-label__is-one-line{
  margin-bottom: 0px;
}
</style>