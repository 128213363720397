<template>
  <div class="lb-orders-activity lboa">

    <v-table-info class="lboa__table" v-if="!isMobile" is-sticky-head>
      <thead>
      <tr>
        <th class="lboa__table-th-date">Date</th>
        <th class="lboa__table-th-id">ID</th>
        <th class="lboa__table-th-activity">Activity</th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="item in linkBuildOrdersStore.ordersManager().activityHistory">
          <td class="lboa__table-td-date">
            <div>
              <div class="lboa__order-date">
                {{item.createdAtFormatted}}
              </div>
            </div>
          </td>
          <td class="lboa__table-td-id">
            <div>
              <div class="lboa__order-id">
                {{item.order.id}}
              </div>
            </div>
          </td>
          <td class="lboa__table-td-activity">
            <div class="lboa__order-activity">
              {{item.order.type}}; {{item.message}}
            </div>
          </td>
        </tr>
      </tbody>

    </v-table-info>

    <div class="lboa__list" v-if="isMobile">
      <template v-for="item in linkBuildOrdersStore.ordersManager().activityHistory">
        <div class="lboa__list-item">
          <div class="lboa__list-item-left">
            <div class="lboa__list-item-top">

              <div class="lboa__order-date">{{item.createdAtFormatted}}</div>
              <div class="lboa__order-id">ID {{item.order.id}}</div>

            </div>
            <div class="lboa__list-item-activity">

              <div class="lboa__order-activity">{{item.order.type}} {{item.message}}</div>

            </div>

          </div>
        </div>
      </template>
    </div>

    <v-block-empty-list class="lboa__empty" v-if="linkBuildOrdersStore.ordersManager().activityHistory.length === 0">
      <span class="lboa__not-found-text" data-name="you-have-no-activity-history" v-auto-tooltip>
        You have no Activity history yet
        <v-icon-question-tooltip v-if="false" :text="inject('fishText')"/>
      </span>
    </v-block-empty-list>

  </div>
</template>

<script setup>

import {useLinkBuildProjectOrders} from "../../stores/useLinkBuilding";
import {computed, getCurrentInstance, inject} from "vue";
import VIconQuestionTooltip from "../Base/VIconQuestionTooltip.vue";
import VBlockEmptyList from "../Base/VBlockEmptyList.vue";
import VTableInfo from "../Base/VTableInfo.vue";

const props = defineProps({
  projectUniquekey: {},
});

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const linkBuildOrdersStore = useLinkBuildProjectOrders(props.projectUniquekey);


</script>

<style lang="scss" scoped>
.lb-orders-activity.lboa{

  .lboa__table{

    th{
      background:#fff;
    }

    .lboa__table-th-date{width:75px;}
    .lboa__table-th-id{width:80px;}
    .lboa__table-th-activity{}

    td{
      height: 70px;
      border-bottom: 1px solid #E8F2FB;

      > div{}

    }

    .lboa__table-td-date{}
    .lboa__table-td-id{}
    .lboa__table-td-activity{}

  }

  .lboa__list{
    .lboa__list-item{
      width:100%;
      display:flex;
      gap:10px;
      justify-content: space-between;
      padding-top:12px;
      padding-bottom:12px;
      align-items:flex-start;
      border-bottom: 1px solid #D6E3EF80;

      .lboa__list-item-left{
        overflow: hidden;
        display:flex;
        flex-direction:column;
        gap:3px;
      }
      .lboa__list-item-right{
        display:flex;
        align-items:center;
        justify-content:flex-end;
      }
      .lboa__list-item-top{
        display:flex;
        align-items: center;
        gap:8px;
      }
      .lboa__list-item-activity{

      }

    }
  }


  .lboa__order-date{
    font-size: 12px;
    font-weight: 400;
    color:#AEB7C7;
  }
  .lboa__order-id{
    color:#798CAE;
    font-weight: 400;
    font-size:12px;
  }
  .lboa__order-activity{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color:#384967;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lboa__not-found-text{
    display:flex;
    align-items:center;
    gap:10px;
  }

}
</style>