import {computed, ref, watch} from "vue";
import {Chart as ChartJS} from "chart.js";
import {isObject} from "lodash";

export class ChartConfigBase {
    constructor(chartController){
        this.chartController = chartController;
    }

    ready(){
        return this.chartController.componentIsMounted.value
            && this.chartController.actualHeight.value !== null
            && this.chartController.actualWidth.value !== null;
    }

    result = computed(() => {
        if(!this.ready()){return null;}
        return {}; //chart config
    });
}
export class ChartControllerBase{

    constructor(refWrapCanvas, refCanvas, ClassChartConfig){

        this.refWrapCanvas = refWrapCanvas;
        this.refCanvas = refCanvas;

        this.chartJS = null;

        this.componentIsMounted = ref(false);
        this.chartConfig = new ClassChartConfig(this);

        watch(() => this.chartConfig.result.value, (config) => {
            if (config) {
                this.createOrUpdateChartJs(config);
            }
        }, {deep: true});

    }

    actualWidth = ref(null);
    actualHeight = ref(null);
    updateActualSize(){
        this.actualWidth.value = this.refWrapCanvas.value.clientWidth;
        this.actualHeight.value = this.refWrapCanvas.value.clientHeight;
    }
    resetActualSize(){
        this.actualWidth.value = null;
        this.actualHeight.value = null;
    }

    prepareConfig(config){

        if(!isObject(config)){config = {}}
        if(!config.hasOwnProperty('type')) config.type = 'line';
        if(!config.hasOwnProperty('data')) config.data = {};
        if(!config.hasOwnProperty('options')) config.options = {};

        return config;
    }

    modifyConfig(config){

        if(!config.options.hasOwnProperty('responsive')){
            config.options.responsive = true;
        }
        if(!config.options.hasOwnProperty('maintainAspectRatio')){
            config.options.maintainAspectRatio = false;
        }

        return config;
    }

    createOrUpdateChartJs(config){

        config = this.prepareConfig(config);
        config = this.modifyConfig(config);

        if(!this.chartJS){
            this.chartJS = new ChartJS(this.refCanvas.value, config);
            this.chartJS.options.onResize = () => {
                this.updateActualSize();
            }
        }else{
            this.chartJS.type = config.type;
            this.chartJS.data = config.data;
            this.chartJS.options = config.options;
            this.chartJS.update();
        }
    }

    componentOnMounted(){
        this.componentIsMounted.value = true;
        this.updateActualSize();
    }

    componentOnUnMounted(){
        this.componentIsMounted.value = false;
        if(this.chartJS) this.chartJS.destroy();
        this.resetActualSize();
    }

}