<template>
  <v-tooltip
      placement="bottom"
      ref="tooltip"
      :style="{width:'100%', maxWidth:'354px', padding:'20px', borderRadius:'6px'}"
      :btn-close-on="false"
      class="v-tooltip-period vtp"
      :get-linked-block="props.getLinkedBlock"
      @eventShow="setDefaultDates()"
      :has-arrow="true"
  >
    <template v-slot:content>
      <div class="vtp__wrap-inner">
        <div class="vtp__title">Period</div>
        <div class="vtp__periods">
          <div class="vtp__period">
            <div>
              <div class="vtp__period-title">From</div>
              <div class="vtp__period-input-wrap">
                <v-form-datepicker v-model="startDateLocal" class="vtp__period-input"/>
              </div>
            </div>
            <div class="vtp__period-icon-wrap">
              <v-icon-svg name="calendar" class="vtp__period-icon"></v-icon-svg>
            </div>
          </div>
          <div class="vtp__period">
            <div>
              <div class="vtp__period-title">To</div>
              <div class="vtp__period-input-wrap">
                <v-form-datepicker v-model="endDateLocal" class="vtp__period-input"/>
              </div>
            </div>
            <div class="vtp__period-icon-wrap">
              <v-icon-svg name="calendar" class="vtp__period-icon"></v-icon-svg>
            </div>
          </div>
        </div>
        <div class="vtp__tags">
          <div class="vtp__tags-title">
            Show for last:
          </div>
          <div class="vtp__tags-list">
            <span :class="(differenceInDays === 7 && endDayIsCurrentDay) ? 'active' : ''" @click="setLastNDays(7)">7 days</span>
            <span :class="(differenceInDays === 30 && endDayIsCurrentDay) ? 'active' : ''" @click="setLastNDays(30)">30 days</span>
            <span :class="(differenceInDays === 365 && endDayIsCurrentDay) ? 'active' : ''" @click="setLastNDays(365)">365 days</span>
          </div>
        </div>
        <div class="vtp__apply">
          <v-button class="vtp__apply-btn" @click.prevent="apply()" :loading="loading" style="width:78px;height:41px;">Apply</v-button>
        </div>
      </div>
    </template>
  </v-tooltip>
</template>

<script setup lang="ts">

import VTooltip from "./VTooltip.vue";
import {computed, ref, watch} from "vue";
import VIconSvg from "./VIconSvg.vue";
import VButton from "./VButton.vue";
import VFormDatepicker from "../VForm/VFormDatepicker.vue";
import VFormInput from "../VForm/VFormInput.vue";

const props = defineProps({
  options: {},
  getLinkedBlock: {type:Function, default:{}},
  multiSelect: {type:Boolean,default:false},
  startDate:{},
  endDate:{},
});

const emit = defineEmits(['save']);

const startDateLocal = ref();
const endDateLocal = ref();
const loading = ref(false);

watch(() => props.startDate, () => {
  startDateLocal.value = new Date(props.startDate);
},{immediate:true});

watch(() => props.endDate, () => {
  endDateLocal.value = new Date(props.endDate);
},{immediate:true});

const setDefaultDates = () => {
  startDateLocal.value = new Date(props.startDate);
  endDateLocal.value = new Date(props.endDate);
}

const differenceInDays = computed(() => {
  let date1 = new Date(startDateLocal.value);
  let date2 = new Date(endDateLocal.value);
  let differenceInMs = date2 - date1;
  return Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
});

const endDayIsCurrentDay = computed(() => {
  let d = new Date;
  return (endDateLocal.value.getDay() === d.getDay())
          && (endDateLocal.value.getMonth() === d.getMonth())
          && (endDateLocal.value.getFullYear() === d.getFullYear());
});

const setLastNDays = (n) => {
  endDateLocal.value = new Date;
  let startDays = new Date();
  startDays.setDate(startDays.getDate() - n);
  startDateLocal.value = startDays;
}

const apply = () => {
  loading.value = true;
  emit('save',startDateLocal.value.getTime(),endDateLocal.value.getTime());
}

const saveWithSuccess = () => {
  loading.value = false;
  toggle();
}

const saveWithError = (message) => {
  loading.value = false;
}

const tooltip = ref();

const toggle = () => {
  tooltip.value.toggle();
}

defineExpose({toggle,saveWithSuccess,saveWithError});

</script>


<style scoped>
:global(.v-tooltip-period.vtp[data-popper-placement^='bottom']){
  //top:-25px!important;
}
:global(.v-tooltip-period.vtp[data-popper-placement^='top']){
  //top:25px!important;
}
.vtp__wrap-inner{

  .vtp__title{
    font-size: 16px;
    font-weight: 600;
    line-height:20px;
    margin-bottom:20px;
  }

  .vtp__periods{
    border:1px solid #D6E3EF;
    border-radius: 4px;
    display: flex;
    margin-bottom:20px;

    .vtp__period{
      width:50%;
      flex-shrink: 0;
      position: relative;

      &:first-child{
        border-right:1px solid #D6E3EF;
      }
      > div{

      }
      .vtp__period-icon-wrap{
        position: absolute;
        width:20px;
        height:20px;
        right:14px;
        top:14px;
        z-index:1;

        .vtp__period-icon{}
      }
      .vtp__period-title{
        position: absolute;
        font-size: 12px;
        color:#00000080;
        top:7px;
        left:10px;
        z-index:1;
      }
      .vtp__period-input-wrap{
        z-index:2;
        position: relative;

        :deep(.vfd__input), input{
          border: none;
          box-sizing: border-box;
          outline: 0;
          position: relative;
          width: 100%;
          padding: 21px 10px 8px;
          background: transparent;
          font-size: 14px;
          line-height: 18px;

          &::-webkit-calendar-picker-indicator{
            background: transparent;
            bottom: 0;
            color: transparent;
            cursor: pointer;
            height: auto;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
          }
        }
      }
    }
  }

  .vtp__tags{
    margin-bottom:20px;

    .vtp__tags-title{
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 10px;
    }
    .vtp__tags-list{
      display: flex;
      gap:2px;

      span{
        padding: 4px 10px 4px 10px;
        border-radius: 15px;
        color:#1C7EE0;
        background-color: #F4F9FD;
        cursor: pointer;
        transition-duration: 0.2s;

        &:hover{
          background-color: #ddecf9;
        }

        &.active{
          background-color: #1D94FF;
          color:#fff;
        }
      }
    }
  }

  .vtp__apply-btn{

  }

}
</style>