export const boolType = {
    bool000: 'bool000', // нет ни у кого
    bool001: 'bool001', // у клиента нет, у конкурентов есть
    bool010: 'bool010', // у клиента есть, у конкурентов нет
    bool011: 'bool011', // у клиента и конкурентов есть
    bool101: 'bool101', // у клиента нет, у конкурентов есть, но не у всех
    bool111: 'bool111', // у клиента есть, у конкурентов тоже есть, но не у всех
}
export const colors = {
    superDanger: 'color-super-danger',
    danger: 'color-danger',
    warning: 'color-warning',
    success: 'color-success',
    default: 'color-default',
    error: 'color-error'
}
export const chartTypes = {
    default: 'default',
    error: 'error',
    boolean: 'bool'
}
export const iconTitle = {
    triangle: 'icon-triangle',
    square: 'icon-square',
    circle: 'icon-circle'
}