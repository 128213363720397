import {runIfHasSelecter} from "../../utils/panel-lib";

const initAction = function () {
    // tiny-slider
    let slider = tns({
        container: '.auth-slider',
        items: 1,
        slideBy: 'page',   // number | "page"
        autoplay: true,
        autoplayTimeout: 10000,
        autoplayButton: false,
        autoplayButtonOutput: false,
        controls: true,
        controlsPosition: 'bottom',
        controlsContainer: '.tns-controls',
        prevButton: '.tns-controls__prev',
        nextButton: '.tns-controls__next',
        nav: true,
        navPosition: 'bottom',
        navContainer: '.tns-nav',
        touch: true,
        mouseDrag: true,
        swipeAngle: false,
        speed: 400
    });
}

export const initCommonAuthScripts = function () {
    runIfHasSelecter('[data-common-role="auth"]', [
        initAction
    ]);
}