import {runIfHasSelecter} from "../utils/panel-lib";

const initAction = () => {
    let $buttonSubmit = $('button[type="submit"]');
    $buttonSubmit.on('click', function (e) {
        e.preventDefault();

        let $form = $(this).closest('form');

        $(this).prop('disabled', true);

        $form.trigger('submit');
    });
}

export const initProfileScripts = function () {
    runIfHasSelecter('[data-role="profile"]', [
        initAction
    ]);
}
