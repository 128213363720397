<template>
  <div class="countries-short-list csl">
    <ul v-if="uniqueCountryCodes.length > 0">
      <li v-for="country in uniqueCountryCodes.slice(0,3)">
        <v-icon-flag :code="country" class="bordered csl__flag"/>
      </li>
    </ul>
    <div class="csl__more-counter" v-if="uniqueCountryCodes.length > 3">
      <v-link @click.prevent class="csl__more-counter-link">+{{uniqueCountryCodes.length - 3}}</v-link>
    </div>
  </div>
</template>

<script setup lang="ts">

import {computed, defineProps, PropType} from 'vue';
import {ProjectCountry} from "../../interfaces/SeoClicksInterfaces";
import VLink from "../Base/VLink.vue";
import VIconFlag from "../Base/VIconFlag.vue";

const props = defineProps({
  countryCodes: {type:Array,default:[]}
});

const uniqueCountryCodes = computed(() => {
  return [...new Set(props.countryCodes.filter((country) => typeof country === 'string').map((country) => country.toLowerCase()))];
});

</script>

<style scoped>
.countries-short-list.csl{
  display: flex;
  align-items:center;
  ul{
    display: flex;
    list-style: none;
    margin:0px;
    padding:0px;

    li{
      margin-right:9px;
      .csl__flag{
        display:block;
      }
    }
  }
  .csl__more-counter{
    .csl__more-counter-link{
      display: flex;
      align-items:center;
    }
  }
}
</style>