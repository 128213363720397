<template>
  <div class="d-main">
    <div class="sidebar">
      <ul>
        <li v-for="page in pages">
          <v-link
              :href="page.url"
              v-text="page.name"
              class="link"
              :class="currentPage === page ? 'is-active' : ''"
          />
        </li>
      </ul>
    </div>
    <div class="content">
      <v-head-title class="title" v-text="currentPage.name"/>
      <component :is="currentPage.component"/>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import DReadme from "./DReadme.vue";
import DButtons from "./DButtons.vue";
import VHeadTitle from "../Base/VHeadTitle.vue";
import VLink from "../Base/VLink.vue";
import DVBaseOther from "./DVBaseOther.vue";
import DColorTransparent from "./DColorTransparent.vue";

const url = ref('/docs');

const pages = [
  {url: '/docs', component: DReadme, name: 'Main',},
  {url: '/docs/buttons', component: DButtons, name: 'Buttons',},
  {url: '/docs/vbase-other', component: DVBaseOther, name: 'VBase Other',},
  {url: '/docs/color-transparent', component: DColorTransparent, name: 'Color transparent',},
];

const currentPage = computed(() => {
  let page = pages.find(page => url.value === page.url);
  return page ? page : pages[0];
})

onMounted(() => {
  url.value = window.location.pathname;
});

</script>

<style lang="scss" scoped>
.d-main{
  @include flex(row,start,start, 20px);
  width:calc(100% - 40px);
  margin:20px;

  .sidebar{
    width:200px;
    border-right:1px solid $dark-30;
    min-height:calc(100vh - 40px);
    flex-shrink:0;
    ul{
      @include flex(column,start,start,6px);
      list-style:none;
      margin:0;
      padding:0;
      .link.is-active{
        color: $dark-100;
      }
    }
  }
  .content{
    @include flex(column,start,stretch,24px);
    width:100%;
  }
}
</style>