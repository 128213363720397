import {createStore} from "vuex";
import {popperInstancesModule} from "./popperInstancesModule";
import {resizeObserverModule} from "./resizeObserverModule";
import {checklistsWidgetModule} from "./checklistsWidgetModule";

export default createStore({
    modules: {
        popperInstances: popperInstancesModule,
        gResizeObserver: resizeObserverModule,
        checklistsWidget: checklistsWidgetModule
    }
})