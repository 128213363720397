<template>
  <div class="v-breadcrumbs vb" v-if="items.length > 0" :class="mainClass">
      <ul class="vb__ul">
        <li class="vb__li" v-for="(item,index) in items">
          <v-link
              class="vb__link"
              v-if="index < items.length - 1"
              :href="item.url"
              v-text="item.title"
          />
          <span class="vb__icon-arrow" v-if="index < items.length - 1">
            <v-icon-svg class="vb__icon-arrow-svg" name="arrow-right"/>
          </span>
          <span
              class="vb__text"
              v-if="index === items.length - 1"
              v-text="item.title"
          />
        </li>
      </ul>
  </div>
</template>

<script setup lang="ts">
import {computed, defineProps} from 'vue';
import VIconSvg from "./VIconSvg.vue";
import VContainer from "./VContainer.vue";
import VLink from "./VLink.vue";

const props = defineProps({
  items: {type: Array, default: []},
  noPadding: {type:Boolean,default:false,},
});

const mainClass = computed(() => {
  return props.noPadding ? 'vb--no-padding' : '';
});

</script>

<style scoped>
.v-breadcrumbs.vb{
  padding-top:20px;
  padding-bottom:20px;

  &.vb--no-padding{
    padding-top:0px;
    padding-bottom:0px;
    @media(max-width:991px){
      padding-top:16px;
    }
  }

  .vb__ul{
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    list-style: none;
    gap:6px;
    margin:0px;
    padding:0px;
  }
  .vb__li{
    display: flex;
    align-items: center;
  }
  .vb__link{

  }
  .vb__icon-arrow{
    margin-left:6px;
  }
  .vb__icon-arrow-svg{

  }
  .vb__text{

  }
}
</style>