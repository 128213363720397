<template>
  <div
      class="v-form-input-counter no-select vfic"
      :class="mainClass"
  >
    <div class="minus" @click="counterMinus()">
      <v-icon-svg name="blue-minus"/>
    </div>

    <input
        type="text"
        v-model="model"
        :style="counterStyle"
        @focusout="inputFocusOut"
        @focusin="inputFocusin"
        @keydown="inputKeydown"
    >

    <div class="plus" @click="counterPlus()">
      <v-icon-svg name="blue-plus"/>
    </div>
  </div>
</template>

<script setup>
import VIconSvg from "../Base/VIconSvg.vue";
import {computed, inject, ref, watch} from "vue";

const props = defineProps({
  min: {type:Number, default: null},
  max: {type:Number, default: null},
  hideBackground: {type:Boolean,default:false},
  isError: {type:Boolean,default:false},
  hasMinWidth: {type:Boolean, default:false},
  disabled: {type:Boolean, default:false,},
})

const hideBackgroundComputed = computed(() => {
  return props.hideBackground && !inputIsFocus?.value;
});

const model = defineModel();

const modelLength = computed(() => {
  if(model.value?.toString()?.length){
    return model.value?.toString()?.length;
  }else{
    return 0;
  }
});

const counterStyle = computed(() => {
  if(props.hasMinWidth){
    return {minWidth:modelLength.value+'ch'};
  }else{
    return {width:modelLength.value+'ch'};
  }

});

const counterCorrect = () => {
  if(model.value === ''){
    return;
  }
  let v = parseInt(model.value);
  if(isNaN(v) && props.min){
    model.value = props.min;
  }else if(isNaN(v)){
    model.value = 1;
  }
}

const counterCorrectMinMax = () => {

  let v = parseInt(model.value);
  if(isNaN(v) && props.min){
    model.value = props.min;
  }else if(isNaN(v)){
    model.value = 1;
  }
  v = parseInt(model.value);
  if(props.min!==null && (v < props.min)){
    model.value = props.min;
  }
  if(props.max!==null && (v > props.max)){
    model.value = props.min;
  }
}
const counterMinus = () => {
  counterCorrect();
  let v = parseInt(model.value);
  if(props.min!==null && v <= props.min){
    return;
  }
  model.value--;
}
const counterPlus = () => {
  counterCorrect();
  let v = parseInt(model.value);
  if(props.max!==null && v >= props.max){
    return;
  }
  model.value = v+1;
}

watch(() => model.value, () => {
  counterCorrect();
});

const mainClass = computed(() => {
  let classes = [];
  if(model.value == props.min){
    classes.push('vfic--disabled-minus');
  }
  if(model.value == props.max){
    classes.push('vfic--disabled-plus');
  }
  if(hideBackgroundComputed.value){
    classes.push('vfic--hide-bg');
  }
  if(props.isError) {
    classes.push('vfic--is-error');
  }
  if(props.hasMinWidth) {
    classes.push('vfic--has-min-width');
  }
  if(props.disabled){
    classes.push('vfic--disabled');
  }
  return classes;
});

const inputIsFocus = ref(false);
const inputFocusOut = () => {
  inputIsFocus.value = false;
  counterCorrectMinMax();
}
const inputFocusin = () => {
  inputIsFocus.value = true;
}

const inputKeydown = (event) => {
  // Разрешаем следующие клавиши:
  // 8 - Backspace
  // 9 - Tab
  // 46 - Delete
  // 37-40 - Стрелки
  // 48-57 - Цифры верхнего ряда
  // 96-105 - Цифры на NumPad
  // 110 - Decimal point
  // 190 - Decimal point на NumPad
  let allowedKeys = [
    8,                       // 8 - Backspace
    9,                       // 9 - Tab
    46,                      // 46 - Delete
    37, 38, 39, 40,          // 37-40 - Стрелки
    48, 49, 50, 51, 52,
    53, 54, 55, 56, 57,      // 48-57 - Цифры верхнего ряда
    96, 97, 98, 99, 100,
    101, 102, 103, 104, 105, // 96-105 - Цифры на NumPad
    //110,                     // 110 - Decimal point
    //190                      // 190 - Decimal point на NumPad
  ];

  // Разрешаем нажатия клавиш только если они являются цифрами или допустимыми клавишами
  if (!(event.key >= '0' && event.key <= '9') && !allowedKeys.includes(event.keyCode)) {
    event.preventDefault();
  }

}

</script>

<style lang="scss" scoped>
.v-form-input-counter.vfic{
  width:min-content;
  display: flex;
  align-items: stretch;
  border:1px solid #D6E3EF;
  border-radius: 4px;
  background: #fff;
  overflow: hidden;
  height: 36px;
  transition-property: opacity;
  transition-duration: 0.2s;

  &.vfic--disabled{
    position:relative;
    opacity:0.5;
    &:after{
      content:'';
      display:block;
      position:absolute;
      width:100%;
      height:100%;
      top:0px;
      left:0px;
    }
  }

  &.vfic--is-error{
    border:1px solid #F2001D;
  }

  input{
    min-width:20px;
    text-align: center;
    width:1ch;
    flex-grow:2;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid transparent;
    transition-duration:0.2s;
    background-color: #fff;
    box-sizing:content-box;

    &:focus{
      box-shadow: none;
      outline:0px;
      border: 1px solid #1C7EE0
    }

    &:hover:not(:focus){
      background-color: #E8F2FB;
    }

  }
  .plus,.minus{
    width:24px;
    flex-shrink:0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    background-color: #fff;
    transition-duration:0.2s;
    &:hover{
      background-color: #E8F2FB;
    }
  }

  &.vfic--disabled-plus{
    .plus{
      opacity:0.3;
      background-color: #fff;
      cursor:initial
    }
  }
  &.vfic--disabled-minus{
    .minus{
      opacity:0.3;
      background-color: #fff;
      cursor:initial
    }
  }

  &.vfic--hide-bg{
    border-color:transparent;
    background: transparent;
    input{background:transparent;}
    .plus,.minus{
      opacity:0;
    }
  }

}
</style>