<template>
  <div class="lb-projects-preview lbpp" @click="clickPreview" :class="mainClass">

    <div class="lbpp__head">

      <div class="lbpp__title-section">
        <div class="lbpp__title">
          ID: {{projectStore.uniquekey}}
        </div>
        <div class="lbpp__mark lbpp__mark--is-deleted" v-if="projectStore.is_deleted">Deleted</div>
        <div class="lbpp__mark lbpp__mark--inactive" v-if="!projectStore.is_deleted && !projectStore.is_active">Inactive</div>
      </div>
      <div class="lbpp__project-name" :class="projectStore.optimizationRatingPercent!==null ? 'lbpp__project-name--show-rating' : ''">
        {{projectStore.name}}
      </div>
      <div class="lbpp__project-keyword">
        Keyword: {{projectStore.keyword}}
      </div>
      <div class="lbpp__project-link-wrap">
        <v-link class="lbpp__project-link" :href="projectStore.correctUrlLink" target="_blank">{{projectStore.shortUrl}}</v-link>
      </div>

    </div>

    <div class="lbpp__footer">

      <div class="lbpp__count-backlinks">
        <div class="lbpp__count-backlinks-text-wrap">
          <div class="lbpp__count-backlinks-text">
            Backlinks:
          </div>
          <div class="lbpp__count-backlinks-value">
            {{projectStore.countAllOrders}}
          </div>
        </div>
        <v-link-plus :href="projectStore.link+'#create-new-backlinks'" class="lbpp__count-backlinks-add"/>
      </div>
      <v-button
          tag="a"
          color="blue2-stroke"
          class="lbpp__button-add-backlinks"
          text="Settings"
          :href="projectStore.link"
      />

    </div>

    <v-circle-state
        v-if="projectStore.optimizationRatingPercent!==null"
        :color="projectStore.optimizationRatingType"
        :value="projectStore.optimizationRatingPercent"
        size="small"
        class="lbpp__rating-circle"
    />

  </div>
</template>

<script setup>
import {useLinkBuildProject} from "../../stores/useLinkBuilding";
import VLink from "../Base/VLink.vue";
import VButton from "../Base/VButton.vue";
import VCircleState from "../Base/VCircleState.vue";
import VLinkPlus from "../Base/VLinkPlus.vue";
import {computed} from "vue";

const props = defineProps({
  projectUniquekey: {},
});

const projectStore = useLinkBuildProject(props.projectUniquekey);

function clickPreview(event){
  let target = event.target;

  // Проверяем все родительские элементы до блока, чтобы найти ссылку с атрибутом href
  while (target && target !== this) {
    if (target.tagName === 'A' && target.hasAttribute('href')) {
      return; // Не выполняем основную функцию
    }
    target = target.parentNode;
  }

  window.location.href=projectStore.link;
}

const mainClass = computed(() => {
  if(!projectStore.is_deleted && !projectStore.is_active){
    return 'lbpp--inactive';
  }else if(projectStore.is_deleted){
    return 'lbpp--deleted';
  }else{
    return '';
  }
})

</script>

<style lang="scss" scoped>
@mixin no-wrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:100%;
}

.lb-projects-preview.lbpp{
  background-color: #fff;
  padding:16px;
  box-shadow: 0px 2px 0px 0px #5988DB0F;
  border: 1px solid transparent;
  border-radius: 8px;
  display:flex;
  flex-direction: column;
  gap:20px;
  align-items:stretch;
  justify-content:space-between;
  position:relative;
  transition-duration: 0.2s;
  cursor: pointer;
  &:hover{
    @media(min-width: 768px){
      border: 1px solid #1C7EE0
    }
  }

  .lbpp__head{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content:flex-start;
    flex-shrink:0;
    gap:8px;
  }
  .lbpp__footer{
    border-top: 1px solid #D6E3EF80;
    padding-top:10px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    flex-shrink:0;
  }
  .lbpp__title-section{
    display:flex;
    align-items:center;
    gap:8px;
  }

  .lbpp__mark{
    padding: 0px 4px;
    border-radius: 4px;
    border: 1px solid;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    text-align:center;
    text-transform: uppercase;
    &.lbpp__mark--inactive{
      border-color:#C70B0B;
      color: #C70B0BB2;
    }
    &.lbpp__mark--is-deleted{
      border-color:#798CAE;
      color: #596C8FB3;
    }
  }

  .lbpp__title{
    color: #596C8FB3;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    @include no-wrap-ellipsis;
  }
  .lbpp__project-name{
    color:#384967;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    overflow-wrap: break-word;
    @include no-wrap-ellipsis;
    &.lbpp__project-name--show-rating {
      max-width:calc(100% - 42px);
    }
  }
  .lbpp__project-keyword{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color:#596C8F;
    @include no-wrap-ellipsis;
  }
  .lbpp__project-link-wrap{
    .lbpp__project-link{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
    @include no-wrap-ellipsis;
    color:#1C7EE0;
  }

  .lbpp__count-backlinks{
    display:flex;
    gap:10px;
    align-items:center;
    .lbpp__count-backlinks-text-wrap{
      display:flex;
      gap:9px;
      align-items:center;
      .lbpp__count-backlinks-text{
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color:#798CAE;
      }
      .lbpp__count-backlinks-value{
        font-size: 17px;
        font-weight: 600;
        color:#384967;
      }
    }

  }
  .lbpp__button-add-backlinks{
    padding:0px 7px;
    height:32px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }

  .lbpp__rating-circle{
    position:absolute;
    top:10px;
    right:10px;
  }

  &.lbpp--deleted{
    .lbpp__project-name,
    .lbpp__project-keyword,
    .lbpp__project-link-wrap,
    .lbpp__count-backlinks,
    .lbpp__head
    {
      opacity:0.5;
    }
    .lbpp__count-backlinks-add{
      display:none;
    }
    .lbpp__rating-circle{
      display:none
    }
  }

  &.lbpp--inactive{
    .lbpp__project-name,
    .lbpp__project-keyword,
    .lbpp__project-link-wrap,
    .lbpp__count-backlinks
    {
      opacity:0.5;
    }
    .lbpp__count-backlinks-add{
      display:none;
    }
    .lbpp__rating-circle{
      display:none
    }
  }

}
</style>