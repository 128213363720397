<template>
  <v-container class="bs-page-single">

    <v-breadcrumbs :items="breadcrumbs" class="scp__project-breadcrumbs" no-padding/>

    <v-white-container class="container" without-padding>
      <v-white-container class="container-info" only-padding>

        <div class="header">
          <v-head-title v-if="!isMobile">
            Subscription & Bonus
          </v-head-title>
          <div class="meta-info">
            <div class="uniqueid">{{useSubscription.id}}</div>
            <div class="date">Started: {{formatDate(useSubscription.createdDate)}}</div>
          </div>
        </div>

        <div class="content">
          <div class="packages-title">
            Credits included in the subscription
            <hr>
          </div>
          <v-bs-packages class="packages">
            <template v-for="product in useSubscription.products">
              <v-bs-package :size="'+'+shortNumber(product.quantity)" :name="product.name"/>
            </template>
          </v-bs-packages>
          <hr>
          <div class="total">
            Monthly Payment:
            <span>${{useSubscription.amount}}</span>
          </div>
        </div>

      </v-white-container>
      <v-white-container class="container-bonus" only-padding>

        <div v-if="useSubscription.hasBonuses" class="header">

          <template v-for="(bonuses,key) in useSubscription.allBonuses">
            <div class="left" :class="'max-rows-'+useSubscription.maxCountBonuses" v-show="activeSliderColumn === key-1">

              <div class="promo-title">
                <v-icon-present/>
                <span v-auto-tooltip data-name="next-payment-bonus">
                  Payment Bonus {{paymentBonusMonth(key)}}
                </span>
              </div>

              <v-bs-packages class="promo-packages">
                <v-bs-package
                    v-for="bonus in bonuses"
                    :size="'+'+shortNumber(bonus.quantity)"
                    :name="bonus.name"
                />
              </v-bs-packages>

            </div>
          </template>


          <div class="right">

            <v-bs-slider-bonus-chart
                class="slider"
                :count-fill="useSubscription.countCollectedBonuses"
                :date-start="useSubscription.createdDate"
                v-model="activeSliderColumn"
            />

          </div>
        </div>

        <hr v-if="useSubscription.hasBonuses && !isMobile"/>

        <div class="footer">
          <v-button color="green" @click.prevent="refPopupBonus.openModal()" v-if="useSubscription.hasBonuses">
            I want a bigger Bonus
          </v-button>
          <v-button color="green-stroke" @click.prevent="keepAndRedirect()">
            {{useSubscription.hasBonuses ? 'Keep the Subscription & Bonus active' : 'Keep the Subscription'}}
          </v-button>
          <v-button color="green-cancel" @click.prevent="refCancelModalConfirm.confirm()">
            {{useSubscription.hasBonuses ? 'Cancel Bonus & Subscription' : 'Cancel the Subscription'}}
          </v-button>
        </div>

        <v-modal ref="refPopupBonus" reset-default-params>
          <v-bs-popup-bonus :subscription-id="useSubscription.id" @i-want-bigger-bonus="keepAndRedirect()"/>
        </v-modal>

        <v-modal-confirm ref="refCancelModalConfirm" @confirm="cancelSubscription">
          <template v-slot:title>
            Cancel Bonus & Subscription
          </template>
          <template v-slot:description>
            Are you sure you want to cancel the subscription and its bonuses?
          </template>
          <template #group-buttons="groupButtons">
            <div class="modal-confirm-group-buttons">
              <v-button
                  color="red"
                  @click="groupButtons.apply()"
                  :loading="groupButtons.loading"
                  class="modal-confirm-group-buttons__button"
              >Yes</v-button>
              <v-button
                  color="green"
                  @click="groupButtons.cancel()"
                  class="modal-confirm-group-buttons__button"
              >No</v-button>
            </div>
          </template>
        </v-modal-confirm>

      </v-white-container>
    </v-white-container>

  </v-container>
</template>

<script setup>

import VContainer from "../Base/VContainer.vue";
import VBreadcrumbs from "../Base/VBreadcrumbs.vue";
import {computed, getCurrentInstance, ref, watch} from "vue";
import VWhiteContainer from "../Base/VWhiteContainer.vue";
import VHeadTitle from "../Base/VHeadTitle.vue";
import VBsPackages from "../Base/BillingSubscriptions/VBsPackages.vue";
import VIconPresent from "../Base/VIconPresent.vue";
import VButton from "../Base/VButton.vue";
import VBsSliderBonusChart from "../Base/BillingSubscriptions/VBsSliderBonusChart.vue";
import VModal from "../Base/VModal.vue";
import VBsPopupBonus from "../Base/BillingSubscriptions/VBsPopupBonus.vue";
import {useBillingSubscription, useBillingSubscriptions} from "../../stores/useBillingSubscriptions";
import {useRoutes} from "../../stores/useRoutes";
import {formatDate, shortNumber} from "../../utils/utils";
import VBsPackage from "../Base/BillingSubscriptions/VBsPackage.vue";
import VModalConfirm from "../Base/VModalConfirm.vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const refPopupBonus = ref();
const refCancelModalConfirm = ref();

const activeSliderColumn = ref(0);

const breadcrumbs = computed(() => {
  return [
    {
      title: 'Subscriptions & Bonuses',
      url:useRoutes().url('subscriptions'),
    },
    {
      title: useSubscription.id,
    }
  ]
});

const useSubscription = useBillingSubscription(useRoutes().getUrlLastSection());
useSubscription.download();

watch(() => useSubscription.nextBonusNumber, (nextBonusNumber) => {
  activeSliderColumn.value = nextBonusNumber-1;
},{immediate:true,});

function keepAndRedirect(){
  refPopupBonus.value.closeModal();
  window.location.href = useRoutes().url('subscriptions');
}

async function cancelSubscription(){
  let [status,message] = await useSubscription.cancel();
  if(status){
    window.location.href = useRoutes().url('subscriptions');
    refCancelModalConfirm.value.closeWithSuccess();
  }else{
    refCancelModalConfirm.value.closeWithError(message);
  }
}

function paymentBonusMonth(n){
  n = parseInt(n);
  const options = { month: 'short', year: '2-digit' };
  const date = new Date(useSubscription.createdDate);
  date.setMonth(date.getMonth() + n);
  return date.toLocaleDateString('en-US', options);
}

</script>

<style lang="scss" scoped>
.bs-page-single{
  padding-bottom:100px!important;

  @include flex(column,start,stretch,32px);

  @media(max-width:767px){
    gap:20px;
  }

  .container{
    .container-info{
      @include flex(column, start,stretch, 30px);

      @media(max-width:767px) {
        @include flex(column, start,stretch, 20px);
        padding-top:20px!important;
        padding-bottom:30px!important;
      }

      .header{
        @include flex(column, start,stretch, 20px);
      }

      .content{
        @include flex(column, start,stretch, 24px);
        max-width:480px;

        @media(max-width: 767px){
          @include flex(column, start,stretch, 16px);
        }

        hr{
          opacity:1;
          border-top-color:#EEF2FB;
          margin:0px;
          padding:0px;
        }
      }

      .meta-info{
        @include flex(row,start,center,16px);
        color:#798CAE;
        @media(max-width:767px){
          @include flex(column,start,start,2px);
        }
      }

      .packages-title{
        @include flex(column,start,stretch,12px);
        color:#AEB7C7;
      }

      .packages{
        gap:12px;
        color:#384967;
      }

      .total{
        @include flex(row,start,center,10px);
        color:#384967;
        span{
          font-weight:600;
        }
      }

    }
    .container-bonus{
      @include flex(column,start,stretch,30px);
      border-top-left-radius:0px;
      border-top-right-radius:0px;
      background-color:#F3FAF3;
      color:#16731A;

      @media(max-width: 767px){
        @include flex(column,start,stretch,16px);
      }

      .header{
        @include flex(row,start,start,80px);
        @media(max-width:767px){
          @include flex(column,start,stretch,16px);
        }
        .left{
          @include flex(column,start,start,11px);
          width:300px;
          &.max-rows-1{ .promo-packages{min-height:calc(16.8px * 1 + 10px * (1 - 1));} }
          &.max-rows-2{ .promo-packages{min-height:calc(16.8px * 2 + 10px * (2 - 1));} }
          &.max-rows-3{ .promo-packages{min-height:calc(16.8px * 3 + 10px * (3 - 1));} }
          &.max-rows-4{ .promo-packages{min-height:calc(16.8px * 4 + 10px * (4 - 1));} }
          &.max-rows-5{ .promo-packages{min-height:calc(16.8px * 5 + 10px * (5 - 1));} }
          &.max-rows-6{ .promo-packages{min-height:calc(16.8px * 6 + 10px * (6 - 1));} }
          @media(max-width: 767px){
            width:initial;
            gap:16px;
          }
        }
        .right{}
      }

      .footer{
        @include flex(row,start,center,13px);
        @media(max-width:767px){
          @include flex(column,start,stretch,13px);
        }
      }

      hr{
        opacity:1;
        border-top-color:#E4F8E5;
        margin:0px;
        padding:0px;
      }

      .promo-title{
        @include flex(row,start,center,11px);
        span{
          @include flex(row,start,center,6px);
        }
      }

      .slider{
        width:304px;
        @media(max-width:767px){
          width:100%;
        }
      }

    }
  }
}

.modal-confirm-group-buttons{
  @include flex(row,center,center,10px);
  .modal-confirm-group-buttons__button{
    width:74px;
    height:40px;
  }
}

</style>