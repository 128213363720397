<template>
  <div class="sc-project-add-form scpaf">

    <v-form-loading-lock v-if="loadingForm"/>

    <div class="scpaf__website">

      <v-form-group-inline
          data-name="is-demo"
          :errors="errs.errors?.is_demo"
          :keep-space-for-question="someHasQuestion"
          ref="refGroupInlineIsDemo"
      >
        <template v-slot:default>
          <v-form-select-inline
              placeholder="Type"
              v-model="fields.is_demo"
              :options="[{value:true,name:'Demo',},{value:false,name:'Normal',},]"
              :is-error="!!errs.errors?.is_demo"
              :has-empty-option="false"
              :enable-search="false"
          />
        </template>
      </v-form-group-inline>

      <v-form-group-inline
          data-name="website"
          :errors="websiteErrors"
          :keep-space-for-question="someHasQuestion"
          ref="refGroupInlineWebsite"
      >
        <template v-slot:default>
          <v-form-input-inline
              placeholder="Website"
              :required="true"
              v-model="fields.website"
              :is-error="!!websiteErrors"
              :loading="useCheckWebsite().loading"
          />
        </template>
      </v-form-group-inline>

    </div>

    <div class="scpaf__top">

      <v-form-group-inline
          data-name="search-engine"
          :errors="errs.errors?.search_engines_id"
          :keep-space-for-question="someHasQuestion"
          ref="refGroupInlineSearchEngine"
      >
        <template v-slot:default>
          <v-form-select-inline
              placeholder="Search Engine"
              v-model="fields.search_engine"
              :options="useStaticData().searchEnginesForSelect"
              :is-error="!!errs.errors?.search_engines_id"
              :disabled="contentBlocked"
              :has-empty-option="false"
              :enable-search="false"
          />
        </template>
      </v-form-group-inline>

    </div>

    <div class="scpaf__middle">
      <v-form-label data-name="keyword" class="flex-shrink-0">
        Keyword
      </v-form-label>
      <sc-form-group-keywords
          :website="fields.website"
          :errors="errs.errors?.keywords"
          ref="refScFormGroupKeywords"
          :disabled="contentBlocked"
      />
    </div>

    <div class="scpaf__bottom">

      <v-form-group-wrap :disabled="contentBlocked">

        <v-form-group :is-one-line="true">
          <v-form-checkbox-slider v-model="fields.device_targeting_enabled"/>
          <v-form-label data-name="device-targeting">
            Device Targeting
            <v-form-caption :is-inline="true">(+1 credit per click)</v-form-caption>
          </v-form-label>
        </v-form-group>

        <v-transition-slide-hide :show="fields.device_targeting_enabled">
          <v-form-group>
            <v-form-group-inline errors="">
              <template v-slot:default>
                <v-form-select-inline
                    placeholder="Device"
                    v-model="fields.device"
                    :options="[{name: 'Desktop', value: 'desktop'},{name: 'Mobile', value: 'mobile'},]"
                    :is-error="false"
                    :has-empty-option="false"
                    :disabled="contentBlocked"
                    :enable-search="false"
                />
              </template>
            </v-form-group-inline>
          </v-form-group>
        </v-transition-slide-hide>

      </v-form-group-wrap>

      <v-form-group-wrap :disabled="contentBlocked">
        <v-form-group :is-one-line="true">
          <v-form-checkbox-slider v-model="fields.daily_limit_enabled"/>
          <v-form-label data-name="daily-limit">Daily limit</v-form-label>
          <div style="height: 0px;" class="d-flex align-items-center">
            <v-form-input-counter
                v-if="fields.daily_limit_enabled"
                v-model="fields.max_clicks_per_day"
                :min="1"
                style="margin-left:20px;"
            />
          </div>
        </v-form-group>

      </v-form-group-wrap>

      <v-form-message-error :message-error="errs.message" class="scpaf__error-message"/>

      <v-button class="scpaf__button-submit" color="blue2" @click.prevent="create" :loading="loadingForm" :is-bold="true" :disabled="contentBlocked">
        Create project
      </v-button>

    </div>

  </div>
</template>

<script setup>

import VFormInputInline from "../VForm/VFormInputInline.vue";
import VFormGroupInline from "../VForm/VFormGroupInline.vue";
import VFormSelectInline from "../VForm/VFormSelectInline.vue";
import VFormLabel from "../VForm/VFormLabel.vue";
import ScFormGroupKeywords from "./ScFormGroupKeywords.vue";
import VFormCheckboxSlider from "../VForm/VFormCheckboxSlider.vue";
import VFormGroupWrap from "../VForm/VFormGroupWrap.vue";
import VFormGroup from "../VForm/VFormGroup.vue";
import VTransitionSlideHide from "../Base/VTransitionSlideHide.vue";
import VFormCaption from "../VForm/VFormCaption.vue";
import VFormInputCounter from "../VForm/VFormInputCounter.vue";
import VFormMessageError from "../VForm/VFormMessageError.vue";
import VButton from "../Base/VButton.vue";
import {useSeoClicksProject} from "../../stores/useSeoClicks";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {useCheckWebsite, useStaticData} from "../../stores/useStaticData";
import VFormLoadingLock from "../VForm/VFormLoadingLock.vue";
import {useSuggestKeywords} from "../../stores/useSuggestKeywords";
import {useRoutes} from "../../stores/useRoutes";

const fields = reactive({
  website: '',
  geo_targeting_enabled: false,
  daily_limit_enabled: false,
  max_clicks_per_day: 1,
  search_engine: null,
  device_targeting_enabled: false,
  is_demo: {value: true, name: 'Demo',},
  device: {value: 'desktop', name: 'Desktop',},
});

const currentRegionCode = ref(null);

onMounted(() => {
  if(window.preloadData.hasOwnProperty('website') && window.preloadData.website!==null && window.preloadData.website.length > 0){
    fields.website = window.preloadData.website;
    currentRegionCode.value = window.preloadData.currentRegionCode;
  }
  if(window.preloadData.hasOwnProperty('keyword') && window.preloadData.keyword!==null && window.preloadData.keyword.length > 0){
    useSuggestKeywords().addSelectedKeyword({name: window.preloadData.keyword, countryCode: window.preloadData.region});
  }
});

const errs = useSeoClicksProject().errors.create;

const websiteErrors = computed(() => {

  if(useCheckWebsite().errorMessage){
    return useCheckWebsite().errorMessage;
  }else if(useSeoClicksProject().errors.create.errors){
    return null;
  }

});

const refScFormGroupKeywords = ref();

const loadingForm = ref(false);

const searchEnginesForSelectStop = watch(() => useStaticData().searchEnginesForSelect, (searchEnginesForSelect) => {
  if(searchEnginesForSelect.length > 0){
    fields.search_engine = searchEnginesForSelect[0];
    searchEnginesForSelectStop();
  }
},{immediate: true,})

const create = () => {
  if(loadingForm.value){return;}
  loadingForm.value = true;

  useSeoClicksProject().create({
    url: fields.website,
    keywords: useSuggestKeywords().selectedKeywordsForSend,
    daily_limit_enabled: fields.daily_limit_enabled,
    daily_limit: fields.max_clicks_per_day,
    device_targeting_enabled: fields.device_targeting_enabled,
    device: fields.device?.value,
    search_engines_id: fields.search_engine?.value,
    geo_targeting_enabled: fields.geo_targeting_enabled,
    is_demo: fields.is_demo?.value,
  }).then((response) => {
    let [status, data] = response;
    if(status) {
      window.location.href = useRoutes().url('seo-clicks.get', {id: data.uniquekey})
    }
    loadingForm.value = false;
  })
}

const contentBlocked = ref(true);

/** Отслеживаем изменение поля website, делаем загрузку данных с сервера */
watch(() => [fields.website, fields.is_demo], ([website, isDemo], [oldWebsite, oldIsDemo]) => {
  contentBlocked.value = true;

  if(website.trim().length === 0){ useCheckWebsite().$reset(); return; }

  if(website!==oldWebsite){

    setTimeout(() => {
      if(website === fields.website){
        checkWebsite(website, isDemo?.value);
      }
    },1000);

  }else if(isDemo!==oldIsDemo){

    checkWebsite(website, isDemo?.value);

  }

});

async function checkWebsite(website, isDemo){
  let [status] = await useCheckWebsite().check(website, isDemo);
  if(status){
    if(currentRegionCode.value !== null){
      refScFormGroupKeywords.value.updateSuggestedKeywords();
      currentRegionCode.value = null;
    }else{
      refScFormGroupKeywords.value.updateSuggestedKeywords();
    }
    contentBlocked.value = false;
  }else{
    contentBlocked.value = true;
  }
}

const refGroupInlineIsDemo = ref();
const refGroupInlineWebsite = ref();
const refGroupInlineSearchEngine = ref();

const someHasQuestion = computed(() => {
  return [
    refGroupInlineIsDemo,
    refGroupInlineWebsite,
    refGroupInlineSearchEngine
  ].some(i => {
    return i.value?.reallyHasQuestion === true;
  });
});

</script>

<style lang="scss" scoped>
.sc-project-add-form.scpaf{
  display:flex;
  flex-direction:column;
  gap:20px;
  position: relative;
  .scpaf__top, .scpaf__website, .scpaf__bottom{
    display:flex;
    flex-direction:column;
    gap:20px;
    max-width:50%;
    max-width:calc(50% - 10px);
    @media(max-width:767px){
      max-width:100%;
    }
  }
  .scpaf__button-submit{
    height:44px;
  }
  .scpaf__error-message{
    margin:0px;
  }

  &.scpaf__blocked{
    .scpaf__top, .scpaf__middle, .scpaf__bottom{
      position:relative;
      &:after{
        content:'';
        display:block;
        position:absolute;
        width:100%;
        height:100%;
        background:#fff;
        opacity:0.5;
        top:0px;
        left:0px;
      }
    }
  }

}
</style>