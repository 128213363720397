<template>
  <table class="sc-project-keywords scpks" :class="(useKeywords._checkboxTriState>0) ? 'is-checked' : ''" v-show="useKeywords.isDownloaded && useKeywords.countShowedIds > 0">
    <thead class="scpks__header">
      <tr>
        <th class="scpks__header-checkbox-col">
          <v-form-checkbox-tri-state :value="useKeywords._checkboxTriState" @change-request="useKeywords.tristateUpdate"/>
        </th>
        <template v-if="useKeywords._checkboxTriState===0">
          <th class="scpks__header-keyword-col">
            Keyword
          </th>
          <th class="scpks__header-recommendations-col">
            Recommendations
          </th>
          <th class="scpks__header-chart-col"></th>
          <th class="scpks__header-dayli-limit-col">
            Daily limit
          </th>
          <th class="scpks__header-delete-col"></th>
          <th class="scpks__header-status-col"></th>
        </template>
        <template v-else>
          <th class="scpks__header-filter-col" colspan="6">

            <div class="scpks__filter">
              <div class="scpks__filter-selected">
                <v-form-checkbox-tri-state :value="useKeywords._checkboxTriState" @change-request="useKeywords.tristateUpdate" class="scpks__filter-selected-checkbox"/>
                Selected: <span>{{(useKeywords.countChecked === useKeywords.countShowedIds) ? 'All' : useKeywords.countChecked}}</span>
              </div>
              <div class="scpks__filter-form">
                <div class="scpks__filter-link">
                  <v-link @click.prevent="pauseAll">Pause</v-link>
                </div>
                <div class="scpks__filter-link">
                  <v-link @click.prevent="playAll">Resume</v-link>
                </div>
                <div class="scpks__filter-link">
                  <v-link @click.prevent="deleteModalConfirm.confirm()">Delete</v-link>
                </div>
                <div class="scpks__filter-change-daily-limit">
                  <span class="desktop-label">Change daily limit</span>
                  <span class="mobile-label"><span>Daily</span><span>limit</span></span>
                  <v-form-input-number-edit
                      :value="eachDailyLimit"
                      type="events"
                      :filter-data="(val) => {return val >= 0;}"
                      ref="inputDailyLimit"
                      @save="changeDailyLimit"
                  />
                </div>
              </div>
            </div>

          </th>
        </template>
      </tr>
    </thead>
    <tbody class="scpks__body">
      <template v-for="showedId in useKeywords.keywordsShowedIds" :key="showedId.id">
        <sc-project-keyword
            :data-id="showedId.id"
            v-show="showedId.is_show"
            :keywordId="showedId.id"
            :projectId="projectId"
        />
      </template>
    </tbody>
  </table>
  <div class="sc-project-keywords-loading" v-if="!useKeywords.isDownloaded">
    <div class="inner">
      <div class="icon">
        <v-loader-ship color-line-one="#1C7EE080" color-line-two="#1C7EE0" class="svg"/>
      </div>
      <div class="text">Keywords loading ....</div>
    </div>
  </div>
  <div class="sc-project-keywords-notfound" v-if="useKeywords.isDownloaded && useKeywords.countShowedIds === 0">
    Nothing find on your request
  </div>
  <v-modal-confirm ref="deleteModalConfirm" @confirm="deleteAll">
    <template v-slot:title>
      Delete keywords
    </template>
    <template v-slot:description>
      Do you really want to delete delete the selected keywords ? <br>The selected keywords will be lost
    </template>
  </v-modal-confirm>
</template>

<script setup lang="ts">

import ScProjectKeyword from "./ScProjectKeyword.vue";
import {ref, watch} from "vue";
import VFormCheckboxTriState from "../VForm/VFormCheckboxTriState.vue";
import VLink from "../Base/VLink.vue";
import VFormInputNumberEdit from "../VForm/VFormInputNumberEdit.vue";
import {useSeoClicksKeywords} from "../../stores/useSeoClicks.js";
import VLoaderShip from "../Base/VLoaderShip.vue";
import VModalConfirm from "../Base/VModalConfirm.vue";

const props = defineProps({
  projectId:{},
});

const useKeywords = useSeoClicksKeywords(props.projectId);

watch(() => useKeywords._listChecked, () => {
  useKeywords.updateTriStateCheckbox();
},{deep:true});

const updateKeywords = () => {
  useKeywords.download();
}

defineExpose({updateKeywords});

const pauseAllLoading = ref(false);
const pauseAll = async () => {
  if(pauseAllLoading.value){return;}
  pauseAllLoading.value = true;
  await useKeywords.updateIsActive(useKeywords.getSelectedIds(), 0);
  pauseAllLoading.value = false;
}

const playAllLoading = ref(false)
const playAll = async () => {
  if(playAllLoading.value){return;}
  playAllLoading.value = true;
  await useKeywords.updateIsActive(useKeywords.getSelectedIds(), 1);
  playAllLoading.value = false;
}

const deleteModalConfirm = ref();
const deleteAll = async () => {
  let [status,message] = await useKeywords.updateIsDeleted(useKeywords.getSelectedIds(),true);
  if(status){
    deleteModalConfirm.value.closeWithSuccess();
  }else{
    deleteModalConfirm.value.closeWithError(message);
  }
}

const inputDailyLimit = ref();
const eachDailyLimit = ref(1);
const changeDailyLimit = async (value) => {
  let [status,message] = await useKeywords.updateDailyLimit(useKeywords.getSelectedIds(), value);
  if(status){
    inputDailyLimit.value.saveWithSuccess();
  }else{
    inputDailyLimit.value.saveWithError();
  }
}

</script>

<style scoped lang="scss">
.sc-project-keywords.scpks{
  width:100%;

  .scpks__header{
    tr{
      > th{
        padding-top:16px;
        padding-bottom:16px;
        border-top:1px solid #D6E3EF80;
        border-bottom:1px solid #D6E3EF80;
        color:#0000004D;
        align-content: center;
        vertical-align: middle;
        font-weight: 400;
      }

      .scpks__header-checkbox-col{
        padding-left:30px;
      }
    }
    
    @media (max-width: $mobile-width) {
      &.scpks__header{
        display:block;
      }
    }
    
  }
  .scpks__body{
    width:100%;
    @media(max-width: $mobile-width){
      display:block;
    }
  }

  &.is-checked{
      .scpks__header {
        tr {
          > th {
            padding-top:8px;
            padding-bottom:8px;
            background: #F4F9FD;
            border-top-color: transparent;
            border-bottom: 1px solid #D6E3EF80
          }
        }
      }
  }

  .scpks__filter{
    display: flex;
    align-items: center;
    gap:33px;

    .scpks__filter-selected{
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      color:#0B1424;

      span{
        display: inline-block;
        min-width: 19px;
      }

      .scpks__filter-selected-checkbox{
        @media (min-width:$mobile-width-for-min-width) {
          display: none;
        }
      }
    }
    .scpks__filter-form{
      display: flex;
      align-items: center;
      gap:26px;

      .scpks__filter-link{

      }
      .scpks__filter-change-daily-limit{
        display: flex;
        align-items: center;
        gap:10px;

        > span{
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color:#0B1424;
        }
        .mobile-label{
          display:none;
        }
      }
    }
  }

  @media(max-width:$mobile-width){
    display: block;
    tr,th{
      display: block;
    }
    .scpks__header-checkbox-col,
      .scpks__header-keyword-col,
      .scpks__header-recommendations-col,
      .scpks__header-chart-col,
      .scpks__header-dayli-limit-col,
      .scpks__header-delete-col,
      .scpks__header-status-col {display:none;}
    .scpks__header{
      border-top: 1px solid #D6E3EF80;
    }
    .scpks__header-filter-col{
      padding:0px;
    }
    .scpks__filter{
      flex-direction: column;
      align-items: flex-start;
      gap:14px;
      padding:16px;
      .scpks__filter-selected{
        display: flex;
        align-items: center;
        gap:10px;
      }
      .scpks__filter-link{
        .none-mobile{display: none;}
      }
      .scpks__filter-change-daily-limit{
        justify-content: flex-end;
        flex-grow:2;

        .mobile-label{
          display: flex!important;
          flex-direction: column;
          align-items: flex-start;
          text-transform: uppercase;
          font-size: 9px!important;
          font-weight: 500!important;
          line-height: 11px!important;
          text-align: left!important;
          color: #131D30!important;
          opacity:0.5;
        }
        .desktop-label{
          display: none;
        }
      }
      .scpks__filter-form{
        width:100%;
        gap:16px;
      }
    }

  }

  &.is-checked{
    @media(max-width: $mobile-width){
      .scpks__header{
        border-top:none;
        display:block;
        tr {
          > th {
            padding-top:0px;
            padding-bottom:0px;
          }
        }
      }
    }
  }

}
.sc-project-keywords-loading{
  background-color: #F7FAFF;
  padding:117px 0px;
  margin-left:30px;
  margin-right:30px;

  .inner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .icon{
      .svg{}
      margin-bottom:10px;
    }
    .text{
      color:#596C8F;
      text-align: center;
    }
  }
}
.sc-project-keywords-notfound{
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display:flex;
  justify-content: center;
  align-items:center;
  height:100px;
  border-top: 1px solid #D6E3EF80;
  color: #00000080;
}
</style>