import axios from "axios";

/**
 * Класс, для простого хранения и получения данных с сервера
 *
 * Умеет обрабатывать поле data, в случае успеха
 * Умеет обрабатывать как ошибки сгенерированные laravel, так и сгенерированные логически с ответом 200
 *
 * Пример использования:
 *
 * let data = new ApiResource({url: '/api/path/to'});
 *
 * data.download(); || data.downloadAsync();
 *
 * data.isFirstDownloadAttempt();
 * data.isDownloading(); || data.isDownloaded(); || data.isSuccess(); || data.isError();
 *
 * data.dataRaw
 * data.data
 * data.errorMessage
 * data.errorErrors
 *
 */
export class ApiResource{

    /**
     * Создаем ресурс
     * @param {object} axiosPropertiesDefault - необязательные предопределенные данные для отправки axios запроса
     * @param {string} axiosPropertiesDefault.url - Url адрес, по которому получаем данные с сервера
     * @param {*} defaultData - Данные в data, которые будут до загрузки с севера, по умолчанию null
     */
    constructor(axiosPropertiesDefault = {}, defaultData = null){

        this.reset();

        /**
         * Ответ по умолчанию на запрос data
         */
        this.defaultData = defaultData

        this.defaultProperties = axiosPropertiesDefault;

    }

    reset(){
        /**
         * Отражает текущий статус загрузки
         * 0 - запрос загрузки не производился
         * 1 - успешно загружено
         * 2 - возникли ошибки при загрузке
         * @type {number}
         */
        this.downloadStatus = 0;

        /**
         * В процессе загрузки
         * @type {boolean}
         */
        this._downloading = false;

        /**
         * Ответ с сервера
         */
        this._axiosData = null;
    }

    /**
     * Вернуть data из успешного ответа сервера
     * Если поле data не передано или ответ с ошибкой, то возвращает defaultData
     */
    get data(){
        if(
            this.isSuccess()
            && this.dataRaw instanceof Object
            && this.dataRaw.hasOwnProperty('data')
        ){
            return this.dataRaw.data;
        }else{
            return this.defaultData;
        }
    }

    /**
     * Возвращает ответ с сервера без каких либо преобразований (только JSON преобразование)
     */
    get dataRaw(){
        if(this._axiosData instanceof Object && this._axiosData.hasOwnProperty('data')){
            return this._axiosData.data;
        }else{
            return null;
        }
    }

    /**
     * Вернуть message из ответа с сервера, если сервер отдал ошибку
     * Если поле message нет, или ответ с сервера не является ошибкой, то вернет null
     */
    get errorMessage(){
        if(
            this.isError()
            && this.dataRaw instanceof Object
            && this.dataRaw.hasOwnProperty('message')
        ){
            return this.dataRaw.message;
        }else{
            return null;
        }
    }

    /**
     * Возвращает поле errors из ответа сервера, если сервер отдал ошибку
     * Как правило поле является или объектом, или массивом
     * Если ответ не является ошибкой, или поле errors отсутствует, то вернет []
     */
    get errorErrors(){
        if(
            this.isError()
            && this.dataRaw instanceof Object
            && this.dataRaw.hasOwnProperty('errors')
        ){
            return this.dataRaw.errors;
        }else{
            return null;
        }
    }

    /**
     * Данные с сервера загружены?
     * @returns {boolean}
     */
    isDownloaded(){
        return (this.downloadStatus === 1 || this.downloadStatus === 2) && !this.isDownloading();
    }

    isDownloading(){
        return this._downloading;
    }

    /**
     * Возникли ошибки при загрузке с сервера? (не отслеживается, если ошибка не была подтверждена кодом с сервера)
     * @returns {boolean}
     */
    isError(){
        return this.downloadStatus === 2;
    }

    /**
     * Сейчас первая попытка загрузки с сервера?
     * @returns {boolean}
     */
    isFirstDownloadAttempt(){
        return this.downloadStatus === 0;
    }

    /**
     * Данные успешно загружены?
     * @returns {boolean}
     */
    isSuccess(){
        return this.downloadStatus === 1;
    }

    /**
     * Установить статус загрузки - начало загрузки
     */
    setDownloadStart(){
        this._downloading = true;
    }

    /**
     * Установить статус загрузки - получено успешно
     */
    setDownloadEndSuccess(responseSuccess){
        this._axiosData = responseSuccess;

        if(
            this.dataRaw instanceof Object
            && this.dataRaw.hasOwnProperty('success')
            && this.dataRaw.success === false
        ){
            this.downloadStatus = 2;
        }else{
            this.downloadStatus = 1;
        }
        this._downloading = false;
    }

    /**
     * Установить статус загрузки - произошла ошибка
     */
    setDownloadEndError(responseError){
        this._axiosData = responseError.response;
        this.downloadStatus = 2;
        this._downloading = false;
    }

    /**
     * Начать загрузку с сервера
     * @param {object} params - параметры конфигурации запроса axios
     */
    download(params = {}){

        if(this.isDownloading()){return;}

        this.setDownloadStart();

        let axiosParams = Object.assign(this.defaultProperties,params);

        axios(axiosParams)
            .then(this.setDownloadEndSuccess.bind(this))
            .catch((responseError)=>{
                this._axiosData = responseError.response;
                this.downloadStatus = 2;
                this._downloading = false;
            });

    }

    /**
     * Начать загрузку с сервера (доступны методы then и await)
     * @param {object} params - параметры конфигурации запроса axios
     */
    async downloadAsync(params= {}){

        if(this.isDownloading()){return;}

        this.setDownloadStart();

        let axiosParams = Object.assign(this.defaultProperties,params);

        try{
            const response = await this.customAxios(axiosParams)
            this.setDownloadEndSuccess(response);
            return [this.isSuccess(), this.data];
        }catch(error){
            this.setDownloadEndError(error);
            return [this.isSuccess(), this.errorMessage, this.errorErrors];
        }

    }

    async customAxios(axiosParams){

        if(window.preloadData[axiosParams.url] && (!axiosParams.method || axiosParams.method.toLowerCase() === 'get')){
            if(!axiosParams.hasOwnProperty('forcedDownloadFromServer') && axiosParams.forcedDownloadFromServer !== true){
                let response = window.preloadData[axiosParams.url];
                delete window.preloadData[axiosParams.url];
                return {data:response};
            }else{
                delete window.preloadData[axiosParams.url];
            }
        }

        return await axios(axiosParams);

    }

    static hasPreloadData(url){
        return !!window.preloadData[url];
    }

}