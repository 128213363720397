<template>
  <div class="v-container" :class="mainClasses">
    <slot/>
  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  mobileNoPadding: {default:false,type:Boolean}
});

const mainClasses = computed(() => {
  let classes = [];
  if(props.mobileNoPadding){
    classes.push('v-container-mobile-no-padding');
  }
  return classes;
});

</script>

<style lang="scss" scoped>
.v-container{
  padding:0px 30px;
  max-width:980px;
  &:not(.v-container-mobile-no-padding){
    @media(max-width: $mobile-width){
      padding: 0px 16px;
    }
  }
  &.v-container-mobile-no-padding{
    @media(max-width: $mobile-width){
      padding: 0px 0px;
    }
  }
}
</style>