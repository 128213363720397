export class UrlManipulator {
    /**
     * @param url - url над которым будут производиться манипуляции. Обязательно должен присутствовать host. В противном случае могут быть ошибки или будет подставлен текущий host
     */
    constructor(url) {
        this.url = url;
    }

    getDefaultOrigin(){
        return window.location.origin;
    }

    withOutProtocol() {
        this.url = this.url?.replace(/^(https?:)?\/\//, '');
        this.url = this.url?.replace(/^:\/\//, '');
        return this;
    }

    addHttps() {
        this.withOutProtocol();
        this.url = 'https://' + this.url;
        return this;
    }

    addHttp() {
        this.withOutProtocol();
        this.url = 'http://' + this.url;
        return this;
    }

    static extractProtocol(url) {
        let match = url?.match(/^(https?:)?\/\//) || url?.match(/^:\/\//);
        return match ? match[0] : '';
    }

    withOutWww() {
        let protocol = UrlManipulator.extractProtocol(this.url);
        this.url = this.url?.replace(protocol, '');
        this.url = this.url?.replace(/^www\./, '');
        this.url = protocol + this.url;
        return this;
    }

    addWww() {
        let protocol = UrlManipulator.extractProtocol(this.url);
        this.url = this.url?.replace(protocol, '');
        this.url = this.url?.replace(/^www\./, '');
        this.url = protocol + 'www.' + this.url;
        return this;
    }

    removeTrailingSlashes() {
        this.url = this.url?.replace(/\/+$/, '');
        return this;
    }

    /**
     * Добавить get параметры в текущий url
     * !если нет hostname/protocol/port, то будут добавлены текущие
     * @param {Object} getParams
     */
    addGetParams(getParams){
        let url = new URL(this.url, this.getDefaultOrigin());
        Object.entries(getParams).forEach(([key, value]) => {
            url.searchParams.append(key,value);
        })
        this.url = url.toString();
        return this;
    }

    /**
     * @typedef {Object} getUrlParams
     * @property {boolean} [withoutDomain] - без protocol, без domain, только path и все что после него.
     */
    /**
     * @param {getUrlParams} params
     */
    getUrl(params = {}) {
        if(params?.withoutDomain === true){
            let url = new URL(this.url, this.getDefaultOrigin());
            return url.pathname + url.search + url.hash;
        }else{
            return this.url;
        }
    }
}

function runTests() {
    const testCases = [
        {
            url: 'https://yandex.ru///',
            expected: {
                withOutProtocol: 'yandex.ru///',
                addHttps: 'https://yandex.ru///',
                addHttp: 'http://yandex.ru///',
                withOutWww: 'https://yandex.ru///',
                addWww: 'https://www.yandex.ru///',
                removeTrailingSlashes: 'https://yandex.ru'
            }
        },
        {
            url: 'http://yandex.ru///',
            expected: {
                withOutProtocol: 'yandex.ru///',
                addHttps: 'https://yandex.ru///',
                addHttp: 'http://yandex.ru///',
                withOutWww: 'http://yandex.ru///',
                addWww: 'http://www.yandex.ru///',
                removeTrailingSlashes: 'http://yandex.ru'
            }
        },
        {
            url: '://yandex.ru///',
            expected: {
                withOutProtocol: 'yandex.ru///',
                addHttps: 'https://yandex.ru///',
                addHttp: 'http://yandex.ru///',
                withOutWww: '://yandex.ru///',
                addWww: '://www.yandex.ru///',
                removeTrailingSlashes: '://yandex.ru'
            }
        },
        {
            url: 'https://www.yandex.ru///',
            expected: {
                withOutProtocol: 'www.yandex.ru///',
                addHttps: 'https://www.yandex.ru///',
                addHttp: 'http://www.yandex.ru///',
                withOutWww: 'https://yandex.ru///',
                addWww: 'https://www.yandex.ru///',
                removeTrailingSlashes: 'https://www.yandex.ru'
            }
        },
        {
            url: 'http://www.yandex.ru///',
            expected: {
                withOutProtocol: 'www.yandex.ru///',
                addHttps: 'https://www.yandex.ru///',
                addHttp: 'http://www.yandex.ru///',
                withOutWww: 'http://yandex.ru///',
                addWww: 'http://www.yandex.ru///',
                removeTrailingSlashes: 'http://www.yandex.ru'
            }
        },
        {
            url: '://www.yandex.ru///',
            expected: {
                withOutProtocol: 'www.yandex.ru///',
                addHttps: 'https://www.yandex.ru///',
                addHttp: 'http://www.yandex.ru///',
                withOutWww: '://yandex.ru///',
                addWww: '://www.yandex.ru///',
                removeTrailingSlashes: '://www.yandex.ru'
            }
        },
        {
            url: 'yandex.ru///',
            expected: {
                withOutProtocol: 'yandex.ru///',
                addHttps: 'https://yandex.ru///',
                addHttp: 'http://yandex.ru///',
                withOutWww: 'yandex.ru///',
                addWww: 'www.yandex.ru///',
                removeTrailingSlashes: 'yandex.ru'
            }
        },
        {
            url: 'www.yandex.ru///',
            expected: {
                withOutProtocol: 'www.yandex.ru///',
                addHttps: 'https://www.yandex.ru///',
                addHttp: 'http://www.yandex.ru///',
                withOutWww: 'yandex.ru///',
                addWww: 'www.yandex.ru///',
                removeTrailingSlashes: 'www.yandex.ru'
            }
        }
    ];

    testCases.forEach(({ url, expected }) => {
        let manipulator = new UrlManipulator(url);
        console.log(`Testing URL: ${url}`);

        let result = manipulator.withOutProtocol().getUrl();
        console.log(`withOutProtocol: ${result}`);
        console.assert(result === expected.withOutProtocol, `Expected: ${expected.withOutProtocol}, but got: ${result}`);

        manipulator = new UrlManipulator(url);
        result = manipulator.addHttps().getUrl();
        console.log(`addHttps: ${result}`);
        console.assert(result === expected.addHttps, `Expected: ${expected.addHttps}, but got: ${result}`);

        manipulator = new UrlManipulator(url);
        result = manipulator.addHttp().getUrl();
        console.log(`addHttp: ${result}`);
        console.assert(result === expected.addHttp, `Expected: ${expected.addHttp}, but got: ${result}`);

        manipulator = new UrlManipulator(url);
        result = manipulator.withOutWww().getUrl();
        console.log(`withOutWww: ${result}`);
        console.assert(result === expected.withOutWww, `Expected: ${expected.withOutWww}, but got: ${result}`);

        manipulator = new UrlManipulator(url);
        result = manipulator.addWww().getUrl();
        console.log(`addWww: ${result}`);
        console.assert(result === expected.addWww, `Expected: ${expected.addWww}, but got: ${result}`);

        manipulator = new UrlManipulator(url);
        result = manipulator.removeTrailingSlashes().getUrl();
        console.log(`removeTrailingSlashes: ${result}`);
        console.assert(result === expected.removeTrailingSlashes, `Expected: ${expected.removeTrailingSlashes}, but got: ${result}`);

        console.log('-----------------------------------');
    });

    console.log("All tests completed.");
}