<template>
  <div class="bc-backlink-quantity bcbq">
    <div class="bcbq__list" v-if="useCreditType(creditTypeId)?.firstPlanOptions?.packagesSortedByPrice.length > 0">

      <bc-choose-package
          v-for="pack in useCreditType(creditTypeId)?.firstPlanOptions?.packagesSortedByPrice.slice(0,-1)"
          :key="pack.id"
          class="bcbq__item"
          :plan-options-id="useCreditType(creditTypeId)?.firstPlanOptions.id"
          :plan-options-package-id="pack.id"
          @add-to-cart-click="emit('add-to-cart-click')"
      />
      <bc-choose-package
          :key="useCreditType(creditTypeId)?.firstPlanOptions?.packagesSortedByPrice.at(-1).id"
          class="bcbq__item"
          :plan-options-id="useCreditType(creditTypeId)?.firstPlanOptions.id"
          :plan-options-package-id="useCreditType(creditTypeId)?.firstPlanOptions?.packagesSortedByPrice.at(-1).id"
          @add-to-cart-click="emit('add-to-cart-click')"
          is-custom
      />

    </div>
  </div>
</template>

<script setup>

import BcChoosePackage from "./BcChoosePackage.vue";
import {useCreditType, useCreditTypes} from "../../stores/useBilling";
import {computed} from "vue";

const props = defineProps({
  typeConstant: {},
});

const emit = defineEmits('add-to-cart-click')

const creditTypeId = computed(() => {
  let response = useCreditTypes().creditTypesBacklink.find((item) => {
    return item.firstPlanOptions.typeConstantBacklink === props.typeConstant
  });
  return response?.id;
});

</script>

<style lang="scss" scoped>
.bc-backlink-quantity.bcbq{
  .bcbq__item{
    &:last-child{
      border-bottom:0px;
    }
  }
}
</style>