import axios from "axios";

export const checklistsWidgetModule = {
    namespaced: true,
    state: {
        checklists: null,
        loaded: false,
        completedPercent: 0,
        finished: false
    },
    mutations: {
        setChecklists(state, checklists) {
            state.checklists = checklists;
        },
        setLoaded(state, loaded) {
            state.loaded = loaded;
        },
        setCompletedPercent(state, completedPercent) {
            state.completedPercent = completedPercent;
        },
        setFinished(state, finished) {
            state.finished = finished;
        },
    },
    actions: {
        async fetchChecklists({commit}) {
            try {
                let response = await axios.get('/api/user/checklists');
                let checklists = response.data.data[0];

                if (checklists) {
                    let completedPercent = 100 / checklists.count * checklists.countCompleted;

                    commit('setChecklists', checklists);
                    commit('setLoaded', true);
                    commit('setCompletedPercent', completedPercent);
                    commit('setFinished', checklists.finished === 1 || checklists.countNotCompleted === 0);
                }
            } catch (e) {
                console.log('Error loading checklists');
                console.log(e);
            }
        },
        async setDismissedChecklists({state, commit}, id) {
            try {
                await axios.put('/api/user/checklists/' + id + '/set-dismissed');
            } catch (e) {
                console.log('Error set dismissed checklists');
                console.log(e);
            }
        },
        async startTaskExecutionChecklists({state, commit}, taskCode) {
            try {
                await axios.post('/api/user/checklists/start-task-execution', {"taskCode": taskCode});
            } catch (e) {
                console.log('Error start task execution');
                console.log(e);
            }
        },
        async checkCompletedTasks() {
            try {
                await axios.post('/api/user/checklists/check-completed-tasks');
            } catch (e) {
                console.log('Error check completed tasks');
                console.log(e);
            }
        }
    }
}
