<template>
  <recommendation-keyword
      v-if="useRecommendations.hasRecommendation && useKeyword.is_top100"
      :text="useRecommendations.mainRecommendation.message"
      :degree="useRecommendations.mainRecommendation.degree"
  />
  <recommendation-top100
      v-else-if="!useKeyword.is_top100"
      :keyword-id="keywordId"
      :project-id="projectId"
  />
</template>

<script setup>

import RecommendationTop100 from "../Blocks/RecommendationTop100.vue";
import RecommendationKeyword from "../Blocks/RecommendationKeyword.vue";
import {useSeoClicksKeywordsRecommendations, useSeoClicksKeyword} from "../../stores/useSeoClicks";

const props = defineProps({
  projectId: {},
  keywordId: {},
});

const useKeyword = useSeoClicksKeyword(props.projectId,props.keywordId);
const useRecommendations = useSeoClicksKeywordsRecommendations(props.keywordId);

</script>

<style lang="scss" scoped>

</style>