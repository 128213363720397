<template>
  <div data-can-be-question="custom-by-name" :data-name="dataName" class="v-form-string-select vfss" :class="mainClass">
    <div class="vfss__string" @click.prevent="tooltipToggle" ref="link">
      <span>
        <slot name="default"/>
      </span>
      <span class="vfss__string-icon">
        <v-icon-svg name="arrow-down-blue" class="vfss__string-icon-svg" v-if="!loading"/>
        <v-loader-ship
            class="vfss__string-icon-svg vfss__string-icon-svg--loader"
            v-if="loading"
            color-line-one="#1C7EE080" color-line-two="#1C7EE0"
        />
      </span>
    </div>
    <v-tooltip-question-icon v-if="_hasQuestion" :tooltip-data="_tooltipData"/>
    <v-tooltip-select
        ref="tooltip"
        v-model="model"
        :get-linked-block="() => link"
        :options="options"
        :style="{maxWidth: '216px'}"
        v-bind="tooltipOptions"
        :close-after-selection="true"
        @change="change"
    />
  </div>
</template>

<script setup>

import VIconSvg from "../Base/VIconSvg.vue";
import VTooltipSelect from "../Base/VTooltipSelect.vue";
import {computed, ref, watch} from "vue";
import VLoaderShip from "../Base/VLoaderShip.vue";
import VTooltipQuestionIcon from "../Base/VTooltipQuestionIcon.vue";
import useTooltipHelpers from "../../utils/useTooltipHelpers";

const props = defineProps({
  options: {},
  tooltipOptions: {},
  value:{},
  hasQuestion: {},
  tooltipData: {},
  dataName: {},
});

const {_hasQuestion, _tooltipData} = useTooltipHelpers(
    'custom',
    props.dataName,
    '',
    props.hasQuestion,
    props.questionDescription
);

const model = ref();
watch(() => props.value, () => {
  model.value = props.value;
},{immediate:true});
const emit = defineEmits(['save']);


const link = ref();
const tooltip = ref();
const loading = ref(false);

const tooltipToggle = () => {
  if(!loading.value) {
    tooltip.value.toggle()
  }
}

const mainClass = computed(() => {
  let classes = [];
  if(loading.value){
    classes.push('vfss--loading');
  }
  return classes;
});

const change = (option) => {
  loading.value = true;
  emit('save',option);
}

const saveWithSuccess = () => {
  loading.value = false;
}

const saveWithError = (message) => {
  loading.value = false;
}

defineExpose({saveWithSuccess,saveWithError});

</script>

<style scoped lang="scss">
.v-form-string-select.vfss{

  @include flex(row,start,center, 10px);

  .vfss__string{
    cursor: pointer;
    display: flex;
    align-items:center;
  }
  .vfss__string-icon{
    display: flex;
    width:14px;
    height:14px;
    align-items:center;
    margin-left:4px;

    .vfss__string-icon-svg--loader{
      display: flex;
      position: relative;
      width: 14px;
      height: 14px;
      flex-shrink: 0;
    }
  }

  &.vfss--loading{
    cursor: default;
  }

}
</style>