<template>
  <div class="lb-project-recommendation">

    <div class="difficulty">

      <div class="difficulty__title" data-name="keyword-difficulty" v-auto-tooltip>
        <span>Keyword difficulty <b>{{backlinksRecommendations.keywordDifficultyLabel}}</b></span>
      </div>

      <div class="difficulty__visually">
        <v-lb-difficulty-chart-line :value="backlinksRecommendations.keyword_difficulty"/>
        <div
            class="difficulty__visually-number"
            v-text="backlinksRecommendations.keyword_difficulty"
            :style="{color:backlinksRecommendations.color,}"
        />
      </div>

      <div class="difficulty__text">
        ± {{backlinksRecommendations.count_domains}} backlinks to rank in the Top 10
      </div>
      <div class="difficulty__text" v-if="false">
        <span class="difficulty__link-other-keyword">Take a look at other keyword</span>
      </div>

    </div>

    <hr class="hr"/>

    <div class="recommended">
      <div class="recommended__title">
        We recommend you to buy such backlinks
      </div>
      <div class="recommended__content">
        <v-lb-recommended-backlinks-bagel
            class="recommended__bagel"
            :ratio-number-links="backlinksRecommendations.recommendations"
            :amount-links="backlinksRecommendations.totalCountBacklinks"
        />
        <v-lb-recommended-backlinks-list
            class="recommended__list"
            :count-links="backlinksRecommendations.recommendationsCount"
        />
      </div>
    </div>

    <div class="footer">
      <hr class="hr">
      <div class="total-recommended">
        <div class="total-recommended__title">
          Monthly total:
        </div>
        <div class="total-recommended__amount">
          {{backlinksRecommendations.totalCountBacklinks}}
          {{pluralize('backlink',backlinksRecommendations.totalCountBacklinks)}}
        </div>
      </div>
    </div>


  </div>
</template>

<script setup>

import VLbRecommendedBacklinksBagel from "../Base/LinkBuilding/VLbRecommendedBacklinksBagel.vue";
import VLbRecommendedBacklinksList from "../Base/LinkBuilding/VLbRecommendedBacklinksList.vue";
import {pluralize} from "../../utils/utils";
import {useBacklinksRecommendations} from "../../stores/useLinkBuilding";
import VLbDifficultyChartLine from "../Base/LinkBuilding/VLbDifficultyChartLine.vue";

const props = defineProps({
  projectUniquekey: {},
});

const backlinksRecommendations = useBacklinksRecommendations(props.projectUniquekey);

</script>

<style lang="scss" scoped>
.lb-project-recommendation{
  @include flex(column,start,stretch,24px);
  padding: 24px;
  width:100%;
  border-radius: 4px;
  background:#F7FAFF;
  
  @media (max-width:767px) {
    @include flex(column,start,stretch,20px);
    padding: 12px;
  }

  .hr{
    margin:0px;
    border:none;
    border-bottom: 1px solid #0000000D;
    opacity:1;
  }

  .footer{
    @include flex(column,start,stretch,24px);
    @media(max-width:767px){
      @include flex(column,start,stretch,12px);
      padding-bottom:12px;
    }
  }

  .difficulty{
    @include flex(column,start,stretch,10px);

    @media(max-width:767px){
      @include flex(column,start,stretch,8px);
    }

    .difficulty__title{
      @include flex(row,start,center,8px);
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      b{
        font-weight:700;
      }
    }

    .difficulty__visually{
      @include flex(row,between,center,17px);
      .difficulty__visually-number{
        font-size: 30px;
        font-weight: 700;
        line-height: 18px;
      }
    }

    .difficulty__text{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color:#596C8F;
    }
    .difficulty__link-other-keyword{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #1C7EE0;
      border-bottom: 1px solid #1C7EE080;
    }

  }

  .recommended{
    @include flex(column,start,stretch,24px);

    @media(max-width:767px){
      @include flex(column,start,stretch,20px);
    }

    .recommended__content{
      @include flex(row,between,start,30px);
      @media(max-width:767px){
        @include flex(column,start,stretch,30px);
      }
    }

    .recommended__title{
      color:#798CAE;
      font-size: 14px;
      line-height: 18px;
    }

    .recommended__bagel{
      flex-shrink:0;
      width:133px;
      @media(max-width: 767px){
        margin-left:auto;
        margin-right:auto;
      }
    }
    .recommended__list{
      flex-grow:2;
    }
  }

  .total-recommended{
    @include flex(row,between,center);
    .total-recommended__title{
      font-size: 14px;
      line-height: 18px;
      color:#596C8F;
    }
    .total-recommended__amount{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #1C7EE0;
      border-bottom: 1px solid #1C7EE080;
    }
  }

}
</style>