<template>
  <div class="sc-projects-preview scpp">
    <div class="scpp__project_title">
      <v-link :href="useProject.linkProject">{{useProject.name}}</v-link>
    </div>
    <div class="scpp__data">

      <div class="scpp__controls">

        <div class="scpp__control scpp__control--keywords">
          <div class="scpp__control-column">
            <div class="scpp__control-title">
              Keywords
            </div>
            <div class="scpp__control-value">
              <span
                  v-if="useProject.count_keywords === 0"
                  v-text="useProject.count_keywords"
              />
              <v-link
                  v-if="useProject.count_keywords > 0"
                  :href="useProject.linkProject + '#keywords'"
                  v-text="useProject.count_keywords"
              />
              <v-link :href="useProject.linkProject + '#keywords'" class="scpp__control-value-link">
                <v-icon-svg name="icon_gear_wheel" class="scpp__control-value-link-svg"/>
              </v-link>
            </div>
          </div>
        </div>

        <div class="scpp__control scpp__control--countries">
          <div class="scpp__control-column">
            <div class="scpp__control-title">
              Countries
            </div>
            <div class="scpp__control-value">
              <span v-if="useProject?.countries?.length === 0">Not Selected</span>
              <countries-shor-list v-if="useProject?.countries?.length > 0" :country-codes="useProject?.countries"/>
            </div>
          </div>
        </div>

        <div class="scpp__control scpp__control--devices">
          <div class="scpp__control-column">
            <div class="scpp__control-title">
              Devices
            </div>
            <div class="scpp__control-value">
              <span>{{ useProject.deviceTypeLabel }}</span>
              <v-link :href="useProject.linkProject + '#settings'" class="scpp__control-value-link">
                <v-icon-svg name="icon_pencil_v2" class="scpp__control-value-link-svg"/>
              </v-link>
            </div>
          </div>
        </div>

        <div class="scpp__control scpp__control--recommendations" v-if="useProject?.recommendations?.length > 0">
          <div class="scpp__control-column">
            <div class="scpp__control-title">
              Recommendations
            </div>
            <div class="scpp__control-value">
              <recommendations-groups :link="useProject.linkProject + '#settings'" :recommendations="useProject?.recommendations"/>
            </div>
          </div>
        </div>

      </div>

      <div class="scpp__controls-right">
        <div class="scpp__chart">
          <chart-seo-clicks :chart="useProject.chart_data" class="scpp__chart-inner" :scale-hover="true"/>
        </div>
        <div class="scpp__switch">
          <v-form-checkbox-slider
              type="events"
              :value="useProject.status"
              @save="saveCheckboxStatus"
              ref="checkboxStatus"
          />
        </div>
      </div>

    </div>

  </div>
</template>

<script setup lang="ts">
import {defineProps, ref, watch} from 'vue';
import VLink from "../Base/VLink.vue";
import CountriesShorList from "../Blocks/CountriesShorList.vue";
import RecommendationsGroups from "../Blocks/RecommendationsGroups.vue";
import VFormCheckboxSlider from "../VForm/VFormCheckboxSlider.vue";
import ChartSeoClicks from "../Chart/ChartSeoClicks.vue";
import VIconSvg from "../Base/VIconSvg.vue";
import {useSeoClicksProject} from '../../stores/useSeoClicks.js';

const props = defineProps({
  projectId: {}
});

const useProject = useSeoClicksProject(props.projectId);

const checkboxStatus = ref();

const saveCheckboxStatus = async (isActive) => {
  let [status,message] = await useProject.saveIsActive(isActive);
  status ? checkboxStatus.value.saveWithSuccess() : checkboxStatus.value.saveWithError(message);
}

</script>

<style scoped lang="scss">
.sc-projects-preview.scpp{
  width:100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 14px 0px #1C7EE02E;
  margin-bottom:20px;
  padding: 30px 27px;
  max-width: 1000px;

  @media(max-width: $mobile-width){
    padding:20px 16px;
  }

  .scpp__project_title{
    font-size: 20px;
    font-weight: 600;
    line-height:24px;
    margin-bottom:20px;
    @media(max-width: $mobile-width){
      font-size:16px;
      line-height:20px;
      margin-bottom:16px;
    }
  }
  .scpp__data{
    display: flex;
    justify-content: space-between;
    @media(max-width:1200px){
      flex-wrap:wrap;
    }
  }



  .scpp__controls{
    display: flex;
    margin-right: 23px;

    @media(max-width:1200px){
      margin-right: 0px;
      margin-bottom: 20px;
    }

    @media(max-width: $mobile-width){
      flex-direction: column;
      margin-bottom: 16px;
      width:100%;
    }

    .scpp__control{
      padding-left:23px;
      padding-right:23px;
      flex-shrink: 0;
      position:relative;

      &:first-child{
        padding-left:0px;
      }
      &:last-child{
        padding-right:0px;
      }

      @media(max-width: $mobile-width){
        padding-left:0;
        padding-right:0;
      }

      @media(min-width: $mobile-width-for-min-width){
        &:after{
          content:'';
          display: block;
          width:1px;
          height:100%;
          background-color: #0000001A;
          position:absolute;
          right:0px;
          top:0px;
          @media(max-width: $mobile-width){
            display: none;
          }
        }

        &:last-child:after{
          display: none;
        }
      }

      .scpp__control-title{
        color:#00000080;
        margin-bottom:10px;
        display: flex;
        align-items: center;
      }
      .scpp__control-title-link, .scpp__control-value-link{
        margin-left: 6px;
        display: flex;
        width: 14px;
        height: 14px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
      }
      .scpp__control-value-link{
        display: flex;
        align-items:center;
        justify-content: center;
        .scpp__control-value-link-svg{
          display: block;
          width:14px;
          height:14px;
        }
      }

      .scpp__control-value{
        color:#00000080;
        display: flex;
        align-items: center;
        min-height:20px;
      }

      @media(min-width: $mobile-width-for-min-width){
        &.scpp__control--keywords{
          .scpp__control-column{width:85px;}
          .scpp__control-value{
            font-size: 16px;
            font-weight: 600;
          }
        }
        &.scpp__control--countries{
          .scpp__control-column{width:100px;}
        }
        &.scpp__control--devices{
          .scpp__control-column{width:110px;}
        }
        &.scpp__control--recommendations{
          flex-shrink: initial;
          .scpp__control-column{}
        }
      }

      @media(max-width: $mobile-width){
        .scpp__control-column{
          width:100%!important;
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
          gap:14px;
        }
        .scpp__control-title{
          margin-bottom:0px;
          .scpp__control-title-link{
            display: none;
          }
        }
        .scpp__control-value-link{

        }
        &:last-child{
          .scpp__control-column{
            margin-bottom: 0px;
          }
        }
      }


    }

  }
  .scpp__controls-right{
    display: flex;
    align-items: center;
    gap:14px;

    .scpp__chart-inner{
      width:161px;
      height:44px;
    }

    @media(max-width: 1150px){
      width:100%;
      .scpp__chart{
        width:100%;
        max-width:450px;
        .scpp__chart-inner{
          width:100%;
        }
      }
    }

    @media(max-width: $mobile-width){
      .scpp__chart{
        max-width:100%;
      }
    }



  }

}
</style>