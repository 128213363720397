<template>
  <div class="v-chart" :class="vChartClasses" ref="mainWrap">
    <div
        class="v-chart__inner"
        ref="innerWrap"
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >
      <canvas
          ref="canvas"
          class="v-chart__canvas"
      />
    </div>
  </div>
</template>

<script setup lang="ts">

import {computed, defineProps, onMounted, onUnmounted, PropType, ref, watch} from 'vue';
import {Chart as ChartInterface} from "../../interfaces/SeoClicksInterfaces";
import {prepareChartData} from "../../../../utils/panel-lib.js";
import Chart from 'chart.js/auto';

const props = defineProps({
  chart: {type: Object as PropType<ChartInterface>},
  chartSize: {type: String, default: 'mini' },
  noIfNull:{type:Boolean,default:false},
  scaleHover:{type:Boolean,default:false},
});

const canvas = ref();
let chartObj;
let ctx;

const hover = ref(false);
const mainWrap = ref();
const innerWrap = ref();

let oldWidth = null;
let oldHeight = null;
let newWidth = null;
let newHeight = null;

const clearWidths = () => {
  oldWidth = null;
  oldHeight = null;
  newWidth = null;
  newHeight = null;
  mainWrap.value.style.width = '';
  mainWrap.value.style.height = '';

  innerWrap.value.style.width='';
  innerWrap.value.style.minWidth='';
  innerWrap.value.style.height='';
  innerWrap.value.style.minHeight='';
}

if(props.scaleHover){
  watch(() => hover.value, () => {
    if(hover.value && windowWidth.value > 767){
      if(!oldWidth){
        oldWidth = innerWrap.value.offsetWidth + 'px';
        oldHeight = innerWrap.value.offsetHeight + 'px';
        newWidth = innerWrap.value.offsetWidth*2 + 'px';
        newHeight = innerWrap.value.offsetHeight*2 + 'px';
      }

      mainWrap.value.style.width = oldWidth;
      mainWrap.value.style.height = oldHeight;

      innerWrap.value.style.width=oldWidth;
      innerWrap.value.style.minWidth=oldWidth;
      innerWrap.value.style.height=oldHeight;
      innerWrap.value.style.minHeight=oldHeight;

      setTimeout(() => {
        innerWrap.value.style.width=newWidth;
        innerWrap.value.style.minWidth=newWidth;
        innerWrap.value.style.height=newHeight;
        innerWrap.value.style.minHeight=newHeight;
      },10);

    }else if(windowWidth.value > 767){
      mainWrap.value.style.width = oldWidth;
      mainWrap.value.style.height = oldHeight;

      innerWrap.value.style.width=oldWidth;
      innerWrap.value.style.minWidth=oldWidth;
      innerWrap.value.style.height=oldHeight;
      innerWrap.value.style.minHeight=oldHeight;

    }
  });
}

const windowWidth = ref();

const currentWindowSize = () => {
  windowWidth.value = window.innerWidth;
}

onMounted(() => {
  window.addEventListener('resize', clearWidths);
  window.addEventListener('resize', currentWindowSize);
  currentWindowSize();
  update();
});
onUnmounted(() => {
  window.removeEventListener('resize', clearWidths);
  window.removeEventListener('resize', currentWindowSize);
  currentWindowSize();
})

const vChartClasses = computed(() => {
  let r = [];
  if(props.chartSize === 'normal'){
    r.push('v-chart--normal');
  }
  if(nullableChart.value){
    r.push('v-chart--nullable');
  }
  if(props.scaleHover){
    r.push('v-chart--scale-hover');
  }
  return r;
});

const chartAreaBackgroundPlugin = {
  id: 'chartAreaBackgroundPlugin',
  beforeDraw(chart, args, options) {
    const {ctx, chartArea: {left, top, width, height}} = chart;
    ctx.save();
    ctx.fillStyle = options.backgroundColor; // Цвет фона, задается в опциях плагина
    ctx.fillRect(left, top, width, height);
    ctx.restore();
  }
};

const nullableChart = ref(false);

const update = (chart_data = null) => {
  if(chart_data === null){
    chart_data = props.chart;
  }
  if(!ctx){
    ctx = canvas.value.getContext('2d');
  }
  //if(props.noIfNull){
  //  if(chart_data.totalHits === 0){
  //    nullableChart.value = true;
  //    return
  //  }else{
  //    nullableChart.value = false;
  //  }
  //}
  if(chart_data){
    let chartConfig = prepareChartData(ctx, chart_data, props.chartSize);
    chartConfig.options.plugins.chartAreaBackgroundPlugin = {backgroundColor: '#f6fcf6'}
    chartConfig.plugins = [chartAreaBackgroundPlugin];
    if(false){
      let arr = [];
      for(let i = 0; i <=29; i++){arr.push([i,Math.floor(Math.random() * 11)])}
      chartConfig.data.datasets[0].data = arr;
    }

    if(!chartObj){
      chartObj = new Chart(ctx, chartConfig);
    }else{
      chartObj.data = chartConfig.data;
      chartObj.update();
    }
  }

}

defineExpose({update});

</script>

<style scoped lang="scss">
.v-chart{
  display:flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.3s;
  position: relative;
  z-index:5000;

  .v-chart__inner{
    width:100%;
    height:100%;
    border-radius: 4px;
    background-color: transparent;
    transition-duration: 0.3s;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  @media(min-width: 768px){
    &.v-chart--scale-hover{
      .v-chart__inner{
        &:hover{
          :hover{
            box-shadow: 0 8px 24px rgb(4 61 130 / 15%);
          }
        }
      }
      &:hover{
        z-index:5010;
      }
    }
  }


  &.v-chart--nullable .v-chart__inner{
    background-color: #1CC7230D;
  }

  canvas{
    width:100%!important;
    height: 100%;
    transition-duration: 0.3ms;
  }
}
</style>