import {defineStore} from "pinia";
import {ApiResource} from "../plugins/ApiExtends";

export const usePayBankTransfer = defineStore('pay-by-bank-transfer', {

    state: () => {
        return {
            windowActive: false,
            bankDetails: [],
            orders: [],
            totalAmount: {},
            userId: null,
            orderId: null,
        }
    },

    getters: {

        /**
         * Есть ли в текущем state order ?
         */
        hasOrder: (state) => {
            return state.bankDetails !== null;
        },

    },

    actions: {

        /**
         * передаем plan_id, добавляем новый ордер, сохраняем его данные в state
         */
        async pay(promoQuantity = 0){

            let resource = new ApiResource({
                url: '/api/v1/cart/pay/bank',
                method:'GET',
                params: {
                    promoQuantity: promoQuantity,
                }
            });
            await resource.downloadAsync();
            if(resource.isSuccess()){
                let data = {
                    bankDetails:resource.data.bankDetails,
                    orders:resource.data.orders,
                    totalAmount:resource.data.totalAmount,
                    userId:resource.data.userId,
                    orderId:resource.data.orderId,
                }
                this.$patch(data);

                return [true];
            }else{
                return [false, resource.errorMessage]
            }

        },

        /**
         * Открыть окно оплаты
         */
        openWindow(){
            this.$patch({windowActive: true});
        },

        /**
         * Закрыть окно оплаты
         */
        closeWindow(){
            this.$patch({windowActive: false});
        },

        /**
         * Очищаем state ордера
         */
        clearOrder(){

            let resetData = {
                bankDetails: [],
                orders: [],
                totalAmount: {},
                userId: null,
            };

            this.$patch(resetData);

        },

    }

});