<template>
  <div class="lb-add-project-info lbapi">
    <div class="lbapi__title">
      Tell us more about your website
    </div>
    <template v-for="item in info">
      <div class="lbapi__subtitle">{{item.title}}</div>
      <div class="lbapi__text">{{item.text}}</div>
    </template>
  </div>
</template>

<script setup lang="ts">

import {reactive} from "vue";

const info = reactive([
  {
    title: '1. What is this article trying to achieve?',
    text: 'This could be quite simple, such as “explain the benefits of this product in comparison to red widgets”.',
  },
  {
    title: '2. Who is this article aimed at? ',
    text: 'Extremely knowledgeable users? Non-technical management? Editors? All of them?',
  },
  {
    title: '3. Where is the audience in the buying cycle?',
    text: ' Is this the first time they’ll have encountered the technology? Or is the article aimed at getting them to click the “buy now” button?',
  },
  {
    title: '4. What are the most important concepts you want to get across? ',
    text: 'These could be benefits, or ideas, or specific messages.',
  },
  {
    title: '5. What are the search terms related to the article which you’d like it to appear for? ',
    text: 'Preferably these should be terms you’re not already ranking highly in Google for, and should be achievable (i.e. not too broad).',
  },
]);

</script>

<style scoped>
.lb-add-project-info.lbapi{
  background: #F7FAFF;
  padding:30px 20px;
  display:flex;
  flex-direction:column;
  gap:20px;
  border-radius: 4px;

  .lbapi__title{
    font-size: 16px;
    font-weight: 600;
    color: #384967;
  }

  .lbapi__subtitle{
    font-size: 14px;
    font-weight: 600;
    color: #596C8F;
  }

  .lbapi__text{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #798CAE;
  }

  @media(max-width:767px){
    padding:16px;
  }

}
</style>