<template>
  <div class="v-faq-list vfl" v-if="items.length > 0">
    <v-faq-list-item
        class="vfl__item"
        v-for="item in items"
        ref="itemRefs"
        :answer="item.answer"
        :question="item.question"
        @user-trigger="changeVisibleFaq"
    />
  </div>
</template>

<script setup>
import VFaqListItem from "./VFaqListItem.vue";
import {ref} from "vue";

const props = defineProps({
  /**
   * @type {{question:string,answer:string}[]}
   */
  items: {default:[]}
});

const itemRefs = ref([])

const changeVisibleFaq = (opened) => {
  let lastVal = opened.value;
  itemRefs.value.forEach((item) => {
    item.opened = false;
  })
  opened.value = lastVal;
}

</script>

<style lang="scss" scoped>

</style>