<template>
  <div class="d-color-transparent">
    <v-head-title :type="'h2'">Transparent (percent)</v-head-title>
    <table>
      <thead>
        <tr>
          <th>Left</th>
          <th>Right</th>
          <th>Need</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><input type="text" v-model="transparentLeft" disabled/></td>
          <td><input type="text" v-model="transparentRight" disabled/></td>
          <td><input type="text" v-model="transparentUser"/></td>
          <td><input type="text" v-model="transparentValue" disabled/></td>
        </tr>
      </tbody>

    </table>

    <v-head-title type="h2">Colors</v-head-title>
    <table>
      <thead>
        <tr>
          <th>Start</th>
          <th>Result</th>
        </tr>
      </thead>
      <tr>
        <td><input type="text" v-model="colorRed"></td>
        <td><input type="text" v-model="colorResultRed"></td>
      </tr>
      <tr>
        <td><input type="text" v-model="colorGreen"></td>
        <td><input type="text" v-model="colorResultGreen"></td>
      </tr>
      <tr>
        <td><input type="text" v-model="colorBlue"></td>
        <td><input type="text" v-model="colorResultBlue"></td>
      </tr>
    </table>
  </div>
</template>

<script setup>

import {computed, ref} from "vue";
import VHeadTitle from "../Base/VHeadTitle.vue";

const colorRed = ref('');
const colorGreen = ref('');
const colorBlue = ref('');

const colorResultRed = ref('');
const colorResultGreen = ref('');
const colorResultBlue = ref('');

const transparentLeft = ref(0);
const transparentRight = ref(100);
const transparentUser = ref();
const transparentValue = computed(() => {
  let t = parseInt(transparentUser.value);
  if(t.value < transparentLeft.value) return transparentLeft.value;
  if(t.value > transparentRight.value) return transparentRight.value;
  return t;
});

</script>

<style lang="scss" scoped>

</style>