<template>

  <tr class="sc-project-keyword-grid scpkg scpkg--desktop" :class="mainClasses" v-if="!isMobile">
    <td class="scpkg__checkbox-col"><slot name="checkbox"/></td>
    <td class="scpkg__keyword-col"><slot name="keyword"/></td>
    <td class="scpkg__recommendations-col"><slot name="recommendation"/></td>
    <td class="scpkg__chart-col"><slot name="chart"></slot></td>
    <td class="scpkg__daily-limit-col"><slot name="daily-limit"></slot></td>
    <td class="scpkg__delete-col"><slot name="delete"></slot></td>
    <td class="scpkg__status-col"><slot name="status"></slot></td>
  </tr>

  <div class="sc-project-keyword-grid scpkg scpkg--mobile" :class="mainClasses" v-else>

    <div class="scpkg__sidebar">
      <slot name="checkbox"/>
    </div>

    <div class="scpkg__header">
      <div class="scpkg__header-title">
        <slot name="keyword"/>
      </div>
      <div class="scpkg__header-delete">
        <slot name="delete"/>
      </div>
    </div>

    <div class="scpkg__content">
      <div class="scpkg__chart">
        <slot name="chart"/>
      </div>
      <div class="scpkg__daily-limit">
        <slot name="daily-limit"/>
      </div>
      <div class="scpkg__status">
        <slot name="status"/>
      </div>
    </div>

    <div class="scpkg__footer">
      <slot name="recommendation"/>
    </div>

  </div>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, onUnmounted, ref} from "vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  isActive: {},
  isDeleted: {}
});

const mainClasses = computed(() => {
  let classes = [];
  if(!props.isActive){
    classes.push('scpkg--no-active');
  }
  if(props.isDeleted){
    classes.push('scpkg--is-deleted');
  }
  return classes;
});

</script>

<style scoped lang="scss">
.sc-project-keyword-grid.scpkg.scpkg--desktop{
  transition-duration: 0.2s;
  transition-property: background-color;

  > td{
    vertical-align: middle;
    padding:10px 0px;
    height:67px;
  }

  .scpkg__checkbox-col{
    width:67px;
    padding-left:30px;
    padding-right:10px;
  }
  .scpkg__keyword-col{
    max-width:250px;
    padding-right:10px;
  }
  .scpkg__recommendations-col{
    padding-right:10px;
    max-width:250px;
  }
  .scpkg__chart-col{
    width:130px;
    padding-right:25px;
  }
  .scpkg__daily-limit-col{
    width:108px;
    padding-left:5px;
    padding-right:5px;
  }
  .scpkg__status-col{
    width:87px;
    padding-left:5px;
    padding-right:32px;
  }
  .scpkg__delete-col{
    opacity:0;
    transition-duration: 0.2s;
    transition-property: opacity;
    cursor: pointer;
    padding-left:5px;
    padding-right:5px;
    text-align: center;
    width:30px;
  }

  &:hover{
    background-color: #F4F9FD;
    .scpkg__delete-col{
      opacity:1;
    }
  }

}

.sc-project-keyword-grid.scpkg.scpkg--mobile{
  width:100%;
  display: grid;
  grid-template-columns: min-content auto; /* Два столбца, первый узкий, второй широкий */
  grid-template-rows: auto auto auto; /* Три строки: верх и низ автоматической высоты, средняя - заполняющая оставшееся пространство */
  grid-template-areas:
    "sidebar header"
    "sidebar content"
    "sidebar footer";
  gap: 13px; /* Расстояние между элементами */
  padding:16px;
  border-bottom: 1px solid #D6E3EF80;

  .scpkg__sidebar{
    grid-area: sidebar;
    margin-right:3px;
    padding-top:3px;
  }

  .scpkg__header{
    grid-area: header;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .scpkg__content{
    grid-area: content;
    display: flex;
    gap:14px;
    justify-content: space-between;
    align-items: center;
  }

  .scpkg__footer{
    grid-area: footer;
    @media(max-width: 767px){
      max-width: calc(100% - 48px);
    }
  }

  &.scpkg--is-deleted{
    grid-template-rows: auto auto; /* Три строки: верх и низ автоматической высоты, средняя - заполняющая оставшееся пространство */
    grid-template-areas:
    "sidebar header"
    "sidebar content";
      .scpkg__chart,.scpkg__footer{
        display:none;
      }
  }

}
</style>