<template>
  <div
      class="v-form-caption"
      :class="classes"
  >
    <slot/>
  </div>
</template>

<script setup>

import {ref} from "vue";

const props = defineProps({
  isUnderSelect: {type:Boolean},
  isInline: {type:Boolean}
});

const classes = ref([]);

if(props.isUnderSelect) classes.value.push('v-form-caption--under-select');
if(props.isInline) classes.value.push('v-form-caption--is-inline');

</script>

<style lang="scss" scoped>
.v-form-caption{
  color: rgba(0, 0, 0, 0.5019607843);
  font-size: 13px;
}
.v-form-caption--is-inline{
  display: inline-block;
}
.v-form-caption--under-select{
  display: inline-block;
  margin-left: 11px;
  margin-top: 6px
}
</style>