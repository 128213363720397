<template>
  <a class="v-link"><slot/></a>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.v-link{
  color:$secondary-blue-100;
  text-decoration: none;
  cursor: pointer;
}
.v-link :deep(svg){
  fill:$secondary-blue-100;
}
.v-link:hover{
  color:#186CBF;
}
.v-link:hover :deep(svg){
  fill:#186CBF;
}
</style>