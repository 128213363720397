import {defineStore} from "pinia";
import axios from "axios";
import {useRecoStore} from "./useRecoStore";
import {ApiResource} from "../plugins/ApiExtends";
import {useLinkBuildProject} from "./useLinkBuilding";
import {delay} from "../utils/utils";

export const useRecsStoreRating = defineStore('optimization-rating',{
    state: () => {return {}},
    actions: {
        getOptimizationRatingPercent: (status, optimizationRating, maxWeight) => {
            if(status === 'ready' && optimizationRating!== null && maxWeight!==null){

                if(maxWeight === 0){
                    return 0;
                }else{
                    return Math.round(optimizationRating / maxWeight * 100);
                }

            }else{
                return null;
            }
        },
        getOptimizationRatingType(percent){
            if(percent!==null){
                if(percent >= 80){
                    return 'good';
                }else if(percent >= 50){
                    return 'normal';
                }else{
                    return 'bad';
                }
            }else{
                return null;
            }
        }
    }
});

export const useRecsStore = (projectUniquekey)=> defineStore('recommendations-'+projectUniquekey, {
    state: () => {
        return {
            projectUniquekey: projectUniquekey,
            maxWeight: null,
            optimizationRating: null,
            recommendations: {},
            url: null,
            status: null,
            queryProcess: false,
            queryProcessUntilLoaded: false,
            queryError: false,
            queryErrorMessage: '',
        }
    },
    getters: {
        countRecs: (state) => {
            return Object.keys(state.recommendations).length;
        },
        recoStoreByMetric: (state) => {
            return (metric) => state.recommendations[metric];
        },
        loading: (state) => {
            return state.status === 'process' || state.queryProcess || state.queryProcessUntilLoaded;
        },
        optimizationRatingPercent: (state) => {
          return useRecsStoreRating().getOptimizationRatingPercent(
              state.status,
              state.optimizationRating,
              state.maxWeight,
          );
        },
        optimizationRatingType(){
            return useRecsStoreRating().getOptimizationRatingType(
                this.optimizationRatingPercent
            )
        },
        hasRecsLoaded: (state) => {
            if(
                !state.status
                || state.status === 'not_process'
                || (state.status === 'ready' && state.countRecs === 0)
            ){
                return false;
            }else{
                return true;
            }
        },
        /**
         * @type {'error'|'norecoms'|'recoms'|'clean'}
         */
        statusShow(state){
            if(this.hasRecsLoaded && !state.queryError){
                return 'recoms';
            }else if(!this.hasRecsLoaded && !state.queryError){
                return 'norecoms';
            }else if(state.queryError){
                return 'error';
            }else{
                return 'clean';
            }
        },
    },
    actions: {
        async fetch(){

            let projectUniquekey;

            if(useLinkBuildProject(this.projectUniquekey).hasTestAnalytics){
                projectUniquekey = 'test';
            }else{
                projectUniquekey = this.projectUniquekey;
            }

            this.queryProcess = true;
            let resource = new ApiResource({url: '/api/v1/backlinks/'+projectUniquekey+'/recommendations'});
            await resource.downloadAsync();
            this.queryProcess = false;

            if(resource.isSuccess()){
                this.$patch((state) => {
                    state.maxWeight = resource.data.maxWeight;
                    state.optimizationRating = resource.data.optimizationRating;
                    state.url = resource.data.url;
                    state.status = resource.data.status;

                    resource.data.recommendations.forEach((recommendation)=>{
                        if(!state.recommendations.hasOwnProperty(recommendation.metric)){
                            let recoStore = useRecoStore(state.projectUniquekey, recommendation.metric);
                            recoStore.$patch(recommendation);
                            state.recommendations[recommendation.metric] = recoStore;
                        }
                    });

                })
            }else{
                this.$patch({
                    queryProcess: false,
                    queryError: true,
                    queryErrorMessage: resource.errorMessage
                })
                console.log('Error loading recommendations', resource.errorMessage);
            }

        },
        async fetchUntilNotLoaded(iterationNumber = 1){
            this.queryProcessUntilLoaded = true;
            await this.fetch();
            if(this.status === 'process'){
                await delay(1000);
                return this.fetchUntilNotLoaded(iterationNumber+1);
            }else{
                this.queryProcessUntilLoaded = false;
                return iterationNumber;
            }
        }
    }
})()