import { runIfHasSelecter } from "../../utils/panel-lib";

const initButtons = function (currentData) {
    $('[data-credit-button]').on('click', function (e) {
        e.preventDefault();

        const $button = $(this);
        const size = $button.data('credit-button')

        $button.attr("disabled", true);

        $.ajax({
            type: "POST",
            url: "/api/guaranteed/project/renew",
            data: {
                id: currentData.projectId,
                size: size,
            },
            success: function (response) {
                const data = response.data;
                // $button.attr("disabled", false);
                if (data.url) {
                    window.location.href = data.url;
                } else {
                    window.location.href = '/projects';
                }
            },
            error: function () {
                $button.attr("disabled", false);
            }
        });
    });
}

export const initGuaranteedProjectRenewScripts = () => {
    runIfHasSelecter('[data-role="guaranteed-renew"]', [
        initButtons,
    ]);
}
