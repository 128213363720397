<template>
  <div class="v-bs-packages">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.v-bs-packages{
  @include flex(column,start,stretch,9px);
}
</style>