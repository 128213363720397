<template>
  <v-white-container class="v-bs-popup-bonus">
    <div class="title">
      <v-icon-present/>
      Bigger Bonus!
    </div>
    <table class="bonus-table">
      <thead>
        <tr>
          <th>Bonus</th>
          <th>Next month</th>
          <th>+1 Month</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in useSubscription.nextWithPlusOneMonth">

          <td>{{item.name}}</td>

          <td v-if="item.nextQuantity">+{{shortNumber(item.nextQuantity)}}</td>
          <td v-else></td>

          <td v-if="item.nextQuantityOneMonth">+{{shortNumber(item.nextQuantityOneMonth)}}</td>
          <td v-else></td>

        </tr>
      </tbody>
    </table>

    <v-button color="green" @click="emit('i-want-bigger-bonus')">I want a bigger Bonus</v-button>

  </v-white-container>
</template>

<script setup>

import VWhiteContainer from "../VWhiteContainer.vue";
import VIconPresent from "../VIconPresent.vue";
import VButton from "../VButton.vue";
import {useBillingSubscription} from "../../../stores/useBillingSubscriptions";
import {shortNumber} from "../../../utils/utils";

const props = defineProps({
  subscriptionId: {},
});

const emit = defineEmits(['i-want-bigger-bonus']);

const useSubscription = useBillingSubscription(props.subscriptionId);

</script>

<style lang="scss" scoped>
.v-bs-popup-bonus{
  @include flex(column,start,stretch,20px);
  width:340px!important;
  padding:24px!important;

  .title{
    @include flex(row,start,center,11px);
    font-size:18px;
    font-weight:600;
    color:#384967;
  }

  .bonus-table{
    table-layout: fixed;
    width:100%;
    border-collapse: collapse;

    th{

      word-wrap: break-word;
      white-space: normal;
      padding-top:4px;
      padding-bottom:4px;
      font-size:12px;
      color:#AEB7C7;
      text-align:right;
      font-weight:400;
      vertical-align: middle;

      &:first-child{
        text-align:left;
      }

      &:nth-of-type(1){
        padding-left:0px;
        padding-right:7px;
      }
      &:nth-of-type(2){
        width:calc(73px + 7px);
        padding-left:0px;
        padding-right:7px;
      }
      &:nth-of-type(3){
        width:calc(73px);
        padding-left:0px;
        padding-right:0px;
      }
    }

    tr{
      td{
        word-wrap: break-word;
        white-space: normal;
        padding-top:20px;
        padding-bottom:0px;
        text-align:right;
        vertical-align: middle;
        &:nth-of-type(1){
          color:#16731A;
          text-align:left;
          padding-left:0px;
          padding-right:7px;
        }
        &:nth-of-type(2){
          color:#47B84C;
          font-weight:600;
          padding-left:0px;
          padding-right:7px;
        }
        &:nth-of-type(3){
          color:#16731A;
          font-weight:700;
          font-size:16px;
          padding-left:0px;
          padding-right:0px;
        }
      }
    }

  }

}
</style>