<template>
  <div class="lb-order-review-leave lborl">
    <v-head-title type="h2">
      We need your feedback
    </v-head-title>
    <v-review-stars :size="27" @choose-rating="chooseRating" :value="ratingForm"/>
    <div class="lborl__label">
      Tell us please what is wrong with article
    </div>
    <v-form-textarea-inline placeholder="Comments (Optional)" :required="false" v-model="commentForm" :is-error="!!errs.errors?.comment"/>
    <v-form-message-error :message-error="errs.message" class="lbapf__error-message" no-margin/>
    <v-button is-bold color="blue2" @click="submitLeaveReview" :loading="isLoading" :disabled="!hasRatingForm">Send feedback</v-button>
  </div>
</template>

<script setup>

import {useLinkBuildOrder} from "../../stores/useLinkBuilding";
import VHeadTitle from "../Base/VHeadTitle.vue";
import VReviewStars from "../Base/VReviewStars.vue";
import VFormTextareaInline from "../VForm/VFormTextareaInline.vue";
import VButton from "../Base/VButton.vue";
import {computed, ref} from "vue";
import VFormMessageError from "../VForm/VFormMessageError.vue";

const props = defineProps({
  projectUniquekey: {},
  orderId: {},
});

const emit = defineEmits(['reviewLeaveSuccess']);

const orderState = useLinkBuildOrder(props.projectUniquekey, props.orderId);

const errs = orderState.errors.reviews;

const resetForm = () => {
  ratingForm.value = orderState.reviewScore;
  commentForm.value = orderState.reviewComment;
}

const ratingForm = ref();
const commentForm = ref();
resetForm();

const hasRatingForm = computed(() => {
  return !!(ratingForm.value && ratingForm.value >= 1 && ratingForm.value);
});

const isLoading = ref(false);

const chooseRating = (rating) => {
  ratingForm.value = rating;
}

const preSetRating = (rating) => {
  ratingForm.value = rating;
}

const submitLeaveReview = () => {
  isLoading.value = true;

  orderState.saveReview(ratingForm.value, commentForm.value).then((response) => {
    isLoading.value = false;
    let [status] = response;
    if(status){
      resetForm();
      emit('reviewLeaveSuccess');
    }
  });

}

defineExpose({preSetRating});

</script>

<style lang="scss" scoped>
.lb-order-review-leave.lborl{
  display:flex;
  align-items:stretch;
  gap:10px;
  flex-direction:column;
  .lborl__label{
    font-size: 14px;
    line-height: 18px;
    color:#AEB7C7;
  }
}
</style>