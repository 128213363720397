<template>
  <div class="lb-add-backlinks lbab" :class="mainClass">

    <div class="lbab__form">

      <v-form-loading-lock v-if="formDownloading"/>

      <div class="lbab__title" data-name="title-create-new-backlinks" v-auto-tooltip>
        Create New Backlinks
      </div>

      <div class="lbab__backlinks" v-if="backlinksList.length>0">
        <div
            v-for="(backlink,index) in backlinksList"
            class="lbab__backlink"
        >
          <div class="lbab__backlink-left">
            <div class="lbab__backlink-delete">
              <v-icon-svg name="icon-delete-gray" class="lbab__backlink-delete-icon" @click="deleteBacklinks(index)"/>
            </div>
            <v-product :type-constant="backlink.typeConstant" class="lbab__backlink-type"/>
          </div>
          <div class="lbab__backlink-right">
            <div class="lbab__backlink-right-error" v-if="useUserBalance().getByTypeConstant(backlink.typeConstant).balance < backlink.count">
              <v-form-field-list-errors :errors="'Not enough '+useLinkBuildingType().getTitleShort(backlink.typeConstant)" no-margin/>
              <v-link :href="useRoutes().url('backlinks-pay.get')" target="_blank">Buy now !</v-link>
            </div>
            <div class="lbab__backlink-right-info">
              <v-form-input-counter
                  v-model="backlink.count"
                  :min="1"
                  class="lbab__backlink-count"
                  :is-error="useUserBalance().getByTypeConstant(backlink.typeConstant).balance < backlink.count"
              />
              <div class="lbab__recom-like-icon-container" v-if="!isMobile && backlinksRecommendation.recommendationsCount[backlink.typeConstant] && backlinksRecommendation.canBeShown">
                <v-lb-recommendations-like-icon
                    class="lbab__recom-like-icon"
                    :project-uniquekey="projectUniquekey"
                    :type-constant="backlink.typeConstant"
                    :forced-opened="backlink.count < backlinksRecommendation.recommendationsCount[backlink.typeConstant]"
                    @click-number-links="(e) => {backlink.count = e;}"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <v-button
          ref="refButtonAdd"
          @click="toggleDropdown"
          :color="backlinksList.length > 0 ? 'blue2-stroke' : 'blue2'"
          :is-bold="backlinksList.length <= 0"
          :style="{height: backlinksList.length <= 0 ? '48px' : '40px'}"
      >
        + Choose Blogs
      </v-button>

      <v-tooltip
          placement="bottom"
          ref="refTooltip"
          :style="{
          width:'100%',
          maxWidth: buttonAddWidth+'px',
          padding:'0px',
          borderRadius:'4px',
          border: '1px solid #D6E3EF',
          boxShadow: '0px 0px 12px 0px #1C7EE02E',
        }"
          :btn-close-on="false"
          class="lbab__dropdown-wrap vts"
          :get-linked-block="() => refButtonAdd.$el"
      >
        <template v-slot:content>
          <div class="lbab__dropdown">
            <div class="lbab__dropdown-row" v-for="option in backlinkOptionsComputed" @click="addBacklinks(option)">
              <div class="lbab__dropdown-row-left">
                <v-product :type-constant="option.typeConstant" class="lbab__dropdown-row-backlink"/>
              </div>
              <div class="lbab__dropdown-row-right">
                <div class="lbab__dropdown-row-balance">
                  <div class="lbab__dropdown-row-balance-text">
                    Balance {{useUserBalance().getByTypeConstant(option.typeConstant)?.balance}} Сredit
                  </div>
                  <div
                      class="lbab__dropdown-row-balance-icon"
                      :class="useUserBalance().getByTypeConstant(option.typeConstant)?.status"
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-tooltip>

      <v-form-message-error :message-error="errs.message" class="lbapf__error-message" no-margin/>

      <div class="lbab__wrap-button-submit" v-if="backlinksList.length>0">
        <div class="lbab__agreement" @click="checkboxGetNewEveryMonth = !checkboxGetNewEveryMonth">
          <v-icon-svg name="icon-renewal" class="lbab__agreement-icon"/>
          <div class="lbab__agreement-label">Auto-Repeat in 30 Days</div>
          <v-form-checkbox v-model="checkboxGetNewEveryMonth" class="lbab__agreement-checkbox"/>
        </div>

        <v-button
            color="blue2"
            class="lbab__submit"
            @click.prevent="addOrder"
            :loading="formDownloading"
            is-bold
            :disabled="insufficientBalance"
        >
          Create {{pluralize('Backlink',totalCount)}}
        </v-button>
      </div>

    </div>

    <v-modal ref="refModalSuccess" max-width="350px" padding="24px">
      <lb-add-backlinks-success
          :project-uniquekey="projectUniquekey"
          :added-orders-id="addedOrdersId"
          :added-auto-renewals-id="addedAutoRenewalsId"
          @close="refModalSuccess.closeModal()"
      />
    </v-modal>

  </div>
</template>

<script setup>

import VIconSvg from "../Base/VIconSvg.vue";
import VTooltip from "../Base/VTooltip.vue";
import {computed, getCurrentInstance, inject, onMounted, reactive, ref} from "vue";
import VFormInputCounter from "../VForm/VFormInputCounter.vue";
import VButton from "../Base/VButton.vue";
import VFormCheckbox from "../VForm/VFormCheckbox.vue";
import VButtonDotted from "../Base/VButtonDotted.vue";
import {
  useAutoRenewals,
  useLinkBuildProjectOrders,
  useLinkBuildProject,
  useLinkBuildingType, useBacklinksRecommendations
} from "../../stores/useLinkBuilding";
import {useBacklinkOptions, useUserBalance} from "../../stores/useStaticData";
import VFormLoadingLock from "../VForm/VFormLoadingLock.vue";
import VFormMessageError from "../VForm/VFormMessageError.vue";
import VAlertInline from "../Base/VAlertInline.vue";
import VProduct from "../Base/VProduct.vue";
import VFormFieldListErrors from "../VForm/VFormFieldListErrors.vue";
import {useRoutes} from "../../stores/useRoutes";
import VLink from "../Base/VLink.vue";
import VLbRecommendationsLikeIcon from "../Base/LinkBuilding/VLbRecommendationsLikeIcon.vue";
import {pluralize} from "../../utils/utils";
import VModal from "../Base/VModal.vue";
import LbAddBacklinksSuccess from "./LbAddBacklinksSuccess.vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectUniquekey: {}
});

const refButtonAdd = ref();
const refTooltip = ref();
const refModalSuccess = ref();

const checkboxGetNewEveryMonth = ref(true);
const backlinksList = reactive([]);
const formDownloading = ref(false);

const addedAutoRenewalsId = ref([]);
const addedOrdersId = ref([]);

const backlinksRecommendation = useBacklinksRecommendations(props.projectUniquekey);
const linkBuildOrdersStore = useLinkBuildProjectOrders(props.projectUniquekey);
const errs = linkBuildOrdersStore.errors.addOrders;

const buttonAddWidth = ref(375);

const backlinkOptionsComputed = computed(() => {
  return useBacklinkOptions().options.filter((backlinkOption) => {
    return !backlinksList.find((backlinksListItem) => {
      return backlinkOption.value === backlinksListItem.value
    })
  });
});

const totalCount = computed(() => {
  let count = 0;
  backlinksList.forEach((item) => count+=parseInt(item.count));
  return count;
});

const mainClass = computed(() => {
  if(formDownloading.value){
    return 'lbab--downloading';
  }
});

const insufficientBalance = computed(() => {
  return backlinksList.some((backlink) => {
    return backlink.count > useUserBalance().getByTypeConstant(backlink.typeConstant).balance;
  });
});

onMounted(() => {
  buttonAddWidth.value = refButtonAdd.value.$el.offsetWidth;
});

function toggleDropdown(){
  if(backlinkOptionsComputed.value.length > 0){
    refTooltip.value.toggle();
  }
}
function toggleHide(){
  refTooltip.value.hide();
}

function addBacklinks(data){
  let findEl = backlinksList.find((item) => data.value === item.value)
  if(findEl){
    findEl.count++;
  }else{
    backlinksList.push(Object.assign({count:1},data));
  }
  toggleHide();
}

function deleteBacklinks(index){
  backlinksList.splice(index,1);
  if(backlinksList.length === 0){
    linkBuildOrdersStore.clearErrors('addOrders');
  }
}

async function addOrder(){
  if(formDownloading.value){return;}
  formDownloading.value = true;

  let [status,response] = await linkBuildOrdersStore.addOrders(backlinksList, checkboxGetNewEveryMonth.value)

  if(status){

    await Promise.all([
      useAutoRenewals(props.projectUniquekey).download(),
      useLinkBuildProjectOrders(props.projectUniquekey).download()
    ]);
    backlinksList.splice(0,backlinksList.length);

    formDownloading.value = false;
    addedOrdersId.value = response.ordersId;
    addedAutoRenewalsId.value = response.autoRenewalsId;
    refModalSuccess.value.openModal();

    await useUserBalance().download();


  }else{
    addedOrdersId.value = [];
    addedAutoRenewalsId.value = [];
    formDownloading.value = false;
  }

}

useBacklinkOptions().download();

</script>

<style lang="scss" scoped>
.lb-add-backlinks.lbab{
  display:flex;
  flex-direction:column;
  align-items:stretch;
  gap:20px;
  @media(max-width:767px){
    gap:16px;
  }

  .lbab__form{
    position:relative;
    display:flex;
    flex-direction: column;
    align-items:stretch;
    gap:20px;
    @media(max-width:767px){
      gap:16px;
    }
  }

  .lbab__title{
    font-size: 19px;
    font-weight: 600;
    color:#0F1F3A;
    display:flex;
    align-items:center;
    gap:10px;
    @media(max-width:767px){
      gap:6px;
    }
  }

  .lbab__backlinks{
    display:flex;
    flex-direction:column;
    width:100%;
    gap:18px;

    .lbab__backlink{
      width:100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height:40px;

      .lbab__backlink-left{
        @include flex(row,start,center,10px);
        .lbab__backlink-type{
          font-size:16px;
        }
        .lbab__backlink-delete{
          .lbab__backlink-delete-icon{
            opacity:0.6;
            cursor:pointer;
            transition-property: opacity;
            transition-duration: 0.2s;
            &:hover{
              opacity:1;
            }
          }
        }
      }
      .lbab__backlink-right{
        @include flex(row,end,center,13px);

        .lbab__backlink-right-info{
          display:flex;
          align-items:center;
          position:relative;
          .lbab__recom-like-icon-container{
            @include flex(row,start,center);
            position:absolute;
            height:100%;
            left:100%;
            padding-left:10px;
          }
        }
        .lbab__backlink-right-error{
          display:flex;
          flex-direction:column;
          align-items:flex-end;
          gap:0px;
          font-size:12px;
        }


        .lbab__backlink-count{
          height:38px;
        }

        .lbab__backlink-delete{
          .lbab__backlink-delete-icon{
            opacity:0;
            cursor:pointer;
            transition-property: opacity;
            transition-duration: 0.2s;
            @media(max-width:767px){
              opacity:0.5;
              &:hover{
                opacity:1;
              }
            }
          }
        }

      }

    }
  }

  .lbab__total{
    display:flex;
    justify-content: space-between;
    align-items:center;
    height:40px;

    .lbab__total-label{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color:#384967;
    }
    .lbab__total-count{
      font-size: 14px;
      font-weight: 600;
      color:#0F1F3A;
      min-width:74px;
      text-align:center;
    }
  }

  .lbab__wrap-button-submit {
    display:flex;
    flex-direction: column;
    align-items: stretch;
    gap:16px;
    .lbab__submit {
      width: 100%;
      height:48px;
    }
    .lbab__agreement {
      display: flex;
      justify-content: flex-end;
      gap: 5px;
      cursor: pointer;
      align-items: center;
      width: 100%;

      .lbab__agreement-checkbox {
        flex-shrink: 0;
      }

      .lbab__agreement-icon {
        flex-shrink: 0;
      }

      .lbab__agreement-label {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #384967;
      }
    }
  }
}
</style>
<style lang="scss">
.lbab__dropdown{
  display: flex;
  flex-direction:column;
  gap:0px;

  .lbab__dropdown-row{
    cursor:pointer;
    background-color: transparent;
    padding:12px 16px;
    transition-duration:0.2s;
    transition-property: background-color;
    display:flex;
    justify-content: space-between;

    &:hover{
      background-color:#F4F9FD;
    }

    .lbab__dropdown-row-left{

    }
    .lbab__dropdown-row-right{

    }
    .lbab__dropdown-row-balance{
      display:flex;
      gap:10px;
      align-items:center;

      .lbab__dropdown-row-balance-text{
        font-size: 12px;
        color:#AEB7C7;
      }
      .lbab__dropdown-row-balance-icon{
        width:7px;
        height:7px;
        flex-shrink:0;
        border-radius:50%;
        &.red{
          background-color:#C70B0B;
        }
        &.yellow{
          background-color:#EF8E34;
        }
        &.green{
          background-color:#219E26;
        }
      }
    }
    .lbab__dropdown-row-backlink{

    }

  }

}
</style>