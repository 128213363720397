<template>
  <div class="bc-cart bcc">

    <div class="bcc__items" v-if="billingCart.items.length > 0">
      <bc-cart-item
          v-for="item in billingCart.items"
          :id="item.id"
          class="bcc_item"
          :key="item.id"
          :is-last-item="billingCart.items.at(-1).id === item.id"
      />
    </div>

    <div class="bcc__empty" v-if="billingCart.items.length === 0">
      Card is empty
    </div>

    <div class="bcc__total">
      <div class="bcc__total-left">
        <div class="bcc__total-title">
          Total to pay:
        </div>
      </div>
      <div class="bcc__total-right">
        <div class="bcc__total-price">
          <div
              v-if="billingCart.totalPriceWithoutDiscount > billingCart.totalPrice"
              class="bcc__total-price-discount"
          >
            ${{priceFormatted(billingCart.totalPriceWithoutDiscount)}}
          </div>
          <div class="bcc__total-price-value" :class="billingCart.items.length === 0 ? 'bcc__total-price-value--empty' : ''">
            ${{priceFormatted(billingCart.totalPrice)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {useBillingCart} from "../../stores/useBilling";
import BcCartItem from "./BcCartItem.vue";
import {priceFormatted} from "../../utils/utils";

const billingCart = useBillingCart();
billingCart.update();

</script>

<style lang="scss" scoped>
.bc-cart.bcc{
  display:flex;
  flex-direction:column;
  align-items:stretch;
  .bcc__items{
    display:table;
    table-layout: fixed;
  }
  .bcc__empty{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #AEB7C7;
    height:50px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    border-bottom: 1px solid #D6E3EF80;
  }
  .bcc__total{
    height:65px;
    display:flex;
    justify-content: space-between;
    align-items:center;

    .bcc__total-left{
      display:flex;
      justify-content: flex-start;
      align-items: center;
    }
    .bcc__total-right{
      display:flex;
      justify-content:flex-end;
      align-items:center;
    }
    .bcc__total-title{
      font-size: 18px;
      font-weight: 600;
      color:#3d434e;
    }
    .bcc__total-price{
      @include flex(row,start,center,10px);
      @media(max-width:767px){
        @include flex(column,start,end,0px);
      }
      .bcc__total-price-discount{
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color:#AEB7C7;
        text-decoration-line:line-through;
      }
      .bcc__total-price-value{
        font-size: 24px;
        font-weight: 600;
        color:#151515;
        &.bcc__total-price-value--empty{
          color: #AEB7C7;
        }
        @media(max-width:767px){
          font-size: 20px;
        }
      }
    }
  }
}
</style>