<template>
  <transition
      @before-enter="onBeforeEnter"
      @enter="onEnter"
      @after-enter="onAfterEnter"
      @enter-cancelled="onEnterCancelled"
      @before-leave="onBeforeLeave"
      @leave="onLeave"
      @after-leave="onAfterLeave"
      @leave-cancelled="onLeaveCancelled"
      :css="false"
  >
    <slot></slot>
  </transition>
</template>

<script setup>

let animation;

const duration = 300;

const delay = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

let elHeight = null;

const getCurrentHeight = (el) => {
  if(elHeight === null){
    elHeight = el.offsetHeight;
  }
  return elHeight;
}

const setHeight = (el,h) => {
  elHeight = h;
  el.style.height = h+'px';
}

const getRealHeight = async (el) => {
  let oldHeight = el.style.height;
  el.style.height = '';
  el.style.opacity = 0;
  //await delay(1);
  let realHeight = el.offsetHeight;
  el.style.height = oldHeight;
  el.style.opacity = 1;
  return realHeight;

}

const onBeforeEnter = (el) => {
  el.style.height = getCurrentHeight(el)+'px';
}

const onEnter = (el, done) => {
  let currentHeight = getCurrentHeight(el);
  getRealHeight(el).then((realHeight) => {
    animation = createEaseCubicBezierAnimation(currentHeight, realHeight, duration, (value) => {
      setHeight(el,value);
    }, (finalValue, wasCanceled) => {
      done();
    });
  });
}

const onAfterEnter = (el) => {
  el.style.height = '';
}

const onEnterCancelled = (el) => {
  animation.cancel();
}


const onBeforeLeave = (el) => {
  el.style.height = getCurrentHeight(el)+'px';
}

const onLeave = (el, done) => {
  animation = createEaseCubicBezierAnimation(getCurrentHeight(el), 0, duration, (value) => {
    setHeight(el,value);
  }, (finalValue, wasCanceled) => {
    done();
  });
}

const onAfterLeave = (el) => {
  setHeight(el,getCurrentHeight(el));
}

const onLeaveCancelled = (el) => {
  animation.cancel();
}

const cubicBezier = (p1x, p1y, p2x, p2y) => {
  const cx = 3.0 * p1x;
  const bx = 3.0 * (p2x - p1x) - cx;
  const ax = 1.0 - cx - bx;
  const cy = 3.0 * p1y;
  const by = 3.0 * (p2y - p1y) - cy;
  const ay = 1.0 - cy - by;

  function solve(x, epsilon = 1e-6) {
    let t = x;
    for (let i = 0; i < 8; i++) {
      const x = ((ax * t + bx) * t + cx) * t - x;
      const dx = (3.0 * ax * t + 2.0 * bx) * t + cx;
      if (Math.abs(x) < epsilon) return t;
      t -= x / dx;
    }
    return t;
  }

  return function (t) {
    return ((ay * t + by) * t + cy) * t;
  };
}

const createEaseCubicBezierAnimation = (from, to, duration, onUpdate, onComplete) => {
  let start = null;
  let canceled = false;
  let current = from;

  const easing = cubicBezier(0.25, 0.1, 0.25, 1.0); // Кривая ease, соответствует cubic-bezier(0.25, 0.1, 0.25, 1.0)

  function step(timestamp) {
    if (canceled) {
      if (onComplete) onComplete(current, true);
      return;
    }

    if (!start) start = timestamp;
    const progress = Math.min((timestamp - start) / duration, 1);
    const easeProgress = easing(progress);
    current = from + (to - from) * easeProgress;
    if (onUpdate) onUpdate(current);

    if (progress < 1) {
      requestAnimationFrame(step);
    } else {
      if (onComplete) onComplete(current, false);
    }
  }

  const animationFrameId = requestAnimationFrame(step);

  return {
    cancel() {
      canceled = true;
      cancelAnimationFrame(animationFrameId);
    }
  };
}

</script>

<style lang="scss" scoped>

</style>