<template>
  <div :data-name="dataName" data-can-be-question="custom-by-name" class="v-form-editable-string vfes">

    <div class="vfes__content-show-wrap" v-if="!editable">
      <div class="vfes__content-show">
        <slot name="default"/>
      </div>
      <div class="vfes__content-show-icon-edit" @click="setEditable">
        <v-icon-svg name="icon_pencil_v2" class="vfes__content-show-icon-edid-svg"/>
      </div>
      <div class="vfes__content-show-icon-question" v-if="_hasQuestion">
        <v-tooltip-question-icon :tooltip-data="_tooltipData"/>
      </div>
    </div>

    <div class="vfes__content-edit" v-show="editable" :class="contentEditClassed">
      <div class="vfes__label">
        <div>
          <div class="vfes__label-sign">
            {{label}}
            <span v-if="required" class="vfes__label-sign-required-star">*</span>
          </div>
          <input
              type="text"
              class="vfes__label-input"
              v-model="localValue"
              @keyup.enter="save"
              @focusin="focused = true"
              @focusout="focused = false"
          >
        </div>
        <div class="vfes__apply" @click="save" :class="applyClasses">
          <span v-if="!loader" class="vfes__apply-icon-wrap">
            <v-icon-svg name="apply" class="vfes__apply-icon"/>
          </span>
          <v-loader-ship v-if="loader" class="vfes__apply-loader"/>
        </div>
      </div>
      <div class="vfes__error" v-if="isError">
        {{errorMessage}}
      </div>
    </div>

  </div>
</template>

<script setup>

import VIconSvg from "../Base/VIconSvg.vue";
import {computed, ref, watch} from "vue";
import VLoaderShip from "../Base/VLoaderShip.vue";
import VTooltipQuestionIcon from "../Base/VTooltipQuestionIcon.vue";
import useTooltipHelpers from "../../utils/useTooltipHelpers";

const props = defineProps({
  label: {},
  required: {type: Boolean},
  value: {},
  canBeEmpty: {type: Boolean, default:false},
  tooltipQuestion: {default:false,},
  hasQuestion: {},
  tooltipData: {},
  dataName: {type: String},
});

const {_hasQuestion, _tooltipData} = useTooltipHelpers(
    'custom',
    props.dataName,
    '',
    props.hasQuestion,
    props.questionDescription
);

const localValue = ref(props.value);

const emit = defineEmits(['save']);

const editable = ref(false);
const loader = ref(false);
const focused = ref(false);
const disabled = ref(false);
const isError = ref(false);
const errorMessage = ref();

const contentEditClassed = computed(() => {
  let classes = [];
  if(focused.value){classes.push('vfes__content-edit--focused');}
  if(isError.value){classes.push('vfes__content-edit--error');}
  return classes;
});

watch(() => props.value, () => {
  localValue.value = props.value;
})

watch(() => localValue.value?.trim()?.length, (value)=> {
  if(value === 0 && !props.canBeEmpty){
    disabled.value = true;
  }else{
    disabled.value = false;
  }
})

const applyClasses = computed(() => {
  let classes = [];
  if(disabled.value){classes.push('vfes__apply--disabled');}
  return classes;
});

const setEditable = () => {
  editable.value = true;
}

const save = () => {
  if(!disabled.value && !loader.value){
    loader.value = true;
    emit('save', localValue.value);
  }
}

const saveWithSuccess = () => {
  editable.value = false;
  loader.value = false;
  isError.value = false;
}

const saveWithError = (message) => {
  isError.value = true;
  loader.value = false;
  errorMessage.value = message;
}

defineExpose({saveWithSuccess, saveWithError});

</script>

<style scoped lang="scss">
.v-form-editable-string.vfes{

  .vfes__content-show-wrap{
    display: flex;
    align-items: center;
    gap:10px;

    .vfes__content-show{}
    .vfes__content-show-icon-edit{
      display: flex;
      align-items: center;
      justify-content: center;
      padding:3px;
      cursor: pointer;
      transition-duration: 0.2s;
      border-radius: 3px;
      opacity:0.5;
      width:14px;
      height:14px;
      flex-shrink:0;

      &:hover{
        opacity:1;
        //background-color: #ebf5ff;
      }
      .vfes__content-show-icon-edid-svg{
        display:block;
        width:14px;
        height:14px;
        flex-shrink: 0;
      }
    }
  }

  .vfes__content-edit{
    width:100%;

    .vfes__label {
      border: 1px solid #D6E3EF;
      padding: 6px 10px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width:100%;
      gap:10px;

      > div:first-child{
        width:100%;
      }

      .vfes__label-sign{
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        color:#AEB7C7;

        .vfes__label-sign-required-star{
          color:#F2001D;
        }
      }
      .vfes__label-input{
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        border:none;
        padding:0px;
        outline: 0px;
        width:100%;
      }

      .vfes__apply{
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        font-weight: 500;
        padding: 4px 0px;
        background-color: #219E26;
        color: #fff;
        border-radius: 3px;
        cursor: pointer;
        width: 24px;
        flex-shrink: 0;
        text-align: center;
        justify-content: center;
        text-transform: uppercase;
        height:24px;

        .vfes__apply-icon-wrap{
          .vfes__apply-icon{
            display: block;
          }
        }

        &.vfes__apply--disabled{
          opacity:0.5;
          cursor:default;
        }

        .vfes__apply-loader{
          width: 18px;
          height: 18px;
        }
      }

      .vfes__loader{
        flex-shrink: 0;
        margin-left:5px;
        opacity:0;

        &.active{
          opacity:1;
        }
        .vfes__loader-icon{
          width:20px;
          height:20px;
        }
      }
    }

    .vfes__error{
      margin-top:4px;
      font-size:12px;
      line-height: 15px;
      color:#F2001D;
    }

    &.vfes__content-edit--focused{
      .vfes__label{
        border-color:#1D94FF;
      }
    }

    &.vfes__content-edit--error{
      .vfes__label{
        border-color:#F2001D;
      }
    }

  }

}
</style>