<template>
  <div class="lb-review lbr">

    <div class="lbr__inner">
      <v-review-stars
          @choose-rating="chooseRating"
          :value="currentRating"
          ref="refReviewStars"
          :no-select="isMobile"
          @click="isMobile ? refTooltip.show() : ''"
          :color="colorStars"
      />
      <v-link v-if="orderState.hasReviewComment && hasEdit" class="lbr__edit" @click.prevent="refTooltip.show()">
        <v-icon-svg name="chat"/>
        <span class="lbr__edit-text">Edit</span>
      </v-link>
    </div>

    <v-tooltip
        placement="bottom"
        ref="refTooltip"
        :style="{width:'100%', maxWidth:'400px', borderRadius:'8px',padding:'24px'}"
        :btn-close-on="true"
        :get-linked-block="() => refReviewStars.$el"
    >
      <template v-slot:content>
        <lb-order-review-leave
            ref="refOrderReviewLeave"
            :project-uniquekey="projectUniquekey"
            :order-id="orderState.id"
            @review-leave-success="refTooltip.hide()"
        />
      </template>
    </v-tooltip>

  </div>
</template>

<script setup>

import VReviewStars from "../Base/VReviewStars.vue";
import {useLinkBuildOrder} from "../../stores/useLinkBuilding";
import {computed, getCurrentInstance, ref, watch} from "vue";
import VTooltip from "../Base/VTooltip.vue";
import LbOrderReviewLeave from "./LbOrderReviewLeave.vue";
import VIconSvg from "../Base/VIconSvg.vue";
import VLink from "../Base/VLink.vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const props = defineProps({
  projectUniquekey: {},
  orderId: {},
  colorStars: {default: 'default',},
  hasEdit: {default: true},
});

const refReviewStars = ref();
const refTooltip = ref();
const refOrderReviewLeave = ref();

const orderState = useLinkBuildOrder(props.projectUniquekey, props.orderId);

/**
 * Рейтинг, который уже изменили, но еще не сохранили
 */
const currentRating = ref(orderState.reviewScore);

/**
 * После изменение рейтинга в store, сохранение в локальные изменения
 */
watch(() => orderState.reviewScore, (score) => {
  currentRating.value = score;
});

/**
 * Пользователь выбрал рейтинг
 */
const chooseRating = (rating) => {
  if(isMobile.value){
    return;
  }

  refOrderReviewLeave.value.preSetRating(rating);
  refTooltip.value.show();

}

</script>

<style lang="scss" scoped>
.lb-review.lbr{
  .lbr__inner{
    height:18px;
    display:flex;
    align-items:center;
    gap:12px;
  }
  .lbr__edit{
    display:flex;
    align-items:center;
    gap:6px;
    .lbr__edit-text{
      height:15px;
      font-size: 12px;
      display:flex;
      align-items:center;
      line-height:14.52px;
    }
  }
}
</style>