<template>
  <div class="v-step-cart vsc">
    <div class="vsc__circle">
      <v-head-title
          v-if="type==='number'"
          v-text="value"
          type="h2"
          class="vsc__circle-number"
      />
      <v-icon-svg
          v-if="type==='cart'"
          class="vsc__circle-cart"
          name="shopping-cart"
      />
    </div>
    <v-head-title type="h2" class="vsc__title">
      <slot/>
    </v-head-title>
  </div>
</template>

<script setup>
import VHeadTitle from "./VHeadTitle.vue";
import {computed} from "vue";
import VIconSvg from "./VIconSvg.vue";

const props = defineProps({
  /**
   * @type {'cart'|Number}
   */
  value: {required:true},
});

const type = computed(() => {
  if(props.value === 'cart'){
    return 'cart';
  }else{
    return 'number';
  }
})

</script>

<style lang="scss" scoped>
.v-step-cart.vsc{
  display:flex;
  align-items:center;
  justify-content: flex-start;
  gap:13px;

  .vsc__circle{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1.5px solid #1C7EE0;
    display:flex;
    justify-content:center;
    align-items:center;

    .vsc__circle-number{
      font-size:18px;
    }
    .vsc__circle-cart{
      width:15px;
      height:15px;
      display:block;
      flex-shrink:0;
    }
  }
  .vsc__title{
    font-size:18px;
  }
}
</style>