<template>
  <v-container class="lb-projects lbp">

    <v-breadcrumbs :items="breadcrumbs" no-padding/>

    <bc-balance :credit-type="CREDIT_TYPE_BACKLINK"/>

    <div class="lbp__head">
      <v-head-title class="lbp__head-title">
        My Projects
      </v-head-title>
    </div>

    <lb-projects-chart/>

    <v-filter-complex
      skin="on-gray-bg"
      :filters="projectsFilters"
      v-model="useLinkBuildProjects().filters"
      :disabled="useLinkBuildProjects().list.length === 0"
    />

    <v-button
        tag="a"
        :href="useRoutes().url('backlinks-project.add')"
        class="lbp__button-add-project"
        color="blue2"
        :is-bold="true"
        text="Add project"
        v-if="useLinkBuildProjects().list.length > 0"
    />

    <div class="lbp__projects">

      <div class="lbp__projects-list" v-if="useLinkBuildProjects().listFiltered.length > 0">
        <template v-for="project in useLinkBuildProjects().listFiltered" :key="project.uniquekey">
          <lb-projects-preview :project-uniquekey="project.uniquekey" class="lbp__projects-list-item"/>
        </template>
      </div>

      <div class="lbp__projects-list" v-else>
        <v-not-found-projects v-if="useLinkBuildProjects().list.length === 0" :link="useRoutes().url('backlinks-project.add')"/>
        <v-not-found-projects v-else message="Nothing find on your request"/>
      </div>

    </div>

  </v-container>
</template>

<script setup>

import {useRoutes} from "../../stores/useRoutes";
import VBreadcrumbs from "../Base/VBreadcrumbs.vue";
import VContainer from "../Base/VContainer.vue";
import VHeadTitle from "../Base/VHeadTitle.vue";
import VButton from "../Base/VButton.vue";
import {useLinkBuildProjects} from "../../stores/useLinkBuilding";
import LbProjectsPreview from "./LbProjectsPreview.vue";
import LbProjectsChart from "./LbProjectsChart.vue";
import VSearchBar from "../Base/VSearchBar.vue";
import VNotFoundProjects from "../Base/VNotFoundProjects.vue";
import VFilterComplex from "../Base/VFilterComplex.vue";
import {computed, reactive} from "vue";
import {CREDIT_TYPE_BACKLINK} from "../../stores/useBilling";
import BcBalance from "../BuyCredits/BcBalance.vue";

const breadcrumbs = [
  {'url': useRoutes().url('backlinks-project.list'), 'title': 'Link-building Projects'},
  { 'title': 'My Projects'}
];

useLinkBuildProjects().download();

const projectsFilters = computed(() => {

  return [
    {
      modelName: 'domains',
      props: {
        options: useLinkBuildProjects().listDomainsForSelect,
        tooltipDisplayAsInputChecked: true,
        tooltipMaxWidth: '260px',
        buttonTitle:'Websites',
        searchEnable:true,
      },
    },
    {
      modelName: 'keywords',
      props: {
        options: useLinkBuildProjects().listKeywordsForSelect,
        tooltipDisplayAsInputChecked: true,
        tooltipMaxWidth: '260px',
        buttonTitle:'Keywords',
        searchEnable:true,
      },
    },
    {
      modelName: 'types',
      props: {
        options: useLinkBuildProjects().listTypesForSelect,
        tooltipDisplayAsInputChecked: true,
        tooltipMaxWidth: '260px',
        buttonTitle:'Type',
      },
    },
  ];

});

</script>

<style scoped>
.lb-projects.lbp{
  display:flex;
  flex-direction:column;
  align-items:stretch;
  gap:30px;
  @media(max-width: 767px){
    gap:20px;
  }

  .lbp__head{
    display:flex;
    flex-direction:column;
    gap:20px;
    align-items:flex-start;
    @media(max-width: 767px){
      gap:10px;
    }
  }

  .lbp__button-add-project{
    align-self:flex-start;
  }

  .lbp__projects{
    .lbp__projects-list{
      display:flex;
      gap:24px;
      flex-wrap:wrap;
      .lbp__projects-list-item{
        width:calc(50% - 12px);
        @media(max-width: 767px){
          width:calc(100%);
        }
      }
      @media(max-width:767px){
        gap:12px;
      }

    }
    margin-bottom:40px;
  }

}
</style>