<template>
  <div class="chart-realtime-v2" ref="refWrapCanvas">
    <canvas ref="refCanvas"></canvas>
  </div>
</template>

<script setup>

import {computed, onMounted, onUnmounted, ref} from "vue";
import {getScssVar} from "../../utils/utils";
import {ChartConfigBase, ChartControllerBase} from "../../utils/chartClasses";
import {calculateScaleYMaxForBar} from "../../utils/chartUtils";

const props = defineProps({
  /**
   * @type {Number[]}
   */
  values: {type: Array, default: [],},
});

const refWrapCanvas = ref();
const refCanvas = ref();

class ChartConfig extends ChartConfigBase{

  colors = ref({
    border: getScssVar('project-seo-color'),
    background: getScssVar('project-seo-color-light'),
  });

  values = computed(() => {
    if(this.minValue.value === null){
      return props.values;
    }else{
      return props.values.map(val => val > this.minValue.value ? val : this.minValue.value)
    }
  });

  scaleYMax = computed(() => {
    return calculateScaleYMaxForBar(Math.max(...props.values));
  });

  minValue = computed(() => {
    if(this.chartController.actualHeight.value!==null){
      return this.scaleYMax.value / this.chartController.actualHeight.value
    }else{
      return null;
    }
  });

  result = computed(() => {
    if(!this.ready()){return null;}
    return {
      type: 'bar',
      data: {
        labels: new Array(this.values.value.length).fill(''),
        datasets: [{
          data: this.values.value,
          backgroundColor: this.colors.value.background,
          borderColor: this.colors.value.border,
          borderWidth: {
            top: 1,
            left: 0,
            right: 0,
            bottom: 0,
          },
          barPercentage:1.1,
        }],
      },
      options: {
        animation:{duration:0,},
        responsive: true,
        maintainAspectRatio:false,
        hover: {mode: null},
        plugins: {
          tooltip:{enabled:false,},
          legend: {display: false,},
        },
        scales: {
          x: {display:false,},
          y: {
            display:false,
            max: this.scaleYMax.value,
            min: 0,
          },
        }
      }
    }
  });
}

const chartController = new ChartControllerBase(refWrapCanvas, refCanvas, ChartConfig);

onMounted(() => {chartController.componentOnMounted();});
onUnmounted(() => {chartController.componentOnUnMounted();});

</script>

<style lang="scss" scoped>
.chart-realtime-v2{
  width:100%;
}
</style>