<template>
  <div
      v-if="!isOld"
      class="v-lottie-load"
      ref="containerLottie"
  />
  <div
      v-else
      class="loading-block"
      ref="oldLoadingBlock"
  >
    <div class="lottie-animation" ref="oldLoadingBlockLottieAnimation"/>
  </div>
</template>

<script>
export default{
  props: {
    isOld: {type: Boolean, default: false},
  },
  mounted() {

    if(this.$refs.containerLottie){

      lottie.loadAnimation({
        container: this.$refs.containerLottie,
        renderer: 'svg',  // svg, canvas, html
        loop: true,
        autoplay: true,
        animationData: animationData
      });

    }else if(this.$refs.oldLoadingBlock){

        lottie.loadAnimation({
          container: this.$refs.oldLoadingBlockLottieAnimation,
          renderer: 'svg',  // svg, canvas, html
          loop: true,
          autoplay: true,
          // path: '/img/lottie/loading.json'
          animationData: animationData
        });

    }

  }
}
</script>

<style lang="scss" scoped>
.v-lottie-load{
  width:100%;
  max-width:180px;
  margin:auto;
}
.loading-block.pos-absolute {

}
</style>