<template>
  <div class="v-table-info vti" :class="mainClass">
    <div class="vti__wrap-table">
      <table ref="refTable" class="vti__table">
        <slot/>
      </table>
    </div>
    <div class="vti__wrap-table">
      <table ref="refTableClone" class="vti__table"/>
    </div>
  </div>
</template>

<script setup>

import {computed, onMounted, ref, watchEffect} from "vue";
import {StickyTableHeader} from "vh-sticky-table-header";

const props = defineProps({
  isStickyHead: {type:Boolean, default:false}
});

const refTable = ref(null);
const refTableClone = ref(null);

const mainClass = computed(() => {
  if(props.isStickyHead){
    return 'vti--sticky-head';
  }
});

const initStickyHead = () => {
  watchEffect((onCleanup) => {
    if (!refTable.value && !refTableClone.value) return;

    const stickyTable = new StickyTableHeader(
        refTable.value,
        refTableClone.value,
        { max: 0 },
    );

    onCleanup(() => stickyTable.destroy());
  });
}

onMounted(() => {
  if(props.isStickyHead){
    initStickyHead();
  }
});

</script>

<style lang="scss" scoped>
.v-table-info.vti{

  .vti__wrap-table {

  }

  &.vti--is-sticky-head{
    .vti__wrap-table{
      width:100%;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  .vti__table{
    width:100%;
    table-layout: fixed;

    > :deep(thead){
      > tr{
        > th{
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
          border-bottom: 1px solid #D6E3EF80;
          height:40px;
          color:#AEB7C7;
          padding-right:20px;
        }
      }
    }
    > :deep(tbody){
      > tr{
        > td{
          padding-right:20px;
          &:last-child{
            padding-right:0px;
          }
        }
      }
    }

  }

}
</style>