<template>
  <a :href="link" class="bc-balance-item bcbi" :class="mainClass">
    <div class="bcbi__top">

      <div class="bcbi__indicator"></div>
      <v-product :type-constant="userBalanceItem.typeConstant" class="bcbi__product" :is-short="userBalanceItem.creditType === CREDIT_TYPE_SEO"/>

    </div>
    <div class="bcbi__bottom">

      <div class="bcbi__count-credits">
        Credits: {{userBalanceItem.balance}}
      </div>

    </div>
  </a>
</template>

<script setup>

import VProduct from "../Base/VProduct.vue";
import {useUserBalanceItem} from "../../stores/useStaticData";
import {computed} from "vue";
import {CREDIT_TYPE_BACKLINK, CREDIT_TYPE_SEO} from "../../stores/useBilling";
import {useRoutes} from "../../stores/useRoutes";

const props = defineProps({
  typeConstant: {},
});

const userBalanceItem = useUserBalanceItem(props.typeConstant);

const mainClass = computed(() => {
  return 'bcbi--status-'+userBalanceItem.status;
});

const link = computed(() => {
  switch(userBalanceItem.creditType){
    case CREDIT_TYPE_SEO: return useRoutes().url('buy-credits', {});
    case CREDIT_TYPE_BACKLINK: return useRoutes().url('backlinks-pay.get', {},{da_type:userBalanceItem.typeConstant});
  }
});

</script>

<style lang="scss" scoped>
.bc-balance-item.bcbi{
  @include flex(column,start,start,2px);
  padding: 10px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #D6E3EF;
  text-decoration: none;

  .bcbi__top{
    @include flex(row,start,center,8px);
  }

  .bcbi__indicator{
    width:7px;
    height:7px;
    flex-shrink:0;
    border-radius:50%;
  }

  .bcbi__product{
    font-size:14px;
  }

  .bcbi__count-credits{
    font-size: 12px;
    font-weight: 400;
    color:#798CAE;
  }

  &.bcbi--status-red{
    border-color:#F2001D;
    .bcbi__indicator{
      background-color:#C70B0B;
    }
  }
  &.bcbi--status-yellow{
    .bcbi__indicator{
      background-color:#EF8E34;
    }
  }
  &.bcbi--status-green{
    .bcbi__indicator{
      background-color:#219E26;
    }
  }

}
</style>