import { runIfHasSelecter } from "../../utils/panel-lib";
import { api } from "../../utils/panel-api";

// const initScripts = function (currentData, translations) {
// }

const initAutoRenewStatus = function () {
    $('[data-ajax-action="auto-renew-status"]').on('click', function () {
        let $button = $(this);
        let subscriptionId = $button.data('subscription-id');
        let isPaused = $button.data('paused');
        let status = isPaused ? 'true' : 'false';

        if (!subscriptionId) {
            return;
        }

        let data = {
            subscription_id: subscriptionId,
            status: status
        }

        $button.prop('disabled', true);

        api.billing.autoRenewStatusSubscription(data,
            function (response) {
                if (response.success) {
                    let data = response.data;

                    $button.data('paused', data.isPaused);

                    if (data.isPaused) {
                        $button.text('Resume Subscription');
                    } else {
                        $button.text('Pause Subscription');
                    }
                } else {
                    // $button.text('Resume Subscription');
                    // addNotification(response.message);
                }

                $button.prop('disabled', false);
                // addNotification(response.message);
            },
            function () {
                $button.prop('disabled', false);
            });
    });
}

export const initBillingSubscriptionScripts = () => {
    runIfHasSelecter('[data-role="billing-subscriptions"]', [
        // initScripts,
        initAutoRenewStatus
    ]);
}
