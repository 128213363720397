/**
 * @returns {tooltipObjectRow[]}
 */
function getTooltipsData(){
    if(!window.hasOwnProperty('tooltipsData') || !Array.isArray(window.tooltipsData)){
        return [];
    }else{
        return window.tooltipsData;
    }
}

/**
 * @param type {string}
 * @param name {string}
 * @returns {tooltipObjectRow|null}.
 */
export function getTooltip(type, name){

    let response = getTooltipsData().find((item) => {
        return item?.type?.trim() === type.trim() && item?.name?.trim() === name?.trim();
    });

    if(response && response?.is_active){
        return response;
    }else{
        return null;
    }

}

/**
 * @param type {string}
 * @param elDom {HTMLElement}
 * @returns {tooltipObjectRow|null}.
 */
export function getTooltipBySelector(type, elDom){
    return getTooltipsData().find((item) => {
        return item?.is_active && item?.type?.trim() === type.trim() && elDom.matches(item.selector);
    }) ?? null;

}
/**
 * @typedef {Object} tooltipObjectRow
 * @property {string} description
 * @property {string} icon_filename
 * @property {number} id
 * @property {number} intercom_button_enabled
 * @property {string} intercom_button_title
 * @property {number} is_active
 * @property {string} name
 * @property {string} placement
 * @property {string} selector
 * @property {string} template
 * @property {string} title
 * @property {string} trigger
 * @property {string} type
 * @property {string} url_to_use
 */