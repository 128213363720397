<template>
  <div class="v-circle-state vcs" :class="mainClass">
    <span>{{value}}</span>
  </div>
</template>

<script setup>

import {computed} from "vue";

const props = defineProps({
  /**
   * @type {number}
   */
  value: {},
  /**
   * @type {'bad'|'good'|'normal'}
   */
  color: {},
  /**
   * @type {'big'|'small'|'middle'}
   */
  size: {default:'big'},
});

const mainClass = computed(() => {
  return [
      'vcs--'+props.color,
      'vcs--'+props.size,
      'vcs--'+ ((parseFloat(props.value) >= 100) ? 'big-number' : 'normal-number'),
  ];
})

</script>

<style lang="scss" scoped>
.v-circle-state.vcs{

  border-radius: 50%;

  font-weight: 600;
  letter-spacing: -0.04em;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items:center;
  border-style:solid;

  &.vcs--small{
    width:42px;
    height:42px;
    font-size: 18px;
    border-width: 2.5px;
  }

  &.vcs--middle{
    width:60px;
    height:60px;
    font-size: 22px;
    border-width: 4px;
  }

  &.vcs--big{
    width: 90px;
    height: 90px;
    font-size: 32px;
    border-width: 6px;
  }

  &.vcs--good{
    background-color:#E5F2E5;
    border-color: #0000000D;
    color: #219E26;
  }
  &.vcs--normal{
    background-color: #FDF4EB;
    border-color: #0000000D;
    color: #EF8E34;
  }
  &.vcs--bad{
    background-color: #FFEBED;
    border-color: #0000000D;
    color:#F2001D;
  }
}
</style>