<template>
  <div class="bc-seo-packages bcsps">

    <div class="bcsps__list" v-if="useCreditTypes().creditTypeSeo?.firstPlanOptions.packagesSortedByPrice.length > 0">

      <bc-choose-package
          v-for="pack in useCreditTypes().creditTypeSeo?.firstPlanOptions.packagesSortedByPrice.slice(0,-1)"
          :key="pack.id"
          class="bcsps__item"
          :plan-options-id="useCreditTypes().creditTypeSeo?.firstPlanOptions.id"
          :plan-options-package-id="pack.id"
          @add-to-cart-click="emit('add-to-cart-click')"
      />
      <bc-choose-package
          :key="useCreditTypes().creditTypeSeo?.firstPlanOptions?.packagesSortedByPrice.at(-1).id"
          class="bcsps__item"
          :plan-options-id="useCreditTypes().creditTypeSeo?.firstPlanOptions.id"
          :plan-options-package-id="useCreditTypes().creditTypeSeo?.firstPlanOptions.packagesSortedByPrice.at(-1).id"
          @add-to-cart-click="emit('add-to-cart-click')"
          is-custom
      />

    </div>

  </div>
</template>

<script setup>

import {useCreditType, useCreditTypes} from "../../stores/useBilling";
import BcChoosePackage from "./BcChoosePackage.vue";

const emit = defineEmits(['add-to-cart-click']);

</script>

<style scoped>
.bc-seo-packages.bcsps{

  .bcsps__list{

    display:flex;
    flex-direction: column;
    gap:0px;

  }

  .bcsps__item{
    &:last-child{
      border-bottom:0px;
    }
  }

}
</style>