import {h, render} from 'vue';
import VTooltipQuestionIcon from "../components/Base/VTooltipQuestionIcon.vue";
import {getTooltip} from "../utils/tooltipsData";

export default {
    mounted: async (el, binding) => {

        el.setAttribute('data-can-be-question', 'custom-by-name');

        let tooltipData = getTooltip('custom',el.getAttribute('data-name'));

        if(!tooltipData){return;}

        const tooltipVnode = h(VTooltipQuestionIcon,{tooltipData:tooltipData});

        tooltipVnode.appContext = binding.instance.$.appContext;

        const mountNode = document.createElement('span');

        el.appendChild(mountNode);

        render(tooltipVnode, mountNode);
    }
};