import {ref} from 'vue';
import {useServerTime} from "../stores/useUtils";

export default {
    timer: null,

    install() {
        //if(useServerTime().hasPreloadData()){
            useServerTime().updateFromServer().then(() => {
                if(this.timer !== null){
                    clearInterval(this.timer);
                }
                this.timer = setInterval(() => {
                    useServerTime().updateRealTimeServer();
                },500);
            });
        //}
    }
};