<template>

  <div class="lb-project-head-demo lbph lbph--demo" :class="mainClass" >

    <lb-project-demo-bar class="lbph__demo-bar" :project-uniquekey="projectUniquekey" v-if="!isMobile"/>

    <v-white-container class="lbph__background" without-padding>

      <lb-project-demo-blackout
          :project-uniquekey="projectUniquekey"
          v-if="(projectStore.demo_status === 'delivered' || projectStore.demo_status === 'expired') && !isMobile"
      />

      <div class="lbph__content-top">

        <v-white-container only-padding style="padding-bottom:0px;" class="lbph__content-top-container">
          <div class="lbph__top">

            <div class="lbph__title-tag" v-if="isMobile">DEMO PROJECT</div>

            <v-form-string-input
                label="Project name"
                :value="projectStore.name"
                ref="refProjectTitle"
                @save="saveName"
                required
                data-name="project-name"
            >
              <template v-slot:default>
                <v-head-title>
                  {{projectStore.name}}
                </v-head-title>
              </template>
            </v-form-string-input>

            <div class="lbph__keyword" v-if="!isMobile">
              <div class="lbph__keyword_setting">Keyword: </div>
              <v-form-string-input
                  class="lbph__keyword_wrap"
                  label="Keyword"
                  :value="projectStore.keyword"
                  ref="refProjectKeyword"
                  @save="saveKeyword"
                  required
                  data-name="keyword"
              >
                <template v-slot:default>
                  <div class="lbph__keyword_inner">
                    {{projectStore.keyword}}
                  </div>
                </template>
              </v-form-string-input>
            </div>

            <v-form-string-input
                label="Website"
                :value="projectStore.url"
                ref="refProjectUrl"
                @save="saveUrl"
                required
                data-name="url"
            >
              <template v-slot:default>
                <v-link :href="projectStore.correctUrlLink" target="_blank">{{projectStore.shortUrl}}</v-link>
              </template>
            </v-form-string-input>
          </div>
          <lb-project-demo-bar class="lbph__demo-bar" :project-uniquekey="projectUniquekey" v-if="isMobile"/>
        </v-white-container>

      </div>

      <div class="lbph__content-bottom">

        <lb-project-demo-blackout
            :project-uniquekey="projectUniquekey"
            v-if="(projectStore.demo_status === 'delivered' || projectStore.demo_status === 'expired') && isMobile"
        />

        <v-white-container only-padding style="padding-top:0px;">
          <div class="lbph__keyword" v-if="isMobile">
            <div class="lbph__keyword_setting">Keyword: </div>
            <v-form-string-input
                class="lbph__keyword_wrap"
                label="Keyword"
                :value="projectStore.keyword"
                ref="refProjectKeyword"
                @save="saveKeyword"
                required
                data-name="keyword"
            >
              <template v-slot:default>
                <div class="lbph__keyword_inner">
                  {{projectStore.keyword}}
                </div>
              </template>
            </v-form-string-input>
          </div>

          <div class="lbph__body" :class="mainClassBody">
          <div class="lbph__body-left">

            <div class="lbph__settings">

              <div class="lbph__settings-row">
                <div class="lbph__settings-left">
                  Category:
                </div>

                <div class="lbph__settings-right">
                  <v-form-string-select
                      :options="backlinkCategories"
                      :value="projectStore.categoryForSelect"
                      @save="saveCategory"
                      :tooltip-options="{placement:'bottom-end', style:{maxWidth:'194px'}}"
                      ref="refBacklinkCategory"
                      data-name="category_id"
                  >
                    <template v-slot:default>
                  <span class="lbph__settings-category" v-if="projectStore.categoryForSelect?.name">
                    {{projectStore.categoryForSelect?.name}}
                  </span>
                      <span v-else>
                    Not selected
                  </span>
                    </template>
                  </v-form-string-select>
                </div>
              </div>

              <div class="lbph__settings-row lbph__settings-row--comment" :class="projectStore.hasAnalytics ? 'lbph__settings-row--vertical' : ''">
                <div class="lbph__settings-left">
                  Comments:
                </div>

                <div class="lbph__settings-right">
                  <v-form-string-input
                      label="Comments"
                      :value="projectStore.owner_comments"
                      ref="refProjectComment"
                      @save="saveComment"
                      required
                      :can-be-empty="true"
                      data-name="owner_comments"
                  >
                    <template v-slot:default>
                      <div class="lbph__settings-comment">
                        <template v-if="projectStore.owner_comments !== null && projectStore.owner_comments.trim() !== ''">
                          {{projectStore.owner_comments}}
                        </template>
                        <template v-else>
                          No comments
                        </template>
                      </div>
                    </template>
                  </v-form-string-input>
                </div>
              </div>

            </div>

          </div>
          <div class="lbph__body-right" v-if="useLinkBuildProject(projectUniquekey).hasAnalytics">
            <lb-project-analytics :project-uniquekey="projectUniquekey"/>
          </div>
        </div>
        </v-white-container>

      </div>

    </v-white-container><!-- END контейнер для контента -->
  </div>

</template>

<script setup>

import VFormStringInput from "../VForm/VFormStringInput.vue";
import VHeadTitle from "../Base/VHeadTitle.vue";
import {computed, ref, getCurrentInstance, inject} from "vue";
import VLink from "../Base/VLink.vue";
import VWhiteContainer from "../Base/VWhiteContainer.vue";
import LbProjectAnalytics from "./LbProjectAnalytics.vue";
import {useLinkBuildProject} from "../../stores/useLinkBuilding";
import VFormStringSelect from "../VForm/VFormStringSelect.vue";
import {useStaticData} from "../../stores/useStaticData";
import LbProjectDemoBar from "./LbProjectDemoBar.vue";
import LbProjectDemoBlackout from "./LbProjectDemoBlackout.vue";

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const refProjectTitle = ref();
const refProjectKeyword = ref();
const refProjectUrl = ref();
const refProjectComment = ref();
const refBacklinkCategory = ref();

const props = defineProps({
  projectUniquekey: {}
});

const projectStore = useLinkBuildProject(props.projectUniquekey);

const mainClass = computed(() => {
  let response = [];
  if(projectStore.demo_status === 'delivered'){response.push('is-demo-delivered');}
  if(projectStore.demo_status === 'expired'){response.push('is-demo-expired');}
  return response;
});

const mainClassBody = computed(() => {
  let response = [];
  if(projectStore.hasAnalytics) response.push('lbph__body--has-analytics');
  if(projectStore.isDisabled) response.push('is-disabled');
  return response;
});

const saveKeyword = async (keyword) => {
  let [status,message] = await projectStore.saveKeyword(keyword);
  status ? refProjectKeyword.value.saveWithSuccess() : refProjectKeyword.value.saveWithError(message);
}

const saveUrl = async (url) => {
  let [status,message] = await projectStore.saveUrl(url);
  status ? refProjectUrl.value.saveWithSuccess() : refProjectUrl.value.saveWithError(message);
}

const saveComment = async (comment) => {
  let [status,message] = await projectStore.saveComment(comment);
  status ? refProjectComment.value.saveWithSuccess() : refProjectComment.value.saveWithError(message);
}

const saveName = async (name) => {
  let [status,message] = await projectStore.saveName(name);
  status ? refProjectTitle.value.saveWithSuccess() : refProjectTitle.value.saveWithError(message);
}

const saveCategory = async (category) => {
  let [status,message] = await projectStore.saveCategory(category.value);
  status ? refBacklinkCategory.value.saveWithSuccess() : refBacklinkCategory.value.saveWithError(message);
}

const backlinkCategories = computed(() => {
  return [{'value': null, 'name': 'Not selected'}, ...useStaticData().backlinksCategoriesForSelect];
});

</script>

<style lang="scss" scoped>
.lb-project-head-demo.lbph.lbph--demo{



  .lbph__demo-bar{
    @media(min-width:768px){
      border-bottom-left-radius:0px;
      border-bottom-right-radius:0px;
    }
  }

  .lbph__title-tag{
    font-size: 11px;
    font-weight: 600;
    color:#AEB7C7;
  }

  .lbph__content-bottom{
    position:relative;
  }

  .lbph__background{
    display:flex;
    gap:20px;
    flex-direction:column;
    @media(max-width:767px){
      gap:14px;
    }
    @media(min-width:768px){
      border-top-left-radius:0px;
      border-top-right-radius:0px;
    }
  }

  .lbph__content-top{
    .lbph__content-top-container{
      @media(max-width:767px){
        @include flex(column,start,stretch,16px);
        width:100%;
      }
    }
  }

  .lbph__content-bottom{
    .lbph__keyword{
      padding-bottom:16px;
      border-bottom:1px solid rgba(214, 227, 239, 0.5019607843);
      margin-bottom:16px;
      margin-top:2px;
    }
  }

  .lbph__top{
    @include flex(column,start,stretch,10px);
    width:100%;
    border-bottom:1px solid rgba(214, 227, 239, 0.5019607843);
    padding-bottom:20px;
    @media(max-width:767px){
      padding-bottom:0px;
      border-bottom:none;
      @include flex(column,start,stretch,4px);
    }
  }

  .lbph__body{
    display: flex;
    justify-content: space-between;
    gap:30px;
    align-items: flex-start;
    @media(max-width: 767px){
      flex-direction:column;
      align-items:stretch;
      gap:16px;
    }

    .lbph__body-left{

    }

    .lbph__body-right{
      width:530px;
      flex-shrink:0;
      @media(max-width: 767px){
        width:100%;
      }
    }

    &.lbph__body--has-analytics{
      .lbph__body-left{
        flex-grow:2;
      }
    }

  }

  .lbph__keyword{
    display:flex;
    align-items:center;
    gap:9px;

    .lbph__keyword_setting{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color:#596C8F;
    }
    .lbph__keyword_wrap{
      flex-grow: 2;
    }
    .lbph__keyword_inner{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color:#384967;
    }
  }

  .lbph__settings{
    display: flex;
    flex-direction:column;
    gap:12px;

    .lbph__settings-row{
      display:flex;
      gap:10px;
      &.lbph__settings-row--vertical{
        flex-direction:column;
        @media(max-width: 767px){
          gap:4px;
        }
      }
      @media(max-width: 767px){
        &.lbph__settings-row--comment{
          flex-direction:column;
          gap:4px;
        }
      }
    }
    .lbph__settings-left{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #596C8F;
      min-width:100px;
    }
    .lbph__settings-right{

    }

    .lbph__settings-comment{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #384967;
    }
    .lbph__settings-category{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #384967;
    }
  }

  &.is-demo-delivered, &.is-demo-expired{
    @media(min-width:768px){
      .lbph__background{
        min-height:210px;
        position:relative;
        overflow:hidden;
      }
    }
  }

}
</style>