<template>
  <div class="v-project-preview" :class="mainClass">

    <div class="content">
      <slot name="default"></slot>
    </div>

    <div class="modal-blackout" v-if="modalBlackout" :class="modalBlackoutClass">
      <slot name="blackout"></slot>
    </div>

    <slot name="content-after"></slot>

  </div>
</template>

<script setup>

import {computed} from "vue";

const props = defineProps({
  /**
   * @type {'seotraffic'|'link-building'}
   */
  type: {type:String},
  isDemo: {type:Boolean, default:false,},
  /**
   * @type {'black'|'red'}
   */
  modalBlackout: {type:String},
});

const mainClass = computed(() => {
  let response = [];
  if(props.isDemo){
    response.push('is-demo');
  }
  if(props.modalBlackout){
    response.push('is-modal-blackout');
  }
  return response;
});

const modalBlackoutClass = computed(() => {
  let response = [];
  if(props.modalBlackout){
    response.push(`is-${props.modalBlackout}`);
  }
  return response;
})

</script>

<style lang="scss" scoped>
.v-project-preview{
  &:not(.is-demo){@include project-shadow(seotraffic);}
  &.is-demo{@include project-shadow(seotraffic, $is-demo: true);}

  .content{
    width:calc(100% - 4px);
  }

  .modal-blackout{
    width:100%;
    height:100%;
    position:absolute;
    top:0px;
    left:0px;
    z-index:2;
    padding-left:4px;
    &.is-black{
      background: linear-gradient(90deg, rgba(56, 73, 103, 0.7) 0%, rgba(56, 73, 103, 0.85) 50%, rgba(56, 73, 103, 0.7) 100%);
    }
    &.is-red {
      background: linear-gradient(90deg, rgba(133, 28, 28, 0.7) 0%, rgba(133, 28, 28, 0.85) 50%, rgba(133, 28, 28, 0.7) 100%);
    }
  }

  &.is-demo{
    .modal-blackout{
      padding-left:24px;
    }
    .content{
      width:calc(100% - 24px);
    }
  }

  &.is-modal-blackout{
    overflow:hidden;
  }
}
</style>