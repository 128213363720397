<template>
  <div class="bc-payer-bank-transfer bcpbt" :class="mainClass">

    <transition name="fade" mode="out-in">
      <div v-if="!usePayBankTransfer().windowActive" class="bcpbt__inner">
        <div class="bcpbt__group" v-for="item in bankTransferItems">
          <div class="bcpbt__icons" v-if="item.icons.length > 0">
            <img v-for="icon in item.icons" :src="icon.src" :style="icon.style">
          </div>
          <div class="bcpbt__button-wrap">
            <v-button
                color="green"
                class="bcpbt__button"
                @click="openPayWindow"
                :loading="payButtonLoading"
                :disabled="useBillingCart().totalPrice === 0"
            >{{item.buttonTitle}}</v-button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div v-if="usePayBankTransfer().windowActive">
        <bc-payer-bank-transfer-payment/>
      </div>
    </transition>

  </div>
</template>

<script setup>
import VButton from "../../Base/VButton.vue";
import {computed, reactive, ref, watch} from "vue";
import {usePayBankTransfer} from "../../../stores/usePayBankTransfer.js";
import BcPayerBankTransferPayment from "./BcPayerBankTransferPayment.vue";
import {useBillingCart, useBillingCartItem} from "../../../stores/useBilling";

const bankTransferItems = reactive([
  {
    icons: [
      {
        src:'/img/icons/bank.svg',
        style: {
          width:'48px',
          height:'48px'
        }
      }
    ],
    buttonTitle: 'Pay by Bank Transfer',
  }
]);

const props = defineProps({
  items: {type:Array,default:[]},
  type: {}
});

const mainClass = computed(() => {
  return 'bcpbt--bank';
});

const payButtonLoading = ref(false)

const openPayWindow = () => {
  payButtonLoading.value = true;
  usePayBankTransfer().pay().then((response) => {
    payButtonLoading.value = false;
    let [status, message] = response;
    if(status){
      usePayBankTransfer().openWindow();
    }else{
      console.log('error', message);
    }
  });
}

const cancelPayment = () => {
  usePayBankTransfer().closeWindow();
  usePayBankTransfer().clearOrder();
}

watch(() => useBillingCart().totalPrice, () => {
  cancelPayment();
});

</script>

<style lang="scss" scoped>

.bc-payer-bank-transfer.bcpbt{


  .bcpbt__inner:first-child{
    display: flex;
    flex-direction: column;
    gap:20px;
  }

  .bcpbt__group{
    display:flex;
    flex-direction:column;
    gap:20px;

    @media(max-width: 767px){
      gap:16px;
    }

    .bcpbt__icons{
      display:flex;
      justify-content:center;
      align-items:center;
      gap:16px;
      flex-wrap:wrap;
      padding:6px;

      @media(max-width:767px){
        padding:0px;
        gap:6px;
      }

      img {
        width:48px;
        height:48px;
      }


    }

    .bcpbt__button-wrap{
      .bcpbt__button{
        width:100%;
        font-weight:600;
      }
    }

  }

}

.fade-enter-from{
  opacity:0;
}
.fade-enter-active{
  transition: opacity 0.5s;
}
.fade-enter-to{
  opacity:1;
}
.fade-leave-from{
  display:none;
}
.fade-leave-active{
  display:none;
}
.fade-leave-to{
  display:none;
}

</style>