<template>
  <div class="lb-project-settings lbps" ref="refRoot" :class="linkBuildProjectStore.isDisabled ? 'is-disabled' : ''">

    <div class="lbps__top" v-if="!isMobile && backlinksRecommendations.canBeShown">
      <lb-keyword-difficulty :project-uniquekey="projectUniquekey"/>
    </div>

    <div class="lbps__body" :class="autoRenewalsStore.count === 0 ? 'lbps__body--no-have-auto-renewals' : ''">

      <div class="lbps__content">
        <lb-add-backlinks :project-uniquekey="projectUniquekey"/>
        <lb-project-recommendation v-if="isMobile && backlinksRecommendations.canBeShown" :project-uniquekey="projectUniquekey"/>
        <lb-auto-renewal :project-uniquekey="projectUniquekey" class="lbps__auto-renewal"/>
      </div>
      <div class="lbps__sidebar" v-if="!isMobile">
        <lb-project-recommendation :project-uniquekey="projectUniquekey" v-if="false"/>
      </div>

    </div>

  </div>
</template>

<script setup>

import LbAddBacklinks from "./LbAddBacklinks.vue";
import LbProjectRecommendation from "./LbProjectRecommendation.vue";
import LbAutoRenewal from "./LbAutoRenewal.vue";
import {
  useAutoRenewals,
  useLinkBuildProjectOrders,
  useLinkBuildProject,
  useBacklinksRecommendations
} from "../../stores/useLinkBuilding";
import {computed, getCurrentInstance, ref, watch} from "vue";
import LbKeywordDifficulty from "./LbKeywordDifficulty.vue";

const props = defineProps({
  projectUniquekey: {}
});

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const linkBuildProjectStore = useLinkBuildProject(props.projectUniquekey);
const autoRenewalsStore = useAutoRenewals(props.projectUniquekey);
const backlinksRecommendations = useBacklinksRecommendations(props.projectUniquekey);

const refRoot = ref();

defineExpose({refRoot})

</script>

<style lang="scss" scoped>
.lb-project-settings.lbps{
  @include flex(column,start,stretch,0px);
  border: 1px solid #E5ECF9;
  border-radius: 4px;

  @media(max-width:767px){
    padding:16px;
  }

  .lbps__top{
    overflow:hidden;
    border-radius:4px 4px 0px 0px
  }

  &.is-disabled{
    position:relative;
    opacity:0.5!important;
    &:after{
      content:'';
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
    }
  }

  .lbps__body{
    width:100%;
    padding:30px;
    display:flex;
    justify-content: space-between;
    gap:45px;

    .lbps__content{
      @include flex(column,start,stretch,16px);
      width:50%;
    }
    .lbps__sidebar{
      width:50%;
    }

    @media(max-width:767px){
      flex-direction:column;
      align-items:stretch;
      gap:16px;
      padding: 0px;
      .lbps__content{
        width:100%;
      }
      .lbps__sidebar{
        width:100%;
      }
    }

    &.lbps__body--no-have-auto-renewals{
      align-items:stretch;
      .lbps__sidebar{
        display:flex;
        align-items:stretch;
        .lbps__auto-renewal{
          width:100%;
        }
      }
    }

  }

}

</style>