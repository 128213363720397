<template>
  <div class="v-lb-recommendations-tooltip">
    <div class="section-left">

      <div class="chart-title">
        Recommendations to buy
      </div>

      <v-lb-recommended-backlinks-bagel
          :ratio-number-links="backlinksRecommendations.recommendations"
          :amount-links="backlinksRecommendations.totalCountBacklinks"
      />

    </div>
    <div class="section-right">

      <div class="data-info">

        <div class="data-info__title">Backlinks /type</div>
        <div class="data-info__title">Backlinks /month</div>

        <template v-for="(count,typeConstant) in backlinksRecommendations.recommendationsCount">

          <v-product :type-constant="typeConstant" class="data-info__product"/>
          <div class="data-info__count" v-text="count"/>

        </template>

      </div>

    </div>
    <div class="close" v-if="false"><v-icon-svg name="blue-close" class="close-icon" @click="emit('close')"/></div>
  </div>
</template>

<script setup>

import VProduct from "../VProduct.vue";
import VLbRecommendedBacklinksBagel from "./VLbRecommendedBacklinksBagel.vue";
import {useBacklinksRecommendations} from "../../../stores/useLinkBuilding";
import VIconSvg from "../VIconSvg.vue";

const props = defineProps({
  projectUniquekey:{},
});

const emit = defineEmits(['close']);

const backlinksRecommendations = useBacklinksRecommendations(props.projectUniquekey);

</script>

<style lang="scss" scoped>
.v-lb-recommendations-tooltip{
  @include flex(row,between,start,24px);
  padding:24px;
  cursor:default;

  .section-left{
    @include flex(column,start,start,8px);
    width:133px;
    flex-shrink:0;
  }

  .section-right{
    width:133px;
    flex-shrink:0;
  }

  .chart-title{
    font-size: 14px;
    font-weight: 400;
    color:#596C8F;
  }

  .data-info{
    @include flex(row,between,start,12px);
    flex-wrap:wrap;
    width:100%;

    > * {
      width:52px;
      flex-shrink:0;
    }

    .data-info__title{
      font-size: 12px;
      color:#384967;
      opacity:0.4;
    }
    .data-info__product{
      font-size:14px;
      font-weight:600;
    }
    .data-info__count{
      font-weight:700;
      font-size:15px;
      color:#384967;

    }
  }

  .close{
    position:absolute;
    top:10px;
    right:10px;
    .close-icon{
      width:16px;
      height:16px;
      cursor:pointer;
      opacity:0.3;
      transition-duration: 0.2s;
      &:hover{
        opacity:1;
      }
    }
  }

}
</style>