<template>
  <sc-project-keyword-grid
      class="sc-project-keyword scpk"
      :is-active="useKeyword?.is_active"
      :class="mainClasses"
      :is-deleted="!!useKeyword?.is_deleted"
  >

    <template v-slot:checkbox>

      <v-form-checkbox v-model="useKeywords._listChecked[useKeyword.id]"/>

    </template>

    <template v-slot:keyword>

      <div class="scpk_keyword">
        <span class="scpk_keyword-icon">
          <v-icon-flag :code="useKeyword.region"/>
        </span>
        <span>{{useKeyword.keyword}}</span>
      </div>

    </template>

    <template v-slot:recommendation>

      <sc-project-keyowrd-recommendations
          v-if="!useKeyword.is_deleted && (useRecommendations.hasRecommendation || !useKeyword.is_top100)"
          class="scpk__recommendations-col-inner"
          :project-id="projectId"
          :keyword-id="keywordId"
      />
      <recommendation-daily-limit
        v-else-if="!useKeyword.is_deleted && useKeyword.hasRecomDaily"
        :top1="useKeyword.recom_daily_top1"
        :top3="useKeyword.recom_daily_top3"
        :top10="useKeyword.recom_daily_top10"
        @choose-daily="chooseDaily"
      />

    </template>

    <template v-slot:chart>

      <chart-seo-clicks
          v-show="!useKeyword.is_deleted"
          :chart="useKeyword.chart_data"
          :no-if-null="true"
          class="scpks__chart"
          :scale-hover="true"
      />

    </template>

    <template v-slot:daily-limit>

      <v-form-input-number-edit
          v-show="!useKeyword.is_deleted"
          type="events"
          :value="useKeyword.max_clicks_per_day"
          @save="saveMaxClicksPerDay"
          :filter-data="(val) => {return val >= 1;}"
          ref="inputMaxClicksPerDay"
      />
      <span v-show="!!useKeyword.is_deleted" class="scpk__label-is-deleted">Deleted</span>

    </template>

    <template v-slot:delete>

      <div
          class="scpk__delete"
          :class="!useKeyword.is_active ? 'scpk__delete--project-none-active' : ''"
          v-show="!useKeyword.is_deleted"
      >
        <v-icon-svg name="delete-icon" class="scpk__delete-icon" @click="deleteConfirm" ref="modalConfirmDelete"/>
        <v-modal-confirm ref="deleteModalConfirm" @confirm="deleteKeyword">
          <template v-slot:title>
            Delete keyword
          </template>
          <template v-slot:description>
            Do you really want to delete a keyword ? <br>Keyword will be lost
          </template>
        </v-modal-confirm>
      </div>

    </template>

    <template v-slot:status>

      <v-form-checkbox-slider
          v-show="!useKeyword.is_deleted"
          type="events"
          :value="!!useKeyword.is_active"
          @save="saveCheckboxStatus"
          ref="checkboxStatus"
      />
      <v-link href="#" v-show="!!useKeyword.is_deleted" class="scpk__restore" @click.prevent="restoreKeyword">Restore</v-link>

    </template>

  </sc-project-keyword-grid>
</template>

<script setup>

import VFormCheckbox from "../VForm/VFormCheckbox.vue";
import VFormCheckboxSlider from "../VForm/VFormCheckboxSlider.vue";
import VIconFlag from "../Base/VIconFlag.vue";
import RecommendationKeyword from "../Blocks/RecommendationKeyword.vue";
import ChartSeoClicks from "../Chart/ChartSeoClicks.vue";
import VFormInputNumberEdit from "../VForm/VFormInputNumberEdit.vue";
import VIconSvg from "../Base/VIconSvg.vue";
import VModalConfirm from "../Base/VModalConfirm.vue";
import {computed, ref} from "vue";
import {
  useSeoClicksKeywordsRecommendations,
  useSeoClicksKeyword,
  useSeoClicksKeywords
} from "../../stores/useSeoClicks.js"
import ScProjectKeywordGrid from "./ScProjectKeywordGrid.vue";
import VLink from "../Base/VLink.vue";
import RecommendationDailyLimit from "../Blocks/RecommendationDailyLimit.vue";
import ScProjectKeyowrdRecommendations from "./ScProjectKeyowrdRecommendations.vue";

const props = defineProps({
  keywordId: {},
  projectId: {}
});

const useKeyword = useSeoClicksKeyword(props.projectId,props.keywordId);
const useRecommendations = useSeoClicksKeywordsRecommendations(props.keywordId);
const useKeywords = useSeoClicksKeywords(props.projectId);

const mainClasses = computed(() => {
  let classes = [];
  if(!useKeyword?.is_active){
    classes.push('scpk--no-active');
  }
  if(!!useKeyword.is_deleted){
    classes.push('scpk--deleted');
  }
  return classes;
});

const deleteConfirm = () => {
  deleteModalConfirm.value.confirm();
}

const inputMaxClicksPerDay = ref();
const saveMaxClicksPerDay = async (value) => {
  let [status,message] = await useKeyword.updateDailyLimit(value);
  if(status){
    inputMaxClicksPerDay.value.saveWithSuccess();
  }else{
    inputMaxClicksPerDay.value.saveWithError();
  }
}


const checkboxStatus = ref();
const saveCheckboxStatus = async (value) => {
  let [status,message] = await useKeyword.updateIsActive(value);
  if(status){
    checkboxStatus.value.saveWithSuccess();
  }else{
    checkboxStatus.value.saveWithError();
  }
}

const deleteModalConfirm = ref();
const deleteKeyword = async () => {

  let [status,message] = await useKeyword.updateIsDeleted(true);
  if(status){
    deleteModalConfirm.value.closeWithSuccess();
  }else{
    deleteModalConfirm.value.closeWithError(message);
  }

}

const restoreKeyword = async () => {
  let [status,message] = await useKeyword.updateIsDeleted(false);
}

const chooseDaily = (number) => {
  inputMaxClicksPerDay.value.setContentEditable(number);
}

</script>

<style scoped lang="scss">
.sc-project-keyword.scpk{


  .scpk__delete{
    opacity:0.8;
    transition-duration: 0.2s;
    transition-property: opacity;
    cursor: pointer;
    width:20px;
    height:20px;
    display: inline-block;
  }

  .scpk__delete:hover{
    opacity:1;
  }

  .scpk_keyword{
    display: flex;
    align-items: center;
    gap:8px;
  }

  .scpks__chart{
    width:161px;
    height:44px;
    @media(max-width: 1190px){
      width:111px;
    }
  }

  &.scpk--no-active{
    .scpk_keyword{
      color:#1F293B;
    }
    .scpk_keyword > span{
      opacity:0.3;
    }
    .scpk__recommendations-col-inner{
      opacity:0.3;
    }
    .scpks__chart{
      opacity: 0.3;
    }
  }

  @media(max-width: $mobile-width){
    .scpks__chart{
      width:108px;
      height:32px;
    }
    .scpk__delete{
      opacity:0!important;
    }
    .scpk__delete.scpk__delete--project-none-active{
      opacity:0.5!important;
    }
  }

  .scpk__label-is-deleted{
    color: #AEB7C7;
  }
  .scpk__restore{
    position: relative;
    left:-11px;
  }

}
</style>