<template>
  <div class="lb-auto-reneal lbar" :class="mainClass">

    <template v-if="autoRenewalsStore.count > 0">

      <v-form-loading-lock v-if="loadingUpdate"/>

      <div class="lbar__title" data-name="renewal-backlinks" v-auto-tooltip>
        <v-icon-svg name="icon-renewal" class="lbar__title-icon" as-img/>
        Renewal backlinks {{autoRenewalsStore.list.length}}
      </div>

      <div class="lbar__list">
        <div class="lbar__list-item" v-for="item in autoRenewalsStore.list" @mouseenter="listItemMouseEnter(item)" @mouseleave="listItemMouseLeave(item)">
          <div class="lbar__list-item-left">
            <div class="lbar__list-item-title">
              <v-product :type-constant="item.backlink_option.typeConstant"/>
            </div>
            <div class="lbar__list-item-will-be-added">
              Starts in {{item.next_renewal_days}} days
            </div>
          </div>
          <div class="lbar__list-item-right" v-if="!isMobile">
            <v-icon-svg name="icon-delete-gray" class="lbar__list-item-delete" @click="deleteAutorenewalConfirm(item)"/>
            <v-form-input-counter v-model="item.countInSite" :min="1" class="lbar__list-item-counter" :hide-background="!itemIsHover(item)"/>
          </div>
          <div class="lbar__list-item-right" v-else>
            <div class="lbar__list-item-counter-text">
              {{item.count}}
            </div>
          </div>
        </div>
      </div>

      <div class="lbar__group-buttons" v-if="autoRenewalsStore.hasUserChanges || loadingUpdate">
        <v-button color="link" class="lbar__button lbar__button--cancel" @click.prevent="autoRenewalsStore.resetCounts()">Cancel changes</v-button>
        <v-button color="blue2-stroke" class="lbar__button lbar__button--save" @click="saveChanges" :loading="loadingUpdate">Save</v-button>
      </div>

    </template>

    <template v-else>
      <div class="lbar__have-no" data-name="you-have-no-auto-repeat-yet" v-auto-tooltip>
        You have no Auto-Repeat yet
      </div>
    </template>

    <v-modal-confirm ref="deleteModalConfirm" @confirm="deleteAutorenewal">
      <template v-slot:title>
        Delete Auto-repeat
      </template>
      <template v-slot:description>
        Do you really want to delete?
      </template>
    </v-modal-confirm>

  </div>
</template>

<script setup>

import VFormInputCounter from "../VForm/VFormInputCounter.vue";
import VIconSvg from "../Base/VIconSvg.vue";
import {useAutoRenewals} from "../../stores/useLinkBuilding";
import VButton from '../Base/VButton.vue';
import {computed, getCurrentInstance, inject, onMounted, onUnmounted, reactive, ref} from "vue";
import VModalConfirm from "../Base/VModalConfirm.vue";
import VFormLoadingLock from "../VForm/VFormLoadingLock.vue";
import VProduct from "../Base/VProduct.vue";
import VTooltipQuestionIcon from "../Base/VTooltipQuestionIcon.vue";

const props = defineProps({
  projectUniquekey: {},
});

const mainClass = computed(() => {
  return (autoRenewalsStore.count === 0) ? 'lbar--have-no' : '';
});

const { proxy } = getCurrentInstance();
const isMobile = computed(() => proxy.$isMobile.value);

const autoRenewalsStore = useAutoRenewals(props.projectUniquekey);

const loadingUpdate = ref(false);

const deleteModalConfirm = ref();

const itemIsHover = (item) => {
  return itemsHoverArr.indexOf(item.id)!==-1;
}

const itemsHoverArr = reactive([]);

const listItemMouseEnter = (item) => {
  itemsHoverArr.push(item.id);
}
const listItemMouseLeave = (item) => {
  let index = itemsHoverArr.indexOf(item.id);
  if(index !== -1){
    itemsHoverArr.splice(index,1);
  }
}

const currentAutorenewalIdForDelete = ref(null)
const deleteAutorenewalConfirm = (item) => {
  currentAutorenewalIdForDelete.value = item.id;
  deleteModalConfirm.value.confirm();
}

const deleteAutorenewal = async () => {
  let [status,message] = await autoRenewalsStore.deleteAutoRenewalById(currentAutorenewalIdForDelete.value);
  if(status) {
    deleteModalConfirm.value.closeWithSuccess();
  }else{
    deleteModalConfirm.value.closeWithError(message);
  }

}

const saveChanges = () => {
  if(loadingUpdate.value){return;}
  loadingUpdate.value = true;
  autoRenewalsStore.saveUserCountChanges().then(() => {
    autoRenewalsStore.download().then(() => {
      loadingUpdate.value = false;
    })
  });
}

</script>

<style lang="scss" scoped>
.lb-auto-reneal.lbar{
  position:relative;
  display:flex;
  flex-direction: column;
  gap:10px;
  align-items: stretch;

  .lbar__title{
    display:flex;
    align-items:center;
    gap:10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color:#AEB7C7;
    height:24px;
    padding-right:10px;
    padding-left:10px;
  }

  .lbar__list{

    .lbar__list-item{
      display:flex;
      justify-content: space-between;
      align-items: center;
      height:60px;
      border-bottom: 1px solid #D6E3EF80;
      padding-left:10px;
      padding-right:10px;
      background-color: transparent;
      transition-duration: 0.2s;
      transition-property: background-color;

      .lbar__list-item-left{
        display:flex;
        flex-direction: column;
        gap:2px;
        align-items: flex-start;
      }
      .lbar__list-item-right{
        display:flex;
        align-items:center;
        gap:10px;
      }
      .lbar__list-item-title{
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color:#384967;
      }
      .lbar__list-item-will-be-added{
        font-size: 12px;
        font-weight: 400;
        color:#AEB7C7;
      }
      .lbar__list-item-delete{
        opacity:0;
        cursor:pointer;
      }
      .lbar__list-item-counter{

      }
      .lbar__list-item-counter-text{
        width:40px;
        height:36px;
        display:flex;
        align-items:center;
        justify-content:center;
        font-size: 14px;
        font-weight: 600;
      }

      @media(min-width: 768px){
        &:hover{
          background-color:#F4F8FF;
          .lbar__list-item-delete{
            opacity:0.5;
          }
          .lbar__list-item-delete{
            opacity:1;
          }
        }
      }

      &:last-child{
        @media(max-width:767px){
          border-bottom:none;
        }
      }

    }
  }

  .lbar__group-buttons{
    display:flex;
    gap:5px;
    justify-content: flex-end;
    align-items: center;
    .lbar__button{
      padding-left:12px;
      padding-right:12px;
    }

  }

  .lbar__have-no{
    display:flex;
    align-items:center;
    justify-content:center;
    gap:6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color:#AEB7C7;
    width:100%;
    height:100%;
    @media(max-width:767px){
      justify-content:flex-start;
    }
  }

}
</style>