<template>
  <div class="v-form-group form-group" :class="mainClass" ref="formGroup">
    <slot/>
  </div>
</template>

<script setup>
import {ref, provide, computed} from "vue";
import {generateUUID} from "../../utils/utils";

const props = defineProps({
  isOneLine: {type: Boolean, default: false},
  disabled:{type:Boolean,default:false},
});

const mainClass = computed(() => {
  let classes = [];
  if(props.isOneLine){
    classes.push('v-form-group--is-one-line');
  }
  if(props.disabled){
    classes.push('v-form-group--disabled');
  }
  return classes;
});

const uid = ref('id-'+generateUUID());

const formGroup = ref();

const scrollToMe = (arg = {behavior: 'smooth'}) => {
  formGroup.value.scrollIntoView(arg);
};

provide('formGroupUid', uid);
provide('formGroupIsOneLine', props.isOneLine);

defineExpose({uid,scrollToMe});
</script>

<style lang="scss" scoped>
.v-form-group{
  margin-bottom: 20px;
}
.v-form-group--is-one-line{
  display: flex;
  align-items: center;
  gap:7px;
}
.v-form-group--disabled{
  background: #F9FAFA;
  :deep(> *){
    opacity:0.5;
  }
  position:relative;
  &:after{
    content:'';
    position:absolute;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    display:block;
    z-index:99;
  }
}
</style>