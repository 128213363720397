import _ from 'lodash'

export const runIfHasSelecter = function (selector, handlers) {
    $(document).ready(function() {
        const $element = $(selector);
        if (0 === $element.length) {
            return;
        }

        const data = $element.data('data') || {};
        const translations = $element.data('translations') || {};

        _.map(handlers, function (handler) {
            handler(data, translations);
        });
    });
}

export const makeQueryString = (params) => _.map(_.keys(params), (key) => `${key}=${params[key]}`).join('&')

export const buildNewUrl = function (params) {
    return window.location.pathname + '?' + makeQueryString(params);
}

export const escapeHtml = function (string) {
    const entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;'
    };

    return String(string).replace(/[&<>"'`=\/]/g, function (s) {
        return entityMap[s];
    });
}

export const prepareChartData = function (ctx, projectData, chartSize = 'normal') {
    if (!ctx || !projectData) {
        return {}
    }

    let typeTraffic = projectData.typeTraffic;
    let typeChart = (projectData.typeChart ? projectData.typeChart : 'line');
    let chartLabel = (projectData.translations.clicks ? projectData.translations.clicks : 'Clicks');

    if (typeTraffic === 'hits') {
        chartLabel = (projectData.translations.pageViews ? projectData.translations.pageViews : 'Page Views');
    } else if (typeTraffic === 'visits') {
        chartLabel = (projectData.translations.visits ? projectData.translations.visits : 'Visits');
    }

    let chartLabels = projectData.labels;
    // let maxChartLabels = (chartLabels.length > 10 ? Math.ceil((chartLabels.length) / 5) : 10);
    let maxChartLabels = (chartLabels.length > 10 ? 6 : 10);

    let chartData = projectData.data;
    let maxHits = parseInt(projectData.maxHits);
    maxHits = (maxHits < 0 ? 0 : maxHits);
    let maxHitsD = parseInt(maxHits + ((maxHits / 3) * 2));

    let minY = 0;
    let maxY = 10;

    if (maxHitsD <= 10) {
        maxY = 10
    } else if (maxHitsD > 10 && maxHitsD <= 30) {
        maxY = 30
    } else if (maxHitsD > 30 && maxHitsD <= 50) {
        maxY = 50
    } else if (maxHitsD > 50 && maxHitsD <= 100) {
        maxY = 100
    } else if (maxHitsD > 100 && maxHitsD <= 300) {
        maxY = 300
    } else if (maxHitsD > 300 && maxHitsD <= 500) {
        maxY = 500
    } else if (maxHitsD > 500 && maxHitsD <= 1000) {
        maxY = 1000
    } else if (maxHitsD > 1000 && maxHitsD <= 3000) {
        maxY = 3000
    } else if (maxHitsD > 3000 && maxHitsD <= 5000) {
        maxY = 5000
    } else if (maxHitsD > 5000 && maxHitsD <= 10000) {
        maxY = 10000
    } else if (maxHitsD > 10000) {
        let maxHits1 = parseInt(maxHitsD / 1000);
        // let maxHits2 = parseInt(maxHitsD % 1000);

        maxHitsD = parseInt(String(maxHits1) + '000');
        maxY = maxHitsD + 3000;
    }

    let stepSize = parseInt(maxY / 10);
    let maxTicksLimit = 6;

    if (typeChart === 'bar') {
        maxHitsD = parseInt(maxHits + (maxHits / 10));
        minY = 0;
        maxY = 10;

        if (maxHitsD <= 10) {
            maxY = 10;
        } else if (maxHitsD > 10 && maxHitsD <= 50) {
            maxY = 50;
        } else if (maxHitsD > 50 && maxHitsD <= 100) {
            maxY = 100;
        } else if (maxHitsD > 100 && maxHitsD <= 150) {
            maxY = 150;
        } else if (maxHitsD > 150 && maxHitsD <= 200) {
            maxY = 200
        } else if (maxHitsD > 200 && maxHitsD <= 250) {
            maxY = 250
        } else if (maxHitsD > 250 && maxHitsD <= 300) {
            maxY = 300
        } else if (maxHitsD > 300 && maxHitsD <= 350) {
            maxY = 350
        } else if (maxHitsD > 350 && maxHitsD <= 400) {
            maxY = 400
        } else if (maxHitsD > 400 && maxHitsD <= 450) {
            maxY = 450
        } else if (maxHitsD > 450 && maxHitsD <= 500) {
            maxY = 500
        } else if (maxHitsD > 500 && maxHitsD <= 550) {
            maxY = 550
        } else if (maxHitsD > 550 && maxHitsD <= 600) {
            maxY = 600
        } else if (maxHitsD > 600 && maxHitsD <= 650) {
            maxY = 650
        } else if (maxHitsD > 650 && maxHitsD <= 700) {
            maxY = 700
        } else if (maxHitsD > 700 && maxHitsD <= 750) {
            maxY = 750
        } else if (maxHitsD > 750 && maxHitsD <= 800) {
            maxY = 800
        } else if (maxHitsD > 800 && maxHitsD <= 850) {
            maxY = 850
        } else if (maxHitsD > 850 && maxHitsD <= 900) {
            maxY = 900
        } else if (maxHitsD > 900 && maxHitsD <= 950) {
            maxY = 950
        } else if (maxHitsD > 950 && maxHitsD <= 1000) {
            maxY = 1000
        } else if (maxHitsD > 1000 && maxHitsD <= 1500) {
            maxY = 1500
        } else if (maxHitsD > 1500 && maxHitsD <= 2000) {
            maxY = 2000
        } else if (maxHitsD > 2000 && maxHitsD <= 2500) {
            maxY = 2500
        } else if (maxHitsD > 2500 && maxHitsD <= 3000) {
            maxY = 3000
        } else if (maxHitsD > 3000 && maxHitsD <= 3500) {
            maxY = 3500
        } else if (maxHitsD > 3500 && maxHitsD <= 4000) {
            maxY = 4000
        } else if (maxHitsD > 4000 && maxHitsD <= 4500) {
            maxY = 4500
        } else if (maxHitsD > 4500 && maxHitsD <= 5000) {
            maxY = 5000
        } else if (maxHitsD > 5000 && maxHitsD <= 5500) {
            maxY = 5500
        } else if (maxHitsD > 5500 && maxHitsD <= 6000) {
            maxY = 6000
        } else if (maxHitsD > 6000 && maxHitsD <= 6500) {
            maxY = 6500
        } else if (maxHitsD > 6500 && maxHitsD <= 7000) {
            maxY = 7000
        } else if (maxHitsD > 7000 && maxHitsD <= 7500) {
            maxY = 7500
        } else if (maxHitsD > 7500 && maxHitsD <= 8000) {
            maxY = 8000
        } else if (maxHitsD > 8000 && maxHitsD <= 8500) {
            maxY = 8500
        } else if (maxHitsD > 8500 && maxHitsD <= 9000) {
            maxY = 9000
        } else if (maxHitsD > 9000 && maxHitsD <= 9500) {
            maxY = 9500
        } else if (maxHitsD > 9500 && maxHitsD <= 10000) {
            maxY = 10000
        } else if (maxHitsD > 10000) {
            let maxHits1 = parseInt(maxHitsD / 1000);
            // let maxHits2 = parseInt(maxHitsD % 1000);

            maxHitsD = parseInt(String(maxHits1) + '000');
            maxY = maxHitsD + 2000
            stepSize = 5;
        }

        maxTicksLimit = 6;
        stepSize = parseInt(maxY / (maxTicksLimit - 1));
    }

    // console.log('maxHitsD', maxHitsD);
    // console.log('maxY', maxY);
    // console.log('stepSize', stepSize);

    let gradient = ctx.createLinearGradient(0, 0, 0, 80);
    gradient.addColorStop(0, 'rgba(82, 165, 107, 0.5)');
    gradient.addColorStop(1, 'rgba(82, 165, 107, 0.1)');

    let data = {
        labels: chartLabels,
        datasets: [{
            label: chartLabel,
            data: chartData,
            ticks: [],
            fill: 'start',
            borderWidth: (typeChart === 'bar' ? 0 : 1),
            borderColor: (typeChart === 'bar' ? 'rgba(82, 165, 107, 0.5)' : 'rgba(82, 165, 107, 1)'),
            backgroundColor: (typeChart === 'bar' ? 'rgba(82, 165, 107, 1)' : gradient),
            tension: 0.4,
            pointRadius: 0,
            // barThickness: 30,
        }]
    };

    let config = {
        type: typeChart,
        data: data,
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                filler: {
                    propagate: false,
                },
                title: {
                    display: false,
                    text: (ctx) => 'Fill: ' + ctx.chart.data.datasets[0].fill
                },
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: true,
                    multiKeyBackground: 'rgba(82, 165, 107, 1)',
                    // boxWidth: 0,
                    boxPadding: 0,
                    borderWidth: 0
                },
                hover: {
                    mode: null
                },
            },
            scales: {
                x: {
                    display: false,
                    grid: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false,
                        padding: 0,
                        color: 'rgba(31,41,59, 0.5)',
                        autoSkip: false,
                        align: 'center',
                        crossAlign: 'near',
                        // minRotation: 90,
                        // maxRotation: 0,
                        // callback: function(val, index) {
                        //     return index % 4 === 0 ? this.getLabelForValue(val) : '';
                        // },
                    },
                },
                y: {
                    display: false,
                    grid: {
                        display: true,
                        drawBorder: false,
                        color: '#E0E0E0',
                    },
                    ticks: {
                        display: false,
                        padding: 0,
                        stepSize: stepSize,
                    },
                    min: minY,
                    max: maxY,
                    beginAtZero: true,
                },
            },
            interaction: {
                intersect: false,
            },
            padding: 0,
        },
    };

    if (chartSize === 'normal') {
        data.datasets[0].borderWidth = (typeChart === 'bar' ? '0' : '3');

        config.options.scales = {
            x: {
                display: true,
                grid: {
                    display: false,
                    drawBorder: false,
                    offset: false,
                },
                border: {
                    width: 1,
                    color: '#E0E0E0',
                },
                ticks: {
                    display: (typeChart !== 'bar'),
                    padding: 0,
                    color: 'rgba(31,41,59, 0.5)',
                    autoSkip: true,
                    // autoSkipPadding: 40,
                    // includeBounds: false,
                    align: 'center',
                    crossAlign: 'near',
                    // minRotation: 45,
                    maxRotation: 0,
                    maxTicksLimit: maxChartLabels,
                    // mirror: true,
                    // callback: function(val, index) {
                    //     return index % stepChartLabels === 0 ? this.getLabelForValue(val) : '';
                    // },
                    // callback: function(val, index) {
                    //     if (index === 0 || index === (chartLabels.length - 1)) {
                    //         return this.getLabelForValue(val);
                    //     }
                    //
                    //     if (index < 5 || (chartLabels.length - 1) - index < 4) {
                    //         return '';
                    //     }
                    //
                    //     if (index % stepChartLabels === 0) {
                    //         return this.getLabelForValue(val);
                    //     }
                    //
                    //     return '';
                    // },
                },
            },
            y: {
                display: true,
                grid: {
                    display: true,
                    drawBorder: false,
                    color: '#E0E0E0',
                    offset: false,
                    tickLength: 6,
                    tickWidth: 0,
                },
                border: {
                    width: 1,
                    color: '#E0E0E0',
                    dash: [4, 3],
                    // dashOffset: 0.8,
                    // z: -1,
                },
                ticks: {
                    display: true,
                    padding: 0,
                    color: 'rgba(31,41,59, 0.5)',
                    stepSize: stepSize,
                    // autoSkip: true,
                    maxTicksLimit: maxTicksLimit
                },
                min: minY,
                max: maxY,
                beginAtZero: true
            },
        }
    }

    return config;
}

export const formatNumber = function (value) {
    let txt = parseInt(value).toLocaleString().toString();

    return _.replace(txt, /,/g, ' ');
}
export const formatFloatNumber = function (value, fixed = 2) {
    return parseFloat(parseFloat(value).toFixed(fixed)).toLocaleString().toString();
    // return parseFloat((Math.round(value * 100) / 100).toFixed(fixed)).toLocaleString().toString();
}

export const formatTimeleft = function (distance, translations) {
    if (distance <= 0) {
        return translations.expired;
    }

    var days = Math.floor(distance / (60 * 60 * 24));
    var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
    var minutes = Math.floor((distance % (60 * 60)) / (60));

    var expire_text = "";
    if (days > 1) expire_text += days + ' ' + translations.days + ' ';
    else if (days > 0) expire_text += days + ' ' + translations.day + ' ';

    if (hours > 1) expire_text += hours + ' ' + translations.hours + ' ';
    else if (hours > 0) expire_text += hours + ' ' + translations.hour + ' ';

    if (days === 0 && minutes > 1) expire_text += minutes + ' ' + translations.minutes + ' ';
    else if (days === 0 && minutes > 0) expire_text += minutes + ' ' + translations.minute + ' ';

    return expire_text;
}

export const getUserLanguage = function () {
    let language = window.navigator ? (window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage) : 'en';

    language = language.slice(0, 2).toLowerCase();

    return language;
}

export const showInfoMessage = function (title, desc, color = 'green') {
    let $elInfoMessage = $('.info-message');

    if ($elInfoMessage.length === 0) {
        return;
    }

    let $elInfoMessageTitle = $elInfoMessage.find('.info-message__title');
    let $elInfoMessageDesc = $elInfoMessage.find('.info-message__desc');
    let $buttonClose = $elInfoMessage.find('.button-close');
    let classColor = 'info-message_color_' + color;

    $elInfoMessageTitle.html(title);
    $elInfoMessageDesc.html(desc);
    $elInfoMessage.addClass(classColor).show();

    $buttonClose.on('click', function () {
        $elInfoMessage.hide();
        $elInfoMessageTitle.html('');
        $elInfoMessageDesc.html('');
    });
}

export const showInfoMessageForm = function ($element, message, color = 'green') {
    let $elInfoMessage = $element.closest('form').find('.info-message-form');

    if ($elInfoMessage.length === 0) {
        return;
    }

    let $elInfoMessageDesc = $elInfoMessage.find('.info-message-form__desc');
    let classColor = 'info-message_color_' + color;

    $elInfoMessageDesc.html(message);
    $elInfoMessage.attr('class', 'info-message-form').addClass(classColor).show();
}

export const showInfoMessageInput = function ($element, message, color = 'green') {
    let $elInfoMessage = $element.prev('.info-message-input');

    if ($elInfoMessage.length === 0) {
        return;
    }

    let $elInfoMessageDesc = $elInfoMessage.find('.info-message-input__desc');
    let classColor = 'info-message_color_' + color;

    $elInfoMessageDesc.html(message);
    $elInfoMessage.addClass(classColor).show();
}

export const formValidationErrorHandling = function ($form, errors) {
    for (let key in errors) {
        let $input = $form.find('[name="' + key + '"]');

        if (errors[key].length <= 0) {
            continue;
        }

        let $messagesHtml = '';
        for (let i = 0; i < errors[key].length; i++) {
            $messagesHtml += errors[key][i] + '<br>';
        }

        if ($input.length > 0) {
            let invalidFeedbackId = 'input-' + key + '-error';
            let invalidFeedbackHtml = '<div id="' + invalidFeedbackId + '" class="invalid invalid-feedback">' + $messagesHtml + '</div>';

            $input.addClass('invalid');

            $('#' + invalidFeedbackId).remove();

            if ($input.prop('type') === 'checkbox') {
                $input.next('label').after(invalidFeedbackHtml);
            } else {
                $input.after(invalidFeedbackHtml);
            }
        } else {
            $form.find('.error-box').html($messagesHtml).show();
        }
    }
}

export const formValidationErrorClear = function ($form) {
    $form.find('.error-box').html('').hide();

    $form.find('input, select, textarea').each(function () {
        $(this).removeClass('invalid');

        if ($(this).attr('type') === 'checkbox') {
            $(this).next('label').next('.invalid-feedback').remove();
        } else {
            $(this).next('.invalid-feedback').remove();
        }
    });
}

export const sendGaEvent = function (event, data) {
    if (data && typeof window.dataLayer !== 'undefined') {
        // window.dataLayer.push('event', 'purchase');
        // console.log('dataLayer', dataLayer);

        // window.dataLayer.push({
        //     "event": event,
        //     data
        // });

        window.gtag('event', event, data);
        // console.log('dataLayer', window.dataLayer);
    }
}

export const getGaClientId = function () {
    let cookie = {};

    document.cookie.split(';').forEach(function(el) {
        let splitCookie = el.split('=');
        let key = splitCookie[0].trim();
        cookie[key] = splitCookie[1];
    });

    return cookie["_ga"] ? cookie["_ga"].substring(6) : '';
}

export const getKeyByValue = function (object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

export const initTooltips = function () {
    let deviceType = $('[data-device-type]').data('device-type');
    let tooltipTrigger = (deviceType === 'phone' || deviceType === 'tablet' ? 'hover' : 'hover focus');
    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
        let tpl = $(tooltipTriggerEl).data('tooltip-tpl');
        let toBody = $(tooltipTriggerEl).data('tooltip-body');
        let placement = $(tooltipTriggerEl).data('tooltip-placement');
        let template = '<div class="tooltip ' + tpl + '" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'

        return new bootstrap.Tooltip(tooltipTriggerEl, {
            container: (toBody ? 'body' : tooltipTriggerEl),
            // container: 'body',
            placement: (placement ? placement : 'auto'),
            trigger: tooltipTrigger,
            // trigger: 'click',
            // boundary: 'window',
            // offset: [0, 0],
            template: template
        });
    });
}

export const capitalizeFirstLetter = function (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}