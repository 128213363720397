<template>
  <span class="v-number-diff" :class="mainClass">
    {{content}}
  </span>
</template>

<script setup>

import {computed} from "vue";
import {shortNumber} from "../../utils/utils";

const props = defineProps({
  value: {},
  isShortNumber: {type:Boolean, default:true,},
  showZero: {type:Boolean, default: false},
});

const content = computed(() => {
  if(props.value === 0 && !props.showZero) return '';
  let value = props.isShortNumber ? shortNumber(props.value) : props.value;
  let sign = props.value > 0 ? '+' : '';
  return sign + value;
});

const mainClass = computed(() => {
  if(props.value === 0){
    return 'is-zero';
  }else if(props.value > 0){
    return 'is-positive';
  }else if(props.value < 0){
    return 'is-negative';
  }
});

</script>

<style lang="scss" scoped>
.v-number-diff{
  &.is-zero{}
  &.is-positive{
    color:$primary-green-100;
  }
  &.is-negative{
    color:$optional-red-100;
  }
}
</style>