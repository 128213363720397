export const popperInstancesModule = {
    namespaced: true,
    state: {
        list: []
    },
    getters: {
        get(state){
            return state.list;
        }
    },
    mutations:{
        add(state, popperInstance){
            state.list.push(popperInstance);
        }
    },
}