export const resizeObserverModule = {
    namespaced: true,
    state: {
        observer: new ResizeObserver(entries => {
            for(let entry of entries){
                entry.target.observeUpdateFunc();
            }
        })
    },
    getters: {
        get(state){
            return state.observer;
        }
    }
}