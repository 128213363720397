<template>
  <v-modal ref="modalConfirm" max-width="324px">
    <div class="v-modal-confirm vmc">
      <div class="vmc__title">
        <slot name="title"/>
      </div>
      <div class="vmc__description">
        <slot name="description"/>
      </div>
      <v-form-message-error :message-error="errorMessage"/>
        <slot name="group-buttons" :loading="loading" :apply="apply" :cancel="modalConfirm.closeModal">
          <div class="vmc__group-buttons">
            <div class="vmc__button-okey">
              <v-button :color="buttonOkeyColor" @click="apply" :loading="loading" class="vmc__button-okey-btn">{{buttonOkeyName}}</v-button>
            </div>
            <div class="vmc__button-cancel">
              <v-button :color="buttonCancelColor" @click="modalConfirm.closeModal()">{{buttonCancelName}}</v-button>
            </div>
          </div>
        </slot>
    </div>
  </v-modal>
</template>

<script setup lang="ts">
import VModal from "./VModal.vue";
import VButton from "./VButton.vue";
import {ref} from "vue";
import VFormMessageError from "../VForm/VFormMessageError.vue";

const modalConfirm = ref();

const props = defineProps({
  buttonOkeyName: {default:'Delete'},
  buttonOkeyColor: {default:'red'},
  buttonCancelName: {default:'Cancel'},
  buttonCancelColor: {default:'link'},
});

const emit = defineEmits(['confirm']);
const loading = ref(false);
const isError = ref(false);
const errorMessage = ref('');

const confirm = () => {
  isError.value = false;
  errorMessage.value = '';
  modalConfirm.value.openModal();
}

const apply = () => {
  loading.value = true;
  emit('confirm');
}

const closeWithSuccess = () => {
  loading.value = false;
  isError.value = false;
  errorMessage.value = '';
  modalConfirm.value.closeModal();
}

const closeWithError = (message) => {
  loading.value = false;
  isError.value = true;
  errorMessage.value = message;
  //modalConfirm.value.closeModal();
}

defineExpose({confirm,closeWithError,closeWithSuccess});

</script>

<style scoped>
.v-modal-confirm.vmc{
  width:100%;

  .vmc__title{
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    margin-bottom:20px;
    word-wrap: break-word;
    white-space: normal;
  }
  .vmc__description{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    margin-bottom:20px;
    word-wrap: break-word;
    white-space: normal;
  }
  .vmc__group-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;

    .vmc__button-okey{
      .vmc__button-okey-btn{
        min-width:85px;
        height:40px;
      }
    }
    .vmc__button-cancel{}
  }
}
</style>