<template>
  <div class="vfss__item" :class="mainClasses">
    <span class="vfss__title">
      <template v-if="titleHead!==null">
        {{titleHead}}:
      </template>
      {{option.name}}
    </span>
    <span class="vfss__close" @click="removeOption">
        <v-icon-svg name="close-icon-blue2" class="vfss__close-svg"/>
      </span>
  </div>
</template>

<script setup>

import VIconSvg from "./VIconSvg.vue";
import {computed, defineProps} from "vue";

const props = defineProps({
  /**
   * @type {'on-white-bg'|'on-gray-bg'}
   */
  skin: {default: 'on-white-bg'},
  titleHead: {default:null},
});

const option = defineModel();

const emit = defineEmits(['remove']);

const removeOption = () => {
  emit('remove');
}

const mainClasses = computed(() => {
  return 'vfs--skin-'+props.skin;
});

</script>

<style scoped>
.vfss__item{
  background-color: #F4F9FD;
  padding:5px 10px;
  display: flex;
  justify-content: space-between;
  align-items:center;
  gap:8px;
  border-radius: 35px;
  max-width:100%;

  .vfss__title{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color:#0D4E8F;
    max-width:100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .vfss__close{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items:center;
    width:16px;
    height:16px;
    flex-shrink: 0;
    .vfss__close-svg{
    }
  }

  &.vfs--skin-on-gray-bg{
    background-color:transparent;
    padding:4px 10px;
    border: 1px solid #1A73E84D;
    transition-duration:0.2s;
    cursor:default;
    .vfss__title{
      color:#596C8F;
    }
    .vfss__close-svg{
      fill:#798CAE;
      transition-duration:0.2s;
    }
    &:hover{
      border: 1px solid #1C7EE0;
      .vfss__close-svg{
        fill:#1C7EE0;
      }
    }
  }

}
</style>