<template>
  <div class="lb-add-backlinks-success">
    <div class="title">
      Thank you!
    </div>
    <div class="comment">
      We have created {{addedOrdersId.length}} new link-building orders.<br>
      We will notify you once they are delivered.
    </div>
    <v-lb-table-added-orders :orders="orders"/>
    <v-button color="blue2-stroke" class="button" @click="emit('close')">Close</v-button>
  </div>
</template>

<script setup>

import VButton from "../Base/VButton.vue";
import VLbTableAddedOrders from "../Base/LinkBuilding/VLbTableAddedOrders.vue";
import {computed} from "vue";
import {useLinkBuildOrder} from "../../stores/useLinkBuilding";

const props = defineProps({
  projectUniquekey: {},
  addedOrdersId: {type:Array,default:[],},
  addedAutoRenewalsId: {type:Array,default:[],},
});

const emit = defineEmits(['close']);

const isAutoRenewal = computed(() => {
  return props.addedAutoRenewalsId.length > 0;
});

const orders = computed(() => {
  return props.addedOrdersId?.map((orderId) => {
    return {
      id: orderId,
      type: useLinkBuildOrder(props.projectUniquekey, orderId).typeConstant,
      isAutoRenewal: isAutoRenewal.value,
    }
  }) ?? [];
});

</script>

<style lang="scss" scoped>
.lb-add-backlinks-success{
  @include flex(column,start,stretch,24px);

  .title{
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color:#384967;
  }

  .comment{
    font-size: 14px;
    line-height: 18px;
    color:#596C8F;
  }

  .button{
    align-self:flex-end;
    height:40px;
    padding:0px 20px;
  }
}
</style>