<template>
  <div class="lb-keyword-difficulty">
    <div class="left">
      <v-lb-difficulty-chart :value="backlinksRecommendations.keyword_difficulty"/>
      <div class="info">
        <div class="info-title">
          Keyword difficulty
          <span
              v-text="backlinksRecommendations.keywordDifficultyLabel"
              :style="{color:backlinksRecommendations.color,}">
          </span>
        </div>
        <div class="info-subtitle">
          We recommend you to buy ±
          <v-underlined-text ref="refCountDomains" cursor-default @mouseenter="refTooltip.show()" @mouseleave="refTooltip.hide();">
            {{backlinksRecommendations.count_domains}}
            {{pluralize('backlink', backlinksRecommendations.count_domains)}}
          </v-underlined-text>
          to rank in the Top 10
        </div>
      </div>
    </div>
    <div class="right" v-if="false">
      <v-link href="#" class="link">See other keywords</v-link>
    </div>
    <v-tooltip
        placement="bottom-start"
        ref="refTooltip"
        :style="{width:'100%', maxWidth:'340px', borderRadius:'4px', padding:'0px',overflow:'hidden',}"
        :btn-close-on="false"
        class="v-tooltip-period vtp"
        :get-linked-block="() => refCountDomains.$el"
        @eventShow="() => {}"
        :has-arrow="false"
        background="#F7FAFF"
        :hide-click-outside="false"
    >
      <template v-slot:content>
        <v-lb-recommendations-tooltip :project-uniquekey="projectUniquekey"/>
      </template>
    </v-tooltip>
  </div>
</template>

<script setup>

import VLink from "../Base/VLink.vue";
import VLbDifficultyChart from "../Base/LinkBuilding/VLbDifficultyChart.vue";
import {useBacklinksRecommendations} from "../../stores/useLinkBuilding";
import VUnderlinedText from "../Base/VUnderlinedText.vue";
import {pluralize} from "../../utils/utils";
import {ref} from "vue";
import VTooltip from "../Base/VTooltip.vue";
import VLbRecommendationsTooltip from "../Base/LinkBuilding/VLbRecommendationsTooltip.vue";

const props = defineProps({
  projectUniquekey: {},
});

const refCountDomains = ref();
const refTooltip = ref();

const backlinksRecommendations = useBacklinksRecommendations(props.projectUniquekey);

</script>

<style lang="scss" scoped>
.lb-keyword-difficulty{
  background:#F7FAFF;
  height:90px;
  padding:0px 30px;

  @include flex(row,between,center);
  .left{
    @include flex(row,start,center,23px);
  }
  .right{
    @include flex(row,end,center);
  }
  .info{
    @include flex(column,center,start,2px);
    .info-title{
      font-size:16px;
      font-weight:600;
    }
    .info-subtitle{
      color:#596C8F;
    }
    .info-status{
      text-transform:uppercase;
    }
  }
}
</style>