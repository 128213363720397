<template>
  <div class="bs-preview">
    <div class="left-side">

      <div class="meta-info">
        <div class="date">
          Started: {{formatDate(useSubscription.createdDate)}}
        </div>
        <div class="uniquekey">
          {{useSubscription.id}}
        </div>
      </div>

      <v-bs-packages class="packages">
        <template v-for="product in useSubscription.products">
          <v-bs-package :size="'+'+shortNumber(product.quantity)" :name="product.name"/>
        </template>
      </v-bs-packages>

      <div class="total-info">
        Monthly Payment: ${{useSubscription.amount}}
      </div>

      <v-button
          tag="a"
          :href="useRoutes().url('subscription', {id: useSubscription.id})"
          class="button-settings"
          color="blue2-stroke"
          text="Settings"
      />

    </div>
    <div class="right-side" v-if="useSubscription.nextBonuses?.length > 0">

      <div class="promo-title">
        <v-icon-present/>
        <span v-auto-tooltip data-name="next-payment-bonus">Next Payment Bonus</span>
      </div>

      <v-bs-packages class="promo-packages">
        <v-bs-package
            v-for="bonus in useSubscription.nextBonuses"
            :size="'+'+shortNumber(bonus.quantity)"
            :name="bonus.name"
        />
      </v-bs-packages>

    </div>
  </div>
</template>

<script setup>
import VButton from "../Base/VButton.vue";
import VBsPackages from "../Base/BillingSubscriptions/VBsPackages.vue";
import VIconPresent from "../Base/VIconPresent.vue";
import {useBillingSubscription} from "../../stores/useBillingSubscriptions";
import {formatDate, shortNumber} from "../../utils/utils";
import VBsPackage from "../Base/BillingSubscriptions/VBsPackage.vue";
import {useRoutes} from "../../stores/useRoutes";

const props = defineProps({
  id: {},
});

const useSubscription = useBillingSubscription(props.id);

</script>

<style lang="scss" scoped>
.bs-preview{
  @include flex(row,between,stretch);
  box-shadow: 0px 0px 12px 0px #1C7EE02E;
  border-radius: 8px;

  @media(max-width: 767px){
    @include flex(column,start,stretch);
    width:100%;
  }

  .left-side{
    @include flex(column,start,stretch,12px);
    width:100%;
    padding:20px;
    border-radius: 8px 0px 0px 8px;
    background:#fff;
    
    @media (max-width: 767px) {
      padding:20px 16px;
      border-radius:8px 8px 0px 0px;
    }
    
  }

  .right-side{
    @include flex(column,start,stretch,16px);
    width:320px;
    flex-shrink:0;
    color:#16731A;
    padding:20px;
    border-radius:0px 8px 8px 0px;
    background:#EEF8EE;

    @media (max-width: 767px) {
      padding:20px 16px;
      border-radius:0px 0px 8px 8px;
    }

  }

  .meta-info{
    @include flex(row,start,center,14px);
    font-size: 12px;
    color:#798CAE;
  }

  .total-info{
    color:#798CAE;
  }

  .packages{
    color:#384967;
  }

  .promo-title{
    @include flex(row,start,center,11px);
    span{
      @include flex(row,start,center,6px);
    }
  }

  .button-settings{
    align-self:start;
    height:36px;
    padding:0px 12px;
  }

}
</style>